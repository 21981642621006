<template>
  <img ref="tag" />
</template>

<script>
import Barcode from 'jsbarcode'

export default {
  props: {
    value: {
      type: [Number, String],
      required: true,
    },
  },
  mounted() {
    Barcode(this.$refs.tag, this.value, {
      format: 'code39',
      displayValue: false,
      margin: 0,
      height: 20,
      width: 1,
    })
  },
}
</script>
