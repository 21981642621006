<template>
  <div>
    <BaseHeader class="pb-6" type="default">
      <b-row align-v="center" class="py-4">
        <b-col lg="12" cols="7">
          <h1 class="h2 text-white d-inline-block mb-0">
            {{ name }}
          </h1>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </BaseHeader>
    <PageForm :on-success="save" />
  </div>
</template>

<script>
import { RouteBreadcrumb, BaseHeader } from '@coop-zone/coop-zone-argon'
import { mapGetters } from 'vuex'
import PageForm from '@/components/LandingPage/PageForm'

export default {
  components: {
    BaseHeader,
    PageForm,
    RouteBreadcrumb,
  },
  computed: {
    ...mapGetters('landingPages', {
      name: 'getName',
    }),
  },
  async created() {
    await this.$store.dispatch('landingPages/fetch', this.$route.params.id)
  },
  methods: {
    async save() {
      await this.$store.dispatch('landingPages/update')

      this.$notify({
        message: 'Page enregistrée',
        timeout: 5000,
        icon: 'ni ni-check-bold',
        type: 'success',
      })
    },
  },
}
</script>
