<template>
  <BaseButton icon :type="type" size="sm" @click="handleClick">
    <span v-if="icon" class="btn-inner--icon"><i :class="`${icon}`" /></span>
    <span class="btn-inner--text"><slot /></span>
  </BaseButton>
</template>
<script>
import swal from 'sweetalert2'
import { BaseButton } from '@coop-zone/coop-zone-argon'

export default {
  components: {
    BaseButton,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: null,
    },
    skipConfirm: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async handleClick() {
      if (this.skipConfirm) {
        this.$emit('click')
        return
      }

      const { isConfirmed } = await swal.fire({
        html: '<p>Si vous avez effectué des modifications, elles ne seront pas sauvegardées. Voulez-vous continuer ?</p>',
        confirmButtonText: 'Confirmer',
        cancelButtonText: 'Annuler',
        showCancelButton: true,
      })

      if (isConfirmed) {
        this.$emit('click')
      }
    },
  },
}
</script>
