<i18n>
  {
    "fr": {
      "CreateSuccess": "Le transporteur a bien été créée"
    }
  }
</i18n>
<template>
  <div>
    <BaseHeader class="pb-6" type="default">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">
          <h1 class="h2 text-white d-inline-block mb-0">
            Ajouter un transporteur
          </h1>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </BaseHeader>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col>
          <TransporterForm
            title="Transporteur"
            :on-success="save"
            redirect="/shipping/transporters/index"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { RouteBreadcrumb, BaseHeader } from '@coop-zone/coop-zone-argon'
import TransporterForm from '@/components/Shipping/Transporters/TransporterForm'

export default {
  components: {
    TransporterForm,
    RouteBreadcrumb,
    BaseHeader,
  },
  created() {
    this.$store.commit('shipping/transporters/SET_TRANSPORTER', {})
  },
  methods: {
    async save() {
      await this.$store.dispatch('shipping/transporters/createTransporter')
      this.$notify({
        message: this.$t('CreateSuccess'),
        timeout: 5000,
        icon: 'ni ni-check-bold',
        type: 'success',
      })
    },
  },
}
</script>
