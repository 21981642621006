<template>
  <b-card no-body>
    <b-card-header class="border-0 bg-transparent">
      <b-row>
        <b-col cols="6">
          <h2 class="mb-0">
            {{ title }}
          </h2>
        </b-col>
        <b-col
          v-if="Object.keys($listeners).includes('on-add')"
          cols="6"
          class="text-right"
        >
          <el-tooltip content="Ajouter une ligne" placement="top">
            <BaseButton type="primary" icon size="sm" @click.prevent="add">
              <span class="btn-inner--icon"
                ><i class="fas fa-plus-circle"
              /></span>
              <span class="btn-inner--text">Ajouter</span>
            </BaseButton>
          </el-tooltip>
        </b-col>
      </b-row>
    </b-card-header>
    <el-table-draggable v-if="draggable" handle=".handle">
      <el-table
        ref="table"
        class="table-responsive align-items-center table-flush table-striped"
        header-row-class-name="thead-light"
        :data="value"
      >
        <slot :remove="confirmRemove" />
      </el-table>
    </el-table-draggable>
    <el-table
      v-else
      ref="table"
      class="table-responsive align-items-center table-flush table-striped"
      header-row-class-name="thead-light"
      :data="value"
    >
      <slot :remove="confirmRemove" />
    </el-table>

    <b-card-footer class="border-0 bg-transparent">
      <b-row v-if="Object.keys($listeners).includes('on-add')">
        <b-col cols="12" class="text-right">
          <el-tooltip content="Ajouter une ligne" placement="top">
            <BaseButton type="primary" icon size="sm" @click.prevent="add">
              <span class="btn-inner--icon"
                ><i class="fas fa-plus-circle"
              /></span>
              <span class="btn-inner--text">Ajouter</span>
            </BaseButton>
          </el-tooltip>
        </b-col>
      </b-row>
    </b-card-footer>
  </b-card>
</template>

<script>
import { BaseButton } from '@coop-zone/coop-zone-argon'
import ElTableDraggable from 'element-ui-el-table-draggable'
export default {
  components: {
    BaseButton,
    ElTableDraggable,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    draggable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    confirmRemove(item) {
      this.remove(item)
    },
    remove(item) {
      if (!Object.keys(this.$listeners).includes('on-delete')) {
        const index = this.value.indexOf(item)
        if (index !== -1) {
          const array = [...this.value]
          array.splice(index, 1)
          this.$emit('input', array)
        }
      } else {
        this.$emit('on-delete', item)
      }
    },
    add() {
      if (!Object.keys(this.$listeners).includes('on-add')) {
        this.$emit('input', [
          ...this.value,
          {
            key: '',
            value: '',
          },
        ])
      } else {
        this.$emit('on-add')
      }
    },
  },
}
</script>
<style>
.cell .form-group {
  margin-bottom: 0;
}
</style>
