<template>
  <div
    class="opacity-transition"
    :class="{ editing: categoryItemsAreBeingEdited(category) }"
  >
    <BaseForm :on-success="save">
      <slot name="header" />

      <p class="h4 mb-4">Sélection d'articles</p>
      <AddItemToCategory :category="category" />

      <slot name="footer" />

      <b-row>
        <b-col>
          <BaseButton type="default" native-type="submit" size="lg">
            <span class="btn-inner--text">Enregistrer</span>
          </BaseButton>
          <BaseButton
            v-confirm-delete="{
              onConfirm: () => cancelEdit(),
              confirmText: 'Êtes-vous certain de vouloir annuler?',
              successText: `Action annulée`,
            }"
            type="secondary"
            size="lg"
          >
            <span class="btn-inner--text">Annuler</span>
          </BaseButton>
        </b-col>
      </b-row>
    </BaseForm>
  </div>
</template>

<script>
import { BaseButton } from '@coop-zone/coop-zone-argon'
import { mapGetters } from 'vuex'
import BaseForm from '@/components/forms/BaseForm'
import AddItemToCategory from '@/components/SchoolLists/Categories/AddItemToCategory'

export default {
  components: {
    BaseButton,
    BaseForm,
    AddItemToCategory,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('schoolLists/categories', [
      'categoryItemsAreBeingEdited',
      'categoryIsNew',
    ]),
  },
  methods: {
    async save() {
      try {
        this.$emit('saving', true)
        await this.$store.dispatch('schoolLists/categories/saveCategory', {
          category: this.category,
          establishmentId: this.$route.params.establishmentId,
          listId: this.$route.params.id,
        })
        this.$notify({
          message: 'Sauvegarde confirmée',
          timeout: 5000,
          icon: 'ni ni-check-bold',
          type: 'success',
        })
      } finally {
        this.$emit('saving', false)
      }
    },
    submitCategory() {
      this.$refs.categoryForm.submit()
    },
    cancelEdit() {
      if (this.categoryIsNew(this.category)) {
        this.$store.dispatch(
          'schoolLists/categories/removeNewCategory',
          this.category
        )
      } else {
        this.$store.dispatch('schoolLists/categories/cancelCategoryEdit', {
          category: this.category,
          establishmentId: this.$route.params.establishmentId,
        })
      }
    },
  },
}
</script>

<style lang="sass" scoped>
::v-deep .form-group
  margin-bottom: 0;
</style>

<style lang="sass">
.opacity-transition
  position: relative
  display: block

  &::before
    background-color: rgba(255, 255, 255, 0)
    content: ''
    display: block
    height: 100%
    left: 0
    pointer-events: none
    position: absolute
    top: 0
    transition: all 0.2s ease-in-out
    width: 100%
    z-index: -1

  &.editing
    &::before
      background-color: rgba(255, 255, 255, 0.8)
      pointer-events: auto
      z-index: 100

.handle
  cursor: move
</style>
