<template>
  <router-view v-if="loaded" />
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      loaded: false,
    }
  },
  computed: {
    ...mapState('accounts', ['token']),
  },
  async created() {
    if (!this.token) {
      try {
        await this.$store.dispatch('accounts/refreshToken')
        await this.$store.dispatch('accounts/me')
      } catch (e) {
        if (this.$route.name !== 'login') {
          this.$router.push({ name: 'login' })
        }
      }
      this.loaded = true
    }
  },
}
</script>

<style lang="sass">
html
  height: 100%

body
  display: flex
  flex-direction: column
  height: 100%

.el-table .cell
  word-break: break-word
  white-space: nowrap
</style>
