<template>
  <div class="content">
    <base-header class="pb-6 bg-default">
      <b-row align-v="center" class="py-4">
        <b-col>
          <h2 class="h2 text-white d-inline-block mb-0">Commandes</h2>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </base-header>

    <b-container v-if="refund.id" fluid class="mt--6">
      <b-row>
        <b-col>
          <OrderRefundForm
            title="Remboursement"
            :on-success="save"
            redirect="/orders/refunds"
          />
        </b-col>
      </b-row>

      <b-row v-if="refund.items && refund.items.length">
        <b-col>
          <b-card>
            <div slot="header">
              <b-row align-v="center">
                <b-col>
                  <p class="h5 text-uppercase mb-0">
                    {{
                      refund.items.length | pluralize(['Produit', 'Produits'])
                    }}
                    en lien avec ce remboursement
                  </p>
                </b-col>
              </b-row>
            </div>

            <div
              v-for="product in getBundleGroupedProducts(refund.items)"
              :key="product.id"
              class="order-product"
            >
              <OrderItem class="order-product-card" :item="product" />

              <template v-if="product.replacements">
                <OrderItem
                  v-for="replacement in product.replacements"
                  :key="replacement.id"
                  class="order-product-card"
                  :item="replacement"
                  :replacement="true"
                />
              </template>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import {
  BaseButton,
  BaseHeader,
  RouteBreadcrumb,
} from '@coop-zone/coop-zone-argon'
import ErrorHandlerMixin from '@/mixins/errorHandler.mixin'
import OrderItem from '@/components/Orders/OrderItem'
import OrderRefundForm from '@/components/Orders/OrderRefundForm'

export default {
  components: {
    BaseHeader,
    RouteBreadcrumb,
    OrderItem,
    OrderRefundForm,
  },
  mixins: [ErrorHandlerMixin],
  computed: {
    ...mapState('orders/refunds', ['refund']),
    ...mapGetters('orders', ['getBundleGroupedProducts']),
  },
  async created() {
    await this.getRefund(this.$route.params.id)
  },
  methods: {
    ...mapActions('orders/refunds', ['getRefund']),
    async save() {
      await this.$store.dispatch('orders/refunds/updateRefund')
      await this.getRefund(this.$route.params.id)
    },
  },
}
</script>
