import { apolloClient } from '@/apollo'

const searchRefundsQuery = require('@/apollo/queries/SearchRefunds.gql')
const getRefundQuery = require('@/apollo/queries/GetRefund.gql')
const updateRefundMutation = require('@/apollo/mutations/UpdateRefund.gql')

const state = {
  refund: {},
  refunds: [],
  batchActionIds: [],
  pagination: {
    currentPage: 1,
    numberOfPages: 0,
    resultsPerPage: 100,
    totalCount: 0,
  },
}

const actions = {
  async searchRefunds({ commit }, payload) {
    const { statuses } = payload
    if (statuses) {
      delete payload.statuses
      payload.statuses = [statuses]
    }

    const {
      data: {
        refunds: { refunds, pageInfo: pagination },
      },
    } = await apolloClient.query({
      query: searchRefundsQuery,
      variables: payload,
    })

    commit('SET_PAGINATION', pagination)
    commit('SET_REFUNDS', refunds)
  },

  async getRefund({ commit }, id) {
    const {
      data: { refund },
    } = await apolloClient.query({
      query: getRefundQuery,
      variables: { id },
    })

    commit('SET_REFUND', refund)
  },

  async updateRefund({ state, commit }) {
    const { id, items, OrderId, ...refund } = state.refund

    await apolloClient.query({
      query: updateRefundMutation,
      variables: {
        id,
        refund,
      },
    })
  },
}
const mutations = {
  SET_REFUND(state, payload) {
    state.refund = payload
  },

  SET_REFUNDS(state, payload) {
    state.refunds = payload
  },

  SET_STATUS(state, payload) {
    state.refund.status = payload
  },

  SET_PAGINATION(state, payload) {
    state.pagination = payload
  },

  SET_BATCH_ACTION_IDS(state, payload) {
    state.batchActionIds = payload
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
