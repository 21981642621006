<i18n>
  {
    "fr": {
      "CreateSuccess": "La boîte de réception a bien été créée"
    }
  }
</i18n>
<template>
  <div>
    <BaseHeader class="pb-6" type="default">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">
          <h1 class="h2 text-white d-inline-block mb-0">
            Ajouter une boîte de réception
          </h1>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </BaseHeader>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col>
          <ReceptionBoxForm
            title="Boîte de réception"
            :on-success="save"
            redirect="/reception-boxes/index"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { RouteBreadcrumb, BaseHeader } from '@coop-zone/coop-zone-argon'
import ReceptionBoxForm from '@/components/Orders/ReceptionBoxForm'

export default {
  components: {
    ReceptionBoxForm,
    RouteBreadcrumb,
    BaseHeader,
  },
  created() {
    this.$store.commit('receptionBoxes/SET_RECEPTION_BOX', {})
  },
  methods: {
    async save() {
      await this.$store.dispatch('receptionBoxes/createReceptionBox')
    },
  },
}
</script>
