<template>
  <BasePageBlock v-bind="$props">
    <b-row v-if="showTitle">
      <b-col>
        <b-row>
          <b-col>
            <h3 class="heading-small text-muted">Titre du bloc</h3>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <BaseInput
              v-model="blocks[blockIndex].title"
              label="Texte du titre"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-row>
          <b-col>
            <h3 class="heading-small text-muted">Zone de contenu</h3>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <HtmlEditor
              v-model="blocks[blockIndex].text"
              :toolbar-options="[
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ size: ['large', false] }],
                ['link'],
                ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ script: 'sub' }, { script: 'super' }], // outdent/indent
                ['image', 'video'],
                ['clean'],
              ]"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </BasePageBlock>
</template>

<script>
import BasePageBlock from './BasePageBlock'
import { BaseInput, HtmlEditor } from '@coop-zone/coop-zone-argon'
import blockMixin from '@/components/Blocks/mixins/block.mixin'

export default {
  components: {
    BasePageBlock,
    BaseInput,
    HtmlEditor,
  },
  mixins: [blockMixin],
  props: {
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
