<i18n>
{
  "fr" : {
    "DeleteSuccess": "Le transporteur a bien été supprimé"
  }
}
</i18n>

<template>
  <div class="content">
    <base-header class="pb-6 bg-default">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h2 text-white d-inline-block mb-0">Transporteurs</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-card no-body class="mb-4">
        <b-row slot="header" align-v="center">
          <b-col cols="8">
            <h3 class="mb-0">Liste des transporteurs</h3>
          </b-col>
          <b-col cols="4" class="text-right">
            <router-link
              class="btn btn-sm btn-primary"
              to="/shipping/transporters/add"
              data-cy="deliveryrule-add-link"
            >
              Ajouter un transporteur
            </router-link>
          </b-col>
        </b-row>

        <el-table
          :data="transporters"
          row-key="id"
          header-row-class-name="thead-light"
        >
          <el-table-column label="Nom du transporteur" prop="name" />

          <el-table-column min-width="180px" align="right">
            <template slot-scope="{ row }" class="table-actions">
              <router-link
                class="table-action table-action-edit"
                :to="`/shipping/transporters/edit/${row.id}`"
              >
                <i class="fa fa-edit" />
              </router-link>
              <a
                v-confirm-delete="{
                  onConfirm: () => deleteTransporter(row),
                  confirmText: 'Le transporteur sera supprimé définitivement',
                  successText: $t('DeleteSuccess'),
                }"
                href="#!"
                class="table-action table-action-delete"
                data-toggle="tooltip"
                data-original-title="Supprimer"
              >
                <i class="fas fa-trash" />
              </a>
            </template>
          </el-table-column>
        </el-table>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import { BaseHeader, RouteBreadcrumb } from '@coop-zone/coop-zone-argon'
import { mapGetters } from 'vuex'

export default {
  components: {
    BaseHeader,
    RouteBreadcrumb,
  },
  computed: {
    ...mapGetters('shipping/transporters', { transporters: 'getTransporters' }),
  },
  async created() {
    this.$store.dispatch('shipping/transporters/getAllTransporters')
  },
  methods: {
    async deleteTransporter(transporter) {
      await this.$store.dispatch(
        'shipping/transporters/deleteTransporter',
        transporter.id
      )
    },
  },
}
</script>
