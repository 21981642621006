import ProductExportsIndex from '@/views/Dashboard/Exports/ProductExportsIndex'
import CategoryExportsIndex from '@/views/Dashboard/Exports/CategoryExportsIndex'
import UserExportsIndex from '@/views/Dashboard/Exports/UserExportsIndex'
import OrderExportsIndex from '@/views/Dashboard/Exports/OrderExportsIndex'
import SchoolListExportsIndex from '@/views/Dashboard/Exports/SchoolListExportsIndex'

import BlankRouterView from '@/views/Layout/BlankRouterView'

export default {
  path: '/exports',
  name: 'Exportations',
  redirect: '/exports/products/index',
  component: BlankRouterView,
  children: [
    {
      path: '/exports/products/index',
      name: 'Liste des exportations de produits',
      component: ProductExportsIndex,
      meta: {
        roles: ['ADMIN', 'MANAGER', 'SUPER_EDITOR'],
      },
    },
    {
      path: '/exports/categories/index',
      name: 'Liste des exportations de catégories',
      component: CategoryExportsIndex,
      meta: {
        roles: ['ADMIN', 'MANAGER', 'SUPER_EDITOR'],
      },
    },
    {
      path: '/exports/users/index',
      name: "Liste des exportations d'usagers",
      component: UserExportsIndex,
      meta: {
        roles: ['ADMIN', 'MANAGER', 'SUPER_EDITOR'],
      },
    },
    {
      path: '/exports/orders/index',
      name: 'Liste des exportations de commandes',
      component: OrderExportsIndex,
      meta: {
        roles: ['ADMIN', 'MANAGER', 'SUPER_EDITOR'],
      },
    },
    {
      path: '/exports/school-lists/index',
      name: 'Liste des exportations de listes scolaires',
      component: SchoolListExportsIndex,
      meta: {
        roles: ['ADMIN', 'MANAGER', 'SUPER_EDITOR'],
      },
    },
  ],
}
