import BlankRouterView from '@/views/Layout/BlankRouterView'
import CategoriesIndex from '@/views/Dashboard/Categories/CategoriesIndex'
import CategoriesEdit from '@/views/Dashboard/Categories/CategoriesEdit'
import CategoriesAdd from '@/views/Dashboard/Categories/CategoriesAdd'

export default {
  path: '/categories',
  name: 'Catégories',
  redirect: '/categories/index',
  component: BlankRouterView,
  children: [
    {
      path: '/categories/index',
      name: 'Liste des catégories',
      component: CategoriesIndex,
      meta: {
        roles: ['ADMIN', 'MANAGER', 'SUPER_EDITOR', 'EDITOR'],
      },
    },
    {
      path: '/categories/edit/:id',
      name: 'Modifier une catégorie',
      component: CategoriesEdit,
      meta: {
        roles: ['ADMIN', 'MANAGER', 'SUPER_EDITOR', 'EDITOR'],
      },
    },
    {
      path: '/categories/add',
      name: 'Ajouter une catégorie',
      component: CategoriesAdd,
      meta: {
        roles: ['ADMIN', 'MANAGER', 'SUPER_EDITOR', 'EDITOR'],
      },
    },
  ],
}
