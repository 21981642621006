<template>
  <TableInput
    v-slot="{ remove }"
    class="vigie-rules"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot :remove="remove" />
  </TableInput>
</template>

<script>
import TableInput from '@/components/TableInput'

export default {
  components: { TableInput },
}
</script>

<style lang="sass" scoped>
.vigie-rules::v-deep .form-control
  padding: 0.625rem 0.55rem
  font-size: 0.775rem

.vigie-rules::v-deep .el-table
  margin: 0 1.5rem
  width: calc(100% - 3rem)

.vigie-rules::v-deep .el-table th,
.vigie-rules::v-deep .el-table td
  padding-left: 0.5rem !important
  padding-right: 0.5rem !important
</style>
