<template>
  <div>
    <b-row class="mt-4 mb-4">
      <b-col>
        <ProductAutocomplete @change="$emit('add', $event)" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <Draggable
          :value="value"
          handle=".handle"
          @input="$emit('input', $event)"
        >
          <el-table-draggable :animate="300" handle=".handle">
            <el-table
              :data="value"
              header-row-class-name="thead-light"
              :height="value && value.length > 0 ? '450' : null"
            >
              <el-table-column
                label="Nom du produit"
                prop="name"
                min-width="160px"
              >
                <template slot-scope="scope">
                  <b-media no-body class="align-items-center">
                    <img
                      v-if="scope.row.images && scope.row.images.length"
                      width="48px"
                      height="48px"
                      class="mr-1"
                      :src="scope.row.images[0].thumbnail"
                    />
                    <b-media-body>
                      <span class="font-weight-600 name mb-0 text-sm">{{
                        scope.row.name
                      }}</span>
                    </b-media-body>
                  </b-media>
                </template>
              </el-table-column>

              <el-table-column prop="vigieId" label="Code produit" />
              <el-table-column align="right">
                <template slot-scope="scope">
                  <div class="table-actions">
                    <span v-if="canDrag" class="table-action table-action-edit">
                      <i class="handle fas fa-arrows-alt" />
                    </span>

                    <a
                      href="#"
                      target="_self"
                      class="table-action table-action-delete"
                      @click.prevent="$emit('remove', scope.row)"
                    >
                      <i class="fa fa-trash" />
                    </a>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-table-draggable>
        </Draggable>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import Draggable from 'vuedraggable'
import ElTableDraggable from 'element-ui-el-table-draggable'
import ProductAutocomplete from '@/components/ProductAutocomplete'
export default {
  components: {
    Draggable,
    ElTableDraggable,
    ProductAutocomplete,
  },
  props: {
    canDrag: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
