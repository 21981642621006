<template>
  <div class="content">
    <base-header class="pb-6 bg-default">
      <b-row align-v="center" class="py-4">
        <b-col>
          <p class="h2 text-white d-inline-block mb-0">Points de cueillette</p>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-card no-body class="mb-4">
        <b-row slot="header" align-v="center">
          <b-col cols="4">
            <h1 class="h3 mb-0">Liste des points</h1>
          </b-col>
          <b-col class="text-right">
            <router-link
              class="btn btn-sm btn-primary"
              to="/establishments/pickingpoints/add"
              data-cy="establishment-add-link"
            >
              Ajouter un point
            </router-link>
          </b-col>
        </b-row>

        <FuseList :data="pickingPoints" :keys="['id', 'name']">
          <el-table-column label="Nom du point">
            <template slot-scope="scope">
              <router-link
                :to="`/establishments/pickingpoints/edit/${scope.row.id}`"
              >
                <span class="font-weight-600 name text-sm">{{
                  scope.row.name
                }}</span>
              </router-link>
            </template>
          </el-table-column>

          <el-table-column min-width="100px" align="right">
            <template slot-scope="scope" class="table-actions">
              <router-link
                class="table-action table-action-edit"
                :to="`/establishments/pickingpoints/edit/${scope.row.id}`"
              >
                <i class="fa fa-edit" />
              </router-link>
            </template>
          </el-table-column>
        </FuseList>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import { BaseHeader, RouteBreadcrumb } from '@coop-zone/coop-zone-argon'
import { mapState } from 'vuex'
import FuseList from '@/components/FuseList'

export default {
  components: {
    BaseHeader,
    FuseList,
    RouteBreadcrumb,
  },
  computed: {
    ...mapState('pickingpoints', ['pickingPoints']),
  },
  created() {
    this.$store.dispatch('pickingpoints/getPickingPoints')
  },
}
</script>
