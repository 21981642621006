import { apolloClient } from '@/apollo'

const treeselectCategoriesQuery = require('../apollo/queries/GetTreeselectCategories.gql')
const treeselectCategoriesByIdQuery = require('../apollo/queries/GetTreeselectCategoriesById.gql')
const taxGroupsQuery = require('../apollo/queries/GetTaxGroups.gql')
const countriesQuery = require('../apollo/queries/GetCountries.gql')
const statesQuery = require('../apollo/queries/GetStates.gql')
const ExportsQuery = require('../apollo/queries/GetExports.gql')
const ExportFileQuery = require('../apollo/queries/GetExportFile.gql')
const CreateExportationMutation = require('../apollo/mutations/CreateExportation.gql')
const exportSchoolListQuery = require('../apollo/queries/GetExportSchoolList.gql')

const state = {
  treeselectCategories: [],
  treeselectCategoriesById: [],
  taxRuleGroups: [],
}

const actions = {
  async getTreeselectCategories({ state, commit }, cached = false) {
    if (state.treeselectCategories.length === 0 || cached === false) {
      const {
        data: { categories },
      } = await apolloClient.query({
        query: treeselectCategoriesQuery,
      })

      commit('SET_TREESELECT_CATEGORIES', [...categories])
    }
  },
  async getTreeselectCategoriesById({ state, commit }, cached = false) {
    if (state.treeselectCategories.length === 0 || cached === false) {
      const {
        data: { categories },
      } = await apolloClient.query({
        query: treeselectCategoriesByIdQuery,
      })

      commit('SET_TREESELECT_CATEGORIES_BY_ID', [...categories])
    }
  },

  async getTaxRuleGroups({ commit }) {
    const {
      data: { taxGroups },
    } = await apolloClient.query({
      query: taxGroupsQuery,
    })

    commit('SET_TAX_RULE_GROUPS', [...taxGroups])
  },

  async getCountries() {
    const { data } = await apolloClient.query({
      query: countriesQuery,
    })

    return data.countries
  },

  async getStates(context, payload) {
    const { data } = await apolloClient.query({
      query: statesQuery,
      variables: payload,
    })

    return data.states
  },

  async exportSchoolList(context, { schoolList, date }) {
    const {
      data: { file },
    } = await apolloClient.query({
      query: exportSchoolListQuery,
      variables: {
        listId: schoolList.id,
        establishmentId: schoolList.EstablishmentId,
        date: date,
      },
    })
    return file
  },

  async getExportFile(context, filename) {
    const {
      data: { exportation },
    } = await apolloClient.query({
      query: ExportFileQuery,
      variables: {
        filename,
      },
    })

    return exportation
  },

  async createExportation(context, payload) {
    if (!payload.query || Object.keys(payload.query).length === 0)
      delete payload.query

    const {
      data: { exportation },
    } = await apolloClient.mutate({
      mutation: CreateExportationMutation,
      variables: payload,
    })

    return exportation
  },

  async getExportQueue(context, type) {
    const {
      data: { exports },
    } = await apolloClient.query({
      query: ExportsQuery,
      variables: {
        type,
      },
    })

    return exports
  },
}

const getters = {
  getCategoryFromTreeSelect: (state) => (slug) => {
    const slugSplit = slug.split('/')
    slugSplit.shift()
    let category = null
    let currentSlug = ''
    slugSplit.forEach((theCategorySlug, index) => {
      currentSlug += `/${theCategorySlug}`
      if (index === 0) {
        category = state.treeselectCategories.find(
          (treeSelectCategory) => treeSelectCategory.id === currentSlug
        )
      } else {
        category = category.children.find(
          (treeSelectCategory) => treeSelectCategory.id === currentSlug
        )
      }
    })

    return category
  },
}

const mutations = {
  SET_TREESELECT_CATEGORIES(state, payload) {
    state.treeselectCategories = payload
  },
  SET_TREESELECT_CATEGORIES_BY_ID(state, payload) {
    state.treeselectCategoriesById = payload
  },

  SET_TAX_RULE_GROUPS(state, payload) {
    state.taxRuleGroups = payload
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
