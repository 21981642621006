<template>
  <b-card class="mb-0 border-0 bg-transparent" no-body>
    <b-card-header>
      <b-row>
        <b-col cols="6">
          <b-row align-v="center">
            <b-col v-if="canDrag" cols="auto">
              <span class="table-action table-action-edit">
                <i class="handle fas fa-arrows-alt" />
              </span>
            </b-col>
            <b-col>
              <h3 class="h4 mb-0">
                {{ filterGroup.name }}
              </h3>
            </b-col>
          </b-row>
        </b-col>

        <b-col v-if="canAdd" cols="6" class="text-right">
          <el-tooltip content="Ajouter une ligne" placement="top">
            <BaseButton type="primary" icon size="sm" @click.prevent="add">
              <span class="btn-inner--icon"
                ><i class="fas fa-plus-circle"
              /></span>
              <span class="btn-inner--text">Ajouter une métadonnée</span>
            </BaseButton>
          </el-tooltip>
        </b-col>
      </b-row>
    </b-card-header>

    <el-table-draggable :animate="300" handle=".handle">
      <el-table
        class="table-responsive table-flush"
        :show-header="false"
        :cell-style="handleCellStyle"
        :data="filters"
      >
        <el-table-column
          v-slot="{ row }"
          align-v="top"
          label="Filtre"
          min-width="310px"
        >
          <template v-if="row.type === 'FILTER'">
            {{ row.name }}
          </template>
          <template v-else>
            <BaseInput
              v-model="row.name"
              name="Nom"
              placeholder="Nom"
              rules="required"
            />
          </template>
        </el-table-column>

        <el-table-column v-slot="{ row }" label="Valeur" min-width="310px">
          <FilterValueSelect
            v-if="row.type === 'FILTER'"
            :filter-value-object="row.FilterValue || {}"
            :filter-id="row.id"
            @input="row.FilterValue = $event"
          />

          <BaseInput v-else name="Valeur" rules="required">
            <textarea
              v-model="row.value"
              placeholder="Valeur"
              class="form-control"
              rows="1"
            />
          </BaseInput>
        </el-table-column>

        <el-table-column v-slot="{ row }" min-width="100px" align="right">
          <div class="table-actions">
            <span v-if="canDrag" class="table-action table-action-edit">
              <i class="handle fas fa-arrows-alt" />
            </span>

            <el-tooltip
              v-if="row.type !== 'FILTER'"
              content="Supprimer"
              placement="top"
            >
              <a
                href="#!"
                class="table-action table-action-delete"
                data-toggle="tooltip"
                data-original-title="Supprimer"
                @click.prevent="confirmRemove(row)"
              >
                <i class="fas fa-trash" />
              </a>
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>
    </el-table-draggable>
  </b-card>
</template>

<script>
import ElTableDraggable from 'element-ui-el-table-draggable'
import { BaseInput, BaseButton } from '@coop-zone/coop-zone-argon'
import FilterValueSelect from '@/components/Inputs/FilterValueSelect'

export default {
  components: {
    BaseInput,
    BaseButton,
    ElTableDraggable,
    FilterValueSelect,
  },
  props: {
    filterGroup: {
      type: Object,
      required: true,
    },
    filters: {
      type: Array,
      required: true,
    },
    canAdd: {
      type: Boolean,
      default: true,
    },
    canDrag: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    add() {
      this.$emit('update', [
        ...this.filters,
        { name: '', value: '', type: 'META' },
      ])
    },
    confirmRemove(item) {
      if (window.confirm('Supprimer cette ligne?')) {
        this.removeFilter(item)
        this.removeGroup()
      }
    },
    removeFilter(item) {
      const index = this.filters.indexOf(item)
      if (index !== -1) {
        const filters = [...this.filters]
        filters.splice(index, 1)
        this.$emit('update', filters)

        if (filters.length === 0) {
          this.$emit('delete')
        }
      }
    },
    handleCellStyle({ row }) {
      return row.type === 'META' ? 'vertical-align: top;' : ''
    },
  },
}
</script>

<style lang="sass" scoped>
.card
  box-shadow: none

.card-header
  background-color: var(--gray-100)
  border-radius: 0

textarea.form-control
  height: calc(1.5em + 1.25rem + 5px)
</style>
