<template>
  <div class="content">
    <base-header class="pb-6 bg-default">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="12">
          <h6 class="h2 text-white d-inline-block mb-0">Utilisateurs</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <BaseTableSearch
        :data="accounts"
        :pagination="pagination"
        :value="term"
        data-cy="search-user"
        placeholder="Nom, prénom, # membre"
        @search="search"
      >
        <template #header>
          <b-row>
            <b-col>
              <h3 class="mb-0">Rechercher un utilisateur</h3>
            </b-col>
          </b-row>
        </template>

        <template #filters>
          <b-row>
            <b-col sm="12" xl="4">
              <BaseInput label="Filtrer par type d'usager">
                <el-select v-model="memberType">
                  <el-option :value="null" label="Tous les types" />
                  <el-option
                    v-for="{ id, name } in membershipTypes"
                    :key="id"
                    :value="id"
                    :label="name"
                  />
                </el-select>
              </BaseInput>
            </b-col>

            <b-col sm="12" xl="4">
              <BaseInput label="Filtrer par actif/inactif">
                <el-select v-model="active">
                  <el-option :value="null" label="Tous" />
                  <el-option :value="true" label="Actif" />
                  <el-option :value="false" label="Inactif" />
                </el-select>
              </BaseInput>
            </b-col>

            <b-col sm="12" xl="4">
              <BaseInput label="Rôle">
                <el-select v-model="role">
                  <el-option :value="null" label="Tous" />
                  <el-option
                    v-for="optionRole in roles"
                    :key="optionRole.id"
                    :value="optionRole.id"
                    :label="optionRole.name"
                  />
                </el-select>
              </BaseInput>
            </b-col>
          </b-row>
        </template>

        <el-table-column label="Nom de l'utilisateur">
          <template slot-scope="scope">
            <router-link :to="`/users/edit/${scope.row.id}`">
              <span class="font-weight-600 name text-sm mb-0">{{
                `${scope.row.firstName} ${scope.row.lastName}`
              }}</span>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column label="Création du compte">
          <template v-if="row.createdAt" slot-scope="{ row }">
            {{ row.createdAt | dateFormat }}
          </template>
        </el-table-column>
        <el-table-column :formatter="clientNumber" label="Numéro de client" />
        <el-table-column label="Type d'usager">
          <template slot-scope="{ row }">
            {{ $t(`users.memberType.${row.memberType}`) }}
          </template>
        </el-table-column>
        <el-table-column prop="email" label="Courriel" />
        <el-table-column label="Role">
          <template slot-scope="{ row }">
            {{ getRolesBySlug(row.role) }}
          </template>
        </el-table-column>
        <el-table-column label="Actif" prop="active">
          <template slot-scope="scope">
            <span
              v-if="scope.row.active"
              class="badge text-uppercase badge-lg badge-success badge-pill"
            >
              Oui
            </span>

            <span
              v-else
              class="badge text-uppercase badge-lg badge-danger badge-pill"
            >
              Non
            </span>
          </template>
        </el-table-column>
        <el-table-column align="right">
          <template slot-scope="scope" class="table-actions">
            <router-link
              class="table-action table-action-edit"
              :to="`/users/edit/${scope.row.id}`"
            >
              <i class="fa fa-edit" />
            </router-link>
          </template>
        </el-table-column>
      </BaseTableSearch>
    </b-container>
  </div>
</template>
<script>
import { isEqual } from 'lodash'
import {
  BaseHeader,
  BaseInput,
  RouteBreadcrumb,
} from '@coop-zone/coop-zone-argon'
import { mapGetters } from 'vuex'
import BaseTableSearch from '@/components/BaseTableSearch'

export default {
  components: {
    BaseHeader,
    BaseInput,
    BaseTableSearch,
    RouteBreadcrumb,
  },
  data() {
    const {
      term = '',
      memberType = null,
      active = null,
      role = null,
      page = 1,
    } = this.$route.query

    return {
      loading: false,
      term,
      memberType,
      active,
      page,
      role,
      accounts: [],
      pagination: {
        currentPage: 1,
        numberOfPages: 0,
        resultsPerPage: 100,
        totalCount: 0,
      },
    }
  },
  computed: {
    ...mapGetters('accounts', {
      roles: 'getRoles',
      getRolesBySlug: 'getRolesBySlug',
    }),
    membershipTypes() {
      const memberTypes = this.$t('users.memberType')
      return Object.keys(memberTypes).map((key) => ({
        id: key,
        name: memberTypes[key],
      }))
    },
  },
  watch: {
    memberType() {
      this.search(this.term, 1)
    },
    active() {
      this.search(this.term, 1)
    },
    role() {
      this.search(this.term, 1)
    },
  },
  created() {
    this.search(this.term, 1)
  },
  methods: {
    clientNumber({ clientNumber }) {
      return clientNumber !== null ? clientNumber : '-'
    },
    async search(term, page = 1) {
      this.term = term
      this.page = page
      try {
        const params = {
          search: this.term,
          offset: page - 1,
          limit: this.pagination.resultsPerPage,
          memberType: this.memberType,
          active: this.active,
          role: this.role,
        }
        const data = await this.$store.dispatch('users/search', params)

        if (data) {
          this.accounts = data.accounts
          this.pagination = { ...data.pageInfo }
        } else {
          this.accounts = []
        }

        const query = {}
        if (this.term) query.term = this.term
        if (this.memberType) query.memberType = this.memberType
        if (this.active !== null) query.active = !!this.active
        if (this.role) query.role = this.role

        if (isEqual(query, this.$route.query) == false) {
          this.$router.replace({ query })
        }
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}
</style>
