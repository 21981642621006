<template>
  <div>
    <BaseHeader class="pb-6" type="default">
      <b-row align-v="center" class="py-4">
        <b-col lg="12" cols="7">
          <h1 class="h2 text-white d-inline-block mb-0">Modifier un produit</h1>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </BaseHeader>

    <ProductForm @on-success="submit" @action="action = $event" />
  </div>
</template>

<script>
import { RouteBreadcrumb, BaseHeader } from '@coop-zone/coop-zone-argon'
import { mapGetters } from 'vuex'
import ProductForm from '@/components/Product/ProductForm'

export default {
  name: 'CatalogUpdate',
  components: {
    RouteBreadcrumb,
    ProductForm,
    BaseHeader,
  },
  data() {
    return {
      action: null,
      data: {},
    }
  },
  computed: {
    ...mapGetters('products', {
      product: 'getProduct',
    }),
  },
  async created() {
    await this.$store.dispatch('products/getProduct', {
      slug: this.$route.params.slug,
    })
    this.data = { ...this.product }
  },
  methods: {
    submit() {
      this.$notify({
        message: 'Le produit a bien été enregistré',
        timeout: 5000,
        icon: 'ni ni-check-bold',
        type: 'success',
      })

      if (this.action === 'save') {
        this.$router.push('/catalog/search')
      }
    },
  },
}
</script>
