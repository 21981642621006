import Vue from 'vue'
import { apolloClient } from '@/apollo'

const getTransportersQuery = require('../../apollo/queries/GetTransporters.gql')
const getTransporterQuery = require('../../apollo/queries/GetTransporter.gql')
const updateTransporterMutation = require('../../apollo/mutations/UpdateTransporter.gql')
const createTransporterMutation = require('../../apollo/mutations/CreateTransporter.gql')
const deleteTransporterMutation = require('../../apollo/mutations/DeleteTransporter.gql')

const state = {
  transportersMap: {},
  id: null,
  name: null,
  identifiers: [],
  urlTemplate: null,
}

const actions = {
  async getAllTransporters({ commit }) {
    const {
      data: { transporters },
    } = await apolloClient.query({
      query: getTransportersQuery,
    })
    commit('SET_ALL_TRANSPORTERS', transporters)
  },

  async getTransporter({ commit }, id) {
    const {
      data: { transporter },
    } = await apolloClient.query({
      query: getTransporterQuery,
      variables: { id },
    })

    commit('SET_TRANSPORTER', transporter)
  },

  async updateTransporter({
    state: { id, name, identifiers, urlTemplate },
    commit,
  }) {
    const {
      data: { updateTransporter },
    } = await apolloClient.query({
      query: updateTransporterMutation,
      variables: {
        id,
        transporter: { name, identifiers, urlTemplate },
      },
    })
    commit('SET_TRANSPORTER', updateTransporter)
  },

  async createTransporter({
    state: { name, identifiers, urlTemplate },
    commit,
  }) {
    const {
      data: { createTransporter },
    } = await apolloClient.query({
      query: createTransporterMutation,
      variables: {
        transporter: { name, identifiers, urlTemplate },
      },
    })

    commit('SET_TRANSPORTER', createTransporter)
  },

  async deleteTransporter({ commit }, id) {
    await apolloClient.query({
      query: deleteTransporterMutation,
      variables: { id },
    })
    commit('DELETE_TRANSPORTER', id)
  },
}

const getters = {
  getTransporters: (state) => Object.values(state.transportersMap),
  getTransporterName: (state) => (transporterId) =>
    state.transportersMap[transporterId].name,
}

const mutations = {
  SET_NAME(state, payload) {
    state.name = payload
  },

  SET_IDENTIFIERS(state, payload) {
    state.identifiers = payload
  },

  SET_URL_TEMPLATE(state, payload) {
    state.urlTemplate = payload
  },

  SET_ALL_TRANSPORTERS(state, payload) {
    state.transportersMap = payload.reduce(
      (acc, transporter) => ({ ...acc, [transporter.id]: transporter }),
      {}
    )
  },

  DELETE_TRANSPORTER(state, id) {
    state.transporter = {}
    Vue.delete(state.transportersMap, id)
  },

  SET_TRANSPORTER(
    state,
    { id = null, name = null, identifiers = [], urlTemplate = null }
  ) {
    state.id = id
    state.name = name
    state.identifiers = identifiers
    state.urlTemplate = urlTemplate
    Vue.set(state.transportersMap, id, { id, name, identifiers, urlTemplate })
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
