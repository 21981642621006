import { apolloClient } from '@/apollo'

const getReceptionBoxesQuery = require('../apollo/queries/GetReceptionBoxes.gql')
const getReceptionBoxQuery = require('../apollo/queries/GetReceptionBox.gql')
const updateReceptionBoxMutation = require('../apollo/mutations/UpdateReceptionBox.gql')
const createReceptionBoxMutation = require('../apollo/mutations/CreateReceptionBox.gql')
const deleteReceptionBoxMutation = require('../apollo/mutations/DeleteReceptionBox.gql')

const state = {
  receptionBoxes: [],
  id: null,
  name: null,
  priority: null,
  key: null,
  schoolEstablishments: [],
  deletable: false,
  StoreId: null,
}

const actions = {
  async getAllReceptionBoxes({ commit }) {
    const {
      data: { receptionBoxes },
    } = await apolloClient.query({
      query: getReceptionBoxesQuery,
    })

    commit('SET_ALL_RECEPTION_BOXES', receptionBoxes)
  },
  async getReceptionBox({ commit }, id) {
    const {
      data: { receptionBox },
    } = await apolloClient.query({
      query: getReceptionBoxQuery,
      variables: { id },
    })
    commit('SET_RECEPTION_BOX', receptionBox)
  },
  async createReceptionBox({
    state: { name, priority, schoolEstablishments, StoreId },
    commit,
  }) {
    const {
      data: { receptionBox },
    } = await apolloClient.query({
      query: createReceptionBoxMutation,
      variables: {
        receptionBox: {
          name,
          priority: parseInt(priority, 10),
          SchoolEstablishmentIds: schoolEstablishments,
          StoreId,
        },
      },
    })

    commit('SET_RECEPTION_BOX', receptionBox)
  },
  async updateReceptionBox({
    state: { id, name, priority, schoolEstablishments, StoreId },
  }) {
    await apolloClient.query({
      query: updateReceptionBoxMutation,
      variables: {
        id,
        receptionBox: {
          name,
          priority: parseInt(priority, 10),
          SchoolEstablishmentIds: schoolEstablishments,
          StoreId,
        },
      },
    })
  },
  async deleteReceptionBox({ commit }, id) {
    const {
      data: { deleteReceptionBox },
    } = await apolloClient.query({
      query: deleteReceptionBoxMutation,
      variables: { id },
    })
    if (deleteReceptionBox) {
      commit('REMOVE_RECEPTION_BOX', id)
    }
  },
}

const mutations = {
  SET_ALL_RECEPTION_BOXES(state, payload) {
    state.receptionBoxes = payload
  },
  SET_RECEPTION_BOX(
    state,
    {
      id = null,
      name = null,
      priority = null,
      key = null,
      SchoolEstablishmentIds = null,
      deletable = false,
      StoreId = null,
    }
  ) {
    state.id = id
    state.name = name
    state.priority = priority
    state.key = key
    state.schoolEstablishments = SchoolEstablishmentIds
    state.StoreId = StoreId
    deletable = deletable
  },
  SET_NAME(state, payload) {
    state.name = payload
  },
  SET_PRIORITY(state, payload) {
    state.priority = payload
  },
  SET_ESTABLISHEMENTS(state, payload) {
    state.schoolEstablishments = payload
  },
  SET_STORE_ID(state, payload) {
    state.StoreId = payload
  },
  REMOVE_RECEPTION_BOX(state, receptionBoxId) {
    const receptionBoxIndex = state.receptionBoxes.findIndex(
      ({ id }) => id === receptionBoxId
    )
    if (receptionBoxIndex >= 0) {
      state.receptionBoxes.splice(receptionBoxIndex, 1)
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
