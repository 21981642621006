<template>
  <div>
    <BaseHeader class="pb-6" type="default">
      <b-row align-v="center" class="py-4">
        <b-col lg="12" cols="7">
          <h1 class="h2 text-white d-inline-block mb-0">Créer un pays</h1>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </BaseHeader>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="12">
          <CountryForm
            :on-success="save"
            title="Créer un pays"
            redirect="/localisation/countries"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { RouteBreadcrumb, BaseHeader } from '@coop-zone/coop-zone-argon'
import CountryForm from '@/components/Countries/CountryForm'

export default {
  components: {
    CountryForm,
    RouteBreadcrumb,
    BaseHeader,
  },

  methods: {
    async save() {
      await this.$store.dispatch('countries/createCountry')
    },
  },
}
</script>
