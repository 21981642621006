<i18n>
{
  "fr": {
    "DeleteSuccess": "La règle a bien été supprimée",
    "CreateSuccess": "La règle a bien été ajoutée",
    "EditSuccess": "La règle a bien été modifiée"
  }
}
</i18n>
<template>
  <TableInput
    v-if="taxRules"
    v-slot="{ remove }"
    :value="taxRules"
    title="Règles de taxe"
    @on-add="addRuleRow"
    @on-delete="deleteRule"
  >
    <el-table-column label="Taxe" min-width="310px">
      <template #default="{ row }">
        <span v-if="row.edit === false">{{ row.Tax.name }}</span>
        <BaseInput v-else-if="row.edit === true || row.new === true">
          <select v-model="row.TaxId" class="form-control">
            <option />
            <option
              v-for="tax in taxes"
              :key="tax.id"
              :value="tax.id"
              :selected="row.Tax && row.Tax.id === tax.id"
            >
              {{ tax.name }}
            </option>
          </select>
        </BaseInput>
      </template>
    </el-table-column>

    <el-table-column label="Pays" min-width="310px">
      <template #default="{ row }">
        <span v-if="row.edit === false && row.Country">{{
          row.Country.name
        }}</span>
        <BaseInput v-else-if="row.edit === true || row.new === true">
          <select v-model="row.CountryId" class="form-control">
            <option />
            <option
              v-for="country in countries"
              :key="country.id"
              :value="country.id"
              :selected="row.Country && row.Country.id === country.id"
            >
              {{ country.name }}
            </option>
          </select>
        </BaseInput>
      </template>
    </el-table-column>
    <el-table-column label="Province" min-width="310px">
      <template #default="{ row }">
        <span v-if="row.edit === false && row.State">{{ row.State.name }}</span>
        <BaseInput v-else-if="row.edit === true || row.new === true">
          <select v-model="row.StateId" class="form-control">
            <option />
            <option
              v-for="state in provincesForCountry(row.CountryId)"
              :key="state.id"
              :value="state.id"
              :selected="row.State && row.State.id === state.id"
            >
              {{ state.name }}
            </option>
          </select>
        </BaseInput>
      </template>
    </el-table-column>

    <el-table-column min-width="180px" align="right">
      <div slot-scope="{ row }" class="table-actions">
        <div v-if="row.edit === false">
          <a
            href="#!"
            class="table-action table-action-edit"
            data-toggle="tooltip"
            data-original-title="Modifier"
            @click.prevent="updateRuleState(row, true)"
          >
            <i class="fa fa-edit" />
          </a>

          <a
            v-confirm-delete="{
              onConfirm: () => remove(row),
              confirmText: 'La règle de taxe sera supprimé définitivement',
              successText: $t('DeleteSuccess'),
            }"
            href="#!"
            class="table-action table-action-delete"
            data-toggle="tooltip"
            data-original-title="Supprimer"
          >
            <i class="fas fa-trash" />
          </a>
        </div>
        <div v-else-if="row.edit === true">
          <el-tooltip content="Enregistrer" placement="top">
            <a
              href="#!"
              class="table-action table-action-save"
              data-toggle="tooltip"
              data-original-title="Enregistrer"
              @click.prevent="updateRule(row)"
            >
              <i class="ni ni-check-bold text-success" />
            </a>
          </el-tooltip>
          <el-tooltip content="Annuler" placement="top">
            <a
              href="#!"
              class="table-action table-action-cancel"
              data-toggle="tooltip"
              data-original-title="Annuler"
              @click.prevent="updateRuleState(row, false)"
            >
              <i class="ni ni-fat-remove" />
            </a>
          </el-tooltip>
        </div>
        <div v-if="row.new === true">
          <el-tooltip content="Enregistrer" placement="top">
            <a
              href="#!"
              class="table-action table-action-save"
              data-toggle="tooltip"
              data-original-title="Enregistrer"
              @click.prevent="createRule(row)"
            >
              <i class="ni ni-check-bold text-success" />
            </a>
          </el-tooltip>
        </div>
      </div>
    </el-table-column>
  </TableInput>
</template>
<script>
import { BaseInput } from '@coop-zone/coop-zone-argon'
import TableInput from '@/components/TableInput'
import { mapState, mapGetters } from 'vuex'
export default {
  components: {
    BaseInput,
    TableInput,
  },
  computed: {
    ...mapState('taxes', ['taxes']),
    ...mapState('taxes/groups/rules', ['taxRules']),
    ...mapState('countries', ['countries']),
    ...mapState('provinces', ['provinces']),
    ...mapGetters('provinces', ['provincesForCountry']),
  },
  created() {
    this.$store.dispatch('taxes/getTaxes')
    this.$store.dispatch('countries/getAllCountries')
    this.$store.dispatch('provinces/getAllProvinces')
  },
  methods: {
    updateRuleState(rule, edit) {
      this.$store.commit('taxes/groups/rules/SET_RULE_EDIT', { rule, edit })
    },
    addRuleRow() {
      this.$store.commit('taxes/groups/rules/ADD_TAX_RULE_ROW')
    },
    async updateRule(rule) {
      await this.$store.dispatch('taxes/groups/rules/updateRule', rule)
      this.$store.commit('taxes/groups/rules/SET_RULE_EDIT', {
        rule,
        edit: false,
      })
      this.$notify({
        message: this.$t('EditSuccess'),
        timeout: 5000,
        icon: 'ni ni-check-bold',
        type: 'success',
      })
    },
    async createRule(rule) {
      await this.$store.dispatch('taxes/groups/rules/createRule', rule)
      this.$notify({
        message: this.$t('CreateSuccess'),
        timeout: 5000,
        icon: 'ni ni-check-bold',
        type: 'success',
      })
    },
    async deleteRule(rule) {
      await this.$store.dispatch('taxes/groups/rules/deleteRule', rule)
      this.$notify({
        message: this.$t('DeleteSuccess'),
        timeout: 5000,
        icon: 'ni ni-check-bold',
        type: 'success',
      })
    },
  },
}
</script>
