<template>
  <div>
    <BaseForm :on-success="onSuccess">
      <b-card>
        <SchoolListsBaseFormHeader slot="header">
          <slot name="header" />
        </SchoolListsBaseFormHeader>

        <slot />

        <SchoolListsBaseFormFooter
          v-if="
            account &&
            !(account.role === 'TEACHER' && schoolList.status !== 'DRAFT')
          "
          slot="footer"
          :readonly="readonly"
        />
      </b-card>
    </BaseForm>
    <slot name="categories" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import BaseForm from '@/components/forms/BaseForm'
import SchoolListsBaseFormHeader from '@/components/SchoolLists/SchoolListsBaseFormHeader'
import SchoolListsBaseFormFooter from '@/components/SchoolLists/SchoolListsBaseFormFooter'

export default {
  components: {
    BaseForm,
    SchoolListsBaseFormHeader,
    SchoolListsBaseFormFooter,
  },
  props: {
    onSuccess: {
      type: Function,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('accounts', ['account']),
    ...mapGetters('schoolLists', ['schoolList']),
  },
}
</script>
