<template>
  <BaseForm
    id="filterForm"
    success-message="Filtre enregistré"
    :on-success="save"
  >
    <b-card>
      <div slot="header">
        <b-row align-v="center">
          <b-col sm="12" lg="6">
            <p class="h6 text-uppercase text-muted ls-1 mb-1">
              {{ title }}
            </p>
            <h2 class="mb-0">
              {{ name }}
            </h2>
          </b-col>

          <b-col sm="12" lg="6" class="text-right">
            <slot name="actions" />
          </b-col>
        </b-row>
      </div>

      <b-row>
        <b-col sm="6" lg="4" xl="4">
          <BaseInput v-model="name" label="Nom du filtre" rules="required" />
        </b-col>
        <b-col sm="6" lg="4" xl="3">
          <SearchFilterGroupForm v-model="FilterGroup" />
        </b-col>
        <b-col sm="12" lg="3" xl="4" offset-lg="1">
          <BaseInput label="Mode d'affichage par défaut">
            <el-switch
              v-model="expanded"
              active-text="Ouvert"
              inactive-text="Fermé"
            />
          </BaseInput>
        </b-col>
      </b-row>
      <b-row slot="footer" align="end">
        <b-col>
          <BaseButton size="lg" type="primary" native-type="submit">
            Sauvegarder
          </BaseButton>
        </b-col>
      </b-row>
    </b-card>

    <slot />
  </BaseForm>
</template>

<script>
import { BaseButton, BaseInput } from '@coop-zone/coop-zone-argon'
import BaseForm from '@/components/forms/BaseForm'
import SearchFilterGroupForm from '@/components/Filters/SearchFilterGroupForm'

export default {
  components: {
    BaseForm,
    BaseButton,
    BaseInput,
    SearchFilterGroupForm,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    name: {
      get() {
        return this.$store.state.filters.name
      },
      set(name) {
        this.$store.commit('filters/SET_NAME', name)
      },
    },
    expanded: {
      get() {
        return this.$store.state.filters.expanded
      },
      set(expanded) {
        this.$store.commit('filters/SET_EXPANDED', expanded)
      },
    },
    FilterGroup: {
      get() {
        return this.$store.state.filters.FilterGroup
      },
      set(FilterGroup) {
        this.$store.commit('filters/SET_FILTER_GROUP', FilterGroup)
      },
    },
  },
  created() {
    this.$store.commit('filters/SET_FILTER', {})
    if (this.$route.params.filterId) {
      this.$store.dispatch('filters/getFilter', this.$route.params.filterId)
    }
  },
  methods: {
    async save() {
      if (this.$route.params.filterId) {
        await this.$store.dispatch('filters/updateFilter')
      } else {
        await this.$store.dispatch('filters/createFilter')
        this.$router.push(`/filters/${this.$store.state.filters.id}`)
      }
    },
  },
}
</script>
