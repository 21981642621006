<template>
  <b-card no-body>
    <div slot="header">
      <b-row>
        <b-col sm="12" lg="6">
          <p class="h3">Liste des articles</p>
        </b-col>
      </b-row>
    </div>
    <b-row class="p-4">
      <b-col>
        <ItemsList :category="category" :disabled="true" />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import ItemsList from '@/components/SchoolLists/Items/ItemsList'

export default {
  components: {
    ItemsList,
  },
  computed: {
    ...mapGetters('schoolLists/categories', { category: 'getFirstCategory' }),
  },
}
</script>
