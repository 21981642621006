<template>
  <badge class="text-uppercase badge-lg" size="lg" :rounded="true" :type="type">
    {{ status }}
  </badge>
</template>
<script>
import { Badge } from '@coop-zone/coop-zone-argon'

export default {
  components: { Badge },
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    type() {
      switch (this.status) {
        case 'Annulée/Refusée':
          return 'warning'
        case 'Publiée':
          return 'success'
        case 'Brouillon':
          return 'secondary'
        case 'En approbation par CZ':
          return 'info'
        case 'Envoyée aux parents':
          return 'secondary'
        default:
          return 'primary'
      }
    },
  },
}
</script>
