<template>
  <b-list-group class="list-group-lg list-group-flush">
    <Draggable
      v-if="draggableFiles"
      class="row align-items-center"
      :disabled="disabled"
      :value="draggableFiles"
      @input="$emit('order', $event)"
    >
      <b-col
        v-for="(file, index) in files"
        :key="index"
        class="list-group-item"
        cols="auto"
      >
        <FileUploadPreview :file="file" @delete="$emit('delete', $event)" />
        <span>Principale</span>
      </b-col>
    </Draggable>
  </b-list-group>
</template>

<script>
import Draggable from 'vuedraggable'
import FileUploadPreview from '@/components/Inputs/FileUploadPreview'

export default {
  components: {
    Draggable,
    FileUploadPreview,
  },
  props: {
    files: {
      type: Array,
      default() {
        return []
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      draggableFiles: null,
    }
  },
  watch: {
    files: {
      handler(updatedFiles) {
        if (updatedFiles) {
          this.draggableFiles = updatedFiles
        }
      },
      immediate: true,
    },
  },
}
</script>

<style lang="sass" scoped>
.list-group-item
  border: none
  cursor: move
  display: inline-flex
  padding: 1.4rem 1rem
  position: relative

  span
    display: none

.list-group-item:first-child
  border-top: 0

  span
    bottom: 6px
    color: var(--default)
    display: block
    font-size: 11px
    left: 50%
    position: absolute
    transform: translateX(-50%)
</style>
