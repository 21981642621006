<template>
  <div class="content">
    <base-header class="pb-6 bg-default">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="12">
          <h6 class="h2 text-white d-inline-block mb-0">Zones</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-card no-body class="mb-4">
        <b-row slot="header" align-v="center">
          <b-col cols="8">
            <h3 class="mb-0">Zones</h3>
          </b-col>
          <b-col cols="4" class="text-right">
            <router-link
              class="btn btn-sm btn-primary"
              to="/localisation/zones/add"
              data-cy="zone-add-link"
            >
              Ajouter une zone
            </router-link>
          </b-col>
        </b-row>

        <el-table
          :data="zones"
          row-key="id"
          header-row-class-name="thead-light"
        >
          <el-table-column label="Zone" min-width="160px">
            <template slot-scope="scope">
              <router-link :to="`/localisation/zones/edit/${scope.row.id}`">
                <span class="font-weight-600 name text-sm">{{
                  scope.row.name
                }}</span>
              </router-link>
            </template>
          </el-table-column>
          <el-table-column align="right">
            <template slot-scope="scope" class="table-actions">
              <router-link
                class="table-action table-action-edit"
                :to="`/localisation/zones/edit/${scope.row.id}`"
              >
                <i class="fa fa-edit" />
              </router-link>
            </template>
          </el-table-column>
        </el-table>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import { BaseHeader, RouteBreadcrumb } from '@coop-zone/coop-zone-argon'
import { mapState } from 'vuex'

export default {
  components: {
    BaseHeader,
    RouteBreadcrumb,
  },
  computed: {
    ...mapState('zones', ['zones']),
  },
  created() {
    this.$store.dispatch('zones/getAllZones')
  },
}
</script>
