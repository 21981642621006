import BlankRouterView from '@/views/Layout/BlankRouterView'
import ZonesIndex from '@/views/Dashboard/Localisation/Zones/ZonesIndex'
import ZonesAdd from '@/views/Dashboard/Localisation/Zones/ZonesAdd'
import ZonesEdit from '@/views/Dashboard/Localisation/Zones/ZonesEdit'

import CountriesIndex from '@/views/Dashboard/Localisation/Countries/CountriesIndex'
import CountriesAdd from '@/views/Dashboard/Localisation/Countries/CountriesAdd'
import CountriesEdit from '@/views/Dashboard/Localisation/Countries/CountriesEdit'

import StatesIndex from '@/views/Dashboard/Localisation/States/StatesIndex'
import StatesAdd from '@/views/Dashboard/Localisation/States/StatesAdd'
import StatesEdit from '@/views/Dashboard/Localisation/States/StatesEdit'

export default {
  path: '/localisation',
  name: 'Localisation',
  redirect: '/localisation/zones/index',
  component: BlankRouterView,
  children: [
    {
      path: '/localisation/zones',
      redirect: '/localisation/zones/index',
      name: 'Zones',
      component: BlankRouterView,
      children: [
        {
          path: '/localisation/zones/index',
          name: 'Liste des zones',
          component: ZonesIndex,
          meta: {
            roles: ['ADMIN'],
          },
        },
        {
          path: '/localisation/zones/add',
          name: 'Ajouter une zone',
          component: ZonesAdd,
          meta: {
            roles: ['ADMIN'],
          },
        },
        {
          path: '/localisation/zones/edit/:id',
          name: 'Modifier une zone',
          component: ZonesEdit,
          meta: {
            roles: ['ADMIN'],
          },
        },
      ],
    },
    {
      path: '/localisation/countries',
      name: 'Pays',
      redirect: '/localisation/countries/index',
      component: BlankRouterView,
      children: [
        {
          path: '/localisation/countries/index',
          name: 'Liste des pays',
          component: CountriesIndex,
          meta: {
            roles: ['ADMIN'],
          },
        },
        {
          path: '/localisation/countries/add',
          name: 'Ajouter un pays',
          component: CountriesAdd,
          meta: {
            roles: ['ADMIN'],
          },
        },
        {
          path: '/localisation/countries/edit/:id',
          name: 'Modifier un pays',
          component: CountriesEdit,
          meta: {
            roles: ['ADMIN'],
          },
        },
      ],
    },
    {
      path: '/localisation/states',
      name: 'Provinces',
      redirect: '/localisation/states/index',
      component: BlankRouterView,
      children: [
        {
          path: '/localisation/states/index',
          name: 'Liste des provinces',
          component: StatesIndex,
          meta: {
            roles: ['ADMIN'],
          },
        },
        {
          path: '/localisation/states/add',
          name: 'Ajouter une province',
          component: StatesAdd,
          meta: {
            roles: ['ADMIN'],
          },
        },
        {
          path: '/localisation/states/edit/:id',
          name: 'Modifier une province',
          component: StatesEdit,
          meta: {
            roles: ['ADMIN'],
          },
        },
      ],
    },
  ],
}
