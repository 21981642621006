<i18n>
{
  "fr": {
    "DeleteSuccess": "La section a bien été supprimée",
    "CreateSuccess": "La section a bien été ajoutée",
    "EditSuccess": "La section a bien été modifiée"
  }
}
</i18n>

<template>
  <div class="content">
    <base-header class="pb-6 bg-default">
      <b-row align-v="center" class="py-4">
        <b-col>
          <p class="h2 text-white d-inline-block mb-0">Sections de cours</p>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb />
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-card no-body class="mb-4">
        <b-row slot="header" align-v="center">
          <b-col cols="4">
            <h1 class="h3 mb-0">Liste des sections</h1>
          </b-col>
          <b-col class="text-right">
            <router-link
              class="btn btn-sm btn-primary"
              to="/school-lists/sections/add"
              data-cy="section-add-link"
            >
              Ajouter une section
            </router-link>
          </b-col>
        </b-row>
        <FuseList :data="sections" :keys="['id', 'name']">
          <el-table-column
            label="Nom de la section"
            min-width="160px"
            sortable
            sort-by="name"
            :sort-method="(a, b) => a.name.localeCompare(b.name)"
          >
            <template slot-scope="scope">
              <router-link :to="`/school-lists/sections/${scope.row.id}`">
                <span class="font-weight-600 name text-sm">{{
                  scope.row.name
                }}</span>
              </router-link>
            </template>
          </el-table-column>

          <el-table-column
            label="Niveau scolaire"
            min-width="160px"
            sortable
            sort-by="establishmentType"
            :sort-method="
              (a, b) => a.establishmentType.localeCompare(b.establishmentType)
            "
          >
            <template slot-scope="scope">
              {{ $t(`establishments.types.${scope.row.establishmentType}`) }}
            </template>
          </el-table-column>

          <el-table-column min-width="100px" align="right">
            <template slot-scope="scope" class="table-actions">
              <router-link
                class="table-action table-action-edit"
                :to="`/school-lists/sections/${scope.row.id}`"
              >
                <i class="fa fa-edit" />
              </router-link>
              <a
                v-confirm-delete="{
                  onConfirm: () => deleteSection(scope.row),
                  confirmText: 'La section sera supprimée',
                  successText: `La section a été supprimée`,
                }"
                href="#!"
                class="table-action table-action-delete"
                data-toggle="tooltip"
                data-original-title="Supprimer"
              >
                <i class="fas fa-trash" />
              </a>
            </template>
          </el-table-column>
        </FuseList>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import { BaseHeader, RouteBreadcrumb } from '@coop-zone/coop-zone-argon'
import { mapGetters } from 'vuex'
import FuseList from '@/components/FuseList'
import ErrorHandlerMixin from '@/mixins/errorHandler.mixin'

export default {
  components: {
    BaseHeader,
    RouteBreadcrumb,
    FuseList,
  },
  mixins: [ErrorHandlerMixin],
  computed: {
    ...mapGetters('schoolLists/sections', {
      sections: 'getSections',
    }),
  },
  async created() {
    await this.$store.dispatch('schoolLists/sections/getSections')
  },
  methods: {
    async deleteSection(section) {
      try {
        await this.$store.dispatch(
          'schoolLists/sections/deleteSection',
          section
        )
      } catch (error) {
        this.handleError(error)
        throw error
      }
    },
  },
}
</script>
