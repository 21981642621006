export default {
  type: {
    Art: 'Arts',
    Book: 'Livre',
    CollegeUniversity: 'Liste scolaire collégiale/universitaire',
    Computer: 'Informatique',
    CourseNote: 'Notes de cours',
    DigitalAccessCode: 'Code d’accès',
    DigitalBook: 'Livre numérique',
    DigitalCoupon: 'Coupon (Notes de cours Limoilou)',
    DigitalOther: 'Autre produit numérique',
    DigitalWatermark: 'Document avec filigrane',
    EcoFee: 'Écofrais',
    ElementaryHighschool: 'Liste scolaire primaire/secondaire',
    OfficeSupply: 'Papeterie',
    Program: "Programme d'achat de portable",
  },
}
