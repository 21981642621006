<template>
  <div class="content">
    <base-header class="pb-6 bg-default">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="12">
          <h6 class="h2 text-white d-inline-block mb-0">Catalogue</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <BaseTableSearch
        :data="products"
        :pagination="pagination"
        data-cy="search-product"
        placeholder="Nom, sku, code produit"
        :value="term"
        @selection-change="selectedProducts = $event"
        @term="term = $event"
        @page="page = $event"
        @sort-change="sortMethod"
      >
        <template #header>
          <b-row slot="header" align-v="center">
            <b-col sm="3">
              <h3 class="mb-0">Rechercher un produit</h3>
            </b-col>
            <b-col sm="9" class="text-right">
              <BaseButton
                icon
                size="sm"
                type="warning"
                @click="confirmIndexation"
              >
                <span class="btn-inner--text">Indexer les produits</span>
                <span class="btn-inner--icon">
                  <i class="fas fa-address-book"></i>
                </span>
              </BaseButton>
              <BaseButton icon size="sm" type="warning" @click="confirmSync">
                <span class="btn-inner--text"
                  >Synchroniser les règles vigie</span
                >
                <span class="btn-inner--icon">
                  <i class="fas fa-sync-alt"></i
                ></span>
              </BaseButton>
              <router-link class="btn btn-sm btn-primary" to="/catalog/add">
                Ajouter un produit
              </router-link>
            </b-col>
          </b-row>
        </template>

        <template #filters>
          <TreeselectInput
            v-if="treeselectCategories"
            v-model="selectedCategories"
            form-group-classes="mb-0"
            label="Filtrer par catégories"
            :multiple="true"
            placeholder="Sélectionnez les catégories"
            :options="treeselectCategories"
          />
        </template>

        <template #batch>
          <BatchActionEditProductsFilters
            :batch-action-items="selectedProducts.map(({ id }) => id)"
          />

          <BatchActionAddProductsSuggestions
            :batch-action-items="selectedProducts.map(({ id }) => id)"
          />
        </template>

        <el-table-column type="selection" width="55" />

        <el-table-column
          label="Nom du produit"
          min-width="160px"
          sortable="custom"
          prop="name.raw"
        >
          <template slot-scope="scope">
            <b-media no-body class="align-items-center">
              <router-link
                v-if="scope.row.images && scope.row.images.length > 0"
                :to="`/catalog/edit/${scope.row.id}-${scope.row.slug}`"
                class="avatar mr-3"
              >
                <img :src="scope.row.images[0].thumbnail" />
              </router-link>
              <b-media-body>
                <router-link
                  :to="`/catalog/edit/${scope.row.id}-${scope.row.slug}`"
                >
                  <span class="font-weight-600 name mb-0 text-sm">{{
                    scope.row.name
                  }}</span>
                </router-link>
              </b-media-body>
            </b-media>
          </template>
        </el-table-column>
        <el-table-column label="SKU" sortable="custom" prop="sku" />
        <el-table-column label="code produit" sortable="custom" prop="vigieId">
          <template slot-scope="scope" class="table-actions">
            {{ scope.row.vigieId ? scope.row.vigieId : '-' }}
          </template>
        </el-table-column>
        <el-table-column align="right">
          <template slot-scope="scope" class="table-actions">
            <router-link
              class="table-action table-action-edit"
              :to="`/catalog/edit/${scope.row.id}-${scope.row.slug}`"
            >
              <i class="fa fa-edit" />
            </router-link>

            <a
              :href="`${websiteUrl}/produit/${scope.row.id}-${scope.row.slug}`"
              class="table-action"
              target="_blank"
            >
              <i class="far fa-eye" />
            </a>
            <a
              href="#!"
              class="table-action table-action-delete"
              data-toggle="tooltip"
              data-original-title="Supprimer"
              @click="deleteProduct(scope.row)"
            >
              <i class="fas fa-trash" />
            </a>
          </template>
        </el-table-column>
      </BaseTableSearch>
    </b-container>
  </div>
</template>
<script>
import { types as productTypes } from '@/data/products'

import {
  BaseHeader,
  RouteBreadcrumb,
  BaseButton,
} from '@coop-zone/coop-zone-argon'
import { mapState } from 'vuex'
import { isEqual } from 'lodash'
import swal from 'sweetalert2'
import BatchActionEditProductsFilters from '@/components/BatchAction/BatchActionEditProductsFilters'
import BatchActionAddProductsSuggestions from '@/components/BatchAction/BatchActionAddProductsSuggestions'
import BaseTableSearch from '@/components/BaseTableSearch'
import ErrorHandlerMixin from '@/mixins/errorHandler.mixin'
import TreeselectInput from '@/components/Inputs/TreeselectInput'
import { apolloClient } from '@/apollo'
import reIndexProductsMutation from '@/apollo/mutations/ReIndex.gql'
import resyncProductsMutation from '@/apollo/mutations/Resync.gql'
import BaseDrawer from '@/components/BaseDrawer'

import getEnv from '@/utils/env'

export default {
  components: {
    BaseButton,
    BaseHeader,
    BaseTableSearch,
    BatchActionEditProductsFilters,
    BatchActionAddProductsSuggestions,
    RouteBreadcrumb,
    TreeselectInput,
  },
  mixins: [ErrorHandlerMixin],
  data() {
    return {
      websiteUrl: getEnv('VUE_APP_WEBSITE_URL'),
      term: '',
      selectedCategories: [],
      selectedProducts: [],
      page: 1,
      sortObject: null,
      loading: false,
      exportFilters: {
        categories: null,
      },
    }
  },
  computed: {
    ...mapState('products', ['pagination', 'products']),
    ...mapState('utils', ['treeselectCategories']),
  },
  watch: {
    selectedCategories(newValue) {
      if (newValue) {
        this.page = 1
        this.selectedCategories = newValue
        this.search()
      }
    },
    term() {
      this.search()
    },
    page() {
      this.search()
    },
  },
  created() {
    this.$store.dispatch('utils/getTreeselectCategories')
    if (this.$route.query.term) {
      this.term = this.$route.query.term
    }
    if (this.$route.query.cats) {
      this.selectedCategories = this.$route.query.cats.split(',')
    }
    this.search()
  },
  methods: {
    sortMethod({ prop, order }) {
      if (order) {
        this.sortObject = {
          column: prop,
          order: order === 'descending' ? 'DESC' : 'ASC',
        }
      } else {
        this.sortObject = null
      }

      this.search()
    },
    async search() {
      try {
        await this.$store.dispatch('products/searchProducts', {
          types: productTypes,
          search: this.term,
          page: this.page,
          categories: this.selectedCategories,
          limit: this.pagination.resultsPerPage,
          showAll: true,
          sort: this.sortObject,
        })

        const query = {}
        if (this.term) query.term = this.term
        if (this.selectedCategories.length)
          query.cats = this.selectedCategories.join(',')

        if (isEqual(query, this.$route.query) == false) {
          this.$router.replace({ query })
        }
      } catch (e) {
        console.log(e)
      }
    },
    async deleteProduct(product) {
      try {
        let html = ''
        if (product.bundles && product.bundles.length > 0) {
          const bundles = product.bundles.map(
            (bundle) =>
              `<li class="align-items-left"><a href="/catalog/bundles/edit/${bundle.id}-${bundle.slug}" target="_blank">${bundle.name}</li>`
          )
          html = `<h2>Êtes-vous certain?</h2><p>Le produit sera supprimé. Il fait parti des ensembles suivants. Il est possible que ses ensembles se retrouvent sans aucun produit.</p><ul class="product-delete-modal-list">${bundles.join()}</ul>`
        } else {
          html = '<h2>Êtes-vous certain?</h2><p>Le produit sera supprimé.</p>'
        }
        const { isConfirmed } = await swal.fire({
          html,
          confirmButtonText: 'Supprimer le produit',
          cancelButtonText: 'Annuler',
          showCancelButton: true,
        })
        if (!isConfirmed) return
        await this.$store.dispatch('products/deleteProduct', product)
      } catch (error) {
        this.handleError(error)
        throw error
      }
    },
    async confirmSync() {
      const { isConfirmed } = await swal.fire({
        html: `<h2>Êtes-vous certain?</h2><p>Cette opération peut prendre plusieurs heures`,
        confirmButtonText: 'Synchroniser les règles vigie',
        cancelButtonText: 'Annuler',
        showCancelButton: true,
      })
      if (!isConfirmed) return

      try {
        await apolloClient.query({
          query: resyncProductsMutation,
        })
      } catch (error) {
        this.handleError(error)
      }
    },
    async confirmIndexation() {
      const { isConfirmed } = await swal.fire({
        html: `<h2>Êtes-vous certain?</h2><p>Cette opération peut prendre plusieurs heures`,
        confirmButtonText: 'Indexer les produits',
        cancelButtonText: 'Annuler',
        showCancelButton: true,
      })
      if (!isConfirmed) return

      try {
        await apolloClient.query({
          query: reIndexProductsMutation,
        })
      } catch (error) {
        this.handleError(error)
      }
    },
    receptionBoxName(receptionBox) {
      return receptionBox ? receptionBox.name : '-'
    },
  },
}
</script>

<style>
.no-border-card .card-footer {
  border-top: 0;
}
.align-items-left {
  text-align: left;
}
</style>
