import { GraphQLErrors } from '@coop-zone/coop-zone-form-errors'

export default {
  methods: {
    handleError(error) {
      const errors = GraphQLErrors(error).logicErrors()
      if (errors && errors.length > 0) {
        this.$notify({
          message: this.$t(`logicError.${errors[0]}`),
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type: 'danger',
        })
      } else {
        this.$notify({
          message: this.$t(`logicError.DEFAULT`),
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type: 'danger',
        })
      }
    },
  },
}
