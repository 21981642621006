import BlankRouterView from '@/views/Layout/BlankRouterView'
import HandlingFeesEdit from '@/views/Dashboard/Shipping/HandlingFees/HandlingFeesEdit'
import DeliveryRulesIndex from '@/views/Dashboard/Shipping/DeliveryRules/DeliveryRulesIndex'
import DeliveryRulesEdit from '@/views/Dashboard/Shipping/DeliveryRules/DeliveryRulesEdit'
import DeliveryRulesAdd from '@/views/Dashboard/Shipping/DeliveryRules/DeliveryRulesAdd'

import TransportersIndex from '@/views/Dashboard/Shipping/Transporters/TransportersIndex'
import TransportersAdd from '@/views/Dashboard/Shipping/Transporters/TransportersAdd'
import TransportersEdit from '@/views/Dashboard/Shipping/Transporters/TransportersEdit'

export default {
  path: '/shipping',
  name: 'Transport',
  redirect: '/shipping/handling_fees/edit',
  component: BlankRouterView,
  children: [
    {
      path: '/shipping/transporters',
      redirect: '/shipping/transporters/index',
      name: 'Transporteurs',
      component: BlankRouterView,
      meta: {
        roles: ['ADMIN'],
      },
      children: [
        {
          path: '/shipping/transporters/index',
          name: 'Liste des transporteurs',
          component: TransportersIndex,
          meta: {
            roles: ['ADMIN'],
          },
        },
        {
          path: '/shipping/transporters/add',
          name: 'Ajouter un transporteurs',
          component: TransportersAdd,
          meta: {
            roles: ['ADMIN'],
          },
        },
        {
          path: '/shipping/transporters/edit/:id',
          name: 'Modifier un transporteurs',
          component: TransportersEdit,
          meta: {
            roles: ['ADMIN'],
          },
        },
      ],
    },
    {
      path: '/shipping/handling_fees',
      redirect: '/shipping/handling_fees/edit',
      name: 'Frais de manutention',
      component: BlankRouterView,
      meta: {
        roles: ['ADMIN'],
      },
      children: [
        {
          path: '/shipping/handling_fees/edit',
          name: 'Modifier les frais de manutention',
          component: HandlingFeesEdit,
          meta: {
            roles: ['ADMIN'],
          },
        },
      ],
    },
    {
      path: '/shipping/delivery_rules',
      name: 'Frais de transport',
      redirect: '/shipping/delivery_rules/index',
      component: BlankRouterView,
      meta: {
        roles: ['ADMIN'],
      },
      children: [
        {
          path: '/shipping/delivery_rules/index',
          name: 'Liste des frais',
          component: DeliveryRulesIndex,
          meta: {
            roles: ['ADMIN'],
          },
        },
        {
          path: '/shipping/delivery_rules/add',
          name: 'Ajouter une règle de transport',
          component: DeliveryRulesAdd,
          meta: {
            roles: ['ADMIN'],
          },
        },
        {
          path: '/shipping/delivery_rules/edit/:id',
          name: 'Modifier une règle de transport',
          component: DeliveryRulesEdit,
          meta: {
            roles: ['ADMIN'],
          },
        },
      ],
    },
  ],
}
