<template>
  <el-select
    placeholder="Sélectionner un statut"
    :value="value"
    :size="size"
    :disabled="disabled"
    @change="$emit('input', $event)"
  >
    <slot />
    <el-option
      v-for="status in statuses"
      :key="status"
      :value="status"
      :label="statusLabel(status)"
      :selected="status == value"
    >
      {{ statusLabel(status) }}
    </el-option>
  </el-select>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '',
    },
  },
  computed: {
    statuses() {
      return Object.keys(this.$t('orders.refund.statuses'))
    },
  },
  methods: {
    statusLabel(status) {
      return this.$t(`orders.refund.statuses.${status}`)
    },
  },
}
</script>
