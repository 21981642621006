<template>
  <BaseInput>
    <el-select
      v-loading="loading"
      placeholder="Sélectionner une valeur"
      :value="value"
      @change="handleChange"
    >
      <el-option label="Sélectionner une valeur" :value="null" />
      <el-option-group
        v-for="group in options"
        :key="group.id"
        :label="group.name"
      >
        <el-option
          v-for="item in group.filterValues"
          :key="item.id"
          :label="item.value"
          :value="item.id"
        />
      </el-option-group>
    </el-select>
  </BaseInput>
</template>

<script>
import { BaseInput } from '@coop-zone/coop-zone-argon'

export default {
  components: {
    BaseInput,
  },
  props: {
    filterValueObject: {
      type: Object,
      required: true,
    },
    filterId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      value: null,
      options: [],
      loading: false,
    }
  },
  computed: {
    optionsMap() {
      return this.options.reduce(
        (acc, group) => ({
          ...acc,
          ...group.filterValues.reduce(
            (gacc, value) => ({ ...gacc, [value.id]: value }),
            {}
          ),
        }),
        {}
      )
    },
  },
  watch: {
    filterValueObject: {
      async handler() {
        this.loading = true

        try {
          this.options = await this.$store.dispatch(
            'filters/values/getFilterValuesByGroup',
            this.filterId
          )
          this.value = this.filterValueObject.id
          this.loading = false
        } catch (e) {
          this.loading = false
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleChange(value) {
      if (value) {
        this.$emit('input', this.optionsMap[value])
      } else {
        this.$emit('input', null)
      }
    },
  },
}
</script>
