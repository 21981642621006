import { apolloClient } from '@/apollo'

import categories from './categories.module'
import sections from './sections.module'

const searchSchoolListsQuery = require('../../apollo/queries/SearchSchoolLists.gql')
const getSchoolListQuery = require('../../apollo/queries/GetSchoolList.gql')
const createSchoolListMutation = require('../../apollo/mutations/CreateSchoolList.gql')
const createTeacherSchoolListMutation = require('../../apollo/mutations/CreateTeacherSchoolList.gql')
const updateSchoolListMutation = require('../../apollo/mutations/UpdateSchoolList.gql')
const updateTeacherSchoolListMutation = require('../../apollo/mutations/UpdateTeacherSchoolList.gql')
const updateSchoolListToApprobationMutation = require('../../apollo/mutations/UpdateSchoolListToApprobation.gql')
const updateSchoolListToCanceledMutation = require('../../apollo/mutations/UpdateSchoolListToCanceled.gql')
const updateSchoolListToPublishedMutation = require('../../apollo/mutations/UpdateSchoolListToPublished.gql')
const updateSchoolListToReviewedMutation = require('../../apollo/mutations/UpdateSchoolListToReviewed.gql')
const deleteSchoolListMutation = require('../../apollo/mutations/DeleteSchoolList.gql')
const deleteTeacherSchoolListMutation = require('../../apollo/mutations/DeleteTeacherSchoolList.gql')
const duplicateSchoolListMutation = require('../../apollo/mutations/DuplicateSchoolList.gql')

const state = {
  pagination: {
    currentPage: 1,
    numberOfPages: 0,
    resultsPerPage: 50,
    totalCount: 0,
  },
  statuses: [
    'DRAFT',
    'PUBLISHED',
    'REVIEWED_BY_COOPZONE',
    'APPROBATION_BY_COOPZONE',
    'CANCELED',
  ],
  schoolLists: [],
  id: null,
  name: null,
  status: null,
  courseCode: null,
  option: null,
  nrcCode: null,
  notes: null,
  teacherName: null,
  teacherPhone: null,
  teacherEmail: null,
  depositAmount: null,
  annotations: [],
  Sections: [],
  EstablishmentId: null,
  TeacherId: null,
  SessionId: null,
}

const actions = {
  init({ commit }) {
    commit('SET_SCHOOL_LISTS', [])
  },

  async searchSchoolLists({ commit }, payload) {
    const { statusId, EstablishmentId, sessionId, sort, ...variables } = payload

    if (statusId) variables.status = statusId
    if (EstablishmentId) variables.EstablishmentId = EstablishmentId
    if (sessionId) variables.sessionId = sessionId
    if (sort) variables.sort = sort

    const {
      data: {
        schoolLists: { schoolLists, pageInfo: pagination },
      },
    } = await apolloClient.query({
      query: searchSchoolListsQuery,
      variables,
    })

    commit('SET_PAGINATION', pagination)
    commit('SET_SCHOOL_LISTS', schoolLists)
  },

  async getSchoolList({ commit, dispatch }, payload) {
    const { EstablishmentId } = payload
    const {
      data: { schoolList },
    } = await apolloClient.query({
      query: getSchoolListQuery,
      variables: payload,
    })
    await dispatch('categories/getCategories', {
      establishmentId: EstablishmentId,
      listId: schoolList.id,
    })
    commit('SET_SCHOOL_LIST', schoolList)
  },

  async createSchoolListByType({ dispatch, rootState }, type) {
    const role = rootState.accounts.account.role
    let mutation = createSchoolListMutation

    if (role === 'TEACHER') {
      mutation = createTeacherSchoolListMutation
    }

    if (type === 'grade') {
      await dispatch('createGradeSchoolList', { role, mutation })
    } else if (type === 'course') {
      await dispatch('createCourseSchoolList', { role, mutation })
    } else {
      await dispatch('createProgramList', { role, mutation })
    }
  },

  async createGradeSchoolList({ state, commit }, { role, mutation }) {
    const { name, status, option, notes, EstablishmentId, SessionId } = state
    const { data } = await apolloClient.query({
      query: mutation,
      variables: {
        establishmentId: EstablishmentId,
        schoolEstablishmentList: {
          name,
          status,
          option,
          notes,
          EstablishmentId,
          SessionId,
        },
      },
    })

    if (role === 'TEACHER') {
      commit('SET_SCHOOL_LIST', data.createdTeacherSchoolList)
    } else {
      commit('SET_SCHOOL_LIST', data.createdSchoolList)
    }
  },

  async createCourseSchoolList({ state, commit }, { role, mutation }) {
    const {
      name,
      status,
      courseCode,
      nrcCode,
      notes,
      teacherName,
      teacherPhone,
      teacherEmail,
      Sections,
      EstablishmentId,
      SessionId,
    } = state

    const schoolList = {
      name,
      status,
      courseCode,
      nrcCode,
      notes,
      teacherName,
      teacherPhone,
      teacherEmail,
      EstablishmentId,
      SessionId,
    }

    if (Sections) {
      schoolList.Sections = Sections.map((section) => section.id)
    }

    const { data } = await apolloClient.query({
      query: mutation,
      variables: {
        establishmentId: EstablishmentId,
        schoolEstablishmentList: schoolList,
      },
    })

    if (role === 'TEACHER') {
      commit('SET_SCHOOL_LIST', data.createdTeacherSchoolList)
    } else {
      commit('SET_SCHOOL_LIST', data.createdSchoolList)
    }
  },

  async createProgramList({ state, commit }, { role, mutation }) {
    const { name, status, depositAmount, EstablishmentId, SessionId } = state

    const schoolList = {
      name,
      status,
      depositAmount,
      EstablishmentId,
      SessionId,
    }

    const { data } = await apolloClient.query({
      query: mutation,
      variables: {
        establishmentId: EstablishmentId,
        schoolEstablishmentList: schoolList,
      },
    })

    commit('SET_SCHOOL_LIST', data.createdSchoolList)
  },

  async updateSchoolListByType(
    { dispatch, rootState },
    { currentEstablishmentId, type }
  ) {
    const mutation =
      rootState.accounts.account.role === 'TEACHER'
        ? updateTeacherSchoolListMutation
        : updateSchoolListMutation

    if (type === 'grade') {
      await dispatch('updateGradeSchoolList', {
        mutation,
        currentEstablishmentId,
      })
    } else if (type === 'course') {
      await dispatch('updateCourseSchoolList', {
        mutation,
        currentEstablishmentId,
      })
    } else {
      await dispatch('updateProgramList', {
        mutation,
        currentEstablishmentId,
      })
    }
  },
  async updateGradeSchoolList(
    { state: { id, name, status, option, notes, EstablishmentId, SessionId } },
    { mutation, currentEstablishmentId }
  ) {
    await apolloClient.query({
      query: mutation,
      variables: {
        establishmentId: currentEstablishmentId,
        listId: id,
        schoolEstablishmentList: {
          name,
          status,
          option,
          notes,
          EstablishmentId,
          SessionId,
        },
      },
    })
  },

  async updateCourseSchoolList(
    {
      state: {
        id,
        name,
        status,
        courseCode,
        nrcCode,
        notes,
        teacherName,
        teacherPhone,
        teacherEmail,
        Sections,
        EstablishmentId,
        SessionId,
      },
    },
    { mutation, currentEstablishmentId }
  ) {
    const schoolList = {
      name,
      status,
      courseCode,
      nrcCode,
      notes,
      teacherName,
      teacherPhone,
      teacherEmail,
      EstablishmentId,
      SessionId,
    }

    if (Sections) {
      schoolList.Sections = Sections.map((section) => section.id)
    }
    await apolloClient.query({
      query: mutation,
      variables: {
        establishmentId: currentEstablishmentId,
        listId: id,
        schoolEstablishmentList: schoolList,
      },
    })
  },

  async updateProgramList(
    { state: { id, name, status, depositAmount, EstablishmentId, SessionId } },
    { mutation, currentEstablishmentId }
  ) {
    const schoolList = {
      name,
      status,
      depositAmount,
      EstablishmentId,
      SessionId,
    }

    await apolloClient.query({
      query: mutation,
      variables: {
        establishmentId: currentEstablishmentId,
        listId: id,
        schoolEstablishmentList: schoolList,
      },
    })
  },

  async updateSchoolListToApprobation(context, payload) {
    const { id: listId, name, EstablishmentId } = payload

    await apolloClient.query({
      query: updateSchoolListToApprobationMutation,
      variables: {
        establishmentId: EstablishmentId,
        listId,
      },
    })
  },

  async updateSchoolListToCanceled(context, payload) {
    const {
      listId,
      currentEstablishmentId: establishmentId,
      annotation,
    } = payload

    await apolloClient.query({
      query: updateSchoolListToCanceledMutation,
      variables: {
        establishmentId,
        listId,
        annotation,
      },
    })
  },

  async updateSchoolListToPublished(context, payload) {
    const { listId, currentEstablishmentId: establishmentId } = payload

    await apolloClient.query({
      query: updateSchoolListToPublishedMutation,
      variables: {
        establishmentId,
        listId,
      },
    })
  },

  async updateSchoolListToReviewed(context, payload) {
    const {
      annotation,
      schoolList: {
        id: listId,
        name,
        courseCode,
        nrcCode,
        notes,
        teacherName,
        teacherPhone,
        teacherEmail,
        EstablishmentId,
        SessionId,
        Sections,
      },
    } = payload

    await apolloClient.query({
      query: updateSchoolListToReviewedMutation,
      variables: {
        establishmentId: EstablishmentId,
        listId,
        schoolEstablishmentList: {
          name,
          courseCode,
          nrcCode,
          notes,
          teacherName,
          teacherPhone,
          teacherEmail,
          EstablishmentId,
          SessionId,
          Sections: Sections.length ? Sections.map(({ id }) => id) : [],
        },
        annotation,
      },
    })
  },

  async deleteSchoolList({ commit, rootState }, payload) {
    const { id, EstablishmentId } = payload

    const mutation =
      rootState.accounts.account.role === 'TEACHER'
        ? deleteTeacherSchoolListMutation
        : deleteSchoolListMutation

    await apolloClient.query({
      query: mutation,
      variables: {
        establishmentId: EstablishmentId,
        listId: id,
      },
    })

    commit('SET_SCHOOL_LIST', {})
  },

  async duplicateSchoolList({ state, commit }, payload) {
    const { id, EstablishmentId } = payload

    const {
      data: { duplicatedSchoolList },
    } = await apolloClient.query({
      query: duplicateSchoolListMutation,
      variables: {
        establishmentId: EstablishmentId,
        listId: id,
      },
    })

    const duplicatedCategoryIndex = state.schoolLists.findIndex(
      (x) => x.id === id
    )
    commit('ADD_SCHOOL_LIST', {
      index: duplicatedCategoryIndex,
      duplicatedSchoolList,
    })
  },
}

const mutations = {
  SET_SCHOOL_LISTS(state, payload) {
    state.schoolLists = payload
  },

  ADD_SCHOOL_LIST(state, { index, duplicatedSchoolList }) {
    state.schoolLists.splice(index + 1, 0, duplicatedSchoolList)
  },

  SET_SCHOOL_LIST(
    state,
    {
      id = null,
      name = null,
      status = null,
      courseCode = null,
      option = null,
      nrcCode = null,
      notes = null,
      teacherName = null,
      teacherPhone = null,
      teacherEmail = null,
      depositAmount = null,
      Sections = [],
      EstablishmentId = null,
      SessionId = null,
      annotations = [],
      TeacherId = null,
    }
  ) {
    state.id = id
    state.name = name
    state.status = status
    state.courseCode = courseCode
    state.option = option
    state.nrcCode = nrcCode
    state.notes = notes
    state.teacherName = teacherName
    state.teacherPhone = teacherPhone
    state.teacherEmail = teacherEmail
    state.depositAmount = depositAmount
    state.Sections = Sections
    state.EstablishmentId = EstablishmentId
    state.SessionId = SessionId
    state.annotations = annotations
    state.TeacherId = TeacherId
  },

  SET_NAME(state, payload) {
    state.name = payload
  },

  SET_STATUS(state, payload) {
    state.status = payload
  },

  SET_COURSE_CODE(state, payload) {
    state.courseCode = payload
  },

  SET_OPTION(state, payload) {
    state.option = payload
  },

  SET_NRC_CODE(state, payload) {
    state.nrcCode = payload
  },

  SET_NOTES(state, payload) {
    state.notes = payload
  },

  SET_TEACHER_NAME(state, payload) {
    state.teacherName = payload
  },

  SET_TEACHER_PHONE(state, payload) {
    state.teacherPhone = payload
  },

  SET_TEACHER_EMAIL(state, payload) {
    state.teacherEmail = payload
  },

  SET_DEPOSIT_AMOUNT(state, payload) {
    state.depositAmount = payload
  },

  SET_SECTIONS(state, payload) {
    state.Sections = payload
  },

  SET_ESTABLISHMENT_ID(state, payload) {
    state.EstablishmentId = payload
  },

  SET_SESSION_ID(state, payload) {
    state.SessionId = payload
  },

  SET_PAGINATION(state, payload) {
    state.pagination = payload
  },
}

const getters = {
  schoolList: (state) => {
    return {
      id: state.id,
      name: state.name,
      status: state.status,
      courseCode: state.courseCode,
      option: state.option,
      nrcCode: state.nrcCode,
      notes: state.notes,
      teacherName: state.teacherName,
      teacherPhone: state.teacherPhone,
      teacherEmail: state.teacherEmail,
      depositAmount: state.depositAmount,
      annotations: state.annotations,
      Sections: state.Sections,
      EstablishmentId: state.EstablishmentId,
      SessionId: state.SessionId,
      TeacherId: state.TeacherId,
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  modules: {
    categories,
    sections,
  },
}
