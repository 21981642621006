<template>
  <BaseForm
    id="schoolEstablishmentForm"
    success-message="Établissement scolaire enregistré"
    :on-success="onSuccess"
    :redirect="redirect"
  >
    <b-card>
      <div slot="header">
        <b-row align-v="center">
          <b-col sm="12" lg="6">
            <p class="h6 text-uppercase text-muted ls-1 mb-1">
              {{ title }}
            </p>
            <h2 class="mb-0">
              {{ name }}
            </h2>
          </b-col>
          <b-col sm="12" lg="6" class="text-right">
            <slot name="actions" />
          </b-col>
        </b-row>
      </div>

      <b-row>
        <b-col class="col-sm-auto">
          <BaseInput
            rules="required"
            label="Logo de l'établissement"
            label-classes="form-control-label mb-3"
            class="establishment-logo"
          >
            <SingleFileUpload
              v-model="logo"
              label="Sélectionner une image"
              :formats="[{ width: 300, name: 'thumbnail' }]"
            />
          </BaseInput>
        </b-col>

        <b-col cols="12" lg="" class="mt-4 mt-md-0">
          <b-row>
            <b-col cols="12" lg="6">
              <BaseInput
                v-model="name"
                label="Nom"
                rules="required"
                data-cy="input-establishment-name"
              />
            </b-col>

            <b-col v-if="isUpdating" class="mt-4 mt-md-0">
              <BaseInput v-model="slug" label="Slug" />
            </b-col>

            <b-col cols="12" lg="6">
              <BaseInput label="Type d'établissement" rules="required">
                <select v-model="type" class="form-control">
                  <option
                    v-for="establishmentType in schoolEstablishmentTypes"
                    :key="establishmentType"
                    :value="establishmentType"
                  >
                    {{ $t(`establishments.types.${establishmentType}`) }}
                  </option>
                </select>
              </BaseInput>
            </b-col>
          </b-row>

          <b-row v-if="['ELEMENTARY', 'HIGHSCHOOL'].includes(type)">
            <b-col>
              <BaseInput label="Gestionnaires de listes scolaires">
                <TransferInput
                  v-model="accountIds"
                  :data="accountOptions"
                  :titles="[
                    'Liste des utilisateurs',
                    'Liste des gestionnaires',
                  ]"
                />
              </BaseInput>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>

    <b-card v-if="['ELEMENTARY', 'HIGHSCHOOL'].includes(type)">
      <b-row slot="header" align-v="center">
        <b-col cols="8">
          <h3 class="mb-0">Options de cueillette</h3>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col cols="12">
          <BaseCheckbox v-model="pickUpAvailable">
            Rendre la cueillette disponible aux étudiants
          </BaseCheckbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <TableInput
            v-slot="{ remove }"
            v-model="pickUps"
            title="Lieux de cueillette"
            @on-add="pickUps.push({})"
          >
            <el-table-column label="Nom" min-width="25px">
              <template #default="{ row }">
                <BaseInput
                  v-model="row.name"
                  rules="required"
                  placeholder="Indiquer le lieu et/ou heure de cueillette"
                />
              </template>
            </el-table-column>
            <el-table-column min-width="25px" align="right">
              <div slot-scope="{ row }" class="table-actions">
                <el-tooltip content="Supprimer" placement="top">
                  <a
                    href="#!"
                    class="table-action table-action-delete"
                    data-toggle="tooltip"
                    data-original-title="Supprimer"
                    @click.prevent="remove(row)"
                  >
                    <i class="fas fa-trash" />
                  </a>
                </el-tooltip>
              </div>
            </el-table-column>
          </TableInput>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      v-if="['ELEMENTARY', 'HIGHSCHOOL'].includes(type) && pickUpAvailable"
    >
      <b-row slot="header" align-v="center">
        <b-col cols="8">
          <h3 class="mb-0">Adresse de l'établissement</h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <CanadaPostInput
            v-model="addressLine1"
            name="establishmentAddressAddress"
            class="billingAddressAddress"
            label="Adresse"
            :rules="pickUpAvailable ? 'required' : ''"
            @fill="fillAddress"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <BaseInput
            v-model="addressLine2"
            label="Numéro appartement, local, bureau, etc. (optionnel)"
            name="establishmentAddressAddress2"
            type="text"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" sm="6">
          <BaseInput
            v-model="city"
            label="Ville"
            name="establishmentAddressCity"
            type="text"
            :rules="{ required: pickUpAvailable === true }"
          />
        </b-col>

        <b-col cols="12" sm="6">
          <BaseInput
            label="Pays"
            name="establishmentAddressCountry"
            :rules="{ required: pickUpAvailable === true }"
          >
            <FormSelectCountry
              v-model="country"
              label="Pays"
              name="establishmentAddressCountry"
            />
          </BaseInput>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" sm="6">
          <BaseInput
            v-model="postalCode"
            vid="address.postalCode"
            label="Code postal"
            name="establishmentAddressPostalCode"
            type="text"
            :rules="{ required: pickUpAvailable === true }"
          />
        </b-col>
        <b-col cols="12" sm="6">
          <BaseInput
            label="Province / État"
            name="establishmentAddressProvince"
            :rules="{ required: pickUpAvailable === true }"
          >
            <FormSelectState
              v-model="province"
              :country="country"
              :disabled="country === ''"
            />
          </BaseInput>
        </b-col>
      </b-row>
    </b-card>

    <b-row align-h="end">
      <b-col cols="auto">
        <BaseButton size="lg" type="primary" native-type="submit">
          Sauvegarder
        </BaseButton>
      </b-col>
    </b-row>
  </BaseForm>
</template>
<script>
import { BaseButton, BaseCheckbox, BaseInput } from '@coop-zone/coop-zone-argon'
import { mapGetters } from 'vuex'
import SingleFileUpload from '@/components/Inputs/SingleFileUpload'
import BaseForm from '@/components/forms/BaseForm'
import CanadaPostInput from '@/components/Inputs/CanadaPostInput'
import TransferInput from '@/components/Inputs/TransferInput'
import FormSelectCountry from '@/components/Inputs/FormSelectCountry'
import FormSelectState from '@/components/Inputs/FormSelectState'
import TableInput from '@/components/TableInput'

export default {
  components: {
    BaseForm,
    BaseButton,
    BaseInput,
    SingleFileUpload,
    CanadaPostInput,
    TransferInput,
    FormSelectCountry,
    FormSelectState,
    TableInput,
    BaseCheckbox,
  },
  props: {
    onSuccess: {
      type: Function,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    redirect: {
      type: [String, Boolean],
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      accountOptions: [],
    }
  },
  computed: {
    ...mapGetters('schoolEstablishments', ['schoolEstablishmentTypes']),
    isUpdating: function () {
      return !!this.$store.state.schoolEstablishments.id
    },
    logo: {
      get() {
        return this.$store.state.schoolEstablishments.logo
      },
      set(logo) {
        this.$store.commit('schoolEstablishments/SET_LOGO', logo)
      },
    },
    name: {
      get() {
        return this.$store.state.schoolEstablishments.name
      },
      set(name) {
        this.$store.commit('schoolEstablishments/SET_NAME', name)
      },
    },
    slug: {
      get() {
        return this.$store.state.schoolEstablishments.slug
      },
      set(slug) {
        this.$store.commit('schoolEstablishments/SET_SLUG', slug)
      },
    },
    type: {
      get() {
        return this.$store.state.schoolEstablishments.type
      },
      set(type) {
        this.$store.commit('schoolEstablishments/SET_TYPE', type)
      },
    },
    pickUpAvailable: {
      get() {
        return this.$store.state.schoolEstablishments.pickUpAvailable
      },
      set(availability) {
        this.$store.commit('schoolEstablishments/SET_ADDRESS', {})
        this.$store.commit(
          'schoolEstablishments/SET_PICK_UP_AVAILABLE',
          availability
        )
      },
    },
    pickUps: {
      get() {
        return this.$store.state.schoolEstablishments.pickUps
      },
      set(pickUps) {
        this.$store.commit('schoolEstablishments/SET_PICK_UPS', pickUps)
      },
    },
    address: {
      get() {
        return this.$store.state.schoolEstablishments.address
      },
      set(address) {
        this.$store.commit('schoolEstablishments/SET_ADDRESS', address)
      },
    },
    accountIds: {
      get() {
        return this.$store.state.schoolEstablishments.accountIds
      },
      set(accountIds) {
        this.$store.commit('schoolEstablishments/SET_ACCOUNT_IDS', accountIds)
      },
    },
    addressLine1: {
      get() {
        return this.$store.state.schoolEstablishments.address.address
      },
      set(addressLine1) {
        this.$store.commit('schoolEstablishments/SET_ADDRESS', {
          ...this.address,
          address: addressLine1,
        })
      },
    },
    addressLine2: {
      get() {
        return this.$store.state.schoolEstablishments.address.address2
      },
      set(addressLine2) {
        this.$store.commit('schoolEstablishments/SET_ADDRESS', {
          ...this.address,
          address2: addressLine2,
        })
      },
    },
    city: {
      get() {
        return this.$store.state.schoolEstablishments.address.city
      },
      set(city) {
        this.$store.commit('schoolEstablishments/SET_ADDRESS', {
          ...this.address,
          city,
        })
      },
    },
    country: {
      get() {
        return this.$store.state.schoolEstablishments.address.country
      },
      set(country) {
        this.$store.commit('schoolEstablishments/SET_ADDRESS', {
          ...this.address,
          country,
        })
      },
    },
    postalCode: {
      get() {
        return this.$store.state.schoolEstablishments.address.postalCode
      },
      set(postalCode) {
        this.$store.commit('schoolEstablishments/SET_ADDRESS', {
          ...this.address,
          postalCode,
        })
      },
    },
    province: {
      get() {
        return this.$store.state.schoolEstablishments.address.province
      },
      set(province) {
        this.$store.commit('schoolEstablishments/SET_ADDRESS', {
          ...this.address,
          province,
        })
      },
    },
  },
  async created() {
    this.loading = true
    const params = {
      active: true,
      role: 'SCHOOL',
    }
    const { accounts } = await this.$store.dispatch('users/search', params)
    this.accountOptions = accounts.map((account) => {
      return {
        key: account.id,
        label: `${account.firstName} ${account.lastName}`,
      }
    })
    this.loading = false
  },
  methods: {
    fillAddress(address) {
      this.addressLine1 = address.Line1
      this.addressLine2 = address.Line2
      this.city = address.City
      this.country = address.CountryIso2
      this.postalCode = address.PostalCode
      this.province = address.ProvinceCode
    },
  },
}
</script>

<style lang="sass" scoped>
.establishment-logo::v-deep .avatar
  height: 150px
  width: 150px
</style>
