<template>
  <el-table
    class="table-responsive align-items-center table-flush table-striped"
    header-row-class-name="thead-light"
    :data="priceTypes"
  >
    <el-table-column label="Type" prop="type" />
    <el-table-column label="Régulier">
      <template #default="{ row }">
        <CurrencyInput
          :rules="{ required: productType === 'Product' }"
          :disabled="productType === 'Bundle'"
          :value="value.prices[row.slug]"
          @input="updatePrices('prices', row.slug, $event)"
        />
      </template>
    </el-table-column>
    <el-table-column label="Spécial">
      <template #default="{ row }">
        <CurrencyInput
          v-if="row.type != 'Non-membre'"
          :disabled="value.prices[row.slug] === null"
          :value="value.specialPrices[row.slug]"
          @input="updatePrices('specialPrices', row.slug, $event)"
        />
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
import CurrencyInput from '@/components/Inputs/CurrencyInput'

export default {
  components: {
    CurrencyInput,
  },
  props: {
    productType: {
      type: String,
      default: 'Product',
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      priceTypes: [
        {
          type: 'Non-membre',
          slug: 'regular',
        },
        {
          type: 'Membre',
          slug: 'member',
        },
        {
          type: 'Employé',
          slug: 'employee',
        },
        {
          type: 'Institutionnel',
          slug: 'institute',
        },
        {
          type: 'Entreprise',
          slug: 'business',
        },
        {
          type: 'Artiste professionnel',
          slug: 'professional_artist',
        },
        {
          type: 'Biblio UL',
          slug: 'library',
        },
        {
          type: 'Artiste émergent',
          slug: 'emerging_artist',
        },
        {
          type: 'CJMS',
          slug: 'cjms',
        },
        {
          type: 'Spécial 1',
          slug: 'special_1',
        },
        {
          type: 'Spécial 2',
          slug: 'special_2',
        },
        {
          type: 'Spécial 3',
          slug: 'special_3',
        },
        {
          type: 'Spécial 4',
          slug: 'special_4',
        },
      ],
    }
  },
  // watch: {
  //   'value.prices': {
  //     // Sets specialPrice to null if price is null
  //     handler(newPrices) {
  //       const priceTypes = Object.keys(newPrices);
  //       const nulls = priceTypes.reduce((result, type) => {
  //         if (newPrices[type] === null) {
  //           return [...result, type];
  //         }
  //         return [...result];
  //       }, []);

  //       nulls.forEach((type) => {
  //         if (this.value.specialPrices[type] !== null) {
  //           this.updatePrices('specialPrices', type, null);
  //         }
  //       });
  //     },
  //     deep: true,
  //   },
  // },
  methods: {
    updatePrices(type, key, price) {
      this.$emit('input', {
        ...this.value,
        [type]: { ...this.value[type], [key]: price },
      })
    },
  },
}
</script>
