<template>
  <div class="content">
    <base-header class="pb-6 bg-default">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="12">
          <h1 class="h2 text-white d-inline-block mb-0">
            Liste des exportations de commandes
          </h1>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <BaseTableSearch
        :data="exports"
        placeholder="Num. de commande, num. de membre, adresse courriel ou nom du client"
      >
        <template #header>
          <b-row slot="header" align-v="center">
            <b-col>
              <h3 class="mb-0">Exportations</h3>
            </b-col>
            <b-col class="text-right">
              <BaseButton
                size="sm"
                :disabled="loading"
                @click="$refs.drawer.open()"
              >
                <i class="fas fa-file-export" />
                Exporter
              </BaseButton>
            </b-col>
          </b-row>
        </template>

        <template #drawer>
          <BaseDrawer ref="drawer" :loading="loading">
            <template #default>
              <b-card no-body>
                <b-card-header>
                  <b-row>
                    <b-col cols="8">
                      <h2 class="mb-0">
                        <h2>Filtrer les résultats de l'exportation</h2>
                      </h2>
                    </b-col>
                    <b-col cols="4" class="text-right">
                      <BaseButton
                        type="secondary"
                        icon
                        size="sm"
                        @click="clearFilters"
                      >
                        <span class="btn-inner--icon"
                          ><i class="fas fa-minus-circle"
                        /></span>
                        <span class="btn-inner--text">Effacer les filtres</span>
                      </BaseButton>
                    </b-col>
                  </b-row>
                </b-card-header>
                <b-card-body>
                  <b-row>
                    <b-col md="12">
                      <b-card no-body>
                        <b-card-header>
                          <h3 class="mb-0">Date de création</h3>
                        </b-card-header>
                        <b-card-body>
                          <b-row class="form-group mt-4">
                            <b-col md="6">
                              <BaseInput
                                label="De"
                                form-group-classes="mb-0"
                                prepend-icon="ni ni-calendar-grid-58"
                              >
                                <flat-pickr
                                  v-model="exportFilters.from"
                                  :config="flatPickrConfig"
                                  class="form-control datepicker"
                                />
                              </BaseInput>
                            </b-col>
                            <b-col md="6">
                              <BaseInput
                                label="À"
                                form-group-classes="mb-0"
                                prepend-icon="ni ni-calendar-grid-58"
                              >
                                <flat-pickr
                                  v-model="exportFilters.to"
                                  :config="flatPickrConfig"
                                  class="form-control datepicker"
                                />
                              </BaseInput>
                            </b-col>
                          </b-row>
                        </b-card-body> </b-card
                    ></b-col>
                    <b-col md="6">
                      <b-card no-body>
                        <b-card-header>
                          <b-row>
                            <b-col md="8">
                              <h3 class="mb-0">Type</h3>
                            </b-col>
                            <b-col md="4" class="text-right">
                              <BaseButton
                                type="secondary"
                                size="sm"
                                @click="selectAllExportOrderTypes"
                                >Tous</BaseButton
                              >
                            </b-col>
                          </b-row>
                        </b-card-header>
                        <b-card-body>
                          <b-row class="form-group mt-4">
                            <b-col md="12">
                              <b-form-checkbox
                                v-for="type in orderTypes"
                                :key="type.id"
                                v-model="exportFilters.types"
                                :value="type.id"
                                >{{ type.name }}</b-form-checkbox
                              >
                            </b-col></b-row
                          >
                        </b-card-body>
                      </b-card>
                    </b-col>
                    <b-col md="6">
                      <b-card no-body>
                        <b-card-header>
                          <b-row>
                            <b-col md="8">
                              <h3 class="mb-0">Statut</h3>
                            </b-col>
                            <b-col md="4" class="text-right">
                              <BaseButton
                                type="secondary"
                                size="sm"
                                @click="selectAllExportOrderStatuses"
                                >Tous</BaseButton
                              >
                            </b-col>
                          </b-row>
                        </b-card-header>
                        <b-card-body>
                          <b-row class="form-group mt-4">
                            <b-col md="12">
                              <b-form-checkbox
                                v-for="status in statuses"
                                :key="status.id"
                                v-model="exportFilters.statuses"
                                :value="status.id"
                                >{{ status.name }}</b-form-checkbox
                              >
                            </b-col>
                          </b-row>
                        </b-card-body>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </template>
            <template #footer="{ cancel, close }">
              <BaseButton ref="closeButton" @click="cancel">Annuler</BaseButton>
              <BaseButton
                :disabled="loading"
                type="primary"
                @click="handleExport(close)"
              >
                <i class="fa fa-download" />
                Exporter
              </BaseButton>
            </template>
          </BaseDrawer>
        </template>

        <el-table-column
          prop="requestDate"
          sortable
          label="Date de requête"
          width="225px"
        >
          <template slot-scope="scope">
            {{ scope.row.requestDate | dateFormatDefaultWithTime }}
          </template>
        </el-table-column>

        <el-table-column prop="status" sortable label="Statut" width="150px">
          <template slot-scope="scope">
            {{ $t(`exports.statuses.${scope.row.status}`) }}
          </template>
        </el-table-column>

        <el-table-column
          prop="completeDate"
          sortable
          label="Complété le"
          width="225px"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.completeDate">
              {{ scope.row.completeDate | dateFormatDefaultWithTime }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          prop="account.firstName"
          sortable
          label="Demandé par"
          width="175px"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.account">
              {{ scope.row.account.firstName }} {{ scope.row.account.lastName }}
            </span>
          </template>
        </el-table-column>

        <el-table-column label="Filtres" min-width="200">
          <template slot-scope="scope">
            <el-popover
              v-if="scope.row.query"
              placement="top-start"
              trigger="hover"
            >
              <code>{{ scope.row.query }}</code>
              <code slot="reference">
                {{ scope.row.query }}
              </code>
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column label="Erreur" min-width="200px">
          <template slot-scope="scope">
            {{ scope.row.exception }}
          </template>
        </el-table-column>

        <el-table-column align="right" width="175px">
          <template slot-scope="scope" class="table-actions">
            <DownloadFileButton
              v-if="scope.row.status === 'COMPLETED'"
              action="utils/getExportFile"
              :filename="scope.row.fileName"
              :loading="loading"
              @loading="loading = $event"
            />
          </template>
        </el-table-column>
      </BaseTableSearch>
    </b-container>
  </div>
</template>
<script>
import 'flatpickr/dist/flatpickr.css'
import { French as locale } from 'flatpickr/dist/l10n/fr'

import {
  BaseHeader,
  BaseButton,
  BaseInput,
  RouteBreadcrumb,
} from '@coop-zone/coop-zone-argon'
import { mapState } from 'vuex'
import BaseTableSearch from '@/components/BaseTableSearch'
import BaseDrawer from '@/components/BaseDrawer'
import DownloadFileButton from '@/components/DownloadFileButton'
import ErrorHandlerMixin from '@/mixins/errorHandler.mixin'

const exportFilters = { from: null, to: null, types: [], statuses: [] }

export default {
  components: {
    BaseHeader,
    BaseButton,
    BaseTableSearch,
    BaseDrawer,
    BaseInput,
    DownloadFileButton,
    RouteBreadcrumb,
  },
  mixins: [ErrorHandlerMixin],
  data() {
    return {
      loading: false,
      refreshTimeout: null,
      exports: [],
      exportFilters: { ...exportFilters },
      orderTypes: [
        {
          id: 'REGULAR',
          name: 'Régulière',
        },
        {
          id: 'ELEMENTARY',
          name: 'Primaire',
        },
        {
          id: 'HIGHSCHOOL',
          name: 'Secondaire',
        },
        {
          id: 'COLLEGE',
          name: 'Collégial',
        },
        {
          id: 'UNIVERSITY',
          name: 'Université',
        },
        {
          id: 'PROGRAM',
          name: 'Programe portable',
        },
      ],
      flatPickrConfig: {
        allowInvalidPreload: true,
        dateFormat: 'Z',
        altFormat: 'Y-m-d',
        altInput: true,
        locale,
      },
    }
  },
  computed: {
    ...mapState('orders', ['statuses']),
    formattedFilters() {
      const filters = {}
      Object.keys(this.exportFilters).forEach((key) => {
        if (this.exportFilters[key]) {
          if (
            Array.isArray(this.exportFilters[key]) === false ||
            this.exportFilters[key].length > 0
          ) {
            filters[key] = this.exportFilters[key]
          }
        }
      })
      return filters
    },
  },
  async created() {
    this.loading = true
    await this.fetch()
    this.loading = false

    this.refreshTimeout = setInterval(async () => {
      await this.fetch()
    }, 15000)
  },
  beforeDestroy() {
    clearInterval(this.refreshTimeout)
  },
  methods: {
    async fetch() {
      this.exports = await this.$store.dispatch('utils/getExportQueue', 'order')
    },
    async handleExport(drawerCloseCb) {
      await this.export()
      drawerCloseCb()
    },
    async export() {
      try {
        this.loading = true
        this.exports.unshift(
          await this.$store.dispatch('utils/createExportation', {
            type: 'order',
            query: this.formattedFilters,
          })
        )

        this.$notify({
          message: 'La requête a bien été enregistrée',
          timeout: 5000,
          icon: 'ni ni-check-bold',
          type: 'success',
        })

        this.loading = false
      } catch (error) {
        this.loading = false
        this.handleError(error)
      }
    },
    clearFilters() {
      Object.keys(this.exportFilters).forEach((key) => {
        this.exportFilters[key] = exportFilters[key]
      })
    },
    selectAllExportOrderTypes() {
      this.exportFilters.types = this.orderTypes.map(({ id }) => id)
    },
    selectAllExportOrderStatuses() {
      this.exportFilters.statuses = this.statuses.map(({ id }) => id)
    },
  },
}
</script>
