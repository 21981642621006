import Vue from 'vue'

const state = {
  blocks: [],
}

const actions = {
  addBlock({ state, commit }, { type }) {
    commit('ADD_BLOCK', {
      type,
      active: false,
    })
    return state.blocks.length - 1
  },
}

const getters = {
  getContent: () =>
    state.blocks.map((block) => {
      const { active, ...content } = block
      return active ? content : null
    }),
}

const mutations = {
  ADD_BLOCK(state, payload) {
    state.blocks.push(payload)
  },
  SET_CONTENT(state, { index, content }) {
    Vue.set(state.blocks, index, { ...state.blocks[index], ...content })
  },
  SET_ACTIVE(state, { index, active }) {
    Vue.set(state.blocks[index], 'active', active)
  },
  RESET_BLOCKS(state) {
    state.blocks = []
  },
  RESET_ACTIVE_STATE(state) {
    state.blocks.forEach((block) => {
      block.active = false
    })
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
