<template>
  <div class="content">
    <base-header class="pb-6 bg-default">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="12">
          <h6 class="h2 text-white d-inline-block mb-0">Promotions</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-card>
        <b-row slot="header" align-v="center">
          <b-col cols="8">
            <h3 class="mb-0">Code Promos</h3>
          </b-col>
          <b-col cols="4" class="text-right">
            <router-link
              class="btn btn-sm btn-primary"
              to="/promotions/codes/add"
            >
              Ajouter un code promo
            </router-link>
          </b-col>
        </b-row>
        <el-table
          :data="searchedPromoCodes"
          row-key="id"
          header-row-class-name="thead-light"
        >
          <el-table-column
            label="Nom"
            min-width="140px"
            sortable
            sort-by="name"
            :sort-method="(a, b) => a.name.localeCompare(b.name)"
          >
            <template slot-scope="scope">
              <b-media no-body class="align-items-center">
                <b-media-body>
                  <router-link :to="`/promotions/codes/edit/${scope.row.id}`">
                    <span class="font-weight-600 name mb-0 text-sm">{{
                      scope.row.name
                    }}</span>
                  </router-link>
                </b-media-body>
              </b-media>
            </template>
          </el-table-column>

          <el-table-column
            label="Code"
            prop="code"
            sortable
            sort-by="code"
            :sort-method="(a, b) => a.code.localeCompare(b.code)"
          />

          <el-table-column
            label="Date de début"
            min-width="85px"
            sortable
            sort-by="startDate"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.startDate">
                {{ scope.row.startDate | dateFormatDefault }}
              </span>
            </template>
          </el-table-column>

          <el-table-column
            label="Date de fin"
            min-width="85px"
            sortable
            sort-by="endDate"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.endDate">
                {{ scope.row.endDate | dateFormatDefault }}
              </span>
            </template>
          </el-table-column>

          <el-table-column
            label="Limite d'utilisation"
            min-width="100px"
            sortable
            sort-by="quantity"
          >
            <template slot-scope="scope">
              {{ scope.row.quantity || 'N/A' }}
            </template>
          </el-table-column>

          <el-table-column
            label="Type"
            min-width="100px"
            sortable
            sort-by="appliedTo"
          >
            <template slot-scope="scope">
              {{ $t(`promoCodes.appliedTo.${scope.row.appliedTo}`) }}
            </template>
          </el-table-column>

          <el-table-column
            label="Rabais"
            min-width="100px"
            sortable
            sort-by="value"
          >
            <template slot-scope="scope">
              {{
                scope.row.type === 'PERCENTAGE'
                  ? scope.row.value
                  : scope.row.value / 100
              }}
              {{ scope.row.type === 'PERCENTAGE' ? '%' : '$' }}
            </template>
          </el-table-column>

          <el-table-column align="right">
            <template slot-scope="scope" class="table-actions">
              <router-link
                class="table-action table-action-edit"
                :to="`/promotions/codes/edit/${scope.row.id}`"
              >
                <i class="fa fa-edit" />
              </router-link>

              <a
                href="#!"
                class="table-action table-action-delete"
                data-toggle="tooltip"
                data-original-title="Supprimer"
                @click="deletePromoCode(scope.row.id)"
              >
                <i class="fas fa-trash" />
              </a>
            </template>
          </el-table-column>
        </el-table>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import swal from 'sweetalert2'
import { BaseHeader, RouteBreadcrumb } from '@coop-zone/coop-zone-argon'
import { mapState, mapActions } from 'vuex'
import ErrorHandlerMixin from '@/mixins/errorHandler.mixin'
import getEnv from '@/utils/env'

export default {
  components: {
    BaseHeader,
    RouteBreadcrumb,
  },
  mixins: [ErrorHandlerMixin],
  data() {
    return {
      websiteUrl: getEnv('VUE_APP_WEBSITE_URL'),
      term: '',
      page: 1,
    }
  },
  computed: {
    ...mapState('promoCodes', ['pagination', 'searchedPromoCodes']),
  },
  watch: {
    term() {
      this.search()
    },
    page() {
      this.search()
    },
  },
  created() {
    if (this.$route.query.term) this.term = this.$route.query.term
    this.search()
  },
  methods: {
    ...mapActions('promoCodes', ['searchPromos']),
    async search() {
      await this.searchPromos()
    },
    async deletePromoCode(id) {
      try {
        const html =
          '<h2>Êtes-vous certain?</h2><p>Le produit sera supprimé.</p>'
        const { isConfirmed } = await swal.fire({
          html,
          confirmButtonText: 'Supprimer le code promo',
          cancelButtonText: 'Annuler',
          showCancelButton: true,
        })

        if (!isConfirmed) return
        await this.$store.dispatch('promoCodes/deletePromoCode', id)
        this.search()
      } catch (error) {
        this.handleError(error)
        throw error
      }
    },
  },
}
</script>

<style>
.no-border-card .card-footer {
  border-top: 0;
}
.align-items-left {
  text-align: left;
}
</style>
