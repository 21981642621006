<template>
  <div>
    <BaseHeader class="pb-6" type="default">
      <b-row align-v="center" class="py-4">
        <b-col>
          <p class="h2 text-white d-inline-block mb-0">
            Programmes d'achat d'ordinateurs
          </p>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </BaseHeader>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="12">
          <ComputerSalesProgramForm
            :on-success="save"
            title="Créer un programme"
            redirect="/school-lists/computer-sales-programs/index"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { RouteBreadcrumb, BaseHeader } from '@coop-zone/coop-zone-argon'
import ComputerSalesProgramForm from '@/components/ComputerSalesPrograms/ComputerSalesProgramForm'

export default {
  components: {
    ComputerSalesProgramForm,
    RouteBreadcrumb,
    BaseHeader,
  },
  created() {
    this.$store.commit('schoolEstablishments/SET_SCHOOL_ESTABLISHMENT', {})
  },
  methods: {
    async save() {
      await this.$store.dispatch(
        'schoolEstablishments/createSchoolEstablishment'
      )
    },
  },
}
</script>
