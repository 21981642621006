<template>
  <div>
    <BaseHeader class="pb-6" type="default">
      <b-row align-v="center" class="py-4">
        <b-col cols="12">
          <h1 class="h2 text-white d-inline-block mb-0">
            Modifier les frais de manutention
          </h1>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </BaseHeader>

    <b-container fluid class="mt--6">
      <HandlingFeesForm
        v-loading="waitForUtils"
        :on-success="saveProductsHandlingFees"
        :handling-fees="productHandlingFees"
        title="Frais de manutention par type de produit"
        type="product_type"
      >
        <el-table-column label="Type de produit" min-width="310px">
          <template #default="{ row }">
            <span>{{ $t(`handlingFees.type.${row.type}`) }}</span>
          </template>
        </el-table-column>
      </HandlingFeesForm>

      <HandlingFeesForm
        v-loading="waitForUtils"
        :on-success="saveSchoolsHandlingFees"
        :handling-fees="schoolHandlingFees"
        title="Frais de manutention par type de matériel scolaire"
        type="school_list"
      >
        <el-table-column label="Type de matériel scolaire" min-width="310px">
          <template #default="{ row }">
            <span>{{ $t(`handlingFees.type.${row.type}`) }}</span>
          </template>
        </el-table-column>
      </HandlingFeesForm>

      <CategoryHandlingFeesForm
        v-loading="waitForUtils"
        :on-success="saveCategories"
        :is-loading="waitForUtils"
        :selected-categories="selectedCategories"
        title="Frais de manutention par catégorie de produit"
        @input="selectedCategories = $event"
      />
    </b-container>
  </div>
</template>

<script>
import { RouteBreadcrumb, BaseHeader } from '@coop-zone/coop-zone-argon'
import { mapState } from 'vuex'
import BaseForm from '@/components/forms/BaseForm'
import HandlingFeesForm from '@/components/Shipping/HandlingFees/HandlingFeesForm'
import CategoryHandlingFeesForm from '@/components/Shipping/HandlingFees/CategoryHandlingFeesForm'

export default {
  components: {
    BaseHeader,
    HandlingFeesForm,
    CategoryHandlingFeesForm,
    RouteBreadcrumb,
  },
  data() {
    return {
      waitForUtils: false,
      productHandlingFees: [],
      schoolHandlingFees: [],
      selectedCategories: [],
    }
  },
  computed: {
    ...mapState('shipping/handlingFees', [
      'handlingFees',
      'categoriesWithHandlingFees',
    ]),
  },
  watch: {
    handlingFees(newValue) {
      if (newValue) {
        this.productHandlingFees = newValue.filter(
          (handlingFee) => handlingFee.category === 'PRODUCT'
        )

        this.schoolHandlingFees = newValue.filter(
          (handlingFee) => handlingFee.category === 'SCHOOL_LIST'
        )
      }
    },
    categoriesWithHandlingFees(newValue) {
      if (newValue) {
        this.selectedCategories = newValue
      }
    },
  },
  async created() {
    this.waitForUtils = true
    await this.$store.dispatch('shipping/handlingFees/getHandlingFees')
    await this.$store.dispatch('utils/getTreeselectCategories')
    this.waitForUtils = false
  },
  methods: {
    async saveProductsHandlingFees() {
      await this.$store.dispatch(
        'shipping/handlingFees/updateHandlingFees',
        this.productHandlingFees
      )
    },
    async saveSchoolsHandlingFees() {
      await this.$store.dispatch(
        'shipping/handlingFees/updateHandlingFees',
        this.schoolHandlingFees
      )
    },
    async saveCategories() {
      await this.$store.dispatch(
        'shipping/handlingFees/saveCategories',
        this.selectedCategories
      )
    },
  },
}
</script>
