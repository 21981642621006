<template>
  <div>
    <BaseButton icon type="primary" @click="$refs.fileInput.click()">
      <span class="btn-inner--icon"><i class="ni ni-image" /></span>
      <span v-if="label" class="btn-inner--text">
        {{ label }}
      </span>
    </BaseButton>

    <input
      v-if="multiple"
      ref="fileInput"
      type="file"
      multiple="multiple"
      @change="handleFileSelect"
    />
    <input v-else ref="fileInput" type="file" @change="handleFileSelect" />
  </div>
</template>

<script>
import { BaseButton } from '@coop-zone/coop-zone-argon'

export default {
  components: {
    BaseButton,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async handleFileSelect(event) {
      const { target } = event
      if (target.files.length) {
        this.$emit('change', target.files)
      }
      target.value = ''
    },
  },
}
</script>

<style lang="sass" scoped>
.custom-file
  height: auto
  position: relative
  z-index: 1
  -ms-flex-order: -1
  -webkit-box-ordinal-group: 0
  -webkit-transition: all .15s ease
  background-color: #fff
  border-radius: .375rem
  border: 1px dashed #dee2e6
  cursor: pointer
  display: block
  order: -1
  padding: 5rem 1rem
  text-align: center
  transition: all .15s ease
  width: 100%
  z-index: 999

input
  display: none
</style>
