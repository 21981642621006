import BlankRouterView from '@/views/Layout/BlankRouterView'
import TaxesIndex from '@/views/Dashboard/TaxesRules/Taxes/TaxesIndex'
import TaxesAdd from '@/views/Dashboard/TaxesRules/Taxes/TaxesAdd'
import TaxesEdit from '@/views/Dashboard/TaxesRules/Taxes/TaxesEdit'
import GroupsIndex from '@/views/Dashboard/TaxesRules/Groups/GroupsIndex'
import GroupsAdd from '@/views/Dashboard/TaxesRules/Groups/GroupsAdd'
import GroupsEdit from '@/views/Dashboard/TaxesRules/Groups/GroupsEdit'

export default {
  path: '/taxes_rules',
  name: 'Règles de taxes',
  redirect: '/taxes_rules/taxes/index',
  component: BlankRouterView,
  children: [
    {
      path: '/taxes_rules/taxes',
      redirect: '/taxes_rules/taxes/index',
      name: 'Taxes',
      component: BlankRouterView,
      meta: {
        roles: ['ADMIN'],
      },
      children: [
        {
          path: '/taxes_rules/taxes/index',
          name: 'Liste',
          component: TaxesIndex,
          meta: {
            roles: ['ADMIN'],
          },
        },
        {
          path: '/taxes_rules/taxes/add',
          name: 'Créer une taxe',
          component: TaxesAdd,
          meta: {
            roles: ['ADMIN'],
          },
        },
        {
          path: '/taxes_rules/taxes/edit/:id',
          name: 'Modifier une taxe',
          component: TaxesEdit,
          meta: {
            roles: ['ADMIN'],
          },
        },
      ],
    },
    {
      path: '/taxes_rules/groups',
      redirect: '/taxes_rules/groups/index',
      name: 'Groupes de règles',
      component: BlankRouterView,
      meta: {
        roles: ['ADMIN'],
      },
      children: [
        {
          path: '/taxes_rules/groups/index',
          name: 'Liste des groupes',
          component: GroupsIndex,
          meta: {
            roles: ['ADMIN'],
          },
        },
        {
          path: '/taxes_rules/groups/add',
          name: 'Créer un groupe',
          component: GroupsAdd,
          meta: {
            roles: ['ADMIN'],
          },
        },
        {
          path: '/taxes_rules/groups/edit/:id',
          name: 'Modifier un groupe',
          component: GroupsEdit,
          meta: {
            roles: ['ADMIN'],
          },
        },
      ],
    },
  ],
}
