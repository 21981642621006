import BlankRouterView from '@/views/Layout/BlankRouterView'
import PagesSearch from '@/views/Dashboard/Pages/PagesSearch'
import PageEdit from '@/views/Dashboard/Pages/PageEdit'
import PageAdd from '@/views/Dashboard/Pages/PageAdd'

export default {
  path: '/pages',
  name: 'Pages',
  redirect: '/pages/index',
  component: BlankRouterView,
  children: [
    {
      path: '/pages/index',
      name: 'Rechercher une page',
      component: PagesSearch,
      meta: {
        roles: ['ADMIN', 'SUPER_EDITOR', 'EDITOR'],
      },
    },
    {
      path: '/pages/add',
      name: 'Ajouter une page',
      component: PageAdd,
      meta: {
        roles: ['ADMIN', 'SUPER_EDITOR', 'EDITOR'],
      },
    },
    {
      path: '/pages/edit/:id',
      name: 'Modifier une page',
      component: PageEdit,
      meta: {
        roles: ['ADMIN', 'SUPER_EDITOR', 'EDITOR'],
      },
    },
  ],
}
