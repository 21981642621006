<template>
  <el-select
    placeholder="Sélectionner une boites de réception"
    class="mr-3"
    :size="size"
    :value="value"
    :disabled="disabled || (receptionBoxes && receptionBoxes.length == 0)"
    @input="$emit('input', $event)"
  >
    <slot />
    <el-option
      v-for="{ id, name } in receptionBoxes"
      :key="id"
      :value="id"
      :label="name"
    />
  </el-select>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState('receptionBoxes', ['receptionBoxes']),
  },
  created() {
    this.$store.dispatch('receptionBoxes/getAllReceptionBoxes')
  },
}
</script>
