<template>
  <el-select
    :value="value"
    :disabled="disabled"
    @change="$emit('input', $event)"
  >
    <slot />
    <el-option
      v-for="{ id, name } in getSchoolEstablishments(type)"
      :key="id"
      :value="id"
      :label="name"
    />
  </el-select>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    types: {
      type: Array,
      default: null,
    },
    type: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('schoolEstablishments', ['getSchoolEstablishments']),
  },
  created() {
    this.$store.dispatch('schoolEstablishments/getSchoolEstablishments')
  },
}
</script>
