import Vue from 'vue'
import { apolloClient } from '@/apollo'

import groups from './groups.module'

const getTaxesQuery = require('../../apollo/queries/GetTaxes.gql')
const getTaxeQuery = require('../../apollo/queries/GetTax.gql')
const createTaxeMutation = require('../../apollo/mutations/CreateTax.gql')
const updateTaxeMutation = require('../../apollo/mutations/UpdateTax.gql')
const deleteTaxeMutation = require('../../apollo/mutations/DeleteTax.gql')

const state = {
  taxes: [],
  id: null,
  name: '',
  code: '',
  percentage: '',
}

const actions = {
  async getTaxes({ commit }) {
    const {
      data: { taxes },
    } = await apolloClient.query({
      query: getTaxesQuery,
    })
    commit('SET_TAXES', taxes)
  },
  async getTaxe({ commit }, id) {
    const {
      data: { tax },
    } = await apolloClient.query({
      query: getTaxeQuery,
      variables: { id },
    })
    if (tax) {
      commit('SET_TAXE', tax)
    }
  },
  createTaxe({ state: { name = '', code = '', percentage = '' } }) {
    apolloClient.query({
      query: createTaxeMutation,
      variables: {
        tax: {
          name,
          code,
          percentage: parseFloat(percentage),
        },
      },
    })
  },
  updateTaxe({ state: { id, name = '', code = '', percentage = '' } }) {
    apolloClient.query({
      query: updateTaxeMutation,
      variables: {
        id,
        tax: {
          name,
          percentage: parseFloat(percentage),
          code,
        },
      },
    })
  },
  deleteTaxe(context, id) {
    apolloClient.query({
      query: deleteTaxeMutation,
      variables: { id },
    })
  },
}

const mutations = {
  SET_TAXES(state, payload) {
    state.taxes = payload
  },
  SET_TAXE(state, { id = null, name = '', code = '', percentage = '' }) {
    state.id = id
    state.name = name
    state.code = code
    state.percentage = percentage
  },
  SET_NAME(state, name) {
    state.name = name
  },
  SET_CODE(state, code) {
    state.code = code
  },
  SET_PERCENTAGE(state, percentage) {
    state.percentage = percentage
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    groups,
  },
}
