<i18n>
{
  "en": {
    "culture": "en-CA"
  },
  "fr": {
    "culture": "fr-CA"
  }
}
</i18n>
<template>
  <BaseInput
    :value="value"
    :name="id"
    :label="label"
    :rules="rules"
    type="text"
    :autocomplete="false"
    :disabled="disabled"
    @input="$emit('input', $event)"
  />
</template>

<script>
import uniqid from 'uniqid'
import { BaseInput } from '@coop-zone/coop-zone-argon'

export default {
  components: { BaseInput },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      default: 'required',
    },
  },
  data() {
    return {
      id: uniqid(),
      address: null,
    }
  },
  mounted() {
    const canadaPostScript = document.getElementById('canadaPostScript')
    const canadaPostStyle = document.getElementById('canadaPostStyle')
    if (!canadaPostStyle) {
      const style = document.createElement('link')
      style.setAttribute('rel', 'stylesheet')
      style.setAttribute('type', 'text/css')
      style.setAttribute(
        'href',
        'https://ws1.postescanada-canadapost.ca/css/addresscomplete-2.30.min.css?key=fy72-pf71-kx39-pk49'
      )
      style.id = 'canadaPostStyle'
      document.head.appendChild(style)
    }
    if (!canadaPostScript) {
      const script = document.createElement('script')
      script.setAttribute(
        'src',
        'https://ws1.postescanada-canadapost.ca/js/addresscomplete-2.30.min.js?key=fy72-pf71-kx39-pk49'
      )
      script.id = 'canadaPostScript'
      const addedScript = document.head.appendChild(script)
      addedScript.onload = () => {
        this.initCanadaPost()
      }
    } else {
      const interval = setInterval(() => {
        if (window.pca) {
          this.initCanadaPost()
          clearInterval(interval)
        }
      }, 100)
    }
  },
  beforeDestroy() {
    const canadaPostScript = document.getElementById('canadaPostScript')
    if (canadaPostScript) {
      document.head.removeChild(canadaPostScript)
    }
  },
  methods: {
    initCanadaPost() {
      const fields = [
        { element: this.id, field: '', mode: window.pca.fieldMode.SEARCH },
      ]

      const options = {
        key: 'fy72-pf71-kx39-pk49',
      }
      const control = new window.pca.Address(fields, options)
      control.setCulture(this.$t('culture'))
      control.listen('populate', (address) => {
        this.$emit('fill', address)
      })
    },
  },
}
</script>
