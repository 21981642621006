import FiltersIndex from '@/views/Dashboard/Filters/FiltersIndex'
import FiltersAdd from '@/views/Dashboard/Filters/FiltersAdd'
import FiltersEdit from '@/views/Dashboard/Filters/FiltersEdit'
import FiltersGroupsIndex from '@/views/Dashboard/Filters/FiltersGroupsIndex'
import FiltersGroupsAdd from '@/views/Dashboard/Filters/FiltersGroupsAdd'
import FiltersGroupsEdit from '@/views/Dashboard/Filters/FiltersGroupsEdit'
import FiltersValuesAdd from '@/views/Dashboard/Filters/FiltersValuesAdd'
import FiltersValuesEdit from '@/views/Dashboard/Filters/FiltersValuesEdit'

import FiltersValueGroupAdd from '@/views/Dashboard/Filters/FiltersValueGroupAdd'
import FiltersValueGroupEdit from '@/views/Dashboard/Filters/FiltersValueGroupEdit'
import BlankRouterView from '@/views/Layout/BlankRouterView'

export default {
  path: '/filters',
  name: 'Filtres',
  component: BlankRouterView,
  redirect: '/filters/index',
  children: [
    {
      path: '/filters/index',
      name: 'Liste des filtres',
      component: FiltersIndex,
      meta: {
        roles: ['ADMIN'],
      },
    },
    {
      path: '/filters/add',
      name: 'Ajouter un filtre',
      component: FiltersAdd,
      meta: {
        roles: ['ADMIN'],
      },
    },
    {
      path: '/filters/:filterId',
      name: 'Modifier un filtre',
      component: FiltersEdit,
      meta: {
        roles: ['ADMIN'],
      },
    },
    {
      path: '/filters/:filterId/value/add',
      name: 'Ajouter une valeur de filtre',
      component: FiltersValuesAdd,
      meta: {
        roles: ['ADMIN'],
      },
    },
    {
      path: '/filters/:filterId/value/:filterValueId',
      name: 'Modifier une valeur de filtre',
      component: FiltersValuesEdit,
      meta: {
        roles: ['ADMIN'],
      },
    },
    {
      path: '/filters/:filterId/valueGroup/add',
      name: 'Ajouter un groupe de valeur',
      component: FiltersValueGroupAdd,
      meta: {
        roles: ['ADMIN'],
      },
    },
    {
      path: '/filters/:filterId/valueGroup/:filterValueGroupId',
      name: 'Modifier un groupe de valeur',
      component: FiltersValueGroupEdit,
      meta: {
        roles: ['ADMIN'],
      },
    },
    {
      path: '/filters/groups/index',
      name: 'Liste des catégories de filtres',
      component: FiltersGroupsIndex,
      meta: {
        roles: ['ADMIN'],
      },
    },
    {
      path: '/filters/groups/add',
      name: 'Ajouter une catégorie de filtre',
      component: FiltersGroupsAdd,
      meta: {
        roles: ['ADMIN'],
      },
    },
    {
      path: '/filters/groups/:groupId',
      name: 'Modifier une catégorie de filtre',
      component: FiltersGroupsEdit,
      meta: {
        roles: ['ADMIN'],
      },
    },
  ],
}
