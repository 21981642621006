const state = {
  title: null,
  description: null,
  ogTitle: null,
  ogDescription: null,
  ogImage: null,
}

const mutations = {
  SET_CONTENT(
    state,
    {
      title = null,
      description = null,
      ogTitle = null,
      ogDescription = null,
      ogImage = null,
    }
  ) {
    state.title = title
    state.description = description
    state.ogTitle = ogTitle
    state.ogDescription = ogDescription
    state.ogImage = ogImage
  },
  SET_TITLE(state, payload) {
    state.title = payload
  },
  SET_DESCRIPTION(state, payload) {
    state.description = payload
  },
  SET_OG_TITLE(state, payload) {
    state.ogTitle = payload
  },
  SET_OG_DESCRIPTION(state, payload) {
    state.ogDescription = payload
  },
  SET_OG_IMAGE(state, payload) {
    state.ogImage = payload
  },
}
export default {
  namespaced: true,
  state,
  mutations,
}
