<template>
  <BaseForm
    id="handlingFeesForm"
    success-message="Frais de manutention enregistrés"
    :toast-only="true"
    :on-success="onSuccess"
    :redirect="redirect"
  >
    <b-card no-body>
      <b-card-header v-if="title" class="border-0 bg-transparent">
        <b-row>
          <b-col cols="6">
            <h2 class="mb-0">{{ title }}</h2>
          </b-col>
        </b-row>
      </b-card-header>

      <el-table
        v-if="handlingFees"
        ref="table"
        class="table-responsive align-items-center table-flush table-striped"
        header-row-class-name="thead-light"
        :data="handlingFees"
      >
        <slot />

        <el-table-column label="Frais" min-width="310px">
          <template #default="{ row }">
            <CurrencyInput v-model="row.price" />
          </template>
        </el-table-column>

        <el-table-column label="Règle de taxe" min-width="310px">
          <template #default="{ row }">
            <BaseInput>
              <select v-model="row.TaxRuleGroupId" class="form-control">
                <option
                  v-for="taxGroup in groups"
                  :key="taxGroup.id"
                  :value="taxGroup.id"
                >
                  {{ taxGroup.name }}
                </option>
              </select>
            </BaseInput>
          </template>
        </el-table-column>

        <el-table-column
          v-if="type === 'school_list'"
          align="center"
          label="Applicable pour commande cuillette en magasin"
          min-width="310px"
        >
          <template #default="{ row }">
            <BaseCheckbox v-model="row.isAppliedForPickUp" class="mb-2">
            </BaseCheckbox>
          </template>
        </el-table-column>
      </el-table>

      <b-card-footer>
        <b-row align-h="end">
          <b-col cols="auto">
            <BaseButton size="lg" type="primary" native-type="submit">
              Sauvegarder
            </BaseButton>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-card>
  </BaseForm>
</template>
<script>
import { mapState } from 'vuex'
import { BaseButton, BaseInput, BaseCheckbox } from '@coop-zone/coop-zone-argon'
import CurrencyInput from '@/components/Inputs/CurrencyInput'
import BaseForm from '@/components/forms/BaseForm'

export default {
  components: {
    BaseForm,
    BaseInput,
    BaseButton,
    BaseCheckbox,
    CurrencyInput,
  },
  props: {
    onSuccess: {
      type: Function,
      required: true,
    },
    handlingFees: {
      type: Array,
      required: true,
    },
    redirect: {
      type: [String, Boolean],
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('taxes/groups', ['groups']),
  },
  created() {
    this.$store.dispatch('taxes/groups/getGroups')
  },
}
</script>
