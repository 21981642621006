<template>
  <div>
    <component
      :is="component"
      v-for="({ component, props, type, title, nobody }, index) in pageBlocks"
      :key="`block-${index}`"
      :title="title"
      :nobody="nobody"
      :block-index="index"
      :type="type"
      v-bind="{ ...props }"
    />
  </div>
</template>
<script>
import blockComponents from '@/assets/blocks/component.map'

export default {
  props: {
    blocks: {
      type: Array,
      required: true,
    },
    page: {
      type: Array,
      required: true,
    },
  },
  computed: {
    pageBlocks() {
      return this.blocks.map((block) => {
        return { ...block, component: blockComponents[block.type] }
      })
    },
  },
  watch: {
    page: {
      handler(newContent) {
        this.$store.commit('blocks/RESET_ACTIVE_STATE')
        newContent.forEach((block, index) => {
          if (block) {
            this.$store.commit('blocks/SET_ACTIVE', {
              index: index,
              active: true,
            })
            this.$store.commit('blocks/SET_CONTENT', {
              index: index,
              content: block,
            })
          }
        })
      },
      immediate: true,
    },
  },
  created() {
    this.$store.commit('blocks/RESET_BLOCKS')
    this.blocks.forEach((block) => {
      this.$store.dispatch('blocks/addBlock', {
        ...block,
      })
    })
  },
}
</script>
