<template>
  <b-card v-loading="loading" no-body>
    <div slot="header">
      <b-row>
        <b-col sm="12" lg="6">
          <p class="h3">
            Ajouter les articles pour chacune des matières scolaires
          </p>
        </b-col>

        <b-col sm="12" lg="6" class="text-right">
          <BaseButton
            type="default"
            icon
            size="sm"
            @click.prevent="addNewCategory"
          >
            <span class="btn-inner--icon"
              ><i class="fas fa-plus-circle"
            /></span>
            <span class="btn-inner--text">Ajouter</span>
          </BaseButton>
        </b-col>
      </b-row>
    </div>
    <el-table-draggable :animate="300" handle=".handle" @drop="dragged($event)">
      <el-table
        :data="sortedCategories"
        class="table-responsive table-flush table-striped"
        header-row-class-name="thead-light"
        header-cell-class-name="pl-4 pr-4"
        :cell-class-name="handleCellClassName"
      >
        <el-table-column>
          <i
            class="handle fas fa-arrows-alt"
            :class="{ 'disable-handle': categoriesAreBeingCreated }"
          />
        </el-table-column>
        <el-table-column
          label="Matières"
          class-name="p-0 align-top"
          min-width="740px"
        >
          <template slot-scope="{ row }">
            <BaseCategoryForm
              v-if="categoryIsOpen(row)"
              class="p-4"
              :category="row"
            >
              <template slot="header">
                <b-row class="pb-4 mb-4 border-bottom">
                  <b-col cols="9">
                    <BaseInput
                      v-model="row.name"
                      label="Nom de la matière"
                      placeholder="Ex: Français"
                      rules="required"
                    />
                  </b-col>
                </b-row>
              </template>

              <template slot="footer">
                <b-row
                  v-if="canMakeSuggestionToCategory(account)"
                  class="pt-4 mt-4 pb-4 mb-4 border-top border-bottom"
                >
                  <b-col>
                    <p class="h4 mb-4">
                      Suggestions d'articles supplémentaires
                    </p>
                    <GradeCategorySuggestions :category="row" />
                  </b-col>
                </b-row>
              </template>
            </BaseCategoryForm>

            <b-row v-else class="p-4">
              <b-col cols="6">
                {{ row.name }}
              </b-col>
            </b-row>
          </template>
        </el-table-column>

        <el-table-column
          label="Articles"
          class-name="p-0 align-top"
          min-width="397px"
        >
          <template slot-scope="{ row }">
            <div
              v-if="
                categoryIsBeingEdited(row) === false &&
                categoryIsNew(row) === false
              "
              class="p-4"
            >
              <b-row align-v="center">
                <b-col cols="8">
                  {{ row.ListCategoryItems.length }}
                </b-col>

                <b-col cols="4" align="right">
                  <a
                    v-confirm-delete="{
                      onConfirm: () => deleteCategory(row),
                      confirmText: 'La matière sera supprimée',
                      successText: `La matière ${row.name} a été supprimée`,
                    }"
                    href="#!"
                    class="table-action table-action-delete"
                    data-toggle="tooltip"
                    data-original-title="Supprimer"
                  >
                    <i class="fas fa-trash" />
                  </a>

                  <a
                    href="#!"
                    class="table-action table-action-edit ml-2"
                    data-toggle="tooltip"
                    data-original-title="Modifier"
                    @click.prevent="
                      SET_CATEGORY_EDIT_STATE({
                        category: row,
                        editState: true,
                      })
                    "
                  >
                    <i class="fa fa-edit" />
                  </a>
                </b-col>
              </b-row>
            </div>
            <ItemsList v-else class="p-4" :category="row" />
          </template>
        </el-table-column>
      </el-table>
    </el-table-draggable>
  </b-card>
</template>

<script>
import ElTableDraggable from 'element-ui-el-table-draggable'
import { BaseInput, BaseButton } from '@coop-zone/coop-zone-argon'
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'
import BaseCategoryForm from '@/components/SchoolLists/Categories/BaseCategoryForm'
import GradeCategorySuggestions from '@/components/SchoolLists/Grade/GradeCategorySuggestions'
import ItemsList from '@/components/SchoolLists/Items/ItemsList'

import { canMakeSuggestionToCategory } from '@/abilities/schoolLists'

export default {
  components: {
    BaseInput,
    BaseButton,
    BaseCategoryForm,
    ItemsList,
    ElTableDraggable,
    GradeCategorySuggestions,
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapState('accounts', ['account']),
    ...mapGetters('schoolLists/categories', [
      'sortedCategories',
      'categoryIsBeingEdited',
      'categoryIsNew',
      'categoriesAreBeingCreated',
    ]),
  },
  methods: {
    ...mapActions('schoolLists/categories', ['addNewCategory']),
    ...mapMutations('schoolLists/categories', ['SET_CATEGORY_EDIT_STATE']),
    async dragged({ list }) {
      if (this.categoriesAreBeingCreated) return
      this.loading = true
      await this.$store.dispatch(
        'schoolLists/categories/updateCategoriesPosition',
        {
          list,
          establishmentId: this.$route.params.establishmentId,
          listId: this.$route.params.id,
        }
      )
      this.loading = false
    },
    handleCellClassName({ row, columnIndex }) {
      if (
        (this.categoryIsBeingEdited(row) || this.categoryIsNew(row)) &&
        columnIndex === 2
      ) {
        return 'border-left'
      }
      if (
        (this.categoryIsBeingEdited(row) || this.categoryIsNew(row)) &&
        columnIndex === 0
      ) {
        return 'border-right'
      }
      return ''
    },
    categoryIsOpen(category) {
      return (
        this.categoryIsBeingEdited(category) || this.categoryIsNew(category)
      )
    },
    deleteCategory(category) {
      this.$store.dispatch('schoolLists/categories/deleteCategory', {
        establishmentId: this.$route.params.establishmentId,
        category,
      })
    },
    canMakeSuggestionToCategory,
  },
}
</script>

<style lang="sass" scoped>
.table-striped::v-deep
    &.el-table--enable-row-hover .el-table__body tr
        > td
            border-bottom-width: 4px
            border-bottom-color: #DCDFE6
    tbody tr:nth-of-type(odd)
        background-color: rgba(246, 249, 252, 0.3) !important
</style>

<style lang="sass">
.disable-handle
  opacity: 0.2
  cursor: pointer
</style>
