<template>
  <div>
    <BaseHeader class="pb-6" type="default">
      <b-row align-v="center" class="py-4">
        <b-col lg="12" cols="7">
          <h1 class="h2 text-white d-inline-block mb-0">Modifier un filtre</h1>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </BaseHeader>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="12">
          <FilterForm title="Modifier un filtre">
            <BaseButton
              slot="actions"
              v-confirm-delete="{
                onConfirm: () => deleteFilter(),
              }"
              type="danger"
              icon
              size="sm"
            >
              <span class="btn-inner--icon"
                ><i class="fas fa-minus-circle"
              /></span>
              <span class="btn-inner--text">Supprimer le filtre</span>
            </BaseButton>

            <b-card no-body class="mb-4">
              <b-row slot="header" align-v="center">
                <b-col cols="4">
                  <h2 class="h3 mb-0">Groupes de valeur de filtre</h2>
                </b-col>

                <b-col class="text-right">
                  <router-link
                    class="btn btn-sm btn-primary"
                    :to="`/filters/${$route.params.filterId}/valueGroup/add`"
                  >
                    Ajouter un groupe
                  </router-link>
                </b-col>
              </b-row>
              <FuseList
                :table-default-sort="{ prop: 'name', order: 'ascending' }"
                :data="filterValueGroups"
                :keys="['name']"
              >
                <el-table-column label="Nom" prop="name">
                  <template slot-scope="scope">
                    <router-link
                      :to="`/filters/${$route.params.filterId}/valueGroup/${scope.row.id}`"
                    >
                      <span class="font-weight-600 name text-sm">{{
                        scope.row.name
                      }}</span>
                    </router-link>
                  </template>
                </el-table-column>

                <el-table-column align="right">
                  <template slot-scope="scope" class="table-actions">
                    <router-link
                      class="table-action table-action-edit"
                      :to="`/filters/${$route.params.filterId}/valueGroup/${scope.row.id}`"
                    >
                      <i class="fa fa-edit" />
                    </router-link>
                    <a
                      v-confirm-delete="{
                        onConfirm: () => deleteFilterValueGroup(scope.row),
                        confirmText: 'Le groupe sera supprimé',
                        successText: `La groupe ${scope.row.name} a été supprimé`,
                      }"
                      href="#!"
                      class="table-action table-action-delete"
                      data-toggle="tooltip"
                      data-original-title="Supprimer"
                    >
                      <i class="fas fa-trash" />
                    </a>
                  </template>
                </el-table-column>
              </FuseList>
            </b-card>

            <b-card no-body class="mb-4">
              <b-row slot="header" align-v="center">
                <b-col cols="4">
                  <h2 class="h3 mb-0">Liste des valeurs de filtre</h2>
                </b-col>

                <b-col class="text-right">
                  <router-link
                    class="btn btn-sm btn-primary"
                    :to="`/filters/${$route.params.filterId}/value/add`"
                  >
                    Ajouter une valeur
                  </router-link>
                </b-col>
              </b-row>
              <FuseList
                :table-default-sort="{ prop: 'value', order: 'ascending' }"
                :data="filterValues"
                :keys="['value']"
              >
                <el-table-column label="Nom" prop="value">
                  <template slot-scope="scope">
                    <router-link
                      :to="`/filters/${$route.params.filterId}/value/${scope.row.id}`"
                    >
                      <span class="font-weight-600 name text-sm">{{
                        scope.row.value
                      }}</span>
                    </router-link>
                  </template>
                </el-table-column>

                <el-table-column label="Groupe">
                  <template
                    v-if="scope.row.FilterValueGroup"
                    slot-scope="scope"
                  >
                    {{ scope.row.FilterValueGroup.name }}
                  </template>
                </el-table-column>

                <el-table-column label="Couleur" align="right">
                  <template v-if="scope.row.colorValue" slot-scope="scope">
                    <span
                      class="color-tile"
                      :style="`background-color: ${scope.row.colorValue};`"
                    />
                  </template>
                </el-table-column>

                <el-table-column align="right">
                  <template slot-scope="scope" class="table-actions">
                    <router-link
                      class="table-action table-action-edit"
                      :to="`/filters/${$route.params.filterId}/value/${scope.row.id}`"
                    >
                      <i class="fa fa-edit" />
                    </router-link>
                    <a
                      v-confirm-delete="{
                        onConfirm: () => deleteFilterValue(scope.row),
                        confirmText: 'La valeur sera supprimée',
                        successText: `La valeur ${scope.row.value} a été supprimée`,
                      }"
                      href="#!"
                      class="table-action table-action-delete"
                      data-toggle="tooltip"
                      data-original-title="Supprimer"
                    >
                      <i class="fas fa-trash" />
                    </a>
                  </template>
                </el-table-column>
              </FuseList>
            </b-card>
          </FilterForm>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import {
  BaseButton,
  BaseHeader,
  RouteBreadcrumb,
} from '@coop-zone/coop-zone-argon'
import { mapGetters, mapState } from 'vuex'
import FilterForm from '@/components/Filters/FilterForm'
import FuseList from '@/components/FuseList'
import ErrorHandlerMixin from '@/mixins/errorHandler.mixin'

export default {
  components: {
    BaseButton,
    BaseHeader,
    RouteBreadcrumb,
    FilterForm,
    FuseList,
  },
  mixins: [ErrorHandlerMixin],
  data() {
    return {
      data: {},
    }
  },
  computed: {
    ...mapState('filters/valueGroups', ['filterValueGroups']),
    ...mapState('filters/values', ['filterValues']),
  },
  created() {
    this.$store.dispatch(
      'filters/values/getFilterValues',
      this.$route.params.filterId
    )
    this.$store.dispatch(
      'filters/valueGroups/getFilterValueGroups',
      this.$route.params.filterId
    )
  },
  methods: {
    async deleteFilter() {
      try {
        await this.$store.dispatch(
          'filters/deleteFilter',
          this.$route.params.filterId
        )
        this.$router.push('/filters/index')
      } catch (error) {
        this.handleError(error)
        throw error
      }
    },
    async deleteFilterValue(filterValue) {
      try {
        await this.$store.dispatch('filters/values/deleteFilterValue', {
          filterId: this.$route.params.filterId,
          filterValueId: filterValue.id,
        })
      } catch (error) {
        this.handleError(error)
        throw error
      }
    },
    async deleteFilterValueGroup(filterValueGroup) {
      try {
        await this.$store.dispatch(
          'filters/valueGroups/deleteFilterValueGroup',
          {
            filterId: this.$route.params.filterId,
            filterValueGroupId: filterValueGroup.id,
          }
        )
      } catch (error) {
        this.handleError(error)
        throw error
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.color-tile
  display: block
  height: 25px
  width: 25px
</style>
