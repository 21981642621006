<template>
  <transition name="fade">
    <div v-if="active" class="batch-action-widget">
      <BaseButton type="success" class="mr-0" size="sm" @click="handleOpen">
        <slot name="button" />
      </BaseButton>

      <BaseDrawer ref="drawer" :before-close="beforeClose">
        <template #default>
          <slot name="default" />
        </template>
        <template #footer="{ close, cancel }">
          <slot :close="close" :cancel="cancel" name="footer" />
        </template>
      </BaseDrawer>
    </div>
    <div v-else class="batch-action-widget">
      <BaseButton type="secondary" class="mr-0" size="sm" disabled>
        <slot name="button" />
      </BaseButton>
    </div>
  </transition>
</template>

<script>
import { BaseButton } from '@coop-zone/coop-zone-argon'
import BaseDrawer from '@/components/BaseDrawer'
export default {
  components: {
    BaseDrawer,
    BaseButton,
  },
  props: {
    batchActionItems: {
      type: Array,
      required: true,
    },
    beforeClose: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    active() {
      return this.batchActionItems.length > 0
    },
  },
  methods: {
    handleOpen() {
      this.$refs.drawer.open()
      this.$emit('on-open')
    },
  },
}
</script>

<style lang="sass">
.batch-action-widget
  display: inline-block
  margin-right: 0.5rem

.el-drawer__body
  padding: 0 1em

.fade-enter-active, .fade-leave-active
  transition: opacity .2s ease-in-out

.fade-enter, .fade-leave-to
  opacity: 0
</style>
