<template>
  <BaseForm
    ref="form"
    success-message="Informations enregistrées"
    :on-success="saveCredentials"
    :handler="accountErrorHandler"
  >
    <card>
      <b-row slot="header" align-v="center">
        <b-col cols="4">
          <h3 class="mb-0">Profil d'utilisateur</h3>
        </b-col>

        <b-col cols="8" class="text-right">
          <button
            v-if="mode === 'readonly'"
            class="btn btn-sm btn-primary"
            @click.prevent="mode = 'edit'"
          >
            Modifier les informations
          </button>

          <button
            v-if="mode === 'edit'"
            class="btn btn-sm btn-secondary"
            @click.prevent="cancelInformations"
          >
            Annuler
          </button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h2 class="heading-small text-muted mb-4">
            Information de connexion
          </h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <BaseCheckbox
            v-model="credentials.active"
            class="mb-3"
            :disabled="mode === 'readonly'"
          >
            Utilisateur actif
          </BaseCheckbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <base-input
            v-model="credentials.email"
            type="email"
            :readonly="mode === 'readonly'"
            label="Courriel"
            rules="required|email"
            placeholder="example@coopzone.com"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <base-input
            v-model="credentials.password"
            label="Mot de passe"
            type="password"
            :readonly="mode === 'readonly'"
            name="password"
            rules="password"
            vid="password"
          />
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col cols="12">
          <base-input
            v-model="credentials.passwordConfirmation"
            label="Confirmez le mot de passe"
            :readonly="mode === 'readonly'"
            type="password"
            name="passwordConfirmation"
            :rules="`${
              credentials.password ? 'required' : ''
            }|confirmed:password`"
          />
        </b-col>
      </b-row>

      <h2 class="heading-small text-muted mb-4">Information de contact</h2>
      <b-row>
        <b-col cols="12">
          <base-input
            v-model="credentials.firstName"
            type="text"
            :readonly="mode === 'readonly'"
            label="Prénom"
            rules="required"
            placeholder=""
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <base-input
            v-model="credentials.lastName"
            type="text"
            :readonly="mode === 'readonly'"
            label="Nom"
            rules="required"
            placeholder=""
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <base-input
            v-model="credentials.phoneNumber"
            type="text"
            :readonly="mode === 'readonly'"
            label="Numéro de téléphone"
            name="numéro de téléphone"
            rules="required"
            placeholder="5189986486"
          />
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col v-if="mode !== 'readonly'" cols="12">
          <BaseCheckbox
            v-model="credentials.notifyBySms"
            :readonly="mode === 'readonly'"
          >
            Notifier par sms
          </BaseCheckbox>
        </b-col>
        <b-col v-else cols="12">
          <p v-if="user.notifyBySms" class="mb-0">
            <span class="text-success">● </span>
            <small>Notifier par SMS.</small>
          </p>
          <p v-else>
            <span class="text-danger">● </span>
            <small>Ne pas notifier par SMS.</small>
          </p>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <h2 class="heading-small text-muted mb-4">Autre</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <base-input
            v-model="credentials.studentNumber"
            type="text"
            :readonly="mode === 'readonly'"
            label="Numéro d'étudiant"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <BaseCheckbox
            v-model="credentials.chargeToAccount"
            class="mb-3"
            :disabled="mode === 'readonly'"
          >
            Mode de paiement "Porter au compte"
          </BaseCheckbox>
        </b-col>
      </b-row>

      <b-row v-if="mode === 'edit'" slot="footer" align-v="center">
        <b-col cols="12">
          <BaseButton type="default" native-type="submit">
            Enregistrer les informations
          </BaseButton>
          <BaseButton type="secondary" @click.prevent="cancelInformations">
            Annuler
          </BaseButton>
        </b-col>
      </b-row>
    </card>
  </BaseForm>
</template>
<script>
import {
  Card,
  BaseButton,
  BaseInput,
  BaseCheckbox,
} from '@coop-zone/coop-zone-argon'
import { mapState } from 'vuex'
import AccountErrorsHandler from '@/error-handler/accountErrors.handler'
import BaseForm from '@/components/forms/BaseForm'

export default {
  components: {
    Card,
    BaseButton,
    BaseForm,
    BaseInput,
    BaseCheckbox,
  },
  data() {
    return {
      credentials: null,
      savingCredentials: false,
      accountErrorHandler: AccountErrorsHandler,
      mode: 'readonly', // readonly|edit
    }
  },
  computed: {
    ...mapState('users', ['user']),
  },
  watch: {
    user: {
      handler({
        active,
        firstName,
        lastName,
        email,
        phoneNumber,
        notifyBySms,
        chargeToAccount,
        studentNumber,
      }) {
        this.credentials = {
          active,
          firstName,
          lastName,
          email,
          phoneNumber,
          notifyBySms,
          chargeToAccount,
          studentNumber,
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$store.dispatch('users/getUser', this.$route.params.id)
  },
  methods: {
    async saveCredentials() {
      await this.$store.dispatch('users/updateUserCredentials', {
        userId: this.$route.params.id,
        ...this.credentials,
      })
      this.mode = 'readonly'
    },
    cancelInformations() {
      const { active, firstName, lastName, email, phoneNumber, notifyBySms, chargeToAccount } = {
        ...this.user,
      }
      this.credentials = {
        active,
        firstName,
        lastName,
        email,
        phoneNumber,
        notifyBySms,
        chargeToAccount,
      }
      this.mode = 'readonly'
    },
  },
}
</script>
<style></style>
