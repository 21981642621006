<template>
  <div>
    <BaseInput label="Catégorie" class="input-group-lg input-group-flush mb-0">
      <el-select
        v-model="localValue"
        placeholder="Entrer un nom de catégorie"
        value-key="id"
        :loading="loading"
        @change="$emit('change', localValue)"
      >
        <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.name"
          :value="item"
        />
      </el-select>
    </BaseInput>
  </div>
</template>
<script>
import { BaseInput } from '@coop-zone/coop-zone-argon'

export default {
  components: {
    BaseInput,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      localValue: null,
      options: [],
      loading: false,
    }
  },
  watch: {
    value: {
      handler(newValue) {
        if (newValue) {
          this.localValue = newValue
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    this.loading = true
    try {
      const items = await this.$store.dispatch(
        'filters/groups/searchGroups',
        ''
      )
      this.options = items

      if (this.value === null) {
        const defaultOption = this.options.find((option) => option.default)
        this.localValue = defaultOption
        this.$emit('change', this.localValue)
      }
      this.loading = false
    } catch (e) {
      this.loading = false
    }
  },
}
</script>
