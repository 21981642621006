<template>
  <div>
    <b-row>
      <b-col>
        <BaseRadio v-model="type" name="PRODUCT" class="mb-3">
          Ajouter un article
        </BaseRadio>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <BaseRadio v-model="type" name="GROUP" class="mb-3">
          Offrir une sélection d’articles similaires
        </BaseRadio>
      </b-col>
    </b-row>

    <component :is="form" :category="category" />
  </div>
</template>

<script>
import { BaseRadio } from '@coop-zone/coop-zone-argon'
import BaseNewProductForm from '@/components/SchoolLists/Items/Product/BaseNewProductForm'
import NewGroupForm from '@/components/SchoolLists/Items/Group/NewGroupForm'

export default {
  components: {
    BaseRadio,
    BaseNewProductForm,
    NewGroupForm,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      type: 'PRODUCT', // PRODUCT || GROUP || OTHER,
    }
  },
  computed: {
    form() {
      switch (this.type) {
        case 'PRODUCT':
          return BaseNewProductForm
        case 'GROUP':
          return NewGroupForm
        default:
          return BaseNewProductForm
      }
    },
  },
}
</script>
