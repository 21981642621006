<template>
  <span>
    <BaseDrawer ref="drawer" :loading="loading">
      <template #default="{ cancel, close }">
        <b-card no-body class="text-left">
          <b-card-header>
            <b-row>
              <b-col cols="8">
                <h2 class="mb-0">Filtrer les résultats de l'exportation</h2>
              </b-col>
            </b-row>
          </b-card-header>

          <b-card-body>
            <b-row>
              <b-col>
                <BaseInput
                  label="Date de prix en vigueur"
                  form-group-classes="mb-0"
                  prepend-icon="ni ni-calendar-grid-58"
                  rules="required"
                  :disabled="!canSelectDateOfSchoolListExport(account)"
                >
                  <flat-pickr
                    v-model="exportFilters.date"
                    :config="flatPickrConfig"
                    class="form-control datepicker"
                    :disabled="!canSelectDateOfSchoolListExport(account)"
                  />
                </BaseInput>
              </b-col>
            </b-row>
          </b-card-body>

          <b-card-footer>
            <b-row>
              <b-col class="text-right">
                <BaseButton ref="closeButton" @click="cancel">
                  Annuler
                </BaseButton>
                <BaseButton
                  type="primary"
                  :disabled="loading"
                  @click="exportSchoolList(close)"
                >
                  <i class="fa fa-download" />
                  Exporter
                </BaseButton>
              </b-col>
            </b-row>
          </b-card-footer>
        </b-card>
      </template>
    </BaseDrawer>

    <slot :show-filters="open">
      <b-link class="table-action" @click="open">
        <i class="fa fa-download" />
      </b-link>
    </slot>

    <a
      ref="downloadfile"
      style="
        height: 0px;
        width: 0px;
        visibility: hidden;
        display: block;
        position: absolute;
      "
      :href="fileSource"
      :download="fileName"
    >
      Download
    </a>
  </span>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import 'flatpickr/dist/flatpickr.css'
import { French as locale } from 'flatpickr/dist/l10n/fr'
import { BaseButton, BaseInput } from '@coop-zone/coop-zone-argon'
import BaseDrawer from '@/components/BaseDrawer'
const slugify = require('slugify')

import ErrorHandlerMixin from '@/mixins/errorHandler.mixin'
import { canSelectDateOfSchoolListExport } from '@/abilities/schoolLists'

const exportFilters = { date: null }

export default {
  components: {
    BaseButton,
    BaseDrawer,
    BaseInput,
  },
  mixins: [ErrorHandlerMixin],
  props: {
    schoolList: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      exportFilters: { ...exportFilters },
      flatPickrConfig: {
        minDate: 'today',
        defaultDate: 'today',
        allowInvalidPreload: true,
        dateFormat: 'Z',
        altFormat: 'd-m-Y',
        altInput: true,
        locale,
      },
      fileSource: null,
      fileName: null,
    }
  },
  computed: {
    ...mapState('accounts', ['account']),
    ...mapGetters('schoolEstablishments', ['schoolEstablishmentName']),
  },
  methods: {
    open() {
      this.$refs.drawer.open()
    },
    async exportSchoolList(drawerCloseCb) {
      try {
        this.loading = true

        const base64file = await this.$store.dispatch(
          'utils/exportSchoolList',
          { schoolList: this.schoolList, date: this.exportFilters.date },
          this.schoolList
        )
        this.fileSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64file}`

        const establishmentName = this.schoolEstablishmentName(
          this.schoolList.EstablishmentId
        )

        const fileName = []

        if (establishmentName) fileName.push(establishmentName)
        fileName.push(this.schoolList.name)
        if (this.schoolList.option) fileName.push(this.schoolList.option)

        this.fileName = slugify(fileName.join('_')) + '.xlsx'

        drawerCloseCb()

        this.$nextTick(() => {
          this.$refs.downloadfile.click()
        })

        this.loading = false
      } catch (error) {
        this.loading = false
        this.handleError(error)
      }
    },
    canSelectDateOfSchoolListExport,
  },
}
</script>
