<template>
  <tr
    class="order-product"
    :class="{ 'order-product--with-replacement': item.replacement }"
  >
    <td>
      <template v-if="replacement">
        <OrderTag type="replacement">Remplacement</OrderTag>
        <br />
      </template>
      <strong>{{ item.name }}</strong>
      <br />
      <template v-if="item.BundleItemId">
        <div class="order-bundle">
          <OrderTag type="bundle">Ensemble</OrderTag>
          {{ getBundleNameById(order, item.BundleItemId) }}
        </div>
      </template>
      <template v-if="item.productCode">
        Code produit: {{ item.productCode }}<br />
      </template>
      <template v-if="item.productData.isbn13 || item.productData.isbn10">
        ISBN:
        {{ item.productData.isbn13 || item.productData.isbn10 }}
        <br />
      </template>
      <template v-if="item.productData.brand">
        Fabricant: {{ item.productData.brand }}<br />
      </template>
      <template v-if="item.productData.codeApple">
        Code Apple: {{ item.productData.codeApple }}<br />
      </template>
      <template
        v-if="item.productData.authors && item.productData.authors.length > 0"
      >
        {{ item.productData.authors.length | pluralize('Auteur') }}:
        {{ item.productData.authors.join(', ') }}<br />
      </template>
      <template v-if="item.schoolList && item.schoolList.courseCode">
        Numéro de cours: {{ item.schoolList.courseCode }}<br />
      </template>
      <template
        v-if="
          item.productData.courseCodes &&
          item.productData.courseCodes.length > 0
        "
      >
        Autre cours: {{ item.productData.courseCodes.join(', ') }}<br />
      </template>
      <template v-if="item.productCode">
        <Barcode :key="item.productCode" :value="item.productCode" />
      </template>
    </td>
    <td>{{ item.price | currencyFormat }}</td>
    <td>{{ item.quantity }}</td>
    <td class="text-nowrap">
      <template v-if="item.statuses">
        <span
          v-for="status in statusesWithQuantity(item.statuses)"
          :key="status.status"
        >
          {{ $t(`orders.item.statuses.${status.status}`) }}:
          {{ status.quantity }}
          <br />
        </span>
      </template>
    </td>
    <td>{{ item.vigieCodes }}</td>
    <td>{{ (item.price * item.quantity) | currencyFormat }}</td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex'
import Barcode from '@/components/Barcode'
import OrderTag from '@/components/Orders/OrderTag'

export default {
  components: {
    Barcode,
    OrderTag,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    replacement: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('orders', ['getBundleNameById']),
  },
  methods: {
    statusesWithQuantity(statuses) {
      return statuses.filter((status) => status.quantity > 0)
    },
  },
}
</script>

<style scoped>
/* FOR DEBUGGING PURPOSES  */
/* CSS NEEDS TO BE UPDATED IN /public/css/order.print.css  */

.order-product-table td {
  color: #001e31;
  font-family: 'Inter', sans-serif;
  font-size: 11px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 16px;
  padding: 10px;
  vertical-align: top;
}

.order-product-table td img {
  margin-top: 8px;
}

.order-product-table td:last-child {
  text-align: right;
}

.order-bundle {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
}
</style>
