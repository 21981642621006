<template>
  <validation-observer v-slot="{ handleSubmit }" :ref="id" tag="div">
    <form
      v-loading="loading && hideLoading == false"
      novalidate
      :class="classes"
      @submit.prevent="handleSubmit(onSubmit)"
    >
      <FormSuccess
        v-if="successMessage && success"
        :key="uuidSuccess"
        :toast-only="toastOnly"
        :message="successMessage"
      />

      <FormError
        v-if="error"
        :key="uuidError"
        :toast-only="toastOnly"
        :error="error"
      />

      <template v-if="recaptcha">
        <VueRecaptcha
          ref="recaptcha"
          size="invisible"
          sitekey="6LdWZycdAAAAANIW_B2gN1PU6I_6TbGODU60TeCa"
          :load-recaptcha-script="true"
          @verify="onRecaptchaVerify"
          @error="onRecaptchaError"
          @expired="onRecaptchaExpired"
          @render="onRecaptchaRender"
        />
      </template>

      <slot :loading="loading" />
    </form>
  </validation-observer>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import uniqid from 'uniqid'
import {
  ErrorHandlerMiddleware,
  BaseErrorsHandler,
} from '@coop-zone/coop-zone-form-errors'
import FormError from './FormError'
import FormSuccess from './FormSuccess'

export default {
  components: { FormError, FormSuccess, VueRecaptcha },
  props: {
    classes: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: '',
    },
    handler: {
      type: Function,
      default: BaseErrorsHandler,
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
    onError: {
      type: Function,
      default: null,
    },
    beforeSubmit: {
      type: Function,
      default: () => {},
    },
    successMessage: {
      type: String,
      default: '',
    },
    toastOnly: {
      type: Boolean,
      default: false,
    },
    recaptcha: {
      type: Boolean,
      default: false,
    },
    redirect: {
      type: [String, Boolean],
      default: false,
      description: 'Should we redirect on success?',
    },
    hideLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uuidError: null,
      uuidSuccess: null,
      error: null,
      success: false,
      loading: false,
    }
  },
  methods: {
    async onSubmit() {
      this.loading = true
      this.success = false

      await this.beforeSubmit()

      if (this.recaptcha) {
        this.handleRecaptcha()
      } else {
        await this.submit()
      }
    },

    async submit(recaptcha = false) {
      const error = await ErrorHandlerMiddleware({
        form: this.$refs[this.id],
        query: async () => await this.onSuccess(recaptcha),
        Handler: this.handler,
      })
      this.error = error
      this.success = !this.error
      this.loading = false

      if (this.success) {
        this.uuidSuccess = uniqid()
        if (this.redirect) {
          this.$nextTick(() => {
            this.$router.push(this.redirect)
          })
        } else if (this.$refs[this.id]) {
          this.$refs[this.id].reset()
        }
      } else {
        this.uuidError = uniqid()
        this.$emit('on-error')
      }
    },

    handleRecaptcha() {
      this.$refs.recaptcha.reset()
      this.$refs.recaptcha.execute()
    },

    async onRecaptchaVerify(response) {
      await this.submit(response)
    },

    onRecaptchaExpired() {
      console.log('Recaptcha has expired')
      this.$refs.recaptcha.reset()
    },

    onRecaptchaRender(id) {},

    onRecaptchaError() {
      console.log('Recaptcha error')
    },
  },
}
</script>
