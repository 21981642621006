import { mapState, mapGetters } from 'vuex'
import BaseFlow from './BaseFlow'

export default {
  components: { BaseFlow },
  computed: {
    ...mapState('accounts', ['account']),
    ...mapGetters('schoolLists/categories', ['schoolListHasItems']),
    ...mapGetters('schoolLists', ['schoolList']),
  },
}
