var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BasePageBlock',_vm._b({},'BasePageBlock',_vm.$props,false),[(_vm.showTitle)?_c('b-row',[_c('b-col',[_c('b-row',[_c('b-col',[_c('h3',{staticClass:"heading-small text-muted"},[_vm._v("Titre du bloc")])])],1),_c('b-row',[_c('b-col',[_c('BaseInput',{attrs:{"label":"Texte du titre"},model:{value:(_vm.blocks[_vm.blockIndex].title),callback:function ($$v) {_vm.$set(_vm.blocks[_vm.blockIndex], "title", $$v)},expression:"blocks[blockIndex].title"}})],1)],1)],1)],1):_vm._e(),_c('b-row',[_c('b-col',[_c('b-row',[_c('b-col',[_c('h3',{staticClass:"heading-small text-muted"},[_vm._v("Zone de contenu")])])],1),_c('b-row',[_c('b-col',[_c('HtmlEditor',{attrs:{"toolbar-options":[
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ size: ['large', false] }],
              ['link'],
              ['bold', 'italic', 'underline', 'strike'], // toggled buttons
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }], // outdent/indent
              ['image', 'video'],
              ['clean'] ]},model:{value:(_vm.blocks[_vm.blockIndex].text),callback:function ($$v) {_vm.$set(_vm.blocks[_vm.blockIndex], "text", $$v)},expression:"blocks[blockIndex].text"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }