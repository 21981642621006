<template>
  <div class="content">
    <base-header class="pb-6 bg-default">
      <b-row align-v="center" class="py-4">
        <b-col>
          <p class="h2 text-white d-inline-block mb-0">Succursales</p>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-card no-body class="mb-4">
        <b-row slot="header" align-v="center">
          <b-col cols="4">
            <h1 class="h3 mb-0">Liste des succursales</h1>
          </b-col>
          <b-col class="text-right">
            <router-link
              class="btn btn-sm btn-primary"
              to="/establishments/stores/add"
              data-cy="establishment-add-link"
            >
              Ajouter une succursale
            </router-link>
          </b-col>
        </b-row>
        <el-table-draggable
          :animate="300"
          handle=".handle"
          @drop="dragged($event)"
        >
          <el-table
            :data="stores"
            class="table-responsive table-flush table-striped"
            header-row-class-name="thead-light"
            header-cell-class-name="pl-4 pr-4"
          >
            <el-table-column>
              <i class="handle fas fa-arrows-alt" />
            </el-table-column>
            <el-table-column label="Id Vigie" prop="vigieId" />
            <el-table-column label="Nom de la succursale" min-width="200px">
              <template slot-scope="scope">
                <router-link
                  :to="`/establishments/stores/edit/${scope.row.id}`"
                >
                  <span class="font-weight-600 name text-sm">{{
                    scope.row.name
                  }}</span>
                </router-link>
              </template>
            </el-table-column>

            <el-table-column
              label="Addresse"
              prop="address"
              min-width="300px"
            />
            <el-table-column label="Visible dans le menu" min-width="100px">
              <template slot-scope="scope">
                <i
                  v-if="scope.row.visibleInFooter === true"
                  class="ni ni-check-bold"
                />
              </template>
            </el-table-column>
            <el-table-column label="Afficher l'inventaire" class="no-break">
              <template slot-scope="scope">
                <i v-if="scope.row.active === true" class="ni ni-check-bold" />
              </template>
            </el-table-column>
            <el-table-column align="right">
              <template slot-scope="scope" class="table-actions">
                <router-link
                  class="table-action table-action-edit"
                  :to="`/establishments/stores/edit/${scope.row.id}`"
                >
                  <i class="fa fa-edit" />
                </router-link>
              </template>
            </el-table-column>
          </el-table>
        </el-table-draggable>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import { BaseHeader, RouteBreadcrumb } from '@coop-zone/coop-zone-argon'
import ElTableDraggable from 'element-ui-el-table-draggable'
import { mapGetters } from 'vuex'

export default {
  components: {
    BaseHeader,
    ElTableDraggable,
    RouteBreadcrumb,
  },
  computed: {
    ...mapGetters('stores', {
      stores: 'sortedStores',
    }),
  },
  created() {
    this.$store.dispatch('stores/getStores')
  },
  methods: {
    async dragged({ list }) {
      await this.$store.dispatch('stores/updateStoresPositions', {
        list,
      })
    },
  },
}
</script>
