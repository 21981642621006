<template>
  <base-alert
    v-if="toastOnly === false"
    dismissible
    type="danger"
    icon="ni ni-bell-55"
  >
    {{ message }}
  </base-alert>
</template>

<script>
import { BaseAlert } from '@coop-zone/coop-zone-argon'

export default {
  components: { BaseAlert },
  props: {
    error: {
      type: Object,
      default() {
        return {}
      },
    },
    toastOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    message() {
      if (this.error.fieldErrors.hasError) {
        return 'Le formulaire contient une ou plusieurs erreurs.'
      }
      if (this.error.logicErrors.hasError) {
        return this.$t(`logicError.${this.error.logicErrors.errors[0]}`)
      }
      return "Une erreur s'est produite."
    },
  },
  mounted() {
    this.$notify({
      message: this.message,
      timeout: 5000,
      icon: 'ni ni-bell-55',
      type: 'danger',
    })
  },
}
</script>
