export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: Array,
      default() {
        return [String, Object, Array]
      },
    },
    formats: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      files: [],
    }
  },
  watch: {
    value: {
      handler(updatedFiles) {
        this.files = updatedFiles || []
      },
      immediate: true,
    },
  },
  methods: {
    getfileChunks(file) {
      const chunks = []
      const chunkSize = 1 * (1024 * 128)
      let currentPos = 0
      let endPos = chunkSize
      while (currentPos < file.size) {
        chunks.push(file.slice(currentPos, endPos))
        currentPos = endPos
        endPos = currentPos + chunkSize
      }
      return chunks
    },

    async uploadFiles(files) {
      const promises = files.map(async (file) => {
        const uuid = await this.$store.dispatch('files/createUpload')
        const uploadedFile = await this.uploadFileChunks(uuid, file)
        return uploadedFile
      })
      const uploadedFiles = await Promise.all(promises)
      return uploadedFiles
    },

    async uploadFileChunks(uuid, file) {
      const chunks = this.getfileChunks(file).map((chunk, index) =>
        this.$store.dispatch('files/chunkUpload', {
          uploadId: uuid,
          chunkNumber: index,
          chunk,
        })
      )
      await Promise.all(chunks)
      const uploadedFile = await this.$store.dispatch('files/completeUpload', {
        uploadId: uuid,
        fileName: file.name,
        formats: this.formats,
      })
      const fileObject = uploadedFile.reduce((obj, item) => {
        const key = item.format ? item.format : 'url'
        return {
          ...obj,
          [key]: item.url,
        }
      }, {})
      return fileObject
    },
  },
}
