<template>
  <BaseForm
    id="groupFilterForm"
    success-message="Catégorie de filtre enregistrée"
    redirect="/filters/groups/index"
    :on-success="save"
  >
    <b-card>
      <div slot="header">
        <b-row align-v="center">
          <b-col sm="12" lg="6">
            <p class="h6 text-uppercase text-muted ls-1 mb-1">
              {{ title }}
            </p>
            <h2 class="mb-0">
              {{ name }}
            </h2>
          </b-col>

          <b-col sm="12" lg="6" class="text-right">
            <slot name="actions" />
          </b-col>
        </b-row>
      </div>

      <b-row>
        <b-col col-sm="6">
          <BaseInput
            v-model="name"
            label="Nom de la catégorie"
            rules="required"
          />
        </b-col>

        <b-col col-sm="6">
          <BaseInput
            v-model="label"
            label="Libellé (affichage site publique)"
          />
        </b-col>
      </b-row>
    </b-card>

    <slot />

    <b-row align="end" class="mt-1">
      <b-col>
        <BaseButton size="lg" type="primary" native-type="submit">
          Sauvegarder
        </BaseButton>
      </b-col>
    </b-row>
  </BaseForm>
</template>

<script>
import { BaseButton, BaseInput } from '@coop-zone/coop-zone-argon'
import BaseForm from '@/components/forms/BaseForm'

export default {
  components: {
    BaseForm,
    BaseButton,
    BaseInput,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    name: {
      get() {
        return this.$store.state.filters.groups.name
      },
      set(name) {
        this.$store.commit('filters/groups/SET_NAME', name)
      },
    },
    label: {
      get() {
        return this.$store.state.filters.groups.label
      },
      set(label) {
        this.$store.commit('filters/groups/SET_LABEL', label)
      },
    },
  },
  created() {
    this.$store.commit('filters/groups/SET_GROUP', {})
    if (this.$route.params.groupId) {
      this.$store.dispatch(
        'filters/groups/getGroup',
        this.$route.params.groupId
      )
    }
  },
  methods: {
    async save() {
      if (this.$route.params.groupId) {
        await this.$store.dispatch('filters/groups/updateGroup')
      } else {
        await this.$store.dispatch('filters/groups/createGroup')
      }
    },
  },
}
</script>
