export const types = [
  'Art',
  'Book',
  'Computer',
  'CourseNote',
  'DigitalAccessCode',
  'DigitalBook',
  'DigitalCoupon',
  'DigitalOther',
  'DigitalWatermark',
  'EcoFee',
  'OfficeSupply',
]

export const digitalTypes = [
  'DigitalAccessCode',
  'DigitalBook',
  'DigitalCoupon',
  'DigitalOther',
  'DigitalWatermark',
]
