import { apolloClient } from '@/apollo'
import { groupBy } from 'lodash'

const getSchoolEstablishmentQuery = require('../apollo/queries/GetSchoolEstablishment.gql')
const getSchoolEstablishmentsQuery = require('../apollo/queries/GetSchoolEstablishments.gql')
const createSchoolEstablishmentMutation = require('../apollo/mutations/CreateSchoolEstablishment.gql')
const updateSchoolEstablishmentMutation = require('../apollo/mutations/UpdateSchoolEstablishment.gql')
const deleteSchoolEstablishmentMutation = require('../apollo/mutations/DeleteSchoolEstablishment.gql')

const isEstablishmentAddressIsFilled = (address) => {
  if (Object.entries(address).length < 1) {
    return false
  }

  if (
    !address.address &&
    !address.address2 &&
    !address.city &&
    !address.postalCode &&
    !address.state &&
    !address.country
  ) {
    return false
  }

  return true
}

const schoolListTypes = {
  program: ['PROGRAM'],
  grade: ['ELEMENTARY', 'HIGHSCHOOL'],
  course: ['COLLEGE', 'UNIVERSITY'],
}

const schoolEstablishmentTypes = [
  'ELEMENTARY',
  'HIGHSCHOOL',
  'COLLEGE',
  'UNIVERSITY',
]

const state = {
  schoolEstablishments: [],
  id: null,
  logo: null,
  name: null,
  slug: null,
  type: null,
  pickUpAvailable: false,
  pickUps: [],
  address: {},
  accountIds: [],
}

const actions = {
  async getSchoolEstablishment({ commit }, id) {
    const {
      data: { schoolEstablishment },
    } = await apolloClient.query({
      query: getSchoolEstablishmentQuery,
      variables: { id },
    })

    if (!schoolEstablishment.address) {
      schoolEstablishment.address = {}
    }

    commit('SET_SCHOOL_ESTABLISHMENT', schoolEstablishment)
  },

  async getSchoolEstablishments({ commit }, types = null) {
    const variables = {}
    if (types) variables.types = types

    const {
      data: { schoolEstablishments },
    } = await apolloClient.query({
      query: getSchoolEstablishmentsQuery,
      variables,
    })
    commit('SET_SCHOOL_ESTABLISHMENTS', schoolEstablishments)
  },

  async createSchoolEstablishment({
    state: { name, logo, type, pickUpAvailable, pickUps, address, accountIds },
  }) {
    let schoolEstablishment

    if (!['ELEMENTARY', 'HIGHSCHOOL', 'PROGRAM'].includes(type)) {
      schoolEstablishment = { name, type, logo }
    } else {
      schoolEstablishment = {
        name,
        type,
        logo,
        pickUpAvailable,
      }

      if (Object.entries(address).length >= 1) {
        schoolEstablishment.address = address
      }

      if (pickUps.length >= 1) {
        schoolEstablishment.pickUps = pickUps
      }
    }

    if (['ELEMENTARY', 'HIGHSCHOOL'].includes(type)) {
      schoolEstablishment.accountIds = accountIds
    }

    await apolloClient.query({
      query: createSchoolEstablishmentMutation,
      variables: {
        schoolEstablishment,
      },
    })
  },

  async updateSchoolEstablishment({
    state: {
      id,
      name,
      slug,
      logo,
      type,
      pickUpAvailable,
      pickUps,
      address,
      accountIds,
    },
  }) {
    const schoolEstablishment = {
      name,
      slug,
      type,
      logo,
    }

    if (['ELEMENTARY', 'HIGHSCHOOL', 'PROGRAM'].includes(type)) {
      schoolEstablishment.pickUpAvailable = pickUpAvailable
      if (isEstablishmentAddressIsFilled(address)) {
        schoolEstablishment.address = address
      }
      if (pickUps.length > 0) schoolEstablishment.pickUps = pickUps
    }

    if (['ELEMENTARY', 'HIGHSCHOOL'].includes(type)) {
      schoolEstablishment.accountIds = accountIds
    }

    await apolloClient.query({
      query: updateSchoolEstablishmentMutation,
      variables: {
        id,
        schoolEstablishment,
      },
    })
  },

  async deleteSchoolEstablishment({ commit }, id) {
    await apolloClient.query({
      query: deleteSchoolEstablishmentMutation,
      variables: { id },
    })
    commit('SET_SCHOOL_ESTABLISHMENT', {})
  },
}

const mutations = {
  SET_LOGO(state, payload) {
    state.logo = payload
  },

  SET_NAME(state, payload) {
    state.name = payload
  },

  SET_SLUG(state, payload) {
    state.slug = payload
  },

  SET_TYPE(state, payload) {
    state.type = payload
  },

  SET_PICK_UP_AVAILABLE(state, payload) {
    state.pickUpAvailable = payload
  },

  SET_PICK_UPS(state, payload) {
    state.pickUps = payload
  },

  SET_ADDRESS(state, payload) {
    state.address = payload
  },

  SET_ACCOUNT_IDS(state, payload) {
    state.accountIds = payload
  },

  SET_SCHOOL_ESTABLISHMENTS(state, payload) {
    state.schoolEstablishments = payload
  },
  SET_SCHOOL_ESTABLISHMENT(
    state,
    {
      id = null,
      logo = null,
      name = null,
      slug = null,
      type = null,
      pickUpAvailable = false,
      pickUps = [],
      address = {},
      accountIds = [],
    }
  ) {
    state.id = id
    state.logo = logo
    state.name = name
    state.slug = slug
    state.type = type
    state.pickUpAvailable = pickUpAvailable
    state.pickUps = pickUps
    state.address = address
    state.accountIds = accountIds
  },
}

const getters = {
  schoolEstablishment: (state) => {
    return {
      id: state.id,
      name: state.name,
      slug: state.slug,
      logo: state.logo,
      type: state.type,
      pickUpAvailable: state.pickUpAvailable,
      pickUps: state.pickUps,
      address: state.address,
      accountIds: state.accountIds,
    }
  },

  schoolEstablishmentName: (state) => (establishmentId) => {
    if (!state.schoolEstablishments) return []
    return state.schoolEstablishments.find(
      (establishment) => establishment.id === establishmentId
    ).name
  },

  schoolEstablishmentByTypes: (state) => {
    return groupBy(state.schoolEstablishments, 'type')
  },

  schoolEstablishmentTypes: () => {
    return schoolEstablishmentTypes
  },

  getSchoolEstablishments: (state) => (type) => {
    if (!state.schoolEstablishments) return []
    return state.schoolEstablishments.filter((school) =>
      schoolListTypes[type].includes(school.type)
    )
  },

  getSchoolEstablishmentTypesBySchoolListType: (state) => (type) => {
    return schoolListTypes[type]
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
