<template>
  <BaseForm :on-success="addGroupToCategory">
    <b-row class="mb-5">
      <b-col lg="8">
        <b-row>
          <b-col>
            <BaseInput
              v-model="name"
              placeholder="Nommer le regoupement (ex: Duo tang en poly)"
            />
          </b-col>
        </b-row>

        <b-row class="mt-4">
          <b-col>
            <ProductAutocomplete
              input-label="Rechercher l’article dans le catalogue Coop Zone"
              :show-tags-list="false"
              placeholder="Nom de produit, un SKU, un ISBN, un code Apple, un auteur, une marque"
              @change="addProductToGroup($event)"
            />
          </b-col>
        </b-row>

        <b-row v-if="products.length" class="mt-4">
          <b-col cols="12">
            <b-row class="mb-3">
              <b-col>
                <p class="h4">Article(s) sélectionné(s)</p>
              </b-col>
            </b-row>

            <b-row
              v-for="(product, index) in products"
              :key="index"
              align-v="center"
              class="mt-4"
            >
              <b-col cols="9">
                <b-media no-body class="align-items-center">
                  <ProductThumbnail :product="product" />
                  <b-media-body>
                    <p v-if="product.vigieId" class="h6 text-muted mb-0">
                      <span>Code produit: {{ product.vigieId }}</span>
                    </p>
                    <p
                      v-if="product.isbn13 || product.isbn10"
                      class="h6 text-muted mb-0"
                    >
                      <span>ISBN: {{ product.isbn13 || product.isbn10 }}</span>
                    </p>
                    <p class="h5 mb-0">
                      {{ product.name }}
                    </p>
                  </b-media-body>
                </b-media>
              </b-col>

              <b-col align="end">
                <a
                  href="#!"
                  class="table-action table-action-delete"
                  data-toggle="tooltip"
                  data-original-title="Supprimer"
                  @click.prevent="products.splice(index, 1)"
                >
                  <i class="fas fa-trash" />
                </a>
              </b-col>
            </b-row>

            <b-row class="mt-5">
              <b-col>
                <BaseButton
                  v-if="name && products.length > 0"
                  type="default"
                  size="sm"
                  native-type="submit"
                >
                  <span class="btn-inner--text">Créer le regroupement</span>
                </BaseButton>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </BaseForm>
</template>
<script>
import { BaseInput, BaseButton } from '@coop-zone/coop-zone-argon'
import BaseForm from '@/components/forms/BaseForm'
import ProductAutocomplete from '@/components/ProductAutocomplete'
import ProductThumbnail from '@/components/Product/ProductThumbnail'

export default {
  components: {
    BaseForm,
    BaseInput,
    BaseButton,
    ProductAutocomplete,
    ProductThumbnail,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      name: '',
      products: [],
    }
  },
  methods: {
    addProductToGroup(product) {
      this.products.push(product)
    },
    addGroupToCategory() {
      this.$store.dispatch('schoolLists/categories/addNewItem', {
        category: this.category,
        item: {
          type: 'GROUP',
          name: this.name,
          products: this.products,
        },
      })
      this.name = ''
      this.products = []
    },
  },
}
</script>
