import BlankRouterView from '@/views/Layout/BlankRouterView'
import CodePromosIndex from '@/views/Dashboard/PromoCode/PromoSearch'
import CallToActionsIndex from '@/views/Dashboard/Promotions/CallToActions/CallToActionsIndex'
import CallToActionsAdd from '@/views/Dashboard/Promotions/CallToActions/CallToActionsAdd'
import CodePromosAdd from '@/views/Dashboard/PromoCode/PromoAdd'
import CallToActionsEdit from '@/views/Dashboard/Promotions/CallToActions/CallToActionsEdit'
import CodePromosEdit from '@/views/Dashboard/PromoCode/PromoEdit'

export default {
  path: '/promotions',
  name: 'Promotions',
  redirect: '/promotions/call-to-actions',
  component: BlankRouterView,
  children: [
    {
      path: '/promotions/call-to-actions',
      name: "Appels à l'action",
      redirect: '/promotions/call-to-actions/index',
      component: BlankRouterView,
      children: [
        {
          path: '/promotions/call-to-actions/index',
          name: "Liste d'appels à l'action",
          component: CallToActionsIndex,
          meta: {
            roles: ['ADMIN', 'SUPER_EDITOR', 'EDITOR'],
          },
        },
        {
          path: '/promotions/call-to-actions/add',
          name: "Ajouter un appel à l'action",
          component: CallToActionsAdd,
          meta: {
            roles: ['ADMIN', 'SUPER_EDITOR', 'EDITOR'],
          },
        },
        {
          path: '/promotions/call-to-actions/edit/:callToActionId',
          name: "Modifier un appel à l'action",
          component: CallToActionsEdit,
          meta: {
            roles: ['ADMIN', 'SUPER_EDITOR', 'EDITOR'],
          },
        },
      ],
    },
    {
      path: '/promotions/codes',
      name: 'Codes promo',
      redirect: '/promotions/codes/index',
      component: BlankRouterView,
      children: [
        {
          path: '/promotions/codes/index',
          name: 'Liste des codes promo',
          component: CodePromosIndex,
          meta: {
            roles: ['ADMIN', 'SUPER_EDITOR', 'EDITOR'],
          },
        },
        {
          path: '/promotions/codes/add',
          name: 'Ajouter un code promo',
          component: CodePromosAdd,
          meta: {
            roles: ['ADMIN', 'SUPER_EDITOR', 'EDITOR'],
          },
        },
        {
          path: '/promotions/codes/edit/:codePromoId',
          name: 'Modifier un code promo',
          component: CodePromosEdit,
          meta: {
            roles: ['ADMIN', 'SUPER_EDITOR', 'EDITOR'],
          },
        },
      ],
    },
  ],
}
