import Vue from 'vue'
import { apolloClient } from '@/apollo'

const searchFilterValuesQuery = require('../../apollo/queries/SearchFilterValues.gql')
const getFilterValuesByGroupQuery = require('../../apollo/queries/GetFilterValuesByGroup.gql')
const getFilterValueQuery = require('../../apollo/queries/GetFilterValue.gql')
const createFitlerValueMutation = require('../../apollo/mutations/CreateFilterValue.gql')
const updateFilterValueMutation = require('../../apollo/mutations/UpdateFilterValue.gql')
const deleteFilterValueMutation = require('../../apollo/mutations/DeleteFilterValue.gql')

const state = {
  filterValues: [],
  id: null,
  value: '',
  FilterValueGroup: {},
  colorValue: '',
  vigieRules: [],
}

const actions = {
  async getFilterValues({ commit }, filterId) {
    const {
      data: { filterValues },
    } = await apolloClient.query({
      query: searchFilterValuesQuery,
      variables: {
        search: '',
        filterId,
      },
    })

    commit('SET_FILTER_VALUES', filterValues)
  },
  async getFilterValuesByGroup(context, filterId) {
    const {
      data: { filterValues },
    } = await apolloClient.query({
      query: getFilterValuesByGroupQuery,
      variables: {
        filterId,
      },
    })

    return filterValues
  },
  async getFilterValue({ commit }, { filterId, filterValueId }) {
    const {
      data: { filterValue },
    } = await apolloClient.query({
      query: getFilterValueQuery,
      variables: {
        filterId,
        filterValueId,
      },
    })

    commit('SET_FILTER_VALUE', filterValue)
  },
  async createFilterValue(
    {
      state: {
        value,
        FilterValueGroup = null,
        colorValue = '',
        vigieRules = [],
      },
    },
    filterId
  ) {
    const filterValue = {
      value,
      colorValue,
      vigieRules,
      FilterValueGroupId:
        FilterValueGroup !== null ? FilterValueGroup.id : null,
    }

    await apolloClient.query({
      query: createFitlerValueMutation,
      variables: {
        filterId,
        filterValue,
      },
    })
  },
  async updateFilterValue(
    {
      state: {
        id,
        value,
        FilterValueGroup = null,
        colorValue = '',
        vigieRules = [],
      },
    },
    filterId
  ) {
    const filterValue = {
      value,
      colorValue,
      vigieRules,
      FilterValueGroupId:
        FilterValueGroup !== null ? FilterValueGroup.id : null,
    }

    await apolloClient.query({
      query: updateFilterValueMutation,
      variables: {
        filterId,
        filterValueId: id,
        filterValue: filterValue,
      },
    })
  },

  async deleteFilterValue({ commit }, { filterId, filterValueId }) {
    await apolloClient.query({
      query: deleteFilterValueMutation,
      variables: {
        filterId,
        filterValueId,
      },
    })

    commit('REMOVE_FILTER_VALUE', filterValueId)
  },

  async autocompleteFilterValues(context, payload) {
    const {
      data: { searchFilterValues },
    } = await apolloClient.query({
      query: searchFilterValuesQuery,
      variables: payload,
    })
    return searchFilterValues
  },
}

const mutations = {
  SET_FILTER_VALUES(state, filterValues) {
    state.filterValues = filterValues
  },
  SET_FILTER_VALUE(
    state,
    {
      id = null,
      value = '',
      FilterValueGroup = {},
      colorValue = '',
      vigieRules = [],
    }
  ) {
    state.id = id
    state.value = value
    state.FilterValueGroup = FilterValueGroup
    state.colorValue = colorValue
    state.vigieRules = vigieRules
  },
  SET_VALUE(state, value) {
    state.value = value
  },
  SET_FILTER_VALUE_GROUP(state, FilterValueGroup) {
    state.FilterValueGroup = FilterValueGroup
  },
  SET_COLOR_VALUE(state, colorValue) {
    state.colorValue = colorValue
  },
  SET_VIGIE_RULES(state, vigieRules) {
    state.vigieRules = vigieRules
  },
  REMOVE_FILTER_VALUE(state, filterValueId) {
    const index = state.filterValues.findIndex(({ id }) => id === filterValueId)
    if (index >= 0) {
      state.filterValues.splice(index, 1)
    }
  },
}

const getters = {
  getFilterValues: (state, getters, rootState) =>
    Object.values(state.filterValuesMap).filter(
      (value) => value.filterId === rootState.filters.currentFilterId
    ),
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
