<i18n>
{
  "en": {
    "States": "État / Province"
  },
  "fr": {
    "States": "État / Province"
  }
}
</i18n>

<template>
  <select
    v-if="isSelect"
    class="form-control"
    @change="$emit('input', $event.target.value)"
  >
    <option value="">Sélectionner</option>
    <option
      v-for="option in options"
      :key="option.value"
      :value="option.value"
      :selected="option.value === value"
    >
      {{ option.label }}
    </option>
  </select>
  <input
    v-else
    class="form-control"
    type="text"
    @change="$emit('input', $event.target.value)"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    country: {
      type: String,
      default: 'CA',
    },
    copied: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      states: [],
      copying: false,
    }
  },
  computed: {
    options() {
      return this.states.map((state) => ({
        value: state.code,
        label: state.name,
      }))
    },

    isSelect() {
      return this.states.length > 0 || this.country === ''
    },
  },
  watch: {
    country: {
      async handler(value) {
        if (value) {
          this.states = await this.$store.dispatch('utils/getStates', {
            countryCode: value,
          })
        }

        this.$emit('input', this.copied || this.copying ? this.value : '')
        this.copying = this.copied
      },
    },
  },
  async mounted() {
    if (this.country) {
      this.states = await this.$store.dispatch('utils/getStates', {
        countryCode: this.country,
      })
    }
  },
}
</script>
