import BlankRouterView from '@/views/Layout/BlankRouterView'
import SchoolEstablishmentsIndex from '@/views/Dashboard/Establishments/SchoolEstablishments/SchoolEstablishmentsIndex'
import SchoolEstablishmentsAdd from '@/views/Dashboard/Establishments/SchoolEstablishments/SchoolEstablishmentsAdd'
import SchoolEstablishmentsEdit from '@/views/Dashboard/Establishments/SchoolEstablishments/SchoolEstablishmentsEdit'
import StoresIndex from '@/views/Dashboard/Establishments/Stores/StoresIndex'
import StoresAdd from '@/views/Dashboard/Establishments/Stores/StoresAdd'
import StoresEdit from '@/views/Dashboard/Establishments/Stores/StoresEdit'
import PickingPointsIndex from '@/views/Dashboard/Establishments/PickingPoints/PickingPointsIndex'
import PickingPointsAdd from '@/views/Dashboard/Establishments/PickingPoints/PickingPointsAdd'
import PickingPointsEdit from '@/views/Dashboard/Establishments/PickingPoints/PickingPointsEdit'

export default {
  path: '/establishments',
  name: 'Établissements',
  redirect: '/establishments/schools/index',
  component: BlankRouterView,
  children: [
    {
      path: '/establishments/stores',
      redirect: '/establishments/stores/index',
      name: 'Succursales',
      component: BlankRouterView,
      children: [
        {
          path: '/establishments/stores/index',
          name: 'Liste des succursales',
          component: StoresIndex,
          meta: {
            roles: ['ADMIN', 'MANAGER'],
          },
        },
        {
          path: '/establishments/stores/add',
          name: 'Ajouter une succursale',
          component: StoresAdd,
          meta: {
            roles: ['ADMIN', 'MANAGER'],
          },
        },
        {
          path: '/establishments/stores/edit/:id',
          name: 'Modifier une succursale',
          component: StoresEdit,
          meta: {
            roles: ['ADMIN', 'MANAGER'],
          },
        },
      ],
    },
    {
      path: '/establishments/pickingpoints',
      redirect: '/establishments/pickingpoints/index',
      name: 'Point de cueillette',
      component: BlankRouterView,
      children: [
        {
          path: '/establishments/pickingpoints/index',
          name: 'Liste des points',
          component: PickingPointsIndex,
          meta: {
            roles: ['ADMIN', 'MANAGER'],
          },
        },
        {
          path: '/establishments/pickingpoints/add',
          name: 'Ajouter une point de cueillette',
          component: PickingPointsAdd,
          meta: {
            roles: ['ADMIN', 'MANAGER'],
          },
        },
        {
          path: '/establishments/pickingpoints/edit/:id',
          name: 'Modifier un point de cueillette',
          component: PickingPointsEdit,
          meta: {
            roles: ['ADMIN', 'MANAGER'],
          },
        },
      ],
    },
    {
      path: '/establishments/schools',
      redirect: '/establishments/schools/index',
      name: 'Établissements scolaires',
      component: BlankRouterView,
      children: [
        {
          path: '/establishments/schools/index',
          name: 'Liste des établissements scolaires',
          component: SchoolEstablishmentsIndex,
          meta: {
            roles: ['ADMIN', 'MANAGER'],
          },
        },
        {
          path: '/establishments/schools/add',
          name: 'Créer un établissement scolaire',
          component: SchoolEstablishmentsAdd,
          meta: {
            roles: ['ADMIN', 'MANAGER'],
          },
        },
        {
          path: '/establishments/schools/edit/:id',
          name: 'Modifier un établissement scolaire',
          component: SchoolEstablishmentsEdit,
          meta: {
            roles: ['ADMIN', 'MANAGER'],
          },
        },
      ],
    },
  ],
}
