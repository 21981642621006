<template>
  <b-row align="end">
    <b-col>
      <BaseButton
        v-if="schoolList.status === 'DRAFT'"
        size="lg"
        type="primary"
        native-type="submit"
      >
        Sauvegarder en brouillon
      </BaseButton>
      <BaseButton
        v-else-if="!readonly"
        size="lg"
        type="primary"
        native-type="submit"
      >
        Sauvegarder
      </BaseButton>
    </b-col>
  </b-row>
</template>

<script>
import { BaseButton } from '@coop-zone/coop-zone-argon'
import { mapGetters } from 'vuex'

export default {
  components: {
    BaseButton,
  },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('schoolLists', ['schoolList']),
  },
}
</script>
