<template>
  <div>
    <BaseHeader class="pb-6" type="default">
      <b-row align-v="center" class="py-4">
        <b-col>
          <p class="h2 text-white d-inline-block mb-0">Ajouter une catégorie</p>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </BaseHeader>

    <b-container fluid class="mt--6 pb-4">
      <b-row>
        <b-col xl="12">
          <CategoryForm title="Ajouter une catégorie" :on-success="save" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { RouteBreadcrumb, BaseHeader } from '@coop-zone/coop-zone-argon'
import CategoryForm from '@/components/Categories/CategoryForm'

export default {
  components: {
    CategoryForm,
    RouteBreadcrumb,
    BaseHeader,
  },
  created() {
    this.$store.commit('categories/SET_CURRENT_CATEGORY', {})
  },
  methods: {
    async save() {
      try {
        await this.$store.dispatch('categories/createCategory')
        this.$router.push('/categories/index')
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
