import BlankRouterView from '@/views/Layout/BlankRouterView'

import CatalogSearch from '@/views/Dashboard/Catalog/CatalogSearch'
import BundleSearch from '@/views/Dashboard/Catalog/BundleSearch'
import CatalogAdd from '@/views/Dashboard/Catalog/CatalogAdd'
import BundleAdd from '@/views/Dashboard/Catalog/BundleAdd'
import CatalogUpdate from '@/views/Dashboard/Catalog/CatalogUpdate'
import BundleUpdate from '@/views/Dashboard/Catalog/BundleUpdate'

export default {
  path: '/catalog',
  name: 'Catalogue',
  redirect: '/catalog/search',
  component: BlankRouterView,
  children: [
    {
      path: '/catalog/search',
      name: 'Rechercher un produit',
      component: CatalogSearch,
      meta: {
        roles: ['ADMIN', 'MANAGER', 'SUPER_EDITOR', 'EDITOR'],
      },
    },
    {
      path: '/catalog/add',
      name: 'Ajouter un produit',
      component: CatalogAdd,
      meta: {
        roles: ['ADMIN', 'MANAGER', 'SUPER_EDITOR', 'EDITOR'],
      },
    },
    {
      path: '/catalog/edit/:slug',
      name: 'Modifier un produit',
      component: CatalogUpdate,
      meta: {
        roles: ['ADMIN', 'MANAGER', 'SUPER_EDITOR', 'EDITOR'],
      },
    },
    {
      path: '/catalog/bundles',
      name: 'Rechercher un ensemble',
      component: BundleSearch,
      meta: {
        roles: ['ADMIN', 'MANAGER', 'SUPER_EDITOR', 'EDITOR'],
      },
    },
    {
      path: '/catalog/bundles/add',
      name: 'Ajouter un ensemble',
      component: BundleAdd,
      meta: {
        roles: ['ADMIN', 'MANAGER', 'SUPER_EDITOR', 'EDITOR'],
      },
    },
    {
      path: '/catalog/bundles/edit/:slug',
      name: 'Modifier un ensemble',
      component: BundleUpdate,
      meta: {
        roles: ['ADMIN', 'MANAGER', 'SUPER_EDITOR', 'EDITOR'],
      },
    },
  ],
}
