<template>
  <div class="order-tag" :class="`order-tag--${type}`">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped>
/* CSS ALSO NEEDS TO BE UPDATED IN /public/css/order.print.css  */

.order-tag {
  border-radius: 0.2rem;
  color: #fff;
  display: inline-block;
  font-size: 66%;
  font-weight: 600;
  line-height: 1;
  margin: 2px 5px 0 0;
  padding: 0.3rem 0.35rem;
  text-align: center;
  text-transform: uppercase;
  vertical-align: baseline;
  white-space: nowrap;
}

.order-tag--bundle {
  background-color: #004b80;
}

.order-tag--replacement {
  background-color: #999999;
  margin-bottom: 5px;
}
</style>
