<template>
  <ul class="category-tree">
    <CategoryTreeItem
      v-for="item in tree"
      :key="item.id"
      :item="item"
      :root="true"
      :on-delete="onDelete"
      :on-sort="onSort"
    />
  </ul>
</template>

<script>
import CategoryTreeItem from '@/components/Categories/CategoryTreeItem'

export default {
  components: { CategoryTreeItem },
  props: {
    tree: {
      type: Array,
      required: true,
    },
    onDelete: {
      type: Function,
      required: true,
    },
    onSort: {
      type: Function,
      required: true,
    },
  },
}
</script>

<style scoped>
.category-tree {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.category-tree::v-deep li {
  user-select: none;
}

.category-tree::v-deep label {
  position: relative;
  display: block;
  padding: 18px 18px 18px 25px;
  box-shadow: inset 0 -1px #e9ecef;
}

.category-tree::v-deep label {
  margin-bottom: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
}

.category-tree::v-deep label div.category-item__toggle {
  cursor: pointer;
  margin-right: 10px;
}

.category-tree::v-deep label div.category-item__sort {
  cursor: move;
  margin-right: 10px;
}

.category-tree::v-deep label div .category-item__icon {
  position: relative;
  color: #adb5bd;
  display: inline-block;
  margin-right: 10px;
}

.category-tree::v-deep label div.category-item__actions {
  margin-left: auto;
}

.category-tree::v-deep label.open::before {
  transform: translateY(-25%) rotate(90deg);
}

.category-tree::v-deep ul {
  list-style: none;
  padding: 0;
}

.category-tree::v-deep ul label {
  box-shadow: inset 0 -1px #e9ecef;
  padding-left: 60px;
}

.category-tree::v-deep > li:last-of-type > label,
.category-tree::v-deep > li > ul > li:last-of-type label {
  box-shadow: none;
}

.category-tree::v-deep ul ul label {
  padding-left: 85px;
}

.category-tree::v-deep ul ul ul label {
  padding-left: 105px;
}
</style>
