<template>
  <BaseForm
    id="filterForm"
    success-message="Groupe de valeur enregistré"
    :on-success="save"
  >
    <b-card>
      <div slot="header">
        <b-row align-v="center">
          <b-col sm="12" lg="6">
            <p class="h6 text-uppercase text-muted ls-1 mb-1">
              {{ title }}
            </p>
            <h2 class="mb-0">
              {{ name }}
            </h2>
          </b-col>

          <b-col sm="12" lg="6" class="text-right">
            <slot name="actions" />
          </b-col>
        </b-row>
      </div>

      <b-row>
        <b-col col-sm="6">
          <BaseInput v-model="name" label="Nom" rules="required" />
        </b-col>
      </b-row>
    </b-card>

    <b-row align="end">
      <b-col>
        <BaseButton size="lg" type="primary" native-type="submit">
          Sauvegarder
        </BaseButton>
      </b-col>
    </b-row>
  </BaseForm>
</template>

<script>
import { BaseButton, BaseInput } from '@coop-zone/coop-zone-argon'
import BaseForm from '@/components/forms/BaseForm'

export default {
  components: {
    BaseForm,
    BaseButton,
    BaseInput,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    name: {
      get() {
        return this.$store.state.filters.valueGroups.name
      },
      set(name) {
        this.$store.commit('filters/valueGroups/SET_NAME', name)
      },
    },
  },
  created() {
    this.$store.commit('filters/valueGroups/SET_FILTER_VALUE_GROUP', {})
    if (this.$route.params.filterValueGroupId) {
      this.$store.dispatch('filters/valueGroups/getFilterValueGroup', {
        filterId: this.$route.params.filterId,
        filterValueGroupId: this.$route.params.filterValueGroupId,
      })
    }
  },
  methods: {
    async save() {
      if (this.$route.params.filterValueGroupId) {
        await this.$store.dispatch(
          'filters/valueGroups/updateFilterValueGroup',
          this.$route.params.filterId
        )
        this.$router.push(`/filters/${this.$route.params.filterId}`)
      } else {
        await this.$store.dispatch(
          'filters/valueGroups/createFilterValueGroup',
          this.$route.params.filterId
        )
        this.$router.push(`/filters/${this.$route.params.filterId}`)
      }
    },
  },
}
</script>
