<template>
  <div>
    <BaseHeader class="pb-6" type="default">
      <b-row align-v="center" class="py-4">
        <b-col>
          <p class="h2 text-white d-inline-block mb-0">Succursales</p>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </BaseHeader>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="12">
          <StoreForm
            :on-success="save"
            title="Créer une succursale"
            redirect="/establishments/stores/index"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { RouteBreadcrumb, BaseHeader } from '@coop-zone/coop-zone-argon'
import StoreForm from '@/components/Stores/StoreForm'

export default {
  components: {
    StoreForm,
    RouteBreadcrumb,
    BaseHeader,
  },
  created() {
    this.$store.commit('stores/SET_STORE', {})
  },
  methods: {
    async save() {
      await this.$store.dispatch('stores/createStore')
    },
  },
}
</script>
