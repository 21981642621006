<template>
  <b-row v-if="categoryItems.length > 0">
    <b-col>
      <draggable
        v-model="categoryItems"
        tag="ul"
        class="list-group list-group-flush"
        handle=".item-handle"
      >
        <li
          v-for="item in categoryItems"
          :key="item.id"
          class="list-group-item"
        >
          <BaseItem :item="item" :category="category" :disabled="disabled" />
        </li>
      </draggable>
    </b-col>
  </b-row>

  <b-row v-else>
    <b-col>
      <p class="form-control-label">Liste d’articles</p>
      <p>Aucun article ajouté pour le moment</p>
    </b-col>
  </b-row>
</template>
<script>
import draggable from 'vuedraggable'
import { mapGetters } from 'vuex'
import BaseItem from '@/components/SchoolLists/Items/BaseItem'

export default {
  components: {
    BaseItem,
    draggable,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('schoolLists/categories', [
      'itemIsBeingEdited',
      'itemIsNew',
      'categoryItemsAreBeingEdited',
    ]),
    categoryItems: {
      get() {
        if (!this.category.ListCategoryItems) {
          return []
        }
        return this.category.ListCategoryItems.filter(
          (item) =>
            !this.categoryItemsAreBeingEdited(this.category) ||
            this.itemIsBeingEdited(item) ||
            this.itemIsNew(item)
        )
      },
      set(value) {
        this.$store.dispatch('schoolLists/categories/updateCategoryItems', {
          category: this.category,
          items: value,
        })
      },
    },
  },
}
</script>

<style lang="sass" scoped>
::v-deep .form-group
  margin-bottom: 0;
</style>
