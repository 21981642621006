<template>
  <BaseButton
    size="sm"
    :type="disabled ? 'secondary' : 'default'"
    :class="{ disabled }"
    :disabled="disabled"
    @click="handlePrint"
  >
    <slot />
  </BaseButton>
</template>

<script>
import printjs from 'print-js'
import { BaseButton } from '@coop-zone/coop-zone-argon'

export default {
  components: {
    BaseButton,
  },
  props: {
    containerId: {
      type: String,
      required: true,
    },
    onPrint: {
      type: Function,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async handlePrint() {
      await this.onPrint()
      printjs({
        printable: this.containerId,
        type: 'html',
        css: [
          'https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap',
          '/css/order.print.css',
        ],
        scanStyles: false,
      })
    },
  },
}
</script>
