<i18n>
{
  "fr": {
    "DeleteSuccess": "La section a bien été supprimée",
    "CreateSuccess": "La section a bien été ajoutée",
    "EditSuccess": "La section a bien été modifiée"
  }
}
</i18n>

<template>
  <div class="content">
    <base-header class="pb-6 bg-default">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="12">
          <p class="h2 text-white d-inline-block mb-0">Sections de cours</p>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb />
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6 pb-4">
      <SchoolListSectionForm
        :on-success="save"
        title="Ajouter une section"
        redirect="/school-lists/sections/index"
      />
    </b-container>
  </div>
</template>

<script>
import { BaseHeader, RouteBreadcrumb } from '@coop-zone/coop-zone-argon'
import SchoolListSectionForm from '@/components/SchoolLists/SchoolListSectionForm'
import ErrorHandlerMixin from '@/mixins/errorHandler.mixin'

export default {
  components: {
    BaseHeader,
    RouteBreadcrumb,
    SchoolListSectionForm,
  },
  mixins: [ErrorHandlerMixin],
  async created() {
    try {
      this.$store.dispatch(
        'schoolLists/sections/getSection',
        this.$route.params.sectionId
      )
    } catch (error) {
      this.handleError(error)
    }
  },
  methods: {
    async save() {
      await this.$store.dispatch('schoolLists/sections/updateSection')
    },
  },
}
</script>
