<template>
  <div class="content">
    <base-header class="pb-6 bg-default">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="12">
          <h6 class="h2 text-white d-inline-block mb-0">CMS</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-card no-body class="mb-4">
        <b-row slot="header" align-v="center">
          <b-col cols="4">
            <h3 class="mb-0">Landing pages</h3>
          </b-col>
          <b-col class="text-right">
            <router-link
              class="btn btn-sm btn-primary"
              to="/landing-pages/add"
              data-cy="tax-add-link"
            >
              Créer une nouvelle page
            </router-link>
          </b-col>
        </b-row>
        <FuseList :data="pages" placeholder="Titre" :keys="['name']">
          <el-table-column label="Titre de la page" min-width="160px">
            <template slot-scope="scope">
              <router-link :to="`/landing-pages/edit/${scope.row.id}`">
                <i v-if="scope.row.homepage" class="fa fa-flag mr-3" />
                <span class="font-weight-600 name text-sm">{{
                  scope.row.name
                }}</span>
              </router-link>
            </template>
          </el-table-column>
          <el-table-column align="right">
            <template slot-scope="scope" class="table-actions">
              <router-link
                class="table-action table-action-edit"
                :to="`/landing-pages/edit/${scope.row.id}`"
              >
                <i class="fa fa-edit" />
              </router-link>
              <a
                v-if="!scope.row.homepage"
                v-confirm-delete="{
                  onConfirm: () => deletePage(scope.row.id),
                  confirmText: 'La page sera définitivement effacée',
                  successText: 'La page a été supprimée',
                }"
                href="#!"
                class="table-action table-action-delete"
              >
                <i class="fa fa-trash" />
              </a>
            </template>
          </el-table-column>
        </FuseList>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import { BaseHeader, RouteBreadcrumb } from '@coop-zone/coop-zone-argon'
import { mapState } from 'vuex'
import FuseList from '@/components/FuseList'

export default {
  components: {
    BaseHeader,
    FuseList,
    RouteBreadcrumb,
  },
  computed: {
    ...mapState('landingPages', ['pages']),
  },
  created() {
    this.$store.dispatch('landingPages/getPages')
  },
  methods: {
    deletePage(pageId) {
      this.$store.dispatch('landingPages/delete', pageId)
    },
  },
}
</script>

<style>
.no-border-card .card-footer {
  border-top: 0;
}
</style>
