import BlankRouterView from '@/views/Layout/BlankRouterView'
import LandingPagesSearch from '@/views/Dashboard/LandingPages/PagesSearch'
import LandingPageEdit from '@/views/Dashboard/LandingPages/PageEdit'
import LandingPageAdd from '@/views/Dashboard/LandingPages/PageAdd'

export default {
  path: '/landing-pages',
  name: 'LandingPages',
  redirect: '/landing-pages/index',
  component: BlankRouterView,
  children: [
    {
      path: '/landing-pages/index',
      name: 'Rechercher une landing page',
      component: LandingPagesSearch,
      meta: {
        roles: ['ADMIN', 'SUPER_EDITOR', 'EDITOR'],
      },
    },
    {
      path: '/landing-pages/edit/:id',
      name: 'Modifier une page',
      component: LandingPageEdit,
      meta: {
        roles: ['ADMIN', 'SUPER_EDITOR', 'EDITOR'],
      },
    },
    {
      path: '/landing-pages/add',
      name: 'Ajouter une page',
      component: LandingPageAdd,
      meta: {
        roles: ['ADMIN', 'SUPER_EDITOR', 'EDITOR'],
      },
    },
  ],
}
