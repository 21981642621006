<template>
  <div>
    <base-header class="pb-6" type="">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h2 d-inline-block mb-0">Tableau de bord</h6>
        </b-col>
      </b-row>
      <!-- Card stats -->
    </base-header>

    <!--Charts-->
    <b-container fluid class="mt--6">
      <!--Tables-->
      <b-row>
        <b-col v-if="account && account.role === 'ADMIN'" sm="12">
          <DashboardTasks />
        </b-col>
      </b-row>
      <!--End tables-->
    </b-container>
  </div>
</template>
<script>
import { BaseHeader } from '@coop-zone/coop-zone-argon'
import { mapState } from 'vuex'

import DashboardTasks from './Dashboard/DashboardTasks.vue'
export default {
  components: {
    BaseHeader,
    DashboardTasks,
  },
  computed: {
    ...mapState('accounts', ['account']),
  },
}
</script>

<style lang="sass">
.order-product + .order-product
  margin-top: 1rem

.order-product .el-card__body
  padding: 15px 20px

.order-product-card

  &--with-replacement
    border-bottom-left-radius: 0
    border-bottom-right-radius: 0

.order-product-card + .order-product-card
  background-color: var(--gray-100)
  border-top-left-radius: 0
  border-top-right-radius: 0
  border-top: none
  margin-top: 0 !important

.nav-item .nav-item .router-link-exact-active
  font-weight: bold

.el-select.el-select--mini .el-input .el-input__inner
  height: 28px !important
  line-height: 28px !important

.el-select.el-select--mini .el-input
  font-size: 12px !important

.el-table__fixed-right
  background-color: white

.el-table__fixed-header-wrapper th
  padding-bottom: 0 !important
  padding-top: 0 !important

.el-table__fixed-header-wrapper th .cell
  min-height: 40px
</style>
