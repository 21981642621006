<template>
  <BaseForm
    id="pickingPointForm"
    success-message="Point de cueillette enregistré"
    :on-success="onSuccess"
    :redirect="redirect"
  >
    <b-card>
      <div slot="header">
        <b-row align-v="center">
          <b-col sm="12" lg="6">
            <p class="h6 text-uppercase text-muted ls-1 mb-1">
              {{ title }}
            </p>
            <h2 class="mb-0">
              {{ name }}
            </h2>
          </b-col>
          <b-col sm="12" lg="6" class="text-right">
            <slot name="actions" />
          </b-col>
        </b-row>
      </div>

      <b-row>
        <b-col class="mt-4 mt-md-0">
          <BaseInput v-model="name" label="Nom" rules="required" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <BaseCheckbox v-model="active" class="mb-3"> Actif </BaseCheckbox>
        </b-col>
        <b-col>
          <BaseCheckbox v-model="needLocalNumber" class="mb-3">
            Nécessite un numéro de local
          </BaseCheckbox>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <CanadaPostInput
            v-model="addressLine1"
            class="billingAddressAddress"
            label="Adresse"
            rules="required"
            @fill="fillAddressWithCanadaPostInput"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <BaseInput
            v-model="addressLine2"
            label="Numéro appartement, local, bureau, etc. (optionnel)"
            type="text"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" sm="6">
          <BaseInput
            v-model="city"
            label="Ville"
            type="text"
            rules="required"
          />
        </b-col>

        <b-col cols="12" sm="6">
          <BaseInput label="Pays" rules="required">
            <FormSelectCountry
              v-model="country"
              label="Pays"
              rules="required"
            />
          </BaseInput>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" sm="6">
          <BaseInput
            v-model="postalCode"
            label="Code postal"
            type="text"
            rules="required"
          />
        </b-col>
        <b-col cols="12" sm="6">
          <BaseInput label="Province / État" rules="required">
            <FormSelectState v-model="state" :country="country" />
          </BaseInput>
        </b-col>
      </b-row>

      <b-row align-h="end">
        <b-col cols="auto">
          <BaseButton size="lg" type="primary" native-type="submit">
            Sauvegarder
          </BaseButton>
        </b-col>
      </b-row>
    </b-card>
  </BaseForm>
</template>
<script>
import { BaseButton, BaseCheckbox, BaseInput } from '@coop-zone/coop-zone-argon'
import BaseForm from '@/components/forms/BaseForm'
import CanadaPostInput from '@/components/Inputs/CanadaPostInput'
import FormSelectCountry from '@/components/Inputs/FormSelectCountry'
import FormSelectState from '@/components/Inputs/FormSelectState'

export default {
  components: {
    BaseButton,
    BaseCheckbox,
    BaseForm,
    BaseInput,
    CanadaPostInput,
    FormSelectCountry,
    FormSelectState,
  },
  props: {
    onSuccess: {
      type: Function,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    redirect: {
      type: [String, Boolean],
      default: false,
    },
  },
  computed: {
    name: {
      get() {
        return this.$store.state.pickingpoints.name
      },
      set(name) {
        this.$store.commit('pickingpoints/SET_NAME', name)
      },
    },
    active: {
      get() {
        return this.$store.state.pickingpoints.active
      },
      set(active) {
        this.$store.commit('pickingpoints/SET_ACTIVE', active)
      },
    },
    needLocalNumber: {
      get() {
        return this.$store.state.pickingpoints.needLocalNumber
      },
      set(needLocalNumber) {
        this.$store.commit(
          'pickingpoints/SET_NEED_LOCAL_NUMBER',
          needLocalNumber
        )
      },
    },
    address: {
      get() {
        return this.$store.state.pickingpoints.address
      },
      set(address) {
        this.$store.commit('pickingpoints/SET_ADDRESS', address)
      },
    },
    addressLine1: {
      get() {
        return this.$store.state.pickingpoints.address.address
      },
      set(addressLine1) {
        this.$store.commit('pickingpoints/SET_ADDRESS', {
          ...this.address,
          address: addressLine1,
        })
      },
    },
    addressLine2: {
      get() {
        return this.$store.state.pickingpoints.address.address2
      },
      set(addressLine2) {
        this.$store.commit('pickingpoints/SET_ADDRESS', {
          ...this.address,
          address2: addressLine2,
        })
      },
    },
    city: {
      get() {
        return this.$store.state.pickingpoints.address.city
      },
      set(city) {
        this.$store.commit('pickingpoints/SET_ADDRESS', {
          ...this.address,
          city,
        })
      },
    },
    country: {
      get() {
        return this.$store.state.pickingpoints.address.country
      },
      set(country) {
        this.$store.commit('pickingpoints/SET_ADDRESS', {
          ...this.address,
          country,
        })
      },
    },
    postalCode: {
      get() {
        return this.$store.state.pickingpoints.address.postalCode
      },
      set(postalCode) {
        this.$store.commit('pickingpoints/SET_ADDRESS', {
          ...this.address,
          postalCode,
        })
      },
    },
    state: {
      get() {
        return this.$store.state.pickingpoints.address.state
      },
      set(state) {
        this.$store.commit('pickingpoints/SET_ADDRESS', {
          ...this.address,
          state,
        })
      },
    },
  },
  methods: {
    fillAddressWithCanadaPostInput(address) {
      this.addressLine1 = address.Line1
      this.addressLine2 = address.Line2
      this.city = address.City
      this.country = address.CountryIso2
      this.postalCode = address.PostalCode
      this.state = address.ProvinceCode
    },
  },
}
</script>
