<i18n>
{
  "en": {
    "Country": "Pays"
  },
  "fr": {
    "Country": "Pays"
  }
}
</i18n>

<template>
  <select class="form-control" @change="$emit('input', $event.target.value)">
    <option value="">Sélectionner</option>
    <option
      v-for="option in countries"
      :key="option.value"
      :value="option.value"
      :selected="option.value === value"
    >
      {{ option.label }}
    </option>
  </select>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: 'CA',
    },
  },
  data() {
    return {
      countries: [],
    }
  },
  async mounted() {
    const countries = await this.$store.dispatch('utils/getCountries')
    this.countries = Object.keys(countries).map((key) => ({
      value: countries[key].code,
      label: countries[key].name,
    }))
  },
}
</script>
