<template>
  <b-container v-loading="loading" fluid class="mt--6 pb-5">
    <BaseForm classes="form" :on-success="onSuccess" :redirect="redirect">
      <b-row>
        <b-col xl="8">
          <PageBlocks :blocks="blocks" :page="fields.content" />
          <SeoBlock />

          <b-row align-h="end">
            <b-col cols="auto">
              <BaseButton size="lg" type="primary" native-type="submit">
                Sauvegarder
              </BaseButton>
            </b-col>
          </b-row>
        </b-col>

        <b-col xl="4">
          <div class="sticky">
            <card type="gradient-primary">
              <b-row align-v="center" class="justify-content-between">
                <b-col>
                  <h5 class="h3 text-white mb-0">Identification</h5>
                </b-col>
              </b-row>
              <div class="mt-4">
                <div class="form-primary">
                  <BaseCheckbox v-model="visible" class="mb-3 text-white">
                    Visible sur le site public
                  </BaseCheckbox>

                  <BaseCheckbox
                    v-if="id && visible"
                    v-model="homepage"
                    class="mb-3 text-white"
                  >
                    Est la page d'accueil
                  </BaseCheckbox>

                  <base-input
                    v-model="name"
                    prepend-icon="ni ni-align-center"
                    class="input-group-alternative mb-3"
                    placeholder="Titre de la page"
                    rules="required"
                  />

                  <base-input
                    v-model="slug"
                    prepend-icon="ni ni-app"
                    class="input-group-alternative mb-3"
                    placeholder="Slug"
                  />

                  <base-input
                    :value="slugged"
                    readonly
                    class="input-group-alternative mb-3"
                    label-classes="form-control-label text-white"
                    label="Prévisualisation du slug"
                  />

                  <a
                    v-if="id"
                    class="btn btn-success btn-lg btn-block"
                    :href="homepage ? websiteUrl : `${websiteUrl}/${slug}`"
                    target="_blank"
                    role="button"
                  >
                    Voir sur le site
                  </a>

                  <BaseButton type="secondary" native-type="submit" block>
                    Enregistrer la page
                  </BaseButton>

                  <BaseButton
                    v-if="id"
                    v-confirm-delete="{
                      onConfirm: () => deletePage(),
                      confirmText: 'La page sera définitivement effacée',
                      successText: 'La page été supprimée',
                    }"
                    type="warning"
                    block
                  >
                    Supprimer la page
                  </BaseButton>
                </div>
              </div>
            </card>

            <slot name="actions" />

            <!-- <card v-if="hasActiveBlock" class="preview"> -->
            <!-- <img src="~assets/preview/top.png" /> -->

            <!-- <BaseBlockPreview
                v-for="({ preview, active }, index) in blocks"
                :key="`preview-${index}`"
                :active="active"
              >
                <component :is="preview" :value="blocks[index]" />
              </BaseBlockPreview> -->

            <!-- <img src="~assets/preview/bottom.png" /> -->
            <!-- </card> -->
          </div>
        </b-col>
      </b-row>
    </BaseForm>
  </b-container>
</template>
<script>
import { landing as pageBlocks } from '@/assets/blocks/pages'
import {
  BaseButton,
  BaseCheckbox,
  BaseInput,
  Card,
} from '@coop-zone/coop-zone-argon'
import { mapState } from 'vuex'
import { PageBlocks } from '@/components/Blocks'
import slugify from 'slugify'
import BaseForm from '@/components/forms/BaseForm'
import SeoBlock from '@/components/seo/SeoBlock'
import getEnv from '@/utils/env'
import ErrorHandlerMixin from '@/mixins/errorHandler.mixin'

export default {
  components: {
    PageBlocks,
    BaseButton,
    BaseCheckbox,
    BaseForm,
    BaseInput,
    Card,
    SeoBlock,
  },
  mixins: [ErrorHandlerMixin],
  props: {
    onSuccess: {
      type: Function,
      required: true,
    },
    redirect: {
      type: [String, Boolean],
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapState('landingPages', ['id', 'fields']),
    hasActiveBlock() {
      // return this.blocks.filter((item) => item.active === true).length > 0
      return false
    },
    homepage: {
      get() {
        return this.fields.homepage
      },
      set(homepage) {
        this.$store.commit('landingPages/SET_HOMEPAGE', homepage)
      },
    },
    name: {
      get() {
        return this.fields.name
      },
      set(value) {
        this.$store.commit('landingPages/SET_FIELD_NAME', value)
      },
    },
    slug: {
      get() {
        return this.fields.slug
      },
      set(value) {
        this.$store.commit('landingPages/SET_FIELD_SLUG', value)
      },
    },
    visible: {
      get() {
        return this.fields.visible
      },
      set(value) {
        this.$store.commit('landingPages/SET_FIELD_VISIBLE', value)
      },
    },
    slugged() {
      const options = { lower: true, strict: true }
      const slugged = this.fields.slug
        ? slugify(this.fields.slug, options)
        : this.fields.name
        ? slugify(this.fields.name, options)
        : null
      this.$store.commit('landingPages/SET_FIELD_SLUGGED', slugged)
      return slugged
    },
  },
  created() {
    this.websiteUrl = getEnv('VUE_APP_WEBSITE_URL')
    this.blocks = pageBlocks
  },
  methods: {
    async deletePage() {
      try {
        this.loading = true
        await this.$store.dispatch('landingPages/delete', this.id)
        this.loading = false
        this.$router.push('/landing-pages')
      } catch (error) {
        this.loading = false
        this.handleError(error)
        throw error
      }
    },
  },
}
</script>
<style lang="sass">
.sticky
  position: sticky
  top: 10px

.preview
  padding: 0

  img
    width: 100%

  .card-body
    padding: 0
</style>
