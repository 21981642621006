<template>
  <div class="content">
    <base-header class="pb-6 bg-default">
      <b-row align-v="center" class="py-4">
        <b-col>
          <p class="h2 text-white d-inline-block mb-0">Matériel scolaire</p>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <BaseTableSearch
        :data="schoolLists"
        :pagination="pagination"
        :value="term"
        @sort-change="sortMethod"
        @search="search"
      >
        <template #header>
          <b-row slot="header" align-v="center">
            <b-col xl="4">
              <h3 class="mb-0">Gérer les listes</h3>
            </b-col>
            <b-col class="text-right">
              <router-link
                class="btn btn-sm btn-primary"
                :to="schoolListAddUrl"
                data-cy="schoolsupply-add-link"
              >
                Créer une nouvelle liste
              </router-link>
            </b-col>
          </b-row>
        </template>

        <template #filters>
          <b-row align-v="center">
            <b-col sm="12" xl="3">
              <BaseInput label="Filtrer par établissement">
                <SchoolSelect v-model="schoolId" :type="type">
                  <el-option :value="null" label="Tous les établissements" />
                </SchoolSelect>
              </BaseInput>
            </b-col>

            <b-col sm="12" xl="3">
              <BaseInput label="Filtrer par statut">
                <SchoolListStatusSelect v-model="statusId">
                  <el-option :value="null" label="Tous les statuts" />
                </SchoolListStatusSelect>
              </BaseInput>
            </b-col>

            <b-col sm="12" xl="3">
              <BaseInput label="Filtrer par session">
                <SchoolSessionSelect
                  v-model="sessionId"
                  :establishment-types="schoolSessionsEstablishmentTypes"
                >
                  <el-option :value="null" label="Toutes les sessions" />
                </SchoolSessionSelect>
              </BaseInput>
            </b-col>
          </b-row>
        </template>

        <el-table-column
          label="Niveau scolaire - option"
          sortable="custom"
          prop="name"
          min-width="300px"
        >
          <template slot-scope="scope">
            <router-link :to="schoolListEditUrl(scope.row)">
              <span class="font-weight-600 name text-sm">
                {{ scope.row.name }}
                <span v-if="scope.row.option"> - {{ scope.row.option }} </span>
              </span>
            </router-link>
          </template>
        </el-table-column>

        <el-table-column
          label="statut"
          sortable="custom"
          prop="status"
          width="225px"
        >
          <template slot-scope="scope">
            <SchoolListsStatusBadge
              :status="$t(`schoolLists.statuses.${scope.row.status}`)"
            />
          </template>
        </el-table-column>

        <el-table-column
          label="Session"
          sortable="custom"
          prop="session"
          width="250px"
        >
          <template slot-scope="scope">
            {{ scope.row.Session.name }}
          </template>
        </el-table-column>

        <el-table-column
          label="Date de création"
          sortable="custom"
          prop="createdAt"
          width="250px"
        >
          <template slot-scope="scope">
            {{ scope.row.createdAt | dateFormat }}
          </template>
        </el-table-column>

        <el-table-column
          label="Date de modification"
          sortable="custom"
          prop="updatedAt"
          width="250px"
        >
          <template v-if="scope.row.updatedAt" slot-scope="scope">
            {{ scope.row.updatedAt | dateFormat }}
          </template>
        </el-table-column>

        <el-table-column width="150px" align="right">
          <template slot-scope="scope" class="table-actions">
            <router-link
              class="table-action table-action-edit"
              :to="schoolListEditUrl(scope.row)"
            >
              <i class="fa fa-edit" />
            </router-link>

            <b-link
              class="table-action"
              @click.prevent="duplicateSchoolList(scope.row)"
            >
              <i class="fa fa-copy" />
            </b-link>

            <SchoolListsExportButton :school-list="scope.row" />

            <b-link
              v-if="canDelete(account, scope.row)"
              v-confirm-delete="{
                onConfirm: () => deleteSchoolList(scope.row),
                confirmText: 'La liste sera supprimée',
                successText: 'La liste a été supprimée',
              }"
              class="table-action"
            >
              <i class="fa fa-trash" />
            </b-link>
          </template>
        </el-table-column>
      </BaseTableSearch>
    </b-container>
  </div>
</template>
<script>
import schoolListIndexMixin from './schoolListIndex.mixin'
export default {
  mixins: [schoolListIndexMixin],
  data() {
    return {
      type: 'grade',
    }
  },
}
</script>
