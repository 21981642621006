<template>
  <div>
    <el-select
      :value="value"
      :disabled="readonly"
      placeholder="Sélectionner une session"
      @change="$emit('input', $event)"
    >
      <slot />

      <template v-if="displayByGroup">
        <el-option-group
          v-for="group in optionGroups"
          :key="group"
          :label="$t(`establishments.types.${group}`)"
        >
          <el-option
            v-for="session in options[group]"
            :key="session.id"
            :label="session.name"
            :value="session.id"
          >
            {{ session.name }}
          </el-option>
        </el-option-group>
      </template>

      <template v-else>
        <el-option
          v-for="session in options"
          :key="session.id"
          :label="session.name"
          :value="session.id"
        >
          {{ session.name }}
        </el-option>
      </template>
    </el-select>

    <slot name="error" :anyOptions="anyOptions" />
  </div>
</template>
<script>
import { groupBy } from 'lodash'
import { mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    establishmentTypes: {
      type: Array,
      default: null,
    },
    establishmentId: {
      type: String,
      default: null,
    },
    listId: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters('schoolSessions', {
      sessions: 'getSessions',
    }),
    ...mapGetters('schoolSessions', ['getSessionsByEstablishmentTypes']),
    options() {
      return this.displayByGroup
        ? this.sessionsByType
        : Object.values(this.sessionsByType)[0]
    },

    optionGroups() {
      return Object.keys(this.sessionsByType)
    },

    displayByGroup() {
      return Object.keys(this.sessionsByType).length > 1 ? true : false
    },

    sessionsByType() {
      const sessions = this.establishmentTypes
        ? this.getSessionsByEstablishmentTypes(this.establishmentTypes)
        : this.sessions
      return groupBy(sessions, 'establishmentType')
    },

    anyOptions() {
      return (
        this.sessions.length > 0 ||
        this.loading === true ||
        this.establishmentId === null ||
        this.establishmentId === ''
      )
    },
  },
  watch: {
    establishmentId: {
      async handler(newValue, oldValue) {
        if (newValue) {
          this.loading = true
          await this.$store.dispatch('schoolSessions/getManageableSessions', {
            EstablishmentId: newValue,
            listId: this.listId,
          })
          this.loading = false
        } else {
          this.$store.dispatch('schoolSessions/clearSessions')
        }

        if (oldValue) {
          this.$emit('input', null)
        }
      },
      immediate: true,
    },
  },

  created() {
    if (this.$options.propsData.establishmentId === undefined) {
      this.$store.dispatch('schoolSessions/getSessions')
    }
  },
}
</script>
