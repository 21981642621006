<template>
  <transition name="fade">
    <div v-if="visible" class="base-drawer">
      <el-drawer
        ref="drawer"
        v-loading="loading"
        size="70%"
        :before-close="handleClose"
        :visible.sync="visible"
        :append-to-body="true"
        direction="rtl"
      >
        <slot name="default" :cancel="cancel" :close="close" />

        <b-row>
          <b-col class="text-right">
            <slot name="footer" :cancel="cancel" :close="close" />
          </b-col>
        </b-row>
      </el-drawer>
    </div>
  </transition>
</template>
<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    beforeClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      visible: false,
    }
  },
  methods: {
    cancel() {
      this.visible = false
    },
    close() {
      this.$refs.drawer.closeDrawer()
    },
    open() {
      this.visible = true
    },
    async handleClose(done) {
      const action = await this.beforeClose()
      if (action && action == 'close') {
        return
      }
      done()
    },
  },
}
</script>

<style lang="sass">
.base-drawer
  display: inline-block
  margin-right: 0.5rem

.el-drawer__body
  padding: 0 1em

.fade-enter-active, .fade-leave-active
  transition: opacity .2s ease-in-out

.fade-enter, .fade-leave-to
  opacity: 0
</style>
