<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top border-bottom navbar-expand"
    :class="{ 'bg-default navbar-dark': type !== 'light' }"
  >
    <!-- Navbar links -->
    <b-navbar-nav class="align-items-center">
      <!-- This item dont have <b-nav-item> because they add class 'nav-link' which is not needed here -->
      <li class="nav-item d-xl-none">
        <!-- Sidenav toggler -->
        <div
          class="pr-3 sidenav-toggler"
          :class="{
            active: $sidebar.showSidebar,
            'sidenav-toggler-dark': type !== 'light',
          }"
          @click="toggleSidebar"
        >
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line" />
            <i class="sidenav-toggler-line" />
            <i class="sidenav-toggler-line" />
          </div>
        </div>
      </li>
      <!-- This item dont have <b-nav-item> because item have data-action/data-target on tag <a>, wich we cant add -->
      <!-- <li class="nav-item d-sm-none">
        <a
          class="nav-link"
          href="#"
          data-action="search-show"
          data-target="#navbar-search-main"
        >
          <i class="ni ni-zoom-split-in" />
        </a>
      </li> -->
    </b-navbar-nav>
    <b-navbar-nav class="align-items-center ml-auto">
      <base-dropdown
        menu-on-right
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link pr-0"
      >
        <a slot="title-container" href="#" class="nav-link pr-0" @click.prevent>
          <b-media no-body class="align-items-center">
            <span class="avatar avatar-sm rounded-circle">
              <img alt="" src="/img/brand/avatar.jpg" />
            </span>
            <b-media-body class="ml-2 d-none d-lg-block">
              <span
                v-if="account"
                data-cy="user-logout"
                class="mb-0 text-sm font-weight-bold"
                >Bienvenue {{ account.firstName }}!</span
              >
            </b-media-body>
          </b-media>
        </a>

        <!-- <b-dropdown-header class="noti-title">
            <h6 class="text-overflow m-0">
              Welcome!
            </h6>
          </b-dropdown-header>
          <b-dropdown-item href="#!">
            <i class="ni ni-single-02" />
            <span>My profile</span>
          </b-dropdown-item>
          <b-dropdown-item href="#!">
            <i class="ni ni-settings-gear-65" />
            <span>Settings</span>
          </b-dropdown-item>
          <b-dropdown-item href="#!">
            <i class="ni ni-calendar-grid-58" />
            <span>Activity</span>
          </b-dropdown-item>
          <b-dropdown-item href="#!">
            <i class="ni ni-support-16" />
            <span>Support</span>
          </b-dropdown-item>
          <div class="dropdown-divider" /> -->
        <b-dropdown-item @click="logout">
          <i class="ni ni-user-run disconnect" />
          <span>Se déconnecter</span>
        </b-dropdown-item>
      </base-dropdown>
    </b-navbar-nav>
  </base-nav>
</template>
<script>
import { mapState } from 'vuex'
import { BaseNav, BaseDropdown } from '@coop-zone/coop-zone-argon'

export default {
  components: {
    BaseNav,
    BaseDropdown,
  },
  props: {
    type: {
      type: String,
      default: 'default',
    },
  },
  computed: {
    ...mapState('accounts', ['account']),
    routeName() {
      const { name } = this.$route
      return this.capitalizeFirstLetter(name)
    },
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false)
    },
    async logout() {
      await this.$store.dispatch('accounts/logout')
      this.$router.push('/login')
    },
  },
}
</script>
