<i18n>
{
  "fr" : {
    "DeleteSuccess": "Le point de cueillette a bien été supprimé"
  }
}
</i18n>

<template>
  <div>
    <BaseHeader class="pb-6" type="default">
      <b-row align-v="center" class="py-4">
        <b-col>
          <p class="h2 text-white d-inline-block mb-0">Point de cueillette</p>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </BaseHeader>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="12">
          <PickingPointForm
            v-loading="loading"
            :on-success="save"
            title="Modifier un point de cueillette"
            redirect="/establishments/pickingpoints/index"
          >
            <BaseButton
              slot="actions"
              v-confirm-delete="{
                onConfirm: () => deletePickingPoint(),
                confirmText:
                  'Le point de cueillete sera supprimé définitivement',
                successText: $t('DeleteSuccess'),
              }"
              type="danger"
              icon
              size="sm"
            >
              <span class="btn-inner--icon"
                ><i class="fas fa-minus-circle"
              /></span>
              <span class="btn-inner--text">Supprimer le point</span>
            </BaseButton>
          </PickingPointForm>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {
  RouteBreadcrumb,
  BaseHeader,
  BaseButton,
} from '@coop-zone/coop-zone-argon'
import PickingPointForm from '@/components/PickingPoints/PickingPointForm'

export default {
  components: {
    PickingPointForm,
    RouteBreadcrumb,
    BaseButton,
    BaseHeader,
  },
  async created() {
    await this.$store.dispatch(
      'pickingpoints/getPickingPoint',
      this.$route.params.id
    )
  },
  methods: {
    async deletePickingPoint() {
      await this.$store.dispatch(
        'pickingpoints/deletePickingPoint',
        this.$route.params.id
      )

      this.$notify({
        message: this.$t('DeleteSuccess'),
        timeout: 5000,
        icon: 'ni ni-check-bold',
        type: 'success',
      })
      this.$router.push('/establishments/pickingpoints/index')
    },
    async save() {
      await this.$store.dispatch('pickingpoints/updatePickingPoint')
    },
  },
}
</script>
