export default {
  item: {
    statuses: {
      RESERVED: 'Réservé',
      IN_TRANSFER: 'En transfert',
      BO: 'En rupture de stock',
      REFUNDED: 'Remboursé',
      READY_FOR_PICKUP: 'Prêt pour la cueillette',
      READY_TO_SHIP: 'Prêt pour la livraison',
      CHANGED: 'Remplacé',
      SHIPPED: 'Expédié',
      PICKED_UP: 'Récupéré',
      CANCELED: 'Annulé',
      COMPLETED: 'Terminé',
    },
  },
  refund: {
    statuses: {
      NEW: 'Nouveau',
      RETRY: 'En traitement',
      ERROR: 'En erreur',
      COMPLETED: 'Complété',
    },
  },
}
