import Vue from 'vue'
import VueApollo from 'vue-apollo'

import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { createUploadLink } from 'apollo-upload-client'
// import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory'

import store from '../store'

// Cache implementation
const cache = new InMemoryCache({
  addTypename: false,
})

const tokenLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = `Bearer ${store.state.accounts.token}`
  // Return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token || '',
    },
  }
})

// Create the apollo client
export const apolloClient = new ApolloClient({
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
  },
  link: ApolloLink.from([
    tokenLink,
    createUploadLink({
      uri: '/graphql',
      credentials: 'include',
    }),
  ]),
  cache,
})

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})

Vue.use(VueApollo)

export default apolloProvider
