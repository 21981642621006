<template>
  <BaseFlow
    v-if="canAskForModification(account, schoolList)"
    icon="fas fa-undo-alt"
    :skip-confirm="true"
    @click="sendMail"
  >
    Répondre à Coop Zone
  </BaseFlow>
</template>
<script>
import swal from 'sweetalert2'
import flowMixin from './flow.mixin'

import { canAskForModification } from '@/abilities/schoolLists'

export default {
  mixins: [flowMixin],
  methods: {
    sendMail() {
      window.location = 'mailto:scolaire@coopzone.ca'
    },
    canAskForModification,
  },
}
</script>
