<template>
  <b-container fluid class="mt--6 pb-5">
    <BaseForm>
      <b-row>
        <b-col :xl="12">
          <div v-loading="loading" class="card-wrapper">
            <b-card>
              <div slot="header">
                <h2 class="mb-0">Identification</h2>
              </div>

              <b-row>
                <b-col>
                  <BaseInput
                    v-model="codePromoModel.code"
                    rules="required"
                    label="Code"
                    placeholder="Saisissez le code de la promotion"
                    @keydown="preventSpaces($event)"
                  />
                </b-col>
                <b-col>
                  <BaseInput
                    v-model="codePromoModel.name"
                    label="Nom du code promo"
                    placeholder="Saisissez le nom du code promo"
                    name="Nom du code promo"
                    rules="required"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="5">
                  <BaseInput label="Type de code promo">
                    <el-select
                      v-model="codePromoModel.type"
                      @change="validateTypeSwitch($event)"
                    >
                      <el-option
                        v-for="item in enumType"
                        :key="item.value"
                        :value="item.value"
                        :label="item.label"
                      />
                    </el-select>
                  </BaseInput>
                </b-col>
                <b-col cols="2">
                  <BaseInput
                    v-if="codePromoModel.type == 'PERCENTAGE'"
                    v-model="codePromoModel.value"
                    label="Pourcentage"
                    append-icon="fas fa-percent"
                    rules="required"
                    @keydown="validatePercentageInput($event)"
                  />
                  <CurrencyInput
                    v-if="codePromoModel.type == 'AMOUNT'"
                    v-model="codePromoModel.value"
                    label="Montant"
                    rules="required"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <BaseInput label="Dates applicables">
                    <el-date-picker
                      v-model="codePromoModel.dateRange"
                      type="daterange"
                      range-separator="au"
                      start-placeholder="Début"
                      end-placeholder="Fin"
                    />
                  </BaseInput>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="2">
                  <BaseInput
                    v-model="codePromoModel.quantity"
                    label="Quantité maximale"
                    @keydown="validateNumberInput($event)"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <BaseCheckbox
                    v-model="codePromoModel.membersOnly"
                    class="mb-3"
                  >
                    Promotion que pour les membres
                  </BaseCheckbox>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <BaseCheckbox
                    v-model="codePromoModel.oneTimeUsage"
                    class="mb-3"
                  >
                    Code à usage unique par utilisateur
                  </BaseCheckbox>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <BaseInput label="Appliquer le code sur">
                    <el-radio-group v-model="codePromoModel.appliedTo">
                      <el-radio-button label="ORDER">
                        La commande
                      </el-radio-button>
                      <el-radio-button label="PRODUCTS">
                        Les produits
                      </el-radio-button>
                      <el-radio-button label="SHIPPING">
                        La livraison
                      </el-radio-button>
                    </el-radio-group>
                  </BaseInput>
                </b-col>
              </b-row>
            </b-card>

            <b-card v-if="codePromoModel.appliedTo === 'PRODUCTS'">
              <div slot="header">
                <h2 class="mb-0">Inclusions</h2>
              </div>
              <b-row>
                <b-col cols="8">
                  <div v-loading="waitForUtils">
                    <TreeselectInput
                      v-model="codePromoModel.includedCategories"
                      label="Catégories incluses dans la promo"
                      :multiple="true"
                      placeholder="Sélectionnez la catégorie"
                      :options="categoriesTree"
                      :schema="{
                        id: 'id',
                        label: 'name',
                        children: 'children',
                      }"
                    />
                  </div>
                </b-col>
              </b-row>
              <b-row class="mb-4">
                <b-col>
                  <label class="form-control-label">
                    Produits inclus dans la promo
                  </label>
                  <ProductAutocomplete
                    placeholder="Ajouter un SKU"
                    :omit-types="'EcoFee'"
                    @change="addProduct"
                  />

                  <input
                    v-model="codePromoModel.includedProducts"
                    type="hidden"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <el-table
                    :data="codePromoModel.includedProducts"
                    header-row-class-name="thead-light"
                    :default-sort="{ prop: 'name', order: 'ascending' }"
                  >
                    <el-table-column
                      label="Nom du produit"
                      prop="name"
                      min-width="160px"
                    >
                      <template slot-scope="scope">
                        <b-media no-body class="align-items-center">
                          <img
                            v-if="scope.row.images && scope.row.images.length"
                            width="48px"
                            height="48px"
                            class="mr-1"
                            :src="scope.row.images[0].thumbnail"
                          />
                          <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{
                              scope.row.name
                            }}</span>
                          </b-media-body>
                        </b-media>
                      </template>
                    </el-table-column>

                    <el-table-column prop="vigieId" label="Code produit" />
                    <el-table-column align="right">
                      <template slot-scope="scope">
                        <a
                          href="#"
                          target="_self"
                          class="table-action table-action-delete"
                          @click.prevent="removeProduct(scope.row)"
                          ><i class="fa fa-trash"
                        /></a>
                      </template>
                    </el-table-column>
                  </el-table>
                </b-col>
              </b-row>
            </b-card>
            <b-card v-if="codePromoModel.appliedTo === 'PRODUCTS'">
              <div slot="header">
                <h2 class="mb-0">Exclusions</h2>
              </div>
              <b-row>
                <b-col cols="8">
                  <div v-loading="waitForUtils">
                    <TreeselectInput
                      v-model="codePromoModel.excludedCategories"
                      label="Catégories exclues dans la promo"
                      :multiple="true"
                      placeholder="Sélectionnez la catégorie"
                      :options="categoriesTree"
                      :schema="{
                        id: 'id',
                        label: 'name',
                        children: 'children',
                      }"
                    />
                  </div>
                </b-col>
              </b-row>
              <b-row class="mb-4">
                <b-col>
                  <label class="form-control-label">
                    Produits exclus dans la promo
                  </label>
                  <ProductAutocomplete
                    placeholder="Ajouter un SKU"
                    :omit-types="'EcoFee'"
                    @change="addExcludedProduct"
                  />
                  <input
                    v-model="codePromoModel.excludedProducts"
                    type="hidden"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <el-table
                    :data="codePromoModel.excludedProducts"
                    header-row-class-name="thead-light"
                    :default-sort="{ prop: 'name', order: 'ascending' }"
                  >
                    <el-table-column
                      label="Nom du produit"
                      prop="name"
                      min-width="160px"
                    >
                      <template slot-scope="scope">
                        <b-media no-body class="align-items-center">
                          <img
                            v-if="scope.row.images && scope.row.images.length"
                            width="48px"
                            height="48px"
                            class="mr-1"
                            :src="scope.row.images[0].thumbnail"
                          />
                          <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{
                              scope.row.name
                            }}</span>
                          </b-media-body>
                        </b-media>
                      </template>
                    </el-table-column>

                    <el-table-column prop="vigieId" label="Code produit" />
                    <el-table-column align="right">
                      <template slot-scope="scope">
                        <a
                          href="#"
                          target="_self"
                          class="table-action table-action-delete"
                          @click.prevent="removeExcludedProduct(scope.row)"
                          ><i class="fa fa-trash"
                        /></a>
                      </template>
                    </el-table-column>
                  </el-table>
                </b-col>
              </b-row>
            </b-card>
            <b-row align-h="end">
              <b-col cols="auto">
                <BaseButton
                  size="lg"
                  type="secondary"
                  native-type="submit"
                  @click="save('save-edit')"
                >
                  Sauvegarder et modifier
                </BaseButton>
              </b-col>

              <b-col cols="auto">
                <BaseButton
                  size="lg"
                  type="primary"
                  native-type="submit"
                  @click="save('save')"
                >
                  Sauvegarder
                </BaseButton>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </BaseForm>
  </b-container>
</template>

<script>
import dayjs from 'dayjs'
import { mapState, mapActions } from 'vuex'
import { BaseButton, BaseInput, BaseCheckbox } from '@coop-zone/coop-zone-argon'
import BaseForm from '@/components/forms/BaseForm'
import ProductAutocomplete from '@/components/ProductAutocomplete'
import CurrencyInput from '@/components/Inputs/CurrencyInput'
import TreeselectInput from '@/components/Inputs/TreeselectInput'
import getEnv from '@/utils/env'

function mapIDs(array) {
  return array.map((p) => parseInt(`${p.id}`, 10))
}
function handleEmptyArray(array) {
  return array.length === 0 ? null : array
}

export default {
  components: {
    BaseButton,
    BaseForm,
    BaseInput,
    ProductAutocomplete,
    TreeselectInput,
    CurrencyInput,
    BaseCheckbox,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    onSuccess: {
      type: Function,
      required: false,
      default: null,
    },
    data: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      websiteUrl: getEnv('VUE_APP_WEBSITE_URL'),
      waitForUtils: false,
      enumType: [
        { label: 'En pourcentage', value: 'PERCENTAGE' },
        { label: 'En dollars', value: 'AMOUNT' },
      ],
      codePromoModel: {
        name: '',
        type: 'PERCENTAGE',
        appliedTo: 'ORDER',
        membersOnly: false,
        oneTimeUsage: false,
        value: null,
        code: null,
        dateRange: [],
        startDate: null,
        endDate: null,
        quantity: null,
        includedCategories: [],
        excludedCategories: [],
        includedProducts: [],
        excludedProducts: [],
      },
    }
  },
  computed: {
    ...mapState('categories', ['categoriesTree']),
  },
  async created() {
    if (this.data) {
      this.codePromoModel = {
        ...this.data,
        appliedTo: this.data.appliedTo,
        dateRange:
          this.data.startDate && this.data.endDate
            ? [new Date(this.data.startDate), new Date(this.data.endDate)]
            : null,
        value: this.data.value,
        includedCategories: this.data.includedCategories
          ? this.data.includedCategories
          : [],
        excludedCategories: this.data.excludedCategories
          ? this.data.excludedCategories
          : [],
        includedProducts: this.data.includedProducts
          ? this.data.includedProducts
          : [],
        excludedProducts: this.data.excludedProducts
          ? this.data.excludedProducts
          : [],
      }
    }
    this.waitForUtils = true
    await this.$store.dispatch('categories/getCategories')
    this.waitForUtils = false
  },
  methods: {
    ...mapActions('promoCodes', ['createPromoCode']),
    validateTypeSwitch($event) {
      if (
        $event === 'PERCENTAGE' &&
        parseInt(this.codePromoModel.value) >= 100
      ) {
        this.codePromoModel.value = 0
      }
    },
    validatePercentageInput($event) {
      const dgt = /^\d+$/
      const keys = [
        'Backspace',
        'Tab',
        'Delete',
        'ArrowLeft',
        'ArrowRight',
        'ArrowUp',
        'ArrowDown',
      ]
      const dotPos = $event.target.value.indexOf('.')
      const curPos = $event.target.selectionStart

      // Makes sure input is either a digit, a '.' (unless one already present) or we use backspace, tab or delete keys
      if (
        !(
          $event.key.match(dgt) ||
          keys.find(
            (el) => el === $event.key || ($event.key === '.' && dotPos === -1)
          )
        ) ||
        (parseInt($event.target.value + $event.key, 10) > 100 &&
          curPos === $event.target.selectionEnd) ||
        (((dotPos === curPos && $event.key === 'Delete') ||
          (dotPos === curPos - 1 && $event.key === 'Backspace')) &&
          parseInt($event.target.value.replace('.', ''), 10) > 100)
      ) {
        $event.preventDefault()
      }
    },
    validateNumberInput($event) {
      const dgt = /^\d+$/
      const keys = [
        'Backspace',
        'Tab',
        'Delete',
        'ArrowLeft',
        'ArrowRight',
        'ArrowUp',
        'ArrowDown',
      ]
      // Makes sure input is either a digit, a '.' (unless one already present) or we use backspace, tab or delete keys
      if (!($event.key.match(dgt) || keys.find((el) => el === $event.key)))
        $event.preventDefault()
    },
    preventSpaces($event) {
      if ($event.key === ' ') $event.preventDefault()
    },
    removeProduct(product) {
      const indexOfProduct =
        this.codePromoModel.includedProducts.indexOf(product)
      this.codePromoModel.includedProducts.splice(indexOfProduct, 1)
    },
    removeExcludedProduct(product) {
      const indexOfProduct =
        this.codePromoModel.excludedProducts.indexOf(product)
      this.codePromoModel.excludedProducts.splice(indexOfProduct, 1)
    },
    addProduct(data) {
      this.codePromoModel.includedProducts.push(data)
    },
    addExcludedProduct(data) {
      this.codePromoModel.excludedProducts.push(data)
    },
    save(action) {
      const saveObject = {
        ...this.codePromoModel,
        startDate:
          this.codePromoModel.dateRange && this.codePromoModel.dateRange.length
            ? this.codePromoModel.dateRange[0]
            : null,
        endDate:
          this.codePromoModel.dateRange && this.codePromoModel.dateRange.length
            ? dayjs(this.codePromoModel.dateRange[1]).endOf('day')
            : null,
        appliedTo: this.codePromoModel.appliedTo,
        value: parseFloat(this.codePromoModel.value),
        quantity:
          this.codePromoModel.quantity === null
            ? this.codePromoModel.quantity
            : parseInt(this.codePromoModel.quantity, 10),
      }

      delete saveObject.dateRange

      if (
        saveObject.appliedTo === 'ORDER' ||
        saveObject.appliedTo === 'SHIPPING'
      ) {
        saveObject.includedCategories = null
        saveObject.excludedCategories = null
        saveObject.includedProducts = null
        saveObject.excludedProducts = null
      } else {
        // we only wants array of IDs for products
        saveObject.includedProducts = mapIDs(saveObject.includedProducts)
        saveObject.excludedProducts = mapIDs(saveObject.excludedProducts)

        // if arrays are empty, set to null
        saveObject.includedCategories = handleEmptyArray(
          saveObject.includedCategories
        )
        saveObject.excludedCategories = handleEmptyArray(
          saveObject.excludedCategories
        )
        saveObject.includedProducts = handleEmptyArray(
          saveObject.includedProducts
        )
        saveObject.excludedProducts = handleEmptyArray(
          saveObject.excludedProducts
        )
      }
      this.$emit('action', action)
      this.$emit('save', saveObject)
    },
  },
}
</script>

<style lang="sass">

.card--no-shadow
  box-shadow: none
  border: 1px solid #e9ecef

.el-date-editor .el-range-separator
  width: 9%
</style>
