<template>
  <BaseForm
    id="categoryForm"
    success-message="Catégorie enregistrée"
    :on-success="onSuccess"
  >
    <b-card>
      <div slot="header">
        <b-row align-v="center">
          <b-col sm="12" lg="6">
            <p class="h6 text-uppercase text-muted ls-1 mb-1">
              {{ title }}
            </p>
            <h2 class="mb-0">
              {{ name }}
            </h2>
          </b-col>

          <b-col sm="12" lg="6" class="text-right">
            <slot name="actions" />
          </b-col>
        </b-row>
      </div>

      <b-row>
        <b-col col-sm="6">
          <BaseInput
            v-model="name"
            label="Nom de la catégorie"
            data-cy="input-category-name"
            rules="required"
            :readonly="ancestors[0] && ancestors[0].slug === '/'"
          />
        </b-col>

        <b-col
          v-if="!(ancestors.length === 1 && ancestors[0].slug === '/')"
          v-loading="waitForUtils"
          col-sm="6"
        >
          <BaseInput label="Catégorie parente" rules="required">
            <Treeselect
              v-model="parentId"
              :normalizer="normalizer"
              placeholder="Sélectionnez une catégorie"
              data-cy="select-category"
              :options="treeselectCategoriesById"
            />
          </BaseInput>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col>
        <VigieRulesInput
          v-slot="{ remove }"
          v-model="vigieRules"
          title="Règles vigie"
          @on-add="vigieRules.push({})"
        >
          <el-table-column label="cat1" min-width="50px">
            <template #default="{ row }">
              <BaseInput v-model="row.level1" v-uppercase maxlength="3" />
            </template>
          </el-table-column>

          <el-table-column label="cat2" min-width="50px">
            <template #default="{ row }">
              <BaseInput v-model="row.level2" v-uppercase maxlength="3" />
            </template>
          </el-table-column>

          <el-table-column label="cat3" min-width="50px">
            <template #default="{ row }">
              <BaseInput v-model="row.level3" v-uppercase maxlength="3" />
            </template>
          </el-table-column>

          <el-table-column label="cat4" min-width="50px">
            <template #default="{ row }">
              <BaseInput v-model="row.level4" v-uppercase maxlength="3" />
            </template>
          </el-table-column>

          <el-table-column label="cat5" min-width="50px">
            <template #default="{ row }">
              <BaseInput v-model="row.level5" v-uppercase maxlength="3" />
            </template>
          </el-table-column>

          <el-table-column label="cat6" min-width="50px">
            <template #default="{ row }">
              <BaseInput v-model="row.level6" v-uppercase maxlength="3" />
            </template>
          </el-table-column>

          <el-table-column label="cat7" min-width="50px">
            <template #default="{ row }">
              <BaseInput v-model="row.level7" v-uppercase maxlength="3" />
            </template>
          </el-table-column>

          <el-table-column label="cat8" min-width="50px">
            <template #default="{ row }">
              <BaseInput v-model="row.level8" v-uppercase maxlength="3" />
            </template>
          </el-table-column>

          <el-table-column label="cat9" min-width="50px">
            <template #default="{ row }">
              <BaseInput v-model="row.level9" v-uppercase maxlength="3" />
            </template>
          </el-table-column>

          <el-table-column label="cat10" min-width="50px">
            <template #default="{ row }">
              <BaseInput v-model="row.level10" v-uppercase maxlength="3" />
            </template>
          </el-table-column>

          <el-table-column min-width="40px" align="right">
            <div slot-scope="{ row }" class="table-actions">
              <el-tooltip content="Supprimer" placement="top">
                <a
                  href="#!"
                  class="table-action table-action-delete"
                  data-toggle="tooltip"
                  data-original-title="Supprimer"
                  @click.prevent="remove(row)"
                >
                  <i class="fas fa-trash" />
                </a>
              </el-tooltip>
            </div>
          </el-table-column>
        </VigieRulesInput>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <TableInput
          v-slot="{ remove }"
          v-model="filters"
          title="Filtres"
          :draggable="true"
          @on-add="filters.push({})"
        >
          <el-table-column width="100px">
            <span class="table-action">
              <i class="handle fas fa-arrows-alt" />
            </span>
          </el-table-column>
          <el-table-column label="Filtre">
            <template #default="{ row }">
              <FilterSelect v-model="row.filterId" />
            </template>
          </el-table-column>

          <el-table-column label="Nom du filtre">
            <template #default="{ row }">
              <BaseInput v-model="row.label" />
            </template>
          </el-table-column>

          <el-table-column min-width="25px" align="right">
            <div slot-scope="{ row }" class="table-actions">
              <el-tooltip content="Supprimer" placement="top">
                <a
                  href="#!"
                  class="table-action table-action-delete"
                  data-toggle="tooltip"
                  data-original-title="Supprimer"
                  @click.prevent="remove(row)"
                >
                  <i class="fas fa-trash" />
                </a>
              </el-tooltip>
            </div>
          </el-table-column>
        </TableInput>
      </b-col>
    </b-row>

    <b-row align="end">
      <b-col>
        <BaseButton size="lg" type="primary" native-type="submit">
          Sauvegarder
        </BaseButton>
      </b-col>
    </b-row>
  </BaseForm>
</template>

<script>
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Treeselect from '@riophae/vue-treeselect'

import { BaseButton, BaseInput } from '@coop-zone/coop-zone-argon'
import { mapState } from 'vuex'
import BaseForm from '@/components/forms/BaseForm'
import TableInput from '@/components/TableInput'
import FilterSelect from '@/components/Inputs/FilterSelect'
import VigieRulesInput from '@/components/VigieRulesInput'

export default {
  components: {
    BaseForm,
    BaseButton,
    BaseInput,
    Treeselect,
    TableInput,
    FilterSelect,
    VigieRulesInput,
  },
  props: {
    onSuccess: {
      type: Function,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      waitForUtils: false,
    }
  },
  computed: {
    ...mapState('utils', ['treeselectCategoriesById']),
    name: {
      get() {
        return this.$store.state.categories.name
      },
      set(name) {
        this.$store.commit('categories/SET_NAME', name)
      },
    },
    ancestors: {
      get() {
        return this.$store.state.categories.ancestors
      },
      set(ancestors) {
        this.$store.commit('categories/SET_ANCESTORS', ancestors)
      },
    },
    parentId: {
      get() {
        return this.$store.state.categories.parentId
      },
      set(parentId) {
        this.$store.commit('categories/SET_PARENT_ID', parentId)
      },
    },
    vigieRules: {
      get() {
        return this.$store.state.categories.vigieRules
      },
      set(vigieRules) {
        this.$store.commit('categories/SET_VIGIE_RULES', vigieRules)
      },
    },
    filters: {
      get() {
        return this.$store.state.categories.filters
      },
      set(filters) {
        this.$store.commit('categories/SET_FILTERS', filters)
      },
    },
  },
  async created() {
    this.waitForUtils = true
    await this.$store.dispatch('utils/getTreeselectCategoriesById')
    this.waitForUtils = false
    await this.$store.dispatch('filters/getFilters')
  },
  methods: {
    normalizer(node) {
      // Remove the properties of Children = NULL
      if (node.children == null || node.children === 'null') {
        delete node.children
      }
    },
  },
}
</script>
