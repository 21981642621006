import Vue from 'vue'

Vue.filter('currencyFormat', (float) => {
  if (float) {
    const currencyString = new Intl.NumberFormat('fr-CA', {
      style: 'currency',
      currency: 'CAD',
      currencyDisplay: 'symbol',
    }).format(float / 100)
    return `${currencyString}`
  }
  return ''
})
