<template>
  <BaseFlow
    v-if="canPublish(account, schoolList)"
    type="success"
    icon="ni ni-check-bold"
    @click="publish"
  >
    Publier la liste
  </BaseFlow>
</template>
<script>
import errorHandlerMixin from '@/mixins/errorHandler.mixin'
import flowMixin from './flow.mixin'

import { canPublish } from '@/abilities/schoolLists'

export default {
  mixins: [flowMixin, errorHandlerMixin],
  methods: {
    async publish() {
      try {
        await this.$store.dispatch('schoolLists/updateSchoolListToPublished', {
          currentEstablishmentId: this.$route.params.establishmentId,
          listId: this.schoolList.id,
        })
        this.$notify({
          message: 'La liste a été publiée',
          timeout: 5000,
          icon: 'ni ni-check-bold',
          type: 'success',
        })
        this.$router.push(
          `/school-lists/type/${this.$route.params.type}/index/${this.schoolList.EstablishmentId}`
        )
      } catch (error) {
        this.handleError(error)
      }
    },
    canPublish,
  },
}
</script>
