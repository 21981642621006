import { apolloClient } from '@/apollo'

const getPickingPointQuery = require('../apollo/queries/GetEstablishment.gql')
const getPickingPointsQuery = require('../apollo/queries/GetEstablishments.gql')
const createPickingPointMutation = require('../apollo/mutations/CreateEstablishment.gql')
const updatePickingPointMutation = require('../apollo/mutations/UpdateEstablishment.gql')
const deletePickingPointMutation = require('../apollo/mutations/DeleteEstablishment.gql')

const state = {
  pickingPoints: [],
  id: null,
  name: null,
  active: null,
  needLocalNumber: null,
  address: {},
}

const actions = {
  async getPickingPoint({ commit }, id) {
    const {
      data: { establishment },
    } = await apolloClient.query({
      query: getPickingPointQuery,
      variables: { id },
    })

    if (!establishment.address) {
      establishment.address = {
        firstName: '',
        lastName: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
      }
    }

    commit('SET_PICKING_POINT', establishment)
  },

  async getPickingPoints({ commit }) {
    const {
      data: { establishments },
    } = await apolloClient.query({
      query: getPickingPointsQuery,
    })
    commit('SET_PICKING_POINTS', establishments)
  },

  async createPickingPoint({
    state: { name, active, needLocalNumber, address },
  }) {
    await apolloClient.query({
      query: createPickingPointMutation,
      variables: {
        establishment: {
          active: !!active,
          address: { firstName: 'coopzone', lastName: 'coopzone', ...address },
          name,
          needLocalNumber: !!needLocalNumber,
        },
      },
    })
  },

  async updatePickingPoint({
    state: { id, name, active, needLocalNumber, address },
  }) {
    await apolloClient.query({
      query: updatePickingPointMutation,
      variables: {
        id,
        establishment: {
          active: !!active,
          address: { firstName: 'coopzone', lastName: 'coopzone', ...address },
          name,
          needLocalNumber: !!needLocalNumber,
        },
      },
    })
  },

  async deletePickingPoint({ commit }, id) {
    await apolloClient.query({
      query: deletePickingPointMutation,
      variables: { id },
    })
    commit('SET_PICKING_POINT', {})
  },
}

const mutations = {
  SET_NAME(state, payload) {
    state.name = payload
  },

  SET_ACTIVE(state, payload) {
    state.active = payload
  },

  SET_NEED_LOCAL_NUMBER(state, payload) {
    state.needLocalNumber = payload
  },

  SET_ADDRESS(state, payload) {
    state.address = payload
  },

  SET_PICKING_POINTS(state, payload) {
    state.pickingPoints = payload
  },

  SET_PICKING_POINT(
    state,
    {
      id = null,
      name = null,
      active = false,
      needLocalNumber = false,
      address = {},
    }
  ) {
    state.id = id
    state.name = name
    state.active = active
    state.needLocalNumber = needLocalNumber
    state.address = address
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
