<i18n>
{
  "en": {
    "feeType": {
      "Category": "Category",
      "Manual": "Activé pour ce produit"
    }
  },
  "fr": {
    "feeType": {
      "Category": "Catégorie",
      "Manual": "Activé pour ce produit"
    }
  }
}
</i18n>
<template>
  <b-row>
    <b-col>
      <h5 class="text-white" />

      <b-list-group>
        <b-list-group-item v-for="fee in handlingFees" :key="fee.name">
          <b-row align-v="center">
            <b-col>
              <h4 class="mb-0">
                {{ fee.name }}
              </h4>
              <small class="text-muted">{{ $t(`feeType.${fee.type}`) }}</small>
            </b-col>
            <b-col cols="auto">
              <span class="badge badge-success badge-pill">{{
                fee.price | currencyFormat
              }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-col>
  </b-row>
</template>
<script>
export default {
  props: {
    handlingFees: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      required: true,
    },
  },
}
</script>
