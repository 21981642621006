import Vue from 'vue'
import { apolloClient } from '@/apollo'

const getRulesQuery = require('../../apollo/queries/GetDeliveryRules.gql')
const getRuleQuery = require('../../apollo/queries/GetDeliveryRule.gql')
const updateRuleMutation = require('../../apollo/mutations/UpdateDeliveryRule.gql')
const createRuleMutation = require('../../apollo/mutations/CreateDeliveryRule.gql')
const deleteRuleMutation = require('../../apollo/mutations/DeleteDeliveryRule.gql')

const state = {
  rulesMap: {},
  id: null,
  max: null,
  basePrice: null,
  additionalPrice: null,
  freeAfter: null,
  ZoneId: null,
  TaxRuleGroupId: null,
}

const actions = {
  async getAllRules({ commit }) {
    const {
      data: { deliveryRules },
    } = await apolloClient.query({
      query: getRulesQuery,
    })

    if (deliveryRules) {
      commit('SET_ALL_RULES', deliveryRules)
    }
  },

  async getRule({ commit }, id) {
    const {
      data: { deliveryRule },
    } = await apolloClient.query({
      query: getRuleQuery,
      variables: { id },
    })

    commit('SET_RULE', deliveryRule)
  },

  async updateRule({
    state: {
      id,
      max,
      basePrice,
      additionalPrice,
      freeAfter,
      ZoneId,
      TaxRuleGroupId,
    },
    commit,
  }) {
    const {
      data: { updateDeliveryRule },
    } = await apolloClient.query({
      query: updateRuleMutation,
      variables: {
        id,
        deliveryRule: {
          max: parseInt(max, 10),
          basePrice: parseInt(basePrice, 10),
          additionalPrice: parseInt(additionalPrice, 10),
          freeAfter: parseInt(freeAfter, 10),
          ZoneId,
          TaxRuleGroupId,
        },
      },
    })

    commit('SET_RULE', updateDeliveryRule)
  },

  async createRule({
    state: {
      max,
      basePrice,
      additionalPrice,
      freeAfter,
      ZoneId,
      TaxRuleGroupId,
    },
    commit,
  }) {
    const {
      data: { createDeliveryRule },
    } = await apolloClient.query({
      query: createRuleMutation,
      variables: {
        deliveryRule: {
          max: parseInt(max, 10),
          basePrice: parseInt(basePrice, 10),
          additionalPrice: parseInt(additionalPrice, 10),
          freeAfter: parseInt(freeAfter, 10),
          ZoneId,
          TaxRuleGroupId,
        },
      },
    })

    commit('SET_RULE', createDeliveryRule)
  },

  async deleteRule({ commit }, id) {
    await apolloClient.query({
      query: deleteRuleMutation,
      variables: { id },
    })
    commit('DELETE_RULE', id)
  },
}

const getters = {
  getRules: (state) => Object.values(state.rulesMap),
}

const mutations = {
  SET_ALL_RULES(state, payload) {
    state.rulesMap = payload.reduce(
      (acc, rule) => ({ ...acc, [rule.id]: rule }),
      {}
    )
  },

  SET_MAX(state, payload) {
    state.max = payload
  },

  SET_BASE_PRICE(state, payload) {
    state.basePrice = payload
  },

  SET_ADDITIONAL_PRICE(state, payload) {
    state.additionalPrice = payload
  },

  SET_FREE_AFTER(state, payload) {
    state.freeAfter = payload
  },

  SET_ZONE_ID(state, payload) {
    state.ZoneId = payload
  },

  SET_TAX_RULE_GROUP_ID(state, payload) {
    state.TaxRuleGroupId = payload
  },

  SET_RULE(
    state,
    {
      id = null,
      max = null,
      basePrice = null,
      additionalPrice = null,
      freeAfter = null,
      Zone = null,
      TaxGroup = null,
    }
  ) {
    state.id = id
    state.max = max
    state.basePrice = basePrice
    state.additionalPrice = additionalPrice
    state.freeAfter = freeAfter
    state.TaxRuleGroupId = TaxGroup ? TaxGroup.id : null
    state.ZoneId = Zone ? Zone.id : null

    Vue.set(state.rulesMap, id, {
      id,
      max,
      basePrice,
      additionalPrice,
      freeAfter,
      TaxRuleGroupId: TaxGroup ? TaxGroup.id : null,
      ZoneId: Zone ? Zone.id : null,
    })
  },

  DELETE_RULE(state, id) {
    Vue.delete(state.rulesMap, id)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
