<i18n>
{
  "fr" : {
    "DeleteSuccess": "L'établissement a bien été supprimé"
  }
}
</i18n>

<template>
  <div>
    <BaseHeader class="pb-6" type="default">
      <b-row align-v="center" class="py-4">
        <b-col>
          <p class="h2 text-white d-inline-block mb-0">
            Établissements scolaires
          </p>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </BaseHeader>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="12">
          <SchoolEstablishmentForm
            :on-success="save"
            title="Modifier un établissement"
            redirect="/establishments/schools/index"
          >
            <BaseButton
              slot="actions"
              v-confirm-delete="{
                onConfirm: () => deleteSchoolEstablishment(),
                confirmText: `L'établissement sera supprimé définitivement`,
                successText: $t('DeleteSuccess'),
              }"
              type="danger"
              icon
              size="sm"
            >
              <span class="btn-inner--icon"
                ><i class="fas fa-minus-circle"
              /></span>
              <span class="btn-inner--text">Supprimer l'établissement</span>
            </BaseButton>
          </SchoolEstablishmentForm>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  RouteBreadcrumb,
  BaseHeader,
  BaseButton,
} from '@coop-zone/coop-zone-argon'
import SchoolEstablishmentForm from '@/components/SchoolEstablishments/SchoolEstablishmentForm'
import ErrorHandlerMixin from '@/mixins/errorHandler.mixin'

export default {
  components: {
    SchoolEstablishmentForm,
    RouteBreadcrumb,
    BaseButton,
    BaseHeader,
  },
  mixins: [ErrorHandlerMixin],
  created() {
    this.$store.dispatch(
      'schoolEstablishments/getSchoolEstablishment',
      this.$route.params.id
    )
  },
  methods: {
    async deleteSchoolEstablishment() {
      try {
        await this.$store.dispatch(
          'schoolEstablishments/deleteSchoolEstablishment',
          this.$route.params.id
        )
        this.$notify({
          message: this.$t('DeleteSuccess'),
          timeout: 5000,
          icon: 'ni ni-check-bold',
          type: 'success',
        })
        this.$router.push('/establishments/schools/index')
      } catch (error) {
        this.handleError(error)
        throw error
      }
    },
    async save() {
      await this.$store.dispatch(
        'schoolEstablishments/updateSchoolEstablishment'
      )
    },
  },
}
</script>
