<template>
  <b-row>
    <b-col>
      <BaseForm
        ref="clientNumber"
        success-message="Numéro de membre associé"
        :toast-only="true"
        :on-success="save"
      >
        <BaseInput
          v-model="clientNumber"
          :disabled="mode === 'readonly'"
          form-group-classes="mb-2"
          label="Numéro de client"
          name="clientNumber"
          type="number"
        />
      </BaseForm>

      <BaseButton
        v-if="mode === 'readonly'"
        type="primary"
        size="sm"
        @click="mode = 'edit'"
      >
        Modifier
      </BaseButton>

      <BaseButton
        v-if="mode === 'edit'"
        type="default"
        native-type="submit"
        size="sm"
        @click.prevent="$refs['clientNumber'].submit()"
      >
        Enregistrer
      </BaseButton>

      <BaseButton
        v-if="mode === 'edit'"
        type="secondary"
        size="sm"
        @click="cancel"
      >
        Annuler
      </BaseButton>

      <slot :mode="mode" />
    </b-col>
  </b-row>
</template>
<script>
import { BaseButton, BaseInput } from '@coop-zone/coop-zone-argon'
import { mapState } from 'vuex'
import BaseForm from '@/components/forms/BaseForm'

export default {
  components: { BaseForm, BaseButton, BaseInput },
  data() {
    return {
      clientNumber: null,
      mode: 'readonly', // readonly|edit
    }
  },
  computed: {
    ...mapState('users', ['user']),
  },
  watch: {
    user({ clientNumber }) {
      if (clientNumber) {
        this.clientNumber = clientNumber
      } else {
        this.mode = 'edit'
      }
    },
  },
  mounted() {
    this.$store.dispatch('users/getUser', this.$route.params.id)
  },
  methods: {
    async save() {
      await this.$store.dispatch('users/updateAccountClientNumber', {
        userId: this.$route.params.id,
        clientNumber: parseInt(this.clientNumber, 10),
      })
      this.mode = 'readonly'
      this.clientNumber = this.user.clientNumber
    },
    cancel() {
      this.mode = 'readonly'
      this.clientNumber = this.user.clientNumber
    },
  },
}
</script>
