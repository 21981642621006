<i18n>
  {
    "fr": {
      "CreateSuccess": "Le groupe a bien été créé"
    }
  }
</i18n>
<template>
  <BaseForm
    id="taxGroupForm"
    success-message="Groupe enregistré"
    :on-success="save"
  >
    <b-card>
      <div slot="header">
        <b-row align-v="center">
          <b-col sm="12" lg="6">
            <h6 class="text-uppercase text-muted ls-1 mb-1">
              {{ title }}
            </h6>
            <h2 class="mb-0">
              {{ name }}
            </h2>
          </b-col>
          <b-col sm="12" lg="6" class="text-right">
            <slot name="actions" />
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col>
          <BaseInput v-model="name" label="Nom" rules="required" />
        </b-col>
      </b-row>
      <b-row align-h="end">
        <b-col cols="auto">
          <BaseButton size="lg" type="primary" native-type="submit">
            Sauvegarder
          </BaseButton>
        </b-col>
      </b-row>
    </b-card>
    ­­<RulesList v-if="$store.state.taxes.groups.id !== null" />
  </BaseForm>
</template>
<script>
import { BaseButton, BaseInput } from '@coop-zone/coop-zone-argon'
import BaseForm from '@/components/forms/BaseForm'
import RulesList from '@/components/Taxes/RulesList'

export default {
  components: {
    BaseForm,
    BaseButton,
    BaseInput,
    RulesList,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    name: {
      get() {
        return this.$store.state.taxes.groups.name
      },
      set(name) {
        this.$store.commit('taxes/groups/SET_NAME', name)
      },
    },
  },
  created() {
    this.$store.commit('taxes/groups/SET_GROUP', {})
    if (this.$route.params.id) {
      this.$store.dispatch('taxes/groups/getGroup', this.$route.params.id)
    }
  },
  methods: {
    async save() {
      if (this.$route.params.id) {
        this.$store.dispatch('taxes/groups/updateGroup')
      } else {
        await this.$store.dispatch('taxes/groups/createGroup')
        this.$notify({
          message: this.$t('CreateSuccess'),
          timeout: 5000,
          icon: 'ni ni-check-bold',
          type: 'success',
        })
        this.$router.push(
          `/taxes_rules/groups/edit/${this.$store.state.taxes.groups.id}`
        )
      }
    },
  },
}
</script>
