import { BaseErrorsHandler } from '@coop-zone/coop-zone-form-errors'

export default class AccountErrorsHandler extends BaseErrorsHandler {
  emailAlreadyExists(resolve) {
    if (
      this.logicErrors !== null &&
      this.logicErrors.includes('EMAIL_ALREADY_EXIST')
    ) {
      this.addError('email', 'email.exists')
    }
    resolve()
  }

  constructor(err, form) {
    super(err, form)
    this.handler = [new Promise((resolve) => this.emailAlreadyExists(resolve))]
  }
}
