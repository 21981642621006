<template>
  <div>
    <BaseHeader class="pb-6" type="default">
      <b-row align-v="center" class="py-4">
        <b-col lg="12" cols="7">
          <h1 class="h2 text-white d-inline-block mb-0">
            Modifier une province
          </h1>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </BaseHeader>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="12">
          <StateForm title="Modifier une province" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { RouteBreadcrumb, BaseHeader } from '@coop-zone/coop-zone-argon'
import { mapState } from 'vuex'
import StateForm from '@/components/States/StateForm'

export default {
  components: {
    StateForm,
    RouteBreadcrumb,
    BaseHeader,
  },
  data() {
    return {
      data: {},
    }
  },
  computed: {
    ...mapState('provinces', ['province']),
  },
  watch: {
    province(newValue) {
      if (newValue) {
        this.data = newValue
      }
    },
  },
}
</script>
