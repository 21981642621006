<template>
  <div class="content">
    <base-header class="pb-6 bg-default">
      <b-row align-v="center" class="py-4">
        <b-col>
          <p class="h2 text-white d-inline-block mb-0">Catégories</p>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-card v-loading="loading" no-body class="mb-4">
        <b-row slot="header" align-v="center">
          <b-col cols="4">
            <h1 class="h3 mb-0">Liste des catégories</h1>
          </b-col>
          <b-col class="text-right">
            <router-link class="btn btn-sm btn-primary" to="/categories/add">
              Ajouter une catégorie
            </router-link>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <CategoryTree
              v-if="categoriesTree.length > 0"
              :tree="categoriesTree"
              :on-delete="deleteCategory"
              :on-sort="sortCategory"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import { BaseHeader, RouteBreadcrumb } from '@coop-zone/coop-zone-argon'
import { mapState } from 'vuex'
import CategoryTree from '@/components/Categories/CategoryTree'

export default {
  components: {
    BaseHeader,
    CategoryTree,
    RouteBreadcrumb,
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    ...mapState('categories', ['categoriesTree']),
  },
  async created() {
    this.loading = true
    await this.$store.dispatch('categories/getCategories')
    this.loading = false
  },
  methods: {
    async deleteCategory(item) {
      this.loading = true
      await this.$store.dispatch('categories/deleteCategory', item.id)
      await this.$store.dispatch('categories/getCategories')
      this.loading = false
    },

    async sortCategory(items) {
      this.loading = true
      const categoriesPosition = items.map((item, index) => {
        return { id: item.id, position: index }
      })
      await this.$store.dispatch('categories/sortCategories', {
        categoriesPosition,
      })
      await this.$store.dispatch('categories/getCategories')
      this.loading = false
    },
  },
}
</script>
