import Vue from 'vue'
import VueI18n from 'vue-i18n'
import fr from './fr-CA/index.js'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'fr',
  fallbackLocale: 'fr',
  silentFallbackWarn: true,
  messages: {
    fr,
  },
  silentTranslationWarn: process.env.NODE_ENV === 'production',
})

export default i18n
