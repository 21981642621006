<template>
  <b-row class="mb-5">
    <b-col lg="8">
      <b-row>
        <b-col>
          <ProductAutocomplete
            input-label="Rechercher l’article dans le catalogue Coop Zone"
            :show-tags-list="false"
            placeholder="Nom de produit, un SKU, un ISBN, un code Apple, un auteur, une marque"
            @change="addSuggestionToCategory($event)"
          />
        </b-col>
      </b-row>

      <b-row v-if="suggestions.length" class="mt-4">
        <b-col cols="12">
          <b-row class="mb-3">
            <b-col>
              <p class="h4">Article(s) sélectionné(s)</p>
            </b-col>
          </b-row>

          <b-row
            v-for="(suggestion, index) in suggestions"
            :key="suggestion.id"
            align-v="center"
            :class="{ 'mt-4': index > 0 }"
          >
            <b-col cols="9">
              <b-media no-body class="align-items-center">
                <ProductThumbnail :product="suggestion.products[0]" />
                <b-media-body>
                  <p class="h5 mb-0">
                    {{ suggestion.products[0].name }}
                  </p>
                </b-media-body>
              </b-media>
            </b-col>

            <b-col align="end">
              <a
                href="#!"
                class="table-action table-action-delete"
                data-toggle="tooltip"
                data-original-title="Supprimer"
                @click.prevent="removeSuggestionFromCategory(suggestion)"
              >
                <i class="fas fa-trash" />
              </a>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import ProductAutocomplete from '@/components/ProductAutocomplete'
import ProductThumbnail from '@/components/Product/ProductThumbnail'

export default {
  components: {
    ProductAutocomplete,
    ProductThumbnail,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      suggestions: [],
    }
  },
  watch: {
    'category.ListCategorySuggestions': {
      handler(suggestions) {
        if (suggestions) {
          this.suggestions = suggestions
        }
      },
      immediate: true,
    },
  },
  methods: {
    addSuggestionToCategory(product) {
      this.$store.dispatch('schoolLists/categories/addSuggestionToCategory', {
        category: this.category,
        suggestion: { type: 'PRODUCT', name: '', products: [product] },
      })
    },
    removeSuggestionFromCategory(suggestion) {
      this.$store.dispatch('schoolLists/categories/removeSuggestion', {
        category: this.category,
        suggestion,
      })
    },
  },
}
</script>
