import Vue from 'vue'
import Router from 'vue-router'

import getEnv from '@/utils/env'

import AuthLayout from '@/views/Layout/AuthLayout'
import DashboardLayout from '@/views/Layout/DashboardLayout'
import Dashboard from '@/views/Dashboard/Dashboard'

import NotFound from '@/views/Error/NotFound'
import Login from '@/views/Login'

import store from '@/store'
import catalog from './catalog.router'
import users from './users.router'
import pages from './pages.router'
import landingPages from './landingPages.router'
import localisation from './localisation.router'
import taxesRules from './taxesRules.router'
import shipping from './shipping.router'
import establishments from './establishments.router'
import schoolLists from './schoolLists.router'
import categories from './categories.router'
import filters from './filters.router'
import orders from './orders.router'
import promotions from './promotions.router'
import receptionBoxes from './receptionBoxes.router'
import exports from './exports.router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Tableau de bord',
      redirect: '/dashboard',
      component: DashboardLayout,
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          component: Dashboard,
        },
        catalog,
        users,
        pages,
        landingPages,
        localisation,
        taxesRules,
        shipping,
        establishments,
        schoolLists,
        categories,
        filters,
        orders,
        promotions,
        receptionBoxes,
        exports,
      ],
    },
    {
      path: '/',
      name: 'Authentication',
      component: AuthLayout,
      children: [
        {
          path: '/login',
          name: 'login',
          component: Login,
        },
      ],
    },
    {
      path: '*',
      component: NotFound,
    },
  ],
})

function validateUserRole(to, next) {
  const { role } = store.state.accounts.account

  if (role) {
    if ('roles' in to.meta === false || to.meta.roles.includes(role)) {
      next()
    } else {
      next({ path: '/dashboard' })
    }
  } else {
    window.location = `${getEnv('VUE_APP_WEBSITE_URL')}/utilisateurs/mon-compte`
  }
}

// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
  if (to.name === 'login' || from.name === 'login') return next()

  if (store.state.accounts.account) {
    validateUserRole(to, next)
  } else {
    store.watch(store.getters['accounts/isLoggedIn'], (response) => {
      if (response === true) {
        validateUserRole(to, next)
      }
    })
  }
})

export default router
