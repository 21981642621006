import * as blocks from '@/assets/blocks/types'

export default [
  {
    type: blocks.BLOCK_TYPE_WYSIWYG,
    title: 'Éditeur de texte',
    props: {
      showTitle: false,
    },
  },
]
