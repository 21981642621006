import BlankRouterView from '@/views/Layout/BlankRouterView'

import SchoolListsSectionsIndex from '@/views/Dashboard/SchoolLists/SchoolListsSectionsIndex'
import SchoolListsSectionsAdd from '@/views/Dashboard/SchoolLists/SchoolListsSectionsAdd'
import SchoolListsSectionsEdit from '@/views/Dashboard/SchoolLists/SchoolListsSectionsEdit'

import SchoolListsCourseIndex from '@/views/Dashboard/SchoolLists/SchoolListsCourseIndex'
import SchoolListsGradeIndex from '@/views/Dashboard/SchoolLists/SchoolListsGradeIndex'
import SchoolListsProgramsIndex from '@/views/Dashboard/SchoolLists/SchoolListsProgramIndex'
import SchoolListsAdd from '@/views/Dashboard/SchoolLists/SchoolListsAdd'
import SchoolListsEdit from '@/views/Dashboard/SchoolLists/SchoolListsEdit'

import SchoolSessionsIndex from '@/views/Dashboard/SchoolSessions/SchoolSessionsIndex'
import SchoolSessionsAdd from '@/views/Dashboard/SchoolSessions/SchoolSessionsAdd'
import SchoolSessionsEdit from '@/views/Dashboard/SchoolSessions/SchoolSessionsEdit'

import ComputerSalesProgramsIndex from '@/views/Dashboard/ComputerSalesPrograms/ComputerSalesProgramsIndex'
import ComputerSalesProgramsAdd from '@/views/Dashboard/ComputerSalesPrograms/ComputerSalesProgramsAdd'
import ComputerSalesProgramsEdit from '@/views/Dashboard/ComputerSalesPrograms/ComputerSalesProgramsEdit'

export default {
  path: '/school-lists',
  name: 'Matériel scolaire',
  component: BlankRouterView,
  meta: {
    roles: ['ADMIN', 'MANAGER', 'TEACHER', 'SCHOOL'],
    breadcrumb: {
      url: (context) => {
        const { type } = context.$route.params
        if (type) {
          return `/school-lists/type/${type}/index`
        }
        return context.$route.path
      },
    },
  },
  children: [
    {
      path: '/school-lists/computer-sales-programs/index',
      name: 'Liste des programmes',
      component: ComputerSalesProgramsIndex,
      meta: {
        roles: ['ADMIN', 'MANAGER'],
      },
    },
    {
      path: '/school-lists/computer-sales-programs/add',
      name: 'Ajouter un programme',
      component: ComputerSalesProgramsAdd,
      meta: {
        roles: ['ADMIN', 'MANAGER'],
      },
    },
    {
      path: '/school-lists/computer-sales-programs/edit/:id',
      name: 'Modifier un programme',
      component: ComputerSalesProgramsEdit,
      meta: {
        roles: ['ADMIN', 'MANAGER'],
      },
    },
    {
      path: '/school-lists/sections/index',
      name: 'Sections de cours',
      component: SchoolListsSectionsIndex,
      meta: {
        roles: ['ADMIN', 'MANAGER'],
      },
    },
    {
      path: '/school-lists/sections/add',
      name: 'Créer une sections',
      component: SchoolListsSectionsAdd,
      meta: {
        roles: ['ADMIN', 'MANAGER'],
      },
    },
    {
      path: '/school-lists/sections/:sectionId',
      name: 'Modifier une section',
      component: SchoolListsSectionsEdit,
      meta: {
        roles: ['ADMIN', 'MANAGER'],
      },
    },
    {
      path: '/school-lists/sessions/index',
      name: 'Listes des sessions',
      component: SchoolSessionsIndex,
      meta: {
        roles: ['ADMIN', 'MANAGER'],
      },
    },
    {
      path: '/school-lists/sessions/add',
      name: 'Créer une session',
      component: SchoolSessionsAdd,
      meta: {
        roles: ['ADMIN', 'MANAGER'],
      },
    },
    {
      path: '/school-lists/sessions/:id',
      name: 'Modifier une session',
      component: SchoolSessionsEdit,
      meta: {
        roles: ['ADMIN', 'MANAGER'],
      },
    },
    {
      path: '/school-lists/type/:type(grade|course|program)',
      redirect: '/school-lists/type/:type/index',
      name: 'Listes scolaires par type',
      component: BlankRouterView,
      meta: {
        roles: ['ADMIN', 'MANAGER', 'TEACHER', 'SCHOOL'],
        breadcrumb: {
          url: (context) => {
            const { type } = context.$route.params
            return `/school-lists/type/${type}/index`
          },
        },
      },
      children: [
        {
          path: '/school-lists/type/:type(grade)/index/:establishmentId?',
          name: 'Listes de matériel scolaire',
          component: SchoolListsGradeIndex,
          meta: {
            roles: ['ADMIN', 'MANAGER', 'SCHOOL'],
          },
        },
        {
          path: '/school-lists/type/:type(course)/index/:establishmentId?',
          name: 'Listes de matériel scolaire',
          component: SchoolListsCourseIndex,
          meta: {
            roles: ['ADMIN', 'MANAGER', 'TEACHER'],
          },
        },
        {
          path: '/school-lists/type/:type(program)/index/:establishmentId?',
          name: 'Listes de matériel scolaire',
          component: SchoolListsProgramsIndex,
          meta: {
            roles: ['ADMIN', 'MANAGER'],
          },
        },
        {
          path: '/school-lists/type/:type(grade)/add/:establishmentId?',
          name: 'Créer une liste de matériel scolaire',
          component: SchoolListsAdd,
          meta: {
            roles: ['ADMIN', 'MANAGER', 'SCHOOL'],
          },
        },
        {
          path: '/school-lists/type/:type(course)/add/:establishmentId?',
          name: 'Créer une liste de matériel scolaire',
          component: SchoolListsAdd,
          meta: {
            roles: ['ADMIN', 'MANAGER', 'TEACHER'],
          },
        },
        {
          path: '/school-lists/type/:type(program)/add/:establishmentId?',
          name: 'Créer une liste de matériel scolaire',
          component: SchoolListsAdd,
          meta: {
            roles: ['ADMIN', 'MANAGER'],
          },
        },
        {
          path: '/school-lists/type/:type(grade)/edit/:establishmentId/:id',
          name: 'Modifier une liste de matériel scolaire',
          component: SchoolListsEdit,
          meta: {
            roles: ['ADMIN', 'MANAGER', 'SCHOOL'],
          },
        },
        {
          path: '/school-lists/type/:type(course)/edit/:establishmentId/:id',
          name: 'Modifier une liste de matériel scolaire',
          component: SchoolListsEdit,
          meta: {
            roles: ['ADMIN', 'MANAGER', 'TEACHER'],
          },
        },
        {
          path: '/school-lists/type/:type(program)/edit/:establishmentId/:id',
          name: 'Modifier une liste de matériel scolaire',
          component: SchoolListsEdit,
          meta: {
            roles: ['ADMIN', 'MANAGER'],
          },
        },
      ],
    },
  ],
}
