<template>
  <base-alert
    v-if="toastOnly === false"
    dismissible
    type="success"
    icon="ni ni-like-2"
  >
    {{ message }}
  </base-alert>
</template>

<script>
import { BaseAlert } from '@coop-zone/coop-zone-argon'

export default {
  components: { BaseAlert },
  props: {
    message: {
      type: String,
      default: '',
    },
    toastOnly: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.$notify({
      message: this.message,
      timeout: 5000,
      icon: 'ni ni-check-bold',
      type: 'success',
    })
  },
}
</script>

<style>
.alert-notify {
  align-items: center;
}
</style>
