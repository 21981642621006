<template>
  <BaseBatchAction v-bind="$props" :before-close="beforeClose">
    <template #button>
      <i class="fas fa-lightbulb" />
      <span>Ajouter une suggestion de produits à la sélection</span>
    </template>
    <template #default>
      <card no-body>
        <template #header>
          <b-row align-v="center">
            <b-col>
              <h3 class="h3 mb-0">Suggestions de produit</h3>
            </b-col>
          </b-row>
          <ProductSuggestions
            v-model="suggestions"
            :can-drag="false"
            @add="addSuggestion"
            @remove="removeSuggestion"
          />
        </template>
      </card>
    </template>

    <template v-if="suggestions.length > 0" #footer="{ close }">
      <BaseButton ref="closeButton" @click="close">Annuler</BaseButton>
      <BaseButton type="primary" @click="updateProducts(close)">
        Enregistrer
      </BaseButton>
    </template>
    <template v-else #footer="{ close }">
      <BaseButton @click="close">Fermer</BaseButton>
    </template>
  </BaseBatchAction>
</template>

<script>
import { apolloClient } from '@/apollo'
import addProductSuggestions from '@/apollo/mutations/AddProductSuggestions.gql'

import { BaseButton, Card } from '@coop-zone/coop-zone-argon'
import BaseBatchAction from './BaseBatchAction.vue'
import ProductSuggestions from '@/components/Product/ProductSuggestions.vue'
import ErrorHandlerMixin from '@/mixins/errorHandler.mixin'

export default {
  components: {
    BaseBatchAction,
    BaseButton,
    Card,
    ProductSuggestions,
  },
  mixins: [ErrorHandlerMixin],
  props: {
    batchActionItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      suggestions: [],
      loading: false,
    }
  },
  methods: {
    async beforeClose() {
      this.suggestions = []
    },
    addSuggestion(product) {
      this.suggestions.push(product)
    },
    removeSuggestion(product) {
      const index = this.suggestions.findIndex(({ id }) => id === product.id)
      this.suggestions.splice(index, 1)
    },
    async updateProducts(callback) {
      try {
        await apolloClient.mutate({
          mutation: addProductSuggestions,
          variables: {
            productIds: this.batchActionItems,
            suggestionIds: this.suggestions.map((suggestion) => suggestion.id),
          },
        })

        this.$notify({
          message: 'Suggestions enregistrées',
          timeout: 5000,
          icon: 'ni ni-check-bold',
          type: 'success',
        })

        callback()
      } catch (error) {
        this.handleError(error)
      }
    },
  },
}
</script>
