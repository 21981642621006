<i18n>
{
  "fr" : {
    "DeleteSuccess": "Le transporteur a bien été supprimé"
  }
}
</i18n>
<template>
  <div>
    <BaseHeader class="pb-6" type="default">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">
          <h1 class="h2 text-white d-inline-block mb-0">
            Modifier un transporteur
          </h1>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </BaseHeader>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col>
          <TransporterForm
            title="Transporteur"
            :on-success="save"
            redirect="/shipping/transporters/index"
          >
            <BaseButton
              slot="actions"
              v-confirm-delete="{
                onConfirm: () => deleteTransporter(),
                confirmText: 'Le transporteur sera supprimé définitivement',
                successText: $t('DeleteSuccess'),
              }"
              type="danger"
              icon
              size="sm"
            >
              <span class="btn-inner--icon"
                ><i class="fas fa-minus-circle"
              /></span>
              <span class="btn-inner--text">Supprimer le transporteur</span>
            </BaseButton>
          </TransporterForm>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {
  RouteBreadcrumb,
  BaseHeader,
  BaseButton,
} from '@coop-zone/coop-zone-argon'
import { mapState } from 'vuex'
import TransporterForm from '@/components/Shipping/Transporters/TransporterForm'
import ErrorHandlerMixin from '@/mixins/errorHandler.mixin'

export default {
  components: {
    TransporterForm,
    RouteBreadcrumb,
    BaseHeader,
    BaseButton,
  },
  mixins: [ErrorHandlerMixin],
  created() {
    this.$store.dispatch(
      'shipping/transporters/getTransporter',
      this.$route.params.id
    )
  },
  methods: {
    async save() {
      await this.$store.dispatch('shipping/transporters/updateTransporter')
    },
    async deleteTransporter() {
      try {
        await this.$store.dispatch(
          'shipping/transporters/deleteTransporter',
          this.$route.params.id
        )
        this.$notify({
          message: this.$t('DeleteSuccess'),
          timeout: 5000,
          icon: 'ni ni-check-bold',
          type: 'success',
        })
        this.$router.push('/shipping/transporters/index')
      } catch (error) {
        this.handleError(error)
        throw error
      }
    },
  },
}
</script>
