// Admin: (1 à 12)
// Éditeur: (1-4-7)
// Super-éditeur: (1-4-7-8-11)
// Gestionnaire:(2-3-5-6-8-11) + 4 (08-04-2022)

// 1 - PAGES - Gestion de pages: (ajout et modification de texte et images)
// 2 - ORDERS - Gestion des commandes web: (accès au traitement des commandes régulières, informatique et écoles primaires/secondaires)
// 3 - ORDERS - Gestion des commandes de professeurs:
// 4 - CATALOG - Gestion du catalogue de produits: (ajout et modification d'images, de descriptions, etc, catégories de produits)
// 5 - SCHOOLSUPPLY - Gestion des programmes de vente aux écoles primaires/secondaires: (écoles, sections de cours, regroupements de produits, journées de livraison)
// 6 - OFFICESUPPLY - Gestion des programmes de vente d'ordinateurs: (programmes, regroupements de produits, journées de livraison)
// 7 - MARKETING - Gestion du marketing: (rabais, coupons rabais, notification courriels, produits vedettes, etc)
// 8 - REPORTS - Gestion des rapports:
// 9 - TAXES - Gestion des taxes:
// 9 - SHIPPING - Gestion des frais de manutention / transport:
// 10 - SESSIONS - Gestion des sessions:
// 11 - ACCOUNTS - Gestion des comptes:
// 12 - ROLES - Gestion des droits d'accès:
// 12 - FILTERS - Gestion des filtres

const state = {
  roles: {
    ADMIN: [
      'PAGES',
      'ORDERS',
      'CATALOG',
      'SCHOOLSUPPLY',
      'OFFICESUPPLY',
      'MARKETING',
      'REPORTS',
      'TAXES',
      'SHIPPING',
      'SESSIONS',
      'ACCOUNTS',
      'ROLES',
      'FILTERS',
    ],
    MANAGER: [
      'CATALOG',
      'ORDERS',
      'SCHOOLSUPPLY',
      'OFFICESUPPLY',
      'REPORTS',
      'ACCOUNTS',
    ],
    SUPER_EDITOR: ['PAGES', 'CATALOG', 'MARKETING', 'REPORTS', 'ACCOUNTS'],
    EDITOR: ['PAGES', 'CATALOG', 'MARKETING'],
    TEACHER: ['SCHOOLSUPPLY'],
    SCHOOL: ['SCHOOLSUPPLY'],
  },
}

export default {
  namespaced: true,
  state,
}
