import { apolloClient } from '@/apollo'

const completeUploadMutation = require('../apollo/mutations/files/CompleteUpload.gql')
const chunkUploadMutation = require('../apollo/mutations/files/ChunkUpload.gql')
const createUploadMutation = require('../apollo/mutations/files/CreateUpload.gql')

const state = {}

const actions = {
  async createUpload() {
    const { data } = await apolloClient.mutate({
      mutation: createUploadMutation,
    })
    return data.createUpload
  },

  async chunkUpload(context, payload) {
    await apolloClient.mutate({
      mutation: chunkUploadMutation,
      variables: payload,
      context: { hasUpload: true },
    })
  },

  async completeUpload(context, payload) {
    const { data } = await apolloClient.mutate({
      mutation: completeUploadMutation,
      variables: payload,
    })

    return data.completeUpload
  },
}

const mutations = {}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
