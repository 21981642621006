<template>
  <div>
    <BaseHeader class="pb-6" type="default">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">
          <h1 class="h2 text-white d-inline-block mb-0">Ajouter un ensemble</h1>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </BaseHeader>

    <BundleForm @on-success="onAfterSave" @action="action = $event" />
  </div>
</template>

<script>
import { RouteBreadcrumb, BaseHeader } from '@coop-zone/coop-zone-argon'
import { mapGetters } from 'vuex'
import BundleForm from '@/components/Product/BundleForm'
import ErrorHandlerMixin from '@/mixins/errorHandler.mixin'

export default {
  name: 'BundleAdd',
  components: {
    RouteBreadcrumb,
    BundleForm,
    BaseHeader,
  },
  mixins: [ErrorHandlerMixin],
  data() {
    return {
      action: null,
    }
  },
  computed: {
    ...mapGetters('products', {
      product: 'getBundle',
    }),
  },
  created() {
    this.$store.commit('products/SET_CURRENT_BUNDLE', null)
  },
  methods: {
    async onAfterSave() {
      this.$notify({
        message: "L'ensemble a bien été enregistré",
        timeout: 5000,
        icon: 'ni ni-check-bold',
        type: 'success',
      })

      if (this.action === 'save') {
        this.$router.push('/catalog/bundles')
      } else {
        this.$router.push(
          `/catalog/bundles/edit/${this.product.id}-${this.product.slug}`
        )
      }
    },
  },
}
</script>
