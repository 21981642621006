var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseForm',{attrs:{"id":"categoryForm","success-message":"Catégorie enregistrée","on-success":_vm.onSuccess}},[_c('b-card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"sm":"12","lg":"6"}},[_c('p',{staticClass:"h6 text-uppercase text-muted ls-1 mb-1"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('h2',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.name)+" ")])]),_c('b-col',{staticClass:"text-right",attrs:{"sm":"12","lg":"6"}},[_vm._t("actions")],2)],1)],1),_c('b-row',[_c('b-col',{attrs:{"col-sm":"6"}},[_c('BaseInput',{attrs:{"label":"Nom de la catégorie","data-cy":"input-category-name","rules":"required","readonly":_vm.ancestors[0] && _vm.ancestors[0].slug === '/'},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),(!(_vm.ancestors.length === 1 && _vm.ancestors[0].slug === '/'))?_c('b-col',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.waitForUtils),expression:"waitForUtils"}],attrs:{"col-sm":"6"}},[_c('BaseInput',{attrs:{"label":"Catégorie parente","rules":"required"}},[_c('Treeselect',{attrs:{"normalizer":_vm.normalizer,"placeholder":"Sélectionnez une catégorie","data-cy":"select-category","options":_vm.treeselectCategoriesById},model:{value:(_vm.parentId),callback:function ($$v) {_vm.parentId=$$v},expression:"parentId"}})],1)],1):_vm._e()],1)],1),_c('b-row',[_c('b-col',[_c('VigieRulesInput',{attrs:{"title":"Règles vigie"},on:{"on-add":function($event){return _vm.vigieRules.push({})}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var remove = ref.remove;
return [_c('el-table-column',{attrs:{"label":"cat1","min-width":"50px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('BaseInput',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"maxlength":"3"},model:{value:(row.level1),callback:function ($$v) {_vm.$set(row, "level1", $$v)},expression:"row.level1"}})]}}],null,true)}),_c('el-table-column',{attrs:{"label":"cat2","min-width":"50px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('BaseInput',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"maxlength":"3"},model:{value:(row.level2),callback:function ($$v) {_vm.$set(row, "level2", $$v)},expression:"row.level2"}})]}}],null,true)}),_c('el-table-column',{attrs:{"label":"cat3","min-width":"50px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('BaseInput',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"maxlength":"3"},model:{value:(row.level3),callback:function ($$v) {_vm.$set(row, "level3", $$v)},expression:"row.level3"}})]}}],null,true)}),_c('el-table-column',{attrs:{"label":"cat4","min-width":"50px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('BaseInput',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"maxlength":"3"},model:{value:(row.level4),callback:function ($$v) {_vm.$set(row, "level4", $$v)},expression:"row.level4"}})]}}],null,true)}),_c('el-table-column',{attrs:{"label":"cat5","min-width":"50px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('BaseInput',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"maxlength":"3"},model:{value:(row.level5),callback:function ($$v) {_vm.$set(row, "level5", $$v)},expression:"row.level5"}})]}}],null,true)}),_c('el-table-column',{attrs:{"label":"cat6","min-width":"50px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('BaseInput',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"maxlength":"3"},model:{value:(row.level6),callback:function ($$v) {_vm.$set(row, "level6", $$v)},expression:"row.level6"}})]}}],null,true)}),_c('el-table-column',{attrs:{"label":"cat7","min-width":"50px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('BaseInput',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"maxlength":"3"},model:{value:(row.level7),callback:function ($$v) {_vm.$set(row, "level7", $$v)},expression:"row.level7"}})]}}],null,true)}),_c('el-table-column',{attrs:{"label":"cat8","min-width":"50px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('BaseInput',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"maxlength":"3"},model:{value:(row.level8),callback:function ($$v) {_vm.$set(row, "level8", $$v)},expression:"row.level8"}})]}}],null,true)}),_c('el-table-column',{attrs:{"label":"cat9","min-width":"50px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('BaseInput',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"maxlength":"3"},model:{value:(row.level9),callback:function ($$v) {_vm.$set(row, "level9", $$v)},expression:"row.level9"}})]}}],null,true)}),_c('el-table-column',{attrs:{"label":"cat10","min-width":"50px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('BaseInput',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"maxlength":"3"},model:{value:(row.level10),callback:function ($$v) {_vm.$set(row, "level10", $$v)},expression:"row.level10"}})]}}],null,true)}),_c('el-table-column',{attrs:{"min-width":"40px","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"table-actions"},[_c('el-tooltip',{attrs:{"content":"Supprimer","placement":"top"}},[_c('a',{staticClass:"table-action table-action-delete",attrs:{"href":"#!","data-toggle":"tooltip","data-original-title":"Supprimer"},on:{"click":function($event){$event.preventDefault();return remove(row)}}},[_c('i',{staticClass:"fas fa-trash"})])])],1)}}],null,true)})]}}]),model:{value:(_vm.vigieRules),callback:function ($$v) {_vm.vigieRules=$$v},expression:"vigieRules"}})],1)],1),_c('b-row',[_c('b-col',[_c('TableInput',{attrs:{"title":"Filtres","draggable":true},on:{"on-add":function($event){return _vm.filters.push({})}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var remove = ref.remove;
return [_c('el-table-column',{attrs:{"width":"100px"}},[_c('span',{staticClass:"table-action"},[_c('i',{staticClass:"handle fas fa-arrows-alt"})])]),_c('el-table-column',{attrs:{"label":"Filtre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('FilterSelect',{model:{value:(row.filterId),callback:function ($$v) {_vm.$set(row, "filterId", $$v)},expression:"row.filterId"}})]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Nom du filtre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('BaseInput',{model:{value:(row.label),callback:function ($$v) {_vm.$set(row, "label", $$v)},expression:"row.label"}})]}}],null,true)}),_c('el-table-column',{attrs:{"min-width":"25px","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"table-actions"},[_c('el-tooltip',{attrs:{"content":"Supprimer","placement":"top"}},[_c('a',{staticClass:"table-action table-action-delete",attrs:{"href":"#!","data-toggle":"tooltip","data-original-title":"Supprimer"},on:{"click":function($event){$event.preventDefault();return remove(row)}}},[_c('i',{staticClass:"fas fa-trash"})])])],1)}}],null,true)})]}}]),model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}})],1)],1),_c('b-row',{attrs:{"align":"end"}},[_c('b-col',[_c('BaseButton',{attrs:{"size":"lg","type":"primary","native-type":"submit"}},[_vm._v(" Sauvegarder ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }