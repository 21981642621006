<template>
  <b-row class="mb-5">
    <b-col lg="12">
      <b-row class="mb-3">
        <b-col>
          <ProductAutocomplete
            input-label="Rechercher l’article dans le catalogue Coop Zone"
            :show-tags-list="false"
            placeholder="Nom de produit, un SKU, un ISBN, un code Apple, un auteur, une marque"
            @change="addProductToCategory($event)"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <component :is="newProductForm" :category="category" />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import ProductAutocomplete from '@/components/ProductAutocomplete'
import CourseNewProductForm from '@/components/SchoolLists/Items/Product/CourseNewProductForm'
import GradeNewProductForm from '@/components/SchoolLists/Items/Product/GradeNewProductForm'

export default {
  components: {
    CourseNewProductForm,
    GradeNewProductForm,
    ProductAutocomplete,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  computed: {
    newProductForm() {
      if (this.$route.params.type === 'grade') {
        return GradeNewProductForm
      }
      if (this.$route.params.type === 'course') {
        return CourseNewProductForm
      }
      return null
    },
  },
  methods: {
    addProductToCategory(product) {
      this.$store.dispatch('schoolLists/categories/addNewItem', {
        category: this.category,
        item: {
          type: 'PRODUCT',
          products: [product],
        },
      })
    },
  },
}
</script>
