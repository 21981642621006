import { apolloClient } from '@/apollo'

const countriesQuery = require('../apollo/queries/GetCountries.gql')
const countryQuery = require('../apollo/queries/GetCountry.gql')
const updateCountryMutation = require('../apollo/mutations/UpdateCountry.gql')
const createCountryMutation = require('../apollo/mutations/CreateCountry.gql')

const state = {
  countries: [],
  id: null,
  name: '',
  code: '',
  ZoneId: null,
  states: [],
}

const actions = {
  async getAllCountries({ commit }) {
    const {
      data: { countries },
    } = await apolloClient.query({
      query: countriesQuery,
    })

    if (countries) {
      commit('SET_ALL_COUNTRIES', countries)
    }
  },

  async getCountry({ commit }, id) {
    const {
      data: { country },
    } = await apolloClient.query({
      query: countryQuery,
      variables: {
        id,
      },
    })
    if (country) {
      if (country.zone) {
        country.ZoneId = country.zone.id
      }
      commit('SET_COUNTRY', country)
    }
  },

  async createCountry({ state: { name, code, ZoneId } }) {
    const country = {
      name,
      code,
      ZoneId: ZoneId ? ZoneId : null,
    }

    await apolloClient.query({
      query: createCountryMutation,
      variables: {
        country,
      },
    })
  },

  async updateCountry({ state: { id, name, code, ZoneId } }) {
    const country = {
      name,
      code,
      ZoneId: ZoneId ? ZoneId : null,
    }

    await apolloClient.query({
      query: updateCountryMutation,
      variables: {
        id,
        country,
      },
    })
  },
}
const mutations = {
  SET_ALL_COUNTRIES(state, payload) {
    state.countries = payload
  },
  SET_COUNTRY(
    state,
    { id = null, name = '', code = '', ZoneId = null, states = [] }
  ) {
    state.id = id
    state.name = name
    state.code = code
    state.ZoneId = ZoneId
    state.states = states
  },
  SET_NAME(state, name) {
    state.name = name
  },
  SET_CODE(state, code) {
    state.code = code
  },
  SET_ZONE_ID(state, zoneId) {
    state.ZoneId = zoneId
  },
}

const getters = {
  zoneless: (state) =>
    state.countries.filter((country) => country.zone === null),
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
