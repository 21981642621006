<i18n>
{
  "fr" : {
    "DeleteSuccess": "Le groupe a bien été supprimé"
  }
}
</i18n>
<template>
  <div>
    <BaseHeader class="pb-6" type="default">
      <b-row align-v="center" class="py-4">
        <b-col lg="12" cols="7">
          <h1 class="h2 text-white d-inline-block mb-0">Modifier un groupe</h1>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </BaseHeader>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="12">
          <GroupForm title="Modifier un groupe">
            <BaseButton
              slot="actions"
              v-confirm-delete="{
                onConfirm: () => deleteGroup(),
                confirmText: 'Le groupe de taxe sera supprimé définitivement',
                successText: $t('DeleteSuccess'),
              }"
              type="danger"
              icon
              size="sm"
            >
              <span class="btn-inner--icon"
                ><i class="fas fa-minus-circle"
              /></span>
              <span class="btn-inner--text">Supprimer le groupe</span>
            </BaseButton>
          </GroupForm>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {
  RouteBreadcrumb,
  BaseHeader,
  BaseButton,
} from '@coop-zone/coop-zone-argon'
import GroupForm from '@/components/Taxes/GroupForm'

export default {
  components: {
    GroupForm,
    RouteBreadcrumb,
    BaseHeader,
    BaseButton,
  },

  methods: {
    async deleteGroup() {
      // eslint-disable-next-line no-alert
      await this.$store.dispatch(
        'taxes/groups/deleteGroup',
        this.$route.params.id
      )
      this.$notify({
        message: this.$t('DeleteSuccess'),
        timeout: 5000,
        icon: 'ni ni-check-bold',
        type: 'success',
      })
      this.$router.push('/taxes_rules/groups/')
    },
  },
}
</script>
