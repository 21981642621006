<i18n>
{
  "en": {
    "type": {
      "REINDEX": "Indexation des produits",
      "RESYNC": "Synchronisation des règles vigie",
      "DIGITAL_IMPORT": "Importation des produits DeMarque",
      "MAILCHIMP_SYNC": "Synchronisation des produits vers Mailchimp",
      "RELATED_PRODUCTS": "Liaison des variants de produit"
    },
    "status": {
      "COMPLETED": "complété",
      "FAILED": "échec",
      "PROCESSING": "en cours",
      "NEW": "nouveau"
    }
  },
  "fr": {
    "type": {
      "REINDEX": "Indexation des produits",
      "RESYNC": "Synchronisation des règles vigie",
      "DIGITAL_IMPORT": "Importation des produits DeMarque",
      "MAILCHIMP_SYNC": "Synchronisation des produits vers Mailchimp",
      "RELATED_PRODUCTS": "Liaison des variants de produit"
    },
    "status": {
      "COMPLETED": "complété",
      "FAILED": "échec",
      "PROCESSING": "en cours",
      "NEW": "nouveau"
    }
  }
}
</i18n>
<template>
  <b-card no-body class="bg-default shadow">
    <b-card-header class="bg-transparent border-0">
      <h6 class="text-uppercase text-muted text-white ls-1 mb-1">
        {{ onGoingTasks.length }} tâche{{ onGoingTasks.length > 1 ? 's' : '' }}
        en cours
      </h6>
      <h3 class="mb-0 text-white">Résumé des tâches</h3>
    </b-card-header>
    <el-table
      v-if="queue && queue.length > 0"
      class="table-responsive table-flush table-dark"
      header-row-class-name="thead-dark"
      :data="queue"
    >
      <el-table-column label="Tâche" prop="type" sortable>
        <template #default="{ row }">
          <span class="font-weight-600 name mb-0 text-sm">{{
            $t(`type.${row.type}`)
          }}</span>
        </template>
      </el-table-column>

      <el-table-column
        label="Dernière mise à jour du statut"
        prop="updatedAt"
        sortable
      >
        <template #default="{ row }">
          {{ row.updatedAt | dateFormatDefaultWithTime }}
        </template>
      </el-table-column>

      <el-table-column label="Statut" prop="status" sortable>
        <template #default="{ row }">
          <badge
            class="badge badge-default badge-dot mr-4"
            :type="badgeType(row.status)"
          >
            <i :class="`bg-${badgeType(row.status)}`"></i>
            <span class="status">{{ $t(`status.${row.status}`) }}</span>
          </badge>
        </template>
      </el-table-column>
    </el-table>
    <b-card-body v-else>
      <b-row>
        <b-col>
          <h5 class="card-title text-uppercase text-muted mb-0">
            Aucune tâche en cours
          </h5>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>
<script>
import { apolloClient } from '@/apollo'
import { Badge } from '@coop-zone/coop-zone-argon'

import GetQueueTasks from '@/apollo/queries/GetQueueTasks.gql'
export default {
  components: { Badge },
  data() {
    return {
      queue: [],
    }
  },
  computed: {
    onGoingTasks() {
      return this.queue.filter(({ status }) =>
        ['PROCESSING', 'NEW'].includes(status)
      )
    },
  },
  async created() {
    const {
      data: { tasks },
    } = await apolloClient.query({
      query: GetQueueTasks,
      variables: {
        types: [
          'REINDEX',
          'DIGITAL_IMPORT',
          'RESYNC',
          'MAILCHIMP_SYNC',
          'RELATED_PRODUCTS',
        ],
      },
    })

    this.queue = tasks
  },
  methods: {
    badgeType(status) {
      switch (status) {
        case 'COMPLETED':
          return 'success'
        case 'FAILED':
          return 'warning'
        case 'PROCESSING':
          return 'danger'
        case 'NEW':
          return 'info'
        default:
          return
      }
    },
  },
}
</script>
