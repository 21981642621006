<template>
  <BaseForm
    id="schoolSectionForm"
    success-message="Section enregistrée."
    :on-success="onSuccess"
    :redirect="redirect"
  >
    <b-card>
      <div slot="header">
        <b-row align-v="center">
          <b-col sm="12" lg="6">
            <p class="h6 text-uppercase text-muted ls-1 mb-1">
              {{ id ? 'Modifier une section' : 'Ajouter une section' }}
            </p>
            <h2 v-if="id" class="mb-0">
              {{ initialName }}
            </h2>
          </b-col>

          <b-col sm="12" lg="6" class="text-right">
            <slot name="actions" />
          </b-col>
        </b-row>
      </div>

      <b-row>
        <b-col sm="6">
          <BaseInput
            v-model="name"
            label="Nom de la section"
            rules="required"
          />
        </b-col>
        <b-col sm="6">
          <BaseInput label="Niveau scolaire" rules="required">
            <select v-model="establishmentType" class="form-control">
              <option
                v-for="type in availableSchoolEstablishmentTypes"
                :key="type"
                :value="type"
              >
                {{ $t(`establishments.types.${type}`) }}
              </option>
            </select>
          </BaseInput>
        </b-col>
      </b-row>
    </b-card>

    <slot />

    <b-row align="end" class="mt-1">
      <b-col>
        <BaseButton size="lg" type="primary" native-type="submit">
          Sauvegarder
        </BaseButton>
      </b-col>
    </b-row>
  </BaseForm>
</template>

<script>
import { mapState } from 'vuex'
import { BaseButton, BaseInput } from '@coop-zone/coop-zone-argon'
import { mapGetters } from 'vuex'
import BaseForm from '@/components/forms/BaseForm'

export default {
  components: {
    BaseForm,
    BaseButton,
    BaseInput,
  },
  props: {
    onSuccess: {
      type: Function,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    redirect: {
      type: [String, Boolean],
      default: false,
    },
  },
  data() {
    return {
      initialName: this.name,
    }
  },
  computed: {
    ...mapGetters('schoolEstablishments', ['schoolEstablishmentTypes']),
    ...mapState('schoolLists/sections', ['id']),
    name: {
      get() {
        return this.$store.state.schoolLists.sections.name
      },
      set(name) {
        this.$store.commit('schoolLists/sections/SET_NAME', name)
      },
    },
    establishmentType: {
      get() {
        return this.$store.state.schoolLists.sections.establishmentType
      },
      set(establishmentType) {
        this.$store.commit(
          'schoolLists/sections/SET_ESTABLISHMENT_TYPE',
          establishmentType
        )
      },
    },
    availableSchoolEstablishmentTypes() {
      return this.schoolEstablishmentTypes.filter(
        (type) => type === 'COLLEGE' || type === 'UNIVERSITY'
      )
    },
  },
}
</script>
