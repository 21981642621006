import * as blocks from './types'

export default {
  [blocks.BLOCK_TYPE_BANNER]: {
    primaryTitle: {
      text: '',
      color: '#ffffff',
    },
    secondaryTitle: {
      text: '',
      color: '#ffffff',
    },
    button: {
      text: '',
      url: '',
    },
    images: {
      desktop: null,
      mobile: null,
    },
  },
  [blocks.BLOCK_TYPE_CALL_TO_ACTION]: {
    childrens: [],
  },
  [blocks.BLOCK_TYPE_PRODUCT_LIST]: {
    title: '',
    button: {
      text: '',
      url: '',
    },
    childrens: [],
  },
  [blocks.BLOCK_TYPE_PROMO]: {
    display: 4,
    childrens: [],
  },
  [blocks.BLOCK_TYPE_WYSIWYG]: {
    title: '',
    text: '',
  },
}
