<template>
  <BaseInput rules="required">
    <el-select
      filterable
      clearable
      placeholder="Rechercher un filtre"
      :value="value"
      :loading="loading"
      @change="$emit('input', $event)"
    >
      <el-option
        v-for="item in filters"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      />
    </el-select>
  </BaseInput>
</template>

<script>
import { BaseInput } from '@coop-zone/coop-zone-argon'
import { mapState } from 'vuex'

export default {
  components: {
    BaseInput,
  },
  props: {
    defaultOption: {
      type: Object,
      default: () => {},
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapState('filters', ['filters']),
  },
}
</script>
