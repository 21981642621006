import { mapState } from 'vuex'
import blockContents from '@/assets/blocks/content.map'

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    nobody: {
      type: Boolean,
      default: false,
    },
    blockIndex: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('blocks', ['blocks']),
  },
  created() {
    this.$store.commit('blocks/SET_CONTENT', {
      index: this.blockIndex,
      content: JSON.parse(JSON.stringify(blockContents[this.type])),
    })
  },
}
