<i18n>
{
  "en": {
    "Success": "Les adresses ont été ajoutées au compte avec succès."
  },
  "fr": {
    "Success": "Les adresses ont été ajoutées au compte avec succès."
  }
}
</i18n>
<template>
  <BaseForm
    id="mes-adresses"
    ref="user-addresses"
    classes="form"
    :on-success="updateAddresses"
    :success-message="$t('Success')"
  >
    <card>
      <b-row slot="header" align-v="center">
        <b-col cols="8">
          <h3 class="mb-0">Adresses</h3>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <h2 class="heading-small text-muted mb-4">Adresse de facturation</h2>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" sm="6">
          <BaseInput
            v-model="billingAddress.firstName"
            label="Prénom"
            name="billingAddressFirstname"
            type="text"
            rules="required"
          />
        </b-col>

        <b-col cols="12" sm="6">
          <BaseInput
            v-model="billingAddress.lastName"
            label="Nom"
            name="billingAddressLastname"
            type="text"
            rules="required"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <CanadaPostInput
            v-model="billingAddress.address"
            name="billingAddressAddress"
            class="billingAddressAddress"
            label="Adresse"
            rules="required"
            @fill="fillBillingAddress"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <BaseInput
            v-model="billingAddress.address2"
            label="Numéro appartement, local, bureau, etc. (optionnel)"
            name="billingAddressAddress2"
            type="text"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" sm="6">
          <BaseInput
            v-model="billingAddress.city"
            label="Ville"
            name="billingAddressCity"
            type="text"
            rules="required"
          />
        </b-col>

        <b-col cols="12" sm="6">
          <BaseInput label="Pays" name="billingAddressCountry" rules="required">
            <FormSelectCountry
              v-model="billingAddress.country"
              label="Pays"
              name="billingAddressCountry"
              rules="required"
            />
          </BaseInput>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" sm="6">
          <BaseInput
            v-model="billingAddress.postalCode"
            vid="billingAddress.postalCode"
            label="Code postal"
            name="billingAddressPostalCode"
            type="text"
            rules="required"
          />
        </b-col>
        <b-col cols="12" sm="6">
          <BaseInput
            label="Province / État"
            name="billingAddressProvince"
            rules="required"
          >
            <FormSelectState
              v-model="billingAddress.province"
              :country="billingAddress.country"
              :disabled="!billingAddress.country"
            />
          </BaseInput>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <h2 class="heading-small text-muted mb-4">Adresse de livraison</h2>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" sm="6">
          <BaseInput
            v-model="shippingAddress.firstName"
            label="Prénom"
            name="shippingAddressFirstname"
            type="text"
            rules="required"
          />
        </b-col>

        <b-col cols="12" sm="6">
          <BaseInput
            v-model="shippingAddress.lastName"
            label="Nom"
            name="shippingAddressLastname"
            type="text"
            rules="required"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <CanadaPostInput
            v-model="shippingAddress.address"
            name="shippingAddressAddress"
            class="shippingAddressAddress"
            label="Adresse"
            rules="required"
            @fill="fillShippingAddress"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <BaseInput
            v-model="shippingAddress.address2"
            label="Numéro appartement, local, bureau, etc. (optionnel)"
            name="shippingAddressAddress2"
            type="text"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" sm="6">
          <BaseInput
            v-model="shippingAddress.city"
            label="Ville"
            name="shippingAddressCity"
            type="text"
            rules="required"
          />
        </b-col>
        <b-col cols="12" sm="6">
          <BaseInput label="Pays" name="billingAddressCountry" rules="required">
            <FormSelectCountry
              v-model="shippingAddress.country"
              label="Pays"
              name="shippingAddressCountry"
              rules="required"
            />
          </BaseInput>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" sm="6">
          <BaseInput
            v-model="shippingAddress.postalCode"
            label="Code postal"
            name="shippingAddressPostalCode"
            type="text"
            rules="required"
          />
        </b-col>
        <b-col cols="12" sm="6">
          <BaseInput
            label="Province / État"
            name="shippingAddressProvince"
            rules="required"
          >
            <FormSelectState
              v-model="shippingAddress.province"
              :country="shippingAddress.country"
              :disabled="!shippingAddress.country"
            />
          </BaseInput>
        </b-col>
      </b-row>
      <b-row slot="footer" align-v="center">
        <b-col cols="12">
          <BaseButton type="default" native-type="submit">
            Enregistrer les adresses
          </BaseButton>
        </b-col>
      </b-row>
    </card>
  </BaseForm>
</template>
<script>
import { BaseButton, BaseInput, Card } from '@coop-zone/coop-zone-argon'
import { mapState } from 'vuex'
import BaseForm from '@/components/forms/BaseForm'
import CanadaPostInput from '@/components/Inputs/CanadaPostInput'
import FormSelectCountry from '@/components/Inputs/FormSelectCountry'
import FormSelectState from '@/components/Inputs/FormSelectState'

export default {
  components: {
    BaseButton,
    BaseForm,
    BaseInput,
    Card,
    CanadaPostInput,
    FormSelectCountry,
    FormSelectState,
  },
  data() {
    return {
      billingAddress: null,
      shippingAddress: null,
    }
  },
  computed: {
    ...mapState('users', ['user']),
  },
  watch: {
    user: {
      handler(newVal) {
        this.billingAddress = { ...newVal.billingAddress }
        this.shippingAddress = { ...newVal.shippingAddress }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$store.dispatch('users/getUser', this.$route.params.id)
  },
  methods: {
    async updateAddresses() {
      await this.$store.dispatch('users/updateUserAddresses', {
        userId: this.$route.params.id,
        billingAddress: this.billingAddress,
        shippingAddress: this.shippingAddress,
      })
      this.mode = 'readonly'
    },
    fillShippingAddress(address) {
      this.shippingAddress.address = address.Line1
      this.shippingAddress.city = address.City
      this.shippingAddress.country = address.CountryIso2
      this.shippingAddress.postalCode = address.PostalCode
      this.shippingAddress.province = address.ProvinceCode
    },
    fillBillingAddress(address) {
      this.billingAddress.address = address.Line1
      this.billingAddress.city = address.City
      this.billingAddress.country = address.CountryIso2
      this.billingAddress.postalCode = address.PostalCode
      this.billingAddress.province = address.ProvinceCode
    },
  },
}
</script>
<style></style>
