import Vue from 'vue'

Vue.filter('dateFormat', (string) => {
  const dateObj = new Date(string)
  return new Intl.DateTimeFormat('fr-CA', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(dateObj)
})
