import Vue from 'vue'
import swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.css'

Vue.directive('confirmDelete', {
  inserted(el, binding) {
    binding.handler = () => {
      swal
        .fire({
          title: binding.value.confirmTitle || 'Êtes-vous certain?',
          text: binding.value.confirmText || "L'item sera supprimé",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#f5365c',
          cancelButtonColor: '#001e31',
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
        })
        .then(async (result) => {
          if (result.value) {
            await binding.value.onConfirm()
            swal.fire(
              binding.value.successTitle || 'Succès',
              binding.value.successText || "L'item a été supprimé",
              'success'
            )
          }
        })
    }

    el.addEventListener('click', binding.handler)
  },
  unbind(el, binding) {
    el.removeEventListener('click', binding.handler)
  },
})
