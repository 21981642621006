import { apolloClient } from '@/apollo'

const getCategoriesQuery = require('../apollo/queries/GetCategoryTree.gql')
const getCategoryQuery = require('../apollo/queries/GetCategory.gql')
const updateCategoryMutation = require('../apollo/mutations/UpdateCategory.gql')
const createCategoryMutation = require('../apollo/mutations/CreateCategory.gql')
const deleteCategoryMutation = require('../apollo/mutations/DeleteCategory.gql')
const updateCategoriesPositionMutation = require('../apollo/mutations/UpdateCategoriesPosition.gql')

const state = {
  categoriesTree: [],
  id: null,
  name: '',
  ancestors: [],
  parentId: null,
  vigieRules: [],
  filters: [],
  hierarchyLevel: null,
}

const actions = {
  async getCategories({ commit }) {
    const {
      data: { categories },
    } = await apolloClient.query({
      query: getCategoriesQuery,
    })
    commit('SET_CATEGORIES_TREE', categories)
  },

  async getCategory({ commit }, id) {
    const {
      data: { category },
    } = await apolloClient.query({
      query: getCategoryQuery,
      variables: { id },
    })
    commit('SET_CURRENT_CATEGORY', category)
  },

  async updateCategory({ state: { id, name, parentId, vigieRules, filters } }) {
    await apolloClient.query({
      query: updateCategoryMutation,
      variables: {
        id,
        category: {
          name,
          parentId,
          vigieRules,
          filters: filters.map(({ label, filterId }) => ({ label, filterId })),
        },
      },
    })
  },

  async sortCategories({ commit }, { categoriesPosition }) {
    await apolloClient.query({
      query: updateCategoriesPositionMutation,
      variables: {
        categoriesPosition,
      },
    })
  },

  async createCategory({ state: { name, parentId, vigieRules, filters } }) {
    await apolloClient.query({
      query: createCategoryMutation,
      variables: {
        category: {
          name,
          parentId,
          vigieRules,
          filters,
        },
      },
    })
  },
  async deleteCategory({ commit }, id) {
    await apolloClient.query({
      query: deleteCategoryMutation,
      variables: { id },
    })
  },
}

const mutations = {
  SET_CATEGORIES_TREE(state, payload) {
    state.categoriesTree = payload
  },
  SET_CURRENT_CATEGORY(
    state,
    {
      id = null,
      name = '',
      ancestors = [],
      parentId = null,
      vigieRules = [],
      filters = [],
      hierarchyLevel = null,
    }
  ) {
    state.id = id
    state.name = name
    state.ancestors = ancestors
    state.parentId = parentId
    state.vigieRules = vigieRules
    state.filters = filters
    state.hierarchyLevel = hierarchyLevel
  },
  SET_NAME(state, payload) {
    state.name = payload
  },
  SET_ANCESTORS(state, payload) {
    state.ancestors = payload
  },
  SET_PARENT_ID(state, payload) {
    state.parentId = payload
  },
  SET_VIGIE_RULES(state, payload) {
    state.vigieRules = payload
  },
  SET_FILTERS(state, payload) {
    state.filters = payload
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
