import * as blocks from './types'
export default {
  [blocks.BLOCK_TYPE_BANNER]: () => import('@/components/Blocks/BannerBlock'),
  [blocks.BLOCK_TYPE_CALL_TO_ACTION]: () =>
    import('@/components/Blocks/CallToActionBlock'),
  [blocks.BLOCK_TYPE_PRODUCT_LIST]: () =>
    import('@/components/Blocks/ProductListBlock'),
  [blocks.BLOCK_TYPE_PROMO]: () => import('@/components/Blocks/PromoBlock'),
  [blocks.BLOCK_TYPE_WYSIWYG]: () => import('@/components/Blocks/WYSIWYGBlock'),
}
