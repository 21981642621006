<template>
  <div>
    <base-header class="pb-6" type="default">
      <b-row align-v="center" class="py-4">
        <b-col>
          <p class="h2 text-white d-inline-block mb-0">Sessions</p>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6 pb-4">
      <b-row>
        <b-col>
          <SchoolSessionForm
            :on-success="save"
            redirect="/school-lists/sessions/index"
            title="Modifier la session"
          >
            <BaseButton
              slot="actions"
              v-confirm-delete="{
                onConfirm: () => deleteSession(),
                confirmText: `La session sera supprimée.`,
                successText: `La session a été supprimée`,
              }"
              type="danger"
              icon
              size="sm"
            >
              <span class="btn-inner--icon">
                <i class="fas fa-minus-circle" />
              </span>
              <span class="btn-inner--text">Supprimer la session</span>
            </BaseButton>
          </SchoolSessionForm>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {
  BaseButton,
  BaseHeader,
  RouteBreadcrumb,
} from '@coop-zone/coop-zone-argon'
import { mapState, mapGetters } from 'vuex'
import ErrorHandlerMixin from '@/mixins/errorHandler.mixin'

import SchoolSessionForm from '@/components/SchoolSessions/SchoolSessionForm'

export default {
  components: {
    BaseButton,
    BaseHeader,
    RouteBreadcrumb,
    SchoolSessionForm,
  },
  mixins: [ErrorHandlerMixin],
  computed: {
    ...mapState('schoolSessions', ['name']),
  },
  async created() {
    await this.$store.dispatch(
      'schoolSessions/getSession',
      this.$route.params.id
    )
  },
  methods: {
    async deleteSession() {
      try {
        await this.$store.dispatch(
          'schoolSessions/deleteSession',
          this.$route.params.id
        )
        this.$router.push('/school-lists/sessions/index')
      } catch (error) {
        this.handleError(error)
        throw error
      }
    },
    async save() {
      await this.$store.dispatch('schoolSessions/updateSession')
    },
  },
}
</script>
