var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('base-header',{staticClass:"pb-6 bg-default"},[_c('b-row',{staticClass:"py-4",attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"7","lg":"6"}},[_c('h6',{staticClass:"h2 text-white d-inline-block mb-0"},[_vm._v(" Boîtes de réceptions ")]),_c('nav',{staticClass:"d-none d-md-inline-block ml-md-4",attrs:{"aria-label":"breadcrumb"}},[_c('RouteBreadcrumb')],1)])],1)],1),_c('b-container',{staticClass:"mt--6",attrs:{"fluid":""}},[_c('b-card',{staticClass:"mb-4",attrs:{"no-body":""}},[_c('b-row',{attrs:{"slot":"header","align-v":"center"},slot:"header"},[_c('b-col',{attrs:{"cols":"8"}},[_c('h3',{staticClass:"mb-0"},[_vm._v("Liste des boîtes de réceptions")])]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"4"}},[_c('router-link',{staticClass:"btn btn-sm btn-primary",attrs:{"to":"/reception-boxes/add","data-cy":"deliveryrule-add-link"}},[_vm._v(" Ajouter une boîte de réception ")])],1)],1),_c('el-table',{attrs:{"data":_vm.receptionBoxes,"row-key":"id","header-row-class-name":"thead-light"}},[_c('el-table-column',{attrs:{"width":"100px","label":"Priorité","prop":"priority"}}),_c('el-table-column',{attrs:{"width":"400px","label":"Nom de la boîte de réception","prop":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('router-link',{attrs:{"to":("/reception-boxes/edit/" + (row.id))}},[_c('span',{staticClass:"font-weight-600 name text-sm mb-0"},[_vm._v(_vm._s(row.name))])])]}}])}),_c('el-table-column',{attrs:{"width":"200px","label":"Succursale","prop":"Store"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return (row.Store)?[_vm._v(" "+_vm._s(row.Store.name || '')+" ")]:undefined}}],null,true)}),_c('el-table-column',{attrs:{"min-width":"75px","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('router-link',{staticClass:"table-action table-action-edit",attrs:{"to":("/reception-boxes/edit/" + (row.id))}},[_c('i',{staticClass:"fa fa-edit"})]),(row.deletable)?_c('a',{directives:[{name:"confirm-delete",rawName:"v-confirm-delete",value:({
                onConfirm: function () { return _vm.deleteReceptionBox(row); },
                confirmText:
                  'La boîte de réception sera supprimée définitivement',
                successText: _vm.$t('DeleteSuccess'),
              }),expression:"{\n                onConfirm: () => deleteReceptionBox(row),\n                confirmText:\n                  'La boîte de réception sera supprimée définitivement',\n                successText: $t('DeleteSuccess'),\n              }"}],staticClass:"table-action table-action-delete",attrs:{"href":"#!","data-toggle":"tooltip","data-original-title":"Supprimer"}},[_c('i',{staticClass:"fas fa-trash"})]):_vm._e()]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }