<template>
  <BasePageBlock v-bind="$props">
    <b-row>
      <b-col>
        <BaseInput
          rules="required"
          label="Image pour grand écran (1800x530px)"
          label-classes="form-control-label mb-4"
        >
          <SingleFileUpload
            v-model="blocks[blockIndex].images.desktop"
            label="Sélectionner une image"
            :formats="[
              { width: 300, name: 'thumbnail' },
              { width: 600, name: 'mobile' },
              { width: 1000, name: 'tablette' },
            ]"
          />
        </BaseInput>
      </b-col>

      <b-col>
        <BaseInput
          rules="required"
          label="Image pour écran mobile (375x475px)"
          label-classes="form-control-label mb-4"
        >
          <SingleFileUpload
            v-model="blocks[blockIndex].images.mobile"
            label="Sélectionner une image"
            :formats="[
              { width: 300, name: 'thumbnail' },
              { width: 600, name: 'mobile' },
              { width: 1000, name: 'tablette' },
            ]"
          />
        </BaseInput>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h3 class="heading-small text-muted mb-4">Titre principal</h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" sm="9">
        <BaseInput
          v-model="blocks[blockIndex].primaryTitle.text"
          label="Texte du titre"
        />
      </b-col>
      <b-col cols="12" sm="3">
        <label class="form-control-label">Couleur du texte</label>
        <base-input
          v-model="blocks[blockIndex].primaryTitle.color"
          type="color"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h3 class="heading-small text-muted mb-4">Titre secondaire</h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" sm="9">
        <BaseInput
          v-model="blocks[blockIndex].secondaryTitle.text"
          label="Texte du titre"
        />
      </b-col>
      <b-col cols="12" sm="3">
        <label class="form-control-label">Couleur du texte</label>
        <base-input
          v-model="blocks[blockIndex].secondaryTitle.color"
          type="color"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h3 class="heading-small text-muted mb-4">Bouton</h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <BaseInput
          v-model="blocks[blockIndex].button.text"
          label="Texte du lien"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <BaseInput
          v-model="blocks[blockIndex].button.url"
          label="Url du lien"
        />
      </b-col>
    </b-row>
  </BasePageBlock>
</template>
<script>
import { BaseInput } from '@coop-zone/coop-zone-argon'
import SingleFileUpload from '@/components/Inputs/SingleFileUpload'
import BasePageBlock from './BasePageBlock'
import blockMixin from '@/components/Blocks/mixins/block.mixin'

export default {
  components: {
    BaseInput,
    SingleFileUpload,
    BasePageBlock,
  },
  mixins: [blockMixin],
}
</script>
