<template>
  <el-card
    shadow="never"
    :class="{
      'order-product-card--with-replacement': item.replacement,
    }"
  >
    <template v-if="replacement">
      <b-row class="mb-2">
        <b-col>
          <OrderTag type="replacement">Remplacement</OrderTag>
        </b-col>
      </b-row>
    </template>

    <template v-else-if="item.productData.type === 'Bundle'">
      <b-row class="mb-2">
        <b-col>
          <OrderTag type="bundle">Ensemble</OrderTag>
        </b-col>
      </b-row>
    </template>

    <b-row align-v="start" align-h="between">
      <b-col>
        <b-row>
          <b-col lg="6">
            <b-media no-body>
              <ProductThumbnail :product="item" />

              <b-media-body>
                <p class="h5 mb-0">
                  {{ item.name }}
                </p>
                <p v-if="item.productCode" class="h6 text-muted mb-0">
                  Code produit: {{ item.productCode }}
                </p>
                <p
                  v-if="item.productData.isbn13 || item.productData.isbn10"
                  class="h6 text-muted mb-0"
                >
                  ISBN :
                  {{ item.productData.isbn13 || item.productData.isbn10 }}
                </p>
              </b-media-body>
            </b-media>
          </b-col>

          <b-col v-if="item.price" cols="auto">
            <small class="text-nowrap">
              <strong>Prix unitaire</strong>: {{ item.price | currencyFormat }}
            </small>
          </b-col>
        </b-row>

        <template v-if="item.products">
          <b-row class="mt-3 mb-3">
            <b-col>
              <small>Ce regroupement contient les articles ci-dessous</small>
            </b-col>
          </b-row>

          <b-row v-for="child in item.products" :key="child.id" class="mb-3">
            <b-col>
              <b-media no-body>
                <ProductThumbnail :product="child" />

                <b-media-body>
                  <p class="h5 mb-0">
                    {{ child.name }}
                  </p>
                  <p v-if="child.productCode" class="h6 text-muted mb-0">
                    Code produit: {{ child.productCode }}
                  </p>
                  <p
                    v-if="child.productData.isbn13 || child.productData.isbn10"
                    class="h6 text-muted mb-0"
                  >
                    ISBN :
                    {{ child.productData.isbn13 || child.productData.isbn10 }}
                  </p>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </template>
      </b-col>

      <b-col cols="4">
        <b-row>
          <b-col>
            <small class="text-nowrap">
              <strong>Quantité</strong>: {{ item.quantity }}
            </small>
          </b-col>

          <b-col v-if="item.statuses" cols="auto">
            <ul class="list-unstyled">
              <li
                v-for="status in statusesWithQuantity(item.statuses)"
                :key="status.status"
              >
                <small>
                  <strong>
                    {{ $t(`orders.item.statuses.${status.status}`) }}
                  </strong>
                  :&nbsp;{{ status.quantity }}
                </small>
              </li>
            </ul>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </el-card>
</template>

<script>
import ProductThumbnail from '@/components/Product/ProductThumbnail'
import OrderTag from '@/components/Orders/OrderTag'

export default {
  components: {
    ProductThumbnail,
    OrderTag,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    replacement: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    statusesWithQuantity(statuses) {
      return statuses.filter((status) => status.quantity > 0)
    },
  },
}
</script>
