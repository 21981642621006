<template>
  <BaseFlow
    v-if="canCancel(account, schoolList)"
    icon="fas fa-times"
    type="danger"
    @click="cancelList"
  >
    Annuler la liste
  </BaseFlow>
</template>
<script>
import swal from 'sweetalert2'
import flowMixin from './flow.mixin'

import { canCancel } from '@/abilities/schoolLists'

export default {
  mixins: [flowMixin],
  methods: {
    async cancelList() {
      const { isConfirmed, value: annotation } = await swal.fire({
        input: 'textarea',
        inputLabel: 'Indiquez pourquoi la liste sera annulée',
        confirmButtonText: 'Confirmer',
        cancelButtonText: 'Annuler',
        inputPlaceholder: '',
        showCancelButton: true,
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value) {
              resolve()
            } else {
              resolve('Le champ est obligatoire')
            }
          })
        },
      })

      if (isConfirmed) {
        await this.$store.dispatch('schoolLists/updateSchoolListToCanceled', {
          annotation,
          currentEstablishmentId: this.$route.params.establishmentId,
          listId: this.schoolList.id,
        })
        this.$notify({
          message: 'La liste a été annulée',
          timeout: 5000,
          icon: 'ni ni-check-bold',
          type: 'success',
        })

        this.$router.push(`/school-lists/type/${this.$route.params.type}/index`)
      }
    },
    canCancel,
  },
}
</script>
