<template>
  <div class="content">
    <base-header class="pb-6 bg-default">
      <b-row align-v="center" class="py-4">
        <b-col>
          <h2 class="h2 text-white d-inline-block mb-0">Commandes</h2>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-row v-if="order.id">
        <b-col xl="12">
          <b-card>
            <template #header>
              <b-row>
                <b-col cols="auto">
                  <OrderReceptionBoxWidget v-slot="{ mode, updateMode }">
                    <OrderReceptionBoxForm
                      :on-success="updateReceptionBox"
                      success-message="La commande a bien été transférée à la boîte de réception"
                      :mode="mode"
                      @updateMode="updateMode"
                    />
                  </OrderReceptionBoxWidget>
                  <BaseButton
                    size="sm"
                    type="danger"
                    @click="exportOrderToJSON()"
                  >
                    Récupérer le détail en JSON
                  </BaseButton>
                  <a
                    ref="downloadfile"
                    style="
                      height: 0px;
                      width: 0px;
                      visibility: hidden;
                      display: block;
                    "
                    :href="fileSource"
                    :download="fileName"
                  >
                    Download
                  </a>
                </b-col>

                <b-col class="text-right">
                  <OrderPrintButton
                    container-id="order-print-container"
                    :on-print="togglePrint"
                  >
                    <i class="fas fa-print" />
                    <span>Imprimer la commande</span>
                  </OrderPrintButton>

                  <BaseButton
                    v-if="order.printed"
                    size="sm"
                    type="danger"
                    @click="togglePrint(false)"
                  >
                    Réinitialiser le statut d'impression
                  </BaseButton>
                </b-col>
              </b-row>

              <b-row class="mt-4">
                <b-col cols="auto">
                  <h2 class="mb-3">
                    État de la commande:
                    <span
                      class="badge badge-pill badge-lg"
                      :class="orderStatusColorMap[order.status]"
                    >
                      {{ getStatusName(order.status) }}
                    </span>
                  </h2>

                  <h3 class="mb-0">
                    Date de commande:
                    <span v-if="order.date">
                      {{ order.date | dateFormatDefaultWithTime }}
                    </span>
                    | Courriel: {{ order.email }}
                    | Tél.: {{ order.phoneNumber }}
                  </h3>
                  <h3 class="mb-0">Numéro de commande: {{ order.id }}</h3>
                  <h3 class="mb-0">
                    Numéro de transaction Braintree:
                    {{ order.payment ? order.payment.transactionId : null }}
                  </h3>
                  <h3 class="mb-0 mt-3">
                    Boîte de réception: {{ receptionBoxName }}
                  </h3>
                </b-col>
              </b-row>
            </template>

            <b-list-group>
              <b-list-group-item>
                <b-row>
                  <b-col v-if="order.pickUp" xl="6">
                    <strong>Adresse de cueillette</strong><br />
                    <span>
                      {{ order.pickUp.name }}
                    </span>
                    <br />
                    {{ addressToString(order.shippingAddress) }}
                  </b-col>
                  <b-col v-else xl="6">
                    <strong>Adresse de livraison</strong><br />
                    {{ order.shippingAddress.firstName }}
                    {{ order.shippingAddress.lastName }}<br />
                    {{ addressToString(order.shippingAddress) }}
                  </b-col>
                  <b-col xl="6">
                    <strong>Adresse de facturation</strong><br />
                    {{ order.billingAddress.firstName }}
                    {{ order.billingAddress.lastName }}<br />
                    {{ addressToString(order.billingAddress) }}
                  </b-col>
                </b-row>
              </b-list-group-item>
              <b-list-group-item
                v-if="order.childFirstName && order.childLastName"
              >
                <b-row>
                  <b-col xl="6">
                    <strong>
                      Cette commande contient des items provenant d'une liste
                      scolaire
                    </strong>
                    <br />
                    <p class="mb-0">
                      Identifié au nom de
                      <span class="font-weight-bold">
                        {{ order.childFirstName }}
                        {{ order.childLastName }}
                      </span>
                    </p>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </b-card>

          <b-card>
            <template #header>
              <b-row align-v="center">
                <b-col>
                  <h3 class="h3 mb-0">Résumé de la commande</h3>
                </b-col>
              </b-row>
            </template>

            <div
              v-for="product in getBundleGroupedProducts(products)"
              :key="product.id"
              class="order-product"
            >
              <OrderItem class="order-product-card" :item="product" />

              <template v-if="product.replacements">
                <OrderItem
                  v-for="replacement in product.replacements"
                  :key="replacement.id"
                  class="order-product-card"
                  :item="replacement"
                  :replacement="true"
                />
              </template>
            </div>

            <div v-if="order.payment" class="mt-4">
              <el-card shadow="never">
                <b-row>
                  <b-col>
                    <strong>Mode de paiement</strong>
                  </b-col>

                  <b-col class="text-right">
                    <span class="text-uppercase">
                      {{ order.payment.cardType }}
                    </span>
                    {{ order.payment.maskedNumber }}
                  </b-col>
                </b-row>
              </el-card>
              <el-card shadow="never">
                <b-row>
                  <b-col>
                    <small class="font-weight-bold">Sous-total</small>
                  </b-col>
                  <b-col class="text-right">
                    <small class="font-weight-bold">
                      {{ order.subTotal | currencyFormat }}
                    </small>
                  </b-col>
                </b-row>
                <b-row v-if="order.promoCode">
                  <b-col>
                    <small class="font-weight-bold">
                      {{ order.promoCode }}
                    </small>
                  </b-col>
                  <b-col class="text-right">
                    <small class="font-weight-bold">
                      - {{ order.discountAmount | currencyFormat }}
                    </small>
                  </b-col>
                </b-row>
                <b-row v-if="order.deductedAmountFromLoyaltyPoints">
                  <b-col>
                    <small class="font-weight-bold">
                      Points Z
                    </small>
                  </b-col>
                  <b-col class="text-right">
                    <small class="font-weight-bold">
                      - {{ order.deductedAmountFromLoyaltyPoints | currencyFormat }}
                    </small>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <small class="font-weight-bold">Livraison</small>
                  </b-col>
                  <b-col v-if="order.shippingFees > 0" class="text-right">
                    <small class="font-weight-bold">
                      {{ order.shippingFees | currencyFormat }}
                    </small>
                  </b-col>
                  <b-col v-else class="text-right">
                    <small class="font-weight-bold">
                      {{ '0' | currencyFormat }}
                    </small>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <small class="font-weight-bold">Manutention</small>
                  </b-col>
                  <b-col v-if="order.handlingFees > 0" class="text-right">
                    <small class="font-weight-bold">
                      {{ order.handlingFees | currencyFormat }}
                    </small>
                  </b-col>
                  <b-col v-else class="text-right">
                    <small class="font-weight-bold">
                      {{ '0' | currencyFormat }}
                    </small>
                  </b-col>
                </b-row>
                <b-row v-for="tax in order.taxes" :key="tax.code">
                  <b-col>
                    <small class="font-weight-bold">
                      {{ tax.code }}
                    </small>
                  </b-col>
                  <b-col class="text-right">
                    <small class="font-weight-bold">
                      {{ tax.total | currencyFormat }}
                    </small>
                  </b-col>
                </b-row>
                <b-row v-if="order.refundedAmount > 0">
                  <b-col>
                    <small class="font-weight-bold">Montant remboursé</small>
                  </b-col>
                  <b-col class="text-right">
                    <small class="font-weight-bold">
                      {{ order.refundedAmount | currencyFormat }}
                    </small>
                  </b-col>
                </b-row>
                <b-row v-if="order.refundedShippingFeeAmount > 0">
                  <b-col>
                    <small class="font-weight-bold">
                      Frais de livraison remboursé
                    </small>
                  </b-col>
                  <b-col class="text-right">
                    <small class="font-weight-bold">
                      {{ order.refundedShippingFeeAmount | currencyFormat }}
                    </small>
                  </b-col>
                </b-row>
                <b-row v-if="order.refundedHandlingFeeAmount > 0">
                  <b-col>
                    <small class="font-weight-bold">
                      Frais de manutention remboursé
                    </small>
                  </b-col>
                  <b-col class="text-right">
                    <small class="font-weight-bold">
                      {{ order.refundedHandlingFeeAmount | currencyFormat }}
                    </small>
                  </b-col>
                </b-row>
              </el-card>
              <el-card shadow="never">
                <b-row>
                  <b-col>
                    <strong>Total</strong>
                  </b-col>
                  <b-col class="text-right">
                    <strong>{{ order.total | currencyFormat }}</strong>
                  </b-col>
                </b-row>
              </el-card>
              <el-card shadow="never" v-if="order.totalLoyaltyPoints > 0">
                <b-row>
                  <b-col>
                    <strong>Points Z obtenus</strong>
                  </b-col>
                  <b-col class="text-right">
                    <strong>{{ order.totalLoyaltyPoints }}</strong>
                  </b-col>
                </b-row>
              </el-card>
            </div>

            <div v-if="order.depositAmount" class="mt-4">
              <el-card shadow="never">
                <strong>Paiement d'un dépôt</strong>
              </el-card>
              <el-card shadow="never">
                <b-row>
                  <b-col>Total de la commande</b-col>
                  <b-col class="text-right">
                    {{ order.total | currencyFormat }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>Montant du dépôt</b-col>
                  <b-col class="text-right">
                    {{ order.depositAmount | currencyFormat }}
                  </b-col>
                </b-row>
              </el-card>
              <el-card shadow="never">
                <b-row>
                  <b-col>
                    <strong>Balance à payer</strong>
                  </b-col>
                  <b-col class="text-right">
                    <strong>{{ order.balanceAmount | currencyFormat }}</strong>
                  </b-col>
                </b-row>
              </el-card>
            </div>
          </b-card>

          <b-card v-if="manifests.length" no-body class="mb-4">
            <template #header>
              <b-row align-v="center">
                <b-col>
                  <h3 class="h3 mb-0">
                    <template v-if="order.pickUp">
                      {{
                        manifests.length
                          | pluralize(['Cueillette', 'Cueillettes'])
                      }}
                    </template>
                    <template v-else>
                      {{
                        manifests.length
                          | pluralize(['Expédition', 'Expéditions'])
                      }}
                    </template>
                  </h3>
                </b-col>
              </b-row>
            </template>
            <b-card-body>
              <b-row>
                <b-col>
                  <OrderManifest
                    v-for="(manifest, index) in manifests"
                    :key="manifest.id"
                    :index="index"
                    :manifest="manifest"
                    :type="order.pickUp ? 'PICKUP' : 'DELIVERY'"
                  />
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <OrderPrintTemplate
      v-if="order.id && products.length > 0"
      id="order-print-container"
      :order="order"
      :order-items="products"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import {
  BaseButton,
  BaseHeader,
  RouteBreadcrumb,
} from '@coop-zone/coop-zone-argon'
import OrderPrintTemplate from '@/components/Orders/OrderPrintTemplate'
import OrderPrintButton from '@/components/Orders/OrderPrintButton'
import OrderManifest from '@/components/Orders/OrderManifest'
import OrderItem from '@/components/Orders/OrderItem'
import OrderReceptionBoxForm from '@/components/Orders/OrderReceptionBoxForm'
import OrderReceptionBoxWidget from '@/components/Orders/OrderReceptionBoxWidget'
import ErrorHandlerMixin from '@/mixins/errorHandler.mixin'

export default {
  components: {
    BaseButton,
    BaseHeader,
    RouteBreadcrumb,
    OrderManifest,
    OrderPrintTemplate,
    OrderPrintButton,
    OrderReceptionBoxForm,
    OrderReceptionBoxWidget,
    OrderItem,
  },
  mixins: [ErrorHandlerMixin],
  data() {
    return {
      orderStatusColorMap: {
        VOID: 'badge-danger',
        COMPLETED: 'badge-success',
        PROCESSING: 'badge-info',
        NEW: 'badge-info',
        REFUNDED: 'badge-default',
        INCOMPLETE: 'badge-info',
        AWAITING_PAYMENT: 'badge-info',
      },
      fileSource: null,
      fileName: null,
    }
  },
  computed: {
    ...mapState('orders', ['order', 'manifests', 'processingItems']),
    ...mapGetters('orders', ['getBundleGroupedProducts', 'getStatusName']),
    receptionBoxName() {
      return this.order.receptionBox ? this.order.receptionBox.name : 'N/A'
    },
    products() {
      const allItems = this.order.products
      const processingItems = this.processingItems
      const updates = Object.fromEntries(processingItems.map(o => [o.id, o]))
      return allItems.map(o => updates[o.id] || o)
    },
  },
  async created() {
    await this.getOrder(this.$route.params.id)
  },
  methods: {
    ...mapActions('orders', ['getOrder', 'printOrders']),
    addressToString(address) {
      return `${address.address}, ${
        address.address2 ? address.address2 + ', ' : ''
      }${address.city} (${address.state}), ${address.country}, ${
        address.postalCode
      }`
    },
    async togglePrint(status = true) {
      try {
        await this.printOrders({ ids: [this.$route.params.id], status })
        await this.getOrder(this.$route.params.id)
      } catch (error) {
        this.handleError(error)
        throw error
      }
    },
    async exportOrderToJSON() {
      try {
        const { data } = await this.$store.dispatch('orders/orderJSON', {
          id: this.$route.params.id,
        })
        this.fileSource =
          'data:text/json;charset=utf-8,' +
          encodeURIComponent(JSON.stringify(data.orderJSON.order, null, 4))

        this.fileName = `order_${data.orderJSON.order.id}.json`
        this.$nextTick(() => {
          this.$refs.downloadfile.click()
        })
      } catch (error) {
        this.handleError(error)
        throw error
      }
    },
    async updateReceptionBox(receptionBoxId) {
      await this.$store.dispatch('orders/updateReceptionBox', {
        ids: [this.$route.params.id],
        receptionBoxId,
      })
      await this.getOrder(this.$route.params.id)
    },
  },
}
</script>
