<template>
  <BaseForm id="taxForm" success-message="Taxe enregistrée" :on-success="save">
    <b-card>
      <div slot="header">
        <b-row align-v="center">
          <b-col sm="12" lg="6">
            <h6 class="text-uppercase text-muted ls-1 mb-1">
              {{ title }}
            </h6>
            <h2 class="mb-0">
              {{ name }}
            </h2>
          </b-col>
          <b-col sm="12" lg="6" class="text-right">
            <slot name="actions" />
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col>
          <BaseInput
            v-model="name"
            label="Nom"
            rules="required"
            data-cy="input-tax-name"
          />
        </b-col>
        <b-col>
          <BaseInput
            v-model="code"
            label="Code"
            rules="required"
            data-cy="input-tax-code"
          />
        </b-col>
        <b-col>
          <BaseInput
            v-model="percentage"
            label="Valeur"
            rules="required"
            data-cy="input-tax-percentage"
          />
        </b-col>
      </b-row>
      <b-row align-h="end">
        <b-col cols="auto">
          <BaseButton size="lg" type="primary" native-type="submit">
            Sauvegarder
          </BaseButton>
        </b-col>
      </b-row>
    </b-card>
  </BaseForm>
</template>
<script>
import { BaseButton, BaseInput } from '@coop-zone/coop-zone-argon'
import BaseForm from '@/components/forms/BaseForm'

export default {
  components: {
    BaseForm,
    BaseButton,
    BaseInput,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    name: {
      get() {
        return this.$store.state.taxes.name
      },
      set(name) {
        this.$store.commit('taxes/SET_NAME', name)
      },
    },
    code: {
      get() {
        return this.$store.state.taxes.code
      },
      set(code) {
        this.$store.commit('taxes/SET_CODE', code)
      },
    },
    percentage: {
      get() {
        return this.$store.state.taxes.percentage
      },
      set(percentage) {
        this.$store.commit('taxes/SET_PERCENTAGE', percentage)
      },
    },
  },
  created() {
    this.$store.commit('taxes/SET_TAXE', {})
    if (this.$route.params.id) {
      this.$store.dispatch('taxes/getTaxe', this.$route.params.id)
    }
  },
  methods: {
    async save() {
      if (this.$route.params.id) {
        await this.$store.dispatch('taxes/updateTaxe')
      } else {
        await this.$store.dispatch('taxes/createTaxe')
        this.$router.push('/taxes_rules/taxes/index')
      }
    },
  },
}
</script>
