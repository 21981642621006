<template>
  <card :no-body="nobody">
    <b-row slot="header" align-v="center">
      <b-col cols="8">
        <h2 class="h3 mb-0">
          {{ title }}
        </h2>
      </b-col>
      <b-col cols="4" class="text-right">
        <base-switch v-model="active" on-text="" off-text="" class="mr-1" />
      </b-col>
    </b-row>
    <slot v-if="active" />
  </card>
</template>
<script>
import { Card, BaseSwitch } from '@coop-zone/coop-zone-argon'
export default {
  components: { Card, BaseSwitch },
  props: {
    title: {
      type: String,
      default: '',
    },
    nobody: {
      type: Boolean,
      default: false,
    },
    blockIndex: {
      type: Number,
      required: true,
    },
  },
  computed: {
    active: {
      get() {
        return this.$store.state.blocks.blocks[this.blockIndex].active
      },
      set(active) {
        this.$store.commit('blocks/SET_ACTIVE', {
          index: this.blockIndex,
          active,
        })
      },
    },
  },
}
</script>
