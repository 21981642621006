import { apolloClient } from '@/apollo'

const getHandlingFeeForProductType = require('../apollo/queries/GetHandlingFee.gql')
const getProductQuery = require('../apollo/queries/GetProductBySlug.gql')
const getBundleQuery = require('../apollo/queries/GetBundleBySlug.gql')
const searchProductQuery = require('../apollo/queries/GetProductsSearch.gql')
const getFiltersQuery = require('../apollo/queries/GetCategoryFilters.gql')
const updateProductMutation = require('../apollo/mutations/UpdateProduct.gql')
const updateBundleMutation = require('../apollo/mutations/UpdateBundle.gql')
const createProductMutation = require('../apollo/mutations/CreateProduct.gql')
const createBundleMutation = require('../apollo/mutations/CreateBundle.gql')
const deleteProductMutation = require('../apollo/mutations/DeleteProduct.gql')
const autocompleteQuery = require('../apollo/queries/GetAutocompleteProducts.gql')

const APIMetasFiltersFormat = (filters) =>
  filters.map((group) => {
    const object = {}
    if (group.type === 'FILTER') object.id = group.id
    if (group.type === 'META') object.name = group.name

    object.type = group.type
    object.content = []
    group.content.forEach((filter) => {
      if (filter.type === 'FILTER' && filter.FilterValue !== null) {
        object.content.push({
          filterId: filter.id,
          filterValueId: filter.FilterValue.id,
          type: filter.type,
        })
      } else if (filter.type === 'META') {
        object.content.push({
          name: filter.name,
          value: filter.value,
          type: filter.type,
        })
      }
    })
    return object
  })

const state = {
  pagination: {
    currentPage: 1,
    numberOfPages: 0,
    resultsPerPage: 50,
    totalCount: 0,
  },
  productTypes: [
    { id: 'Book', name: 'Livre' },
    { id: 'DigitalBook', name: 'Livre numérique', digital: true },
    { id: 'Computer', name: 'Informatique' },
    { id: 'Art', name: 'Arts' },
    { id: 'OfficeSupply', name: 'Papeterie' },
    { id: 'CourseNote', name: 'Notes de cours' },
    { id: 'EcoFee', name: 'Écofrais' },
    { id: 'DigitalWatermark', name: 'Document avec filigrane', digital: true },
    {
      id: 'DigitalCoupon',
      name: 'Coupon (Notes de cours Limoilou)',
      digital: true,
    },
    { id: 'DigitalAccessCode', name: 'Code d’accès', digital: true },
    { id: 'DigitalOther', name: 'Autre produit numérique', digital: true },
  ],
  automaticHandlingFees: [],
  manualHandlingFee: null,
  products: [],
  product: null,
  currentProductId: null,
  currentBundleId: null,
}

const actions = {
  resetProduct({ commit }) {
    commit('SET_PRODUCT', null)
  },

  async getProduct({ dispatch, commit }, payload) {
    const {
      data: { product },
    } = await apolloClient.query({
      query: getProductQuery,
      variables: payload,
    })

    commit('SET_PRODUCT', product)

    if (product.type === 'Bundle') return

    await dispatch('getProductHandlingFee')
  },

  async getBundle({ commit }, payload) {
    const {
      data: { product },
    } = await apolloClient.query({
      query: getBundleQuery,
      variables: payload,
    })

    commit('SET_PRODUCT', product)
    return product
  },

  async updateProduct({ dispatch, commit }, payload) {
    const { product } = payload

    delete product.bundles
    delete product.suggestedIn
    delete product.automaticHandlingFees

    const {
      data: { updatedProduct },
    } = await apolloClient.query({
      query: updateProductMutation,
      variables: {
        slug: payload.slug,
        product: {
          ...product,
          suggestions: product.suggestions.map(({ id }) => id),
          filters: APIMetasFiltersFormat(product.filters),
        },
      },
    })

    commit('SET_PRODUCT', updatedProduct)
    await dispatch('getProductHandlingFee')
  },

  async updateBundle({ commit }, payload) {
    const { product } = payload
    const data = { ...product }
    delete data.suggestedIn

    // Product with Type 'bundle' will have related products
    data.products = product.products.map((p) => p.id)
    data.suggestions = product.suggestions.map(({ id }) => id)
    const {
      data: { updatedProduct },
    } = await apolloClient.query({
      query: updateBundleMutation,
      variables: {
        slug: payload.slug,
        product: data,
      },
    })

    commit('SET_PRODUCT', updatedProduct)
  },

  async createProduct({ commit }, payload) {
    const { product } = payload

    product.suggestions = product.suggestions.map(({ id }) => id)

    const {
      data: { createdProduct },
    } = await apolloClient.query({
      query: createProductMutation,
      variables: {
        slug: payload.slug,
        product: {
          ...product,
          filters: APIMetasFiltersFormat(product.filters),
        },
      },
    })

    commit('SET_PRODUCT', createdProduct)
    commit('SET_CURRENT_PRODUCT', createdProduct.id)
  },

  async createBundle({ commit }, payload) {
    const { product } = payload
    const data = { ...product }

    // Product with Type 'bundle' will have related products
    data.products = product.products.map((p) => p.id)
    data.suggestions = product.suggestions.map(({ id }) => id)

    const {
      data: { createdBundle },
    } = await apolloClient.query({
      query: createBundleMutation,
      variables: {
        slug: payload.slug,
        product: data,
      },
    })

    commit('SET_PRODUCT', createdBundle)
    commit('SET_CURRENT_PRODUCT', createdBundle.id)
  },

  async deleteBundle({ commit }, { id }) {
    await apolloClient.query({
      query: deleteProductMutation,
      variables: {
        id,
      },
    })
    commit('DELETE_PRODUCT', id)
  },
  async deleteProduct({ commit }, { id }) {
    await apolloClient.query({
      query: deleteProductMutation,
      variables: {
        id,
      },
    })
    commit('DELETE_PRODUCT', id)
  },

  async searchProducts({ commit }, payload) {
    const {
      data: {
        products: { products, pageInfo: pagination },
      },
    } = await apolloClient.query({
      query: searchProductQuery,
      variables: payload,
    })
    commit('SET_PAGINATION', pagination)
    commit('SET_SEARCH_PRODUCTS', products)
  },

  async getProductFilters(context, payload) {
    const { productId, categories } = payload

    const {
      data: { filters },
    } = await apolloClient.query({
      query: getFiltersQuery,
      variables: {
        productId: parseInt(productId, 10),
        categories,
      },
    })

    return filters
  },

  async autocomplete(context, payload) {
    const {
      data: { result },
    } = await apolloClient.query({
      query: autocompleteQuery,
      variables: payload,
    })
    return result
  },

  async getProductHandlingFeeForType({ state }) {
    if (state.product && state.product.type) {
      const type = state.product.type
      const {
        data: {
          handlingFee: { price },
        },
      } = await apolloClient.query({
        query: getHandlingFeeForProductType,
        variables: { type },
      })
      return price
    } else {
      return null
    }
  },

  async getProductHandlingFee({ state, dispatch, commit }) {
    const { product } = state
    if (
      product &&
      product.automaticHandlingFees &&
      product.automaticHandlingFees.length > 0
    ) {
      commit(
        'SET_AUTOMATIC_HANDLING_FEES',
        await Promise.all(
          product.automaticHandlingFees.map(async (fee) => {
            const price = await dispatch('getProductHandlingFeeForType')
            return { ...fee, price }
          })
        )
      )
      commit('SET_MANUAL_HANDLING_FEE', null)
    } else {
      const price = await dispatch('getProductHandlingFeeForType')
      commit('SET_MANUAL_HANDLING_FEE', price)
      commit('SET_AUTOMATIC_HANDLING_FEES', [])
    }
  },
}

const mutations = {
  SET_CURRENT_PRODUCT(state, productId) {
    state.currentProductId = productId
  },
  SET_CURRENT_BUNDLE(state, bundleId) {
    state.currentBundleId = bundleId
  },
  SET_SEARCH_PRODUCTS(state, products) {
    state.products = products
  },
  SET_PRODUCT(state, payload) {
    state.product = payload
  },
  SET_PAGINATION(state, payload) {
    state.pagination = payload
  },
  SET_AUTOMATIC_HANDLING_FEES(state, payload) {
    state.automaticHandlingFees = payload
  },
  DELETE_PRODUCT(state, idProduct) {
    const product = state.products.filter((p) => p.id === idProduct)
    if (!product && !product.length) return
    state.products.splice(state.products.indexOf(product[0]), 1)
  },
  SET_MANUAL_HANDLING_FEE(state, payload) {
    state.manualHandlingFee = payload
  },
}

const getters = {
  getProductTypeName: (state) => (type) =>
    state.productTypes.find((t) => t.id === type).name,
  getProduct: (state) => {
    const { product } = state
    const specialPrices = {
      regular: null,
      member: null,
      employee: null,
      institute: null,
      business: null,
      library: null,
      emerging_artist: null,
      professional_artist: null,
      cjms: null,
      special_1: null,
      special_2: null,
      special_3: null,
      special_4: null,
    }
    const vigieCategories = {
      level1: '',
      level2: '',
      level3: '',
      level4: '',
      level5: '',
      level6: '',
      level7: '',
      level8: '',
      level9: '',
      level10: '',
    }
    if (product) {
      product.authors = product.authors || []
      product.productCollection = product.productCollection || []
      product.categories = product.categories.map((category) => category.slug)
      product.description = product.description ?? ''
      product.specialPrices = product.specialPrices || specialPrices
      product.vigieCategories = product.vigieCategories || vigieCategories
      product.images = product.images == null ? [] : product.images
    }

    return product
  },
  getBundle: (state) => {
    const { product } = state
    if (product) {
      product.categories = product.categories.map((category) => category.slug)
      product.description = product.description ?? ''
      product.images = product.images == null ? [] : product.images
    }

    return product
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
