<template>
  <div>
    <BaseHeader class="pb-6" type="default">
      <b-row align-v="center" class="py-4">
        <b-col lg="12" cols="7">
          <h1 class="h2 text-white d-inline-block mb-0">Modifier une zone</h1>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </BaseHeader>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="12">
          <ZonesForm title="Modifier la zone" />
        </b-col>
        <b-col sm="12" xl="6">
          <b-card>
            <FuseList
              :data="countries || []"
              :keys="['name', 'code']"
              title="Pays inclus dans la zone"
            >
              <el-table-column label="Pays" min-width="160px">
                <template slot-scope="scope">
                  <router-link
                    :to="`/localisation/countries/edit/${scope.row.id}`"
                  >
                    {{ scope.row.name }}
                  </router-link>
                </template>
              </el-table-column>
              <el-table-column label="Code ISO" min-width="160px">
                <template slot-scope="scope">
                  <router-link
                    :to="`/localisation/countries/edit/${scope.row.id}`"
                  >
                    {{ scope.row.code }}
                  </router-link>
                </template>
              </el-table-column>
            </FuseList>
          </b-card>
        </b-col>
        <b-col sm="12" xl="6">
          <b-card>
            <FuseList
              :data="states || []"
              :keys="['name', 'code']"
              title="Provinces inclues dans la zone"
            >
              <el-table-column label="Province" min-width="160px">
                <template slot-scope="scope">
                  <router-link
                    :to="`/localisation/states/edit/${scope.row.id}`"
                  >
                    {{ scope.row.name }}
                  </router-link>
                </template>
              </el-table-column>
              <el-table-column label="Code ISO" min-width="160px">
                <template slot-scope="scope">
                  <router-link
                    :to="`/localisation/states/edit/${scope.row.id}`"
                  >
                    {{ scope.row.code }}
                  </router-link>
                </template>
              </el-table-column>
            </FuseList>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { RouteBreadcrumb, BaseHeader } from '@coop-zone/coop-zone-argon'
import { mapState } from 'vuex'
import ZonesForm from '@/components/Zones/ZonesForm'
import FuseList from '@/components/FuseList'

export default {
  components: {
    FuseList,
    ZonesForm,
    RouteBreadcrumb,
    BaseHeader,
  },
  data() {
    return {
      data: {},
    }
  },
  computed: {
    ...mapState('zones', ['countries', 'states']),
  },
}
</script>
