<template>
  <li class="category-item" :class="[isFolder ? 'folder' : 'file']">
    <label>
      <div v-if="item.hierarchyLevel > 2" class="category-item__sort">
        <span class="category-item__icon">
          <i class="fas fa-grip-lines category-item__handle" />
        </span>
      </div>
      <div v-if="isFolder" class="category-item__toggle" @click="toggle">
        <span class="category-item__icon">
          <i v-if="isOpen" class="fas fa-minus-square" />
          <i v-else class="fas fa-plus-square" />
        </span>

        {{ item.name }}
      </div>

      <span v-else>
        {{ item.name }}
      </span>

      <div class="category-item__actions">
        <router-link
          class="table-action table-action-edit"
          :to="`/categories/edit/${item.id}`"
        >
          <i class="fa fa-edit" />
        </router-link>

        <b-link
          v-confirm-delete="{
            onConfirm: () => onDelete(item),
            confirmText:
              'La catégorie sera supprimée. Cette action supprimera les catégories enfants ainsi que les liens avec les filtres et les règles Vigie de toutes les catégories ainsi supprimées.',
            successText: 'La catégorie a été supprimée',
          }"
          class="table-action"
        >
          <i v-if="item.hierarchyLevel > 2" class="fa fa-trash" />
        </b-link>
      </div>
    </label>

    <ul v-if="isFolder && isOpen">
      <draggable v-model="sortableChildrens" handle=".category-item__handle">
        <CategoryTreeItem
          v-for="child in sortableChildrens"
          :key="child.id"
          :item="child"
          :on-delete="onDelete"
          :on-sort="onSort"
        />
      </draggable>
    </ul>
  </li>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'CategoryTreeItem',
  components: { draggable },
  props: {
    item: {
      type: Object,
      required: true,
    },
    root: {
      type: Boolean,
      default: false,
    },
    onDelete: {
      type: Function,
      required: true,
    },
    onSort: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      childrens: this.item.children,
    }
  },
  computed: {
    isFolder() {
      return this.childrens && this.childrens.length
    },
    sortableChildrens: {
      get() {
        return this.childrens
      },
      set(items) {
        this.childrens = items
        this.onSort(items)
      },
    },
  },
  methods: {
    toggle() {
      if (this.isFolder) {
        this.isOpen = !this.isOpen
      }
    },
  },
}
</script>
