<template>
  <el-transfer
    :value="value"
    :data="data"
    :titles="titles"
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    titles: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="sass">
.el-transfer
  display: flex
  flex-flow: column wrap
  align-items: center
  justify-content: flex-start

  @media (min-width: 992px)
    flex-flow: row wrap


.el-transfer-panel
  min-width: 200px
  flex: auto
  width: 100%

  @media (min-width: 992px)
    width: auto

.el-transfer-panel .el-transfer-panel__header .el-checkbox .el-checkbox__label
  font-size: 14px

.el-transfer__buttons
  margin: 10px 0

  @media (min-width: 992px)
    margin: 0


.el-transfer__button:first-child
  margin: 0

  @media (min-width: 992px)
    margin-bottom: 10px
</style>
