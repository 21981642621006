import ReceptionBoxesIndex from '@/views/Dashboard/ReceptionBoxes/ReceptionBoxesIndex'
import ReceptionBoxesAdd from '@/views/Dashboard/ReceptionBoxes/ReceptionBoxesAdd'
import ReceptionBoxesEdit from '@/views/Dashboard/ReceptionBoxes/ReceptionBoxesEdit'
import BlankRouterView from '@/views/Layout/BlankRouterView'
export default {
  path: '/reception-boxes',
  name: 'Boîtes de réception',
  redirect: '/reception-boxes/index',
  component: BlankRouterView,
  children: [
    {
      path: '/reception-boxes/index',
      name: 'Liste des boîtes de réception',
      component: ReceptionBoxesIndex,
      meta: {
        roles: ['ADMIN', 'MANAGER'],
      },
    },
    {
      path: '/reception-boxes/add',
      name: 'Ajouter une boîte de réception',
      component: ReceptionBoxesAdd,
      meta: {
        roles: ['ADMIN', 'MANAGER'],
      },
    },
    {
      path: '/reception-boxes/edit/:id',
      name: 'Modifier une boîte de réception',
      component: ReceptionBoxesEdit,
      meta: {
        roles: ['ADMIN', 'MANAGER'],
      },
    },
  ],
}
