import { apolloClient } from '@/apollo'

import rules from './rules.module'

const getGroupsQuery = require('../../apollo/queries/GetTaxGroups.gql')
const getGroupQuery = require('../../apollo/queries/GetTaxGroup.gql')
const createGroupMutation = require('../../apollo/mutations/CreateTaxGroup.gql')
const updateGroupMutation = require('../../apollo/mutations/UpdateTaxGroup.gql')
const deleteGroupMutation = require('../../apollo/mutations/DeleteTaxGroup.gql')

const state = {
  name: '',
  groups: [],
}

const actions = {
  async getGroups({ commit }) {
    const {
      data: { taxGroups },
    } = await apolloClient.query({
      query: getGroupsQuery,
    })
    commit('SET_GROUPS', taxGroups)
  },
  async getGroup({ commit }, id) {
    const {
      data: { taxGroup },
    } = await apolloClient.query({
      query: getGroupQuery,
      variables: { id },
    })

    commit('SET_GROUP', taxGroup)
    commit('rules/SET_TAX_RULES', taxGroup)
  },
  async createGroup({ commit, state: { name } }) {
    const {
      data: { taxGroup },
    } = await apolloClient.query({
      query: createGroupMutation,
      variables: {
        taxGroup: {
          name,
        },
      },
    })
    commit('SET_GROUP', taxGroup)
  },
  updateGroup({ state: { id, name } }) {
    apolloClient.query({
      query: updateGroupMutation,
      variables: {
        id,
        taxGroup: {
          name,
        },
      },
    })
  },
  deleteGroup(context, id) {
    apolloClient.query({
      query: deleteGroupMutation,
      variables: { id },
    })
  },
}

const mutations = {
  SET_GROUPS(state, payload) {
    state.groups = payload
  },
  SET_GROUP(state, { id = null, name = '' }) {
    state.id = id
    state.name = name
  },
  SET_NAME(state, name) {
    state.name = name
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    rules,
  },
}
