<template>
  <b-card v-if="category" v-loading="loading" no-body>
    <div slot="header">
      <b-row>
        <b-col sm="12" lg="6">
          <p class="h3">Ajouter les articles pour ce sous-programme</p>
        </b-col>
      </b-row>
    </div>
    <b-row class="p-4">
      <b-col sm="7">
        <BaseCategoryForm :category="category" @saving="loading = $event" />
      </b-col>
      <b-col sm="5" class="border-left">
        <ItemsList class="p-4" :category="category" />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseCategoryForm from '@/components/SchoolLists/Categories/BaseCategoryForm'
import ItemsList from '@/components/SchoolLists/Items/ItemsList'

export default {
  components: {
    BaseCategoryForm,
    ItemsList,
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters('schoolLists/categories', { category: 'getFirstCategory' }),
  },
}
</script>
