<template>
  <BaseForm
    v-if="!isLoading"
    id="receptionBoxForm"
    success-message="Boîte de réception enregistrée"
    :on-success="onSuccess"
    :redirect="redirect"
  >
    <b-card>
      <div slot="header">
        <b-row align-v="center">
          <b-col sm="12" lg="6">
            <h6 class="text-uppercase text-muted ls-1 mb-1">
              {{ title }}
            </h6>
          </b-col>
          <b-col sm="12" lg="6" class="text-right">
            <slot name="actions" />
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col sm="6" lg="4">
          <BaseInput
            v-model="name"
            label="Nom de la boîte de réception"
            type="text"
            rules="required"
          />
        </b-col>
        <b-col sm="6" lg="4">
          <BaseInput label="Succursale" rules="required">
            <StoreSelect v-model="StoreId">
              <el-option :value="null" />
            </StoreSelect>
          </BaseInput>
        </b-col>
      </b-row>
      <b-row v-if="key === null">
        <b-col sm="6" lg="4">
          <BaseInput
            label="Établissements scolaires"
            class="input-group-lg input-group-flush"
          >
            <el-select v-model="schoolEstablishments" filterable multiple>
              <el-option-group
                v-for="(establishements, id) in schoolEstablishmentByTypes"
                :key="id"
                :label="$t(`establishments.types.${id}`)"
              >
                <el-option
                  v-for="establishement in schoolEstablishmentByTypes[id]"
                  :key="establishement.id"
                  :label="establishement.name"
                  :value="establishement.id"
                >
                </el-option>
              </el-option-group>
            </el-select>
          </BaseInput>
        </b-col>
      </b-row>
      <b-row v-if="key === null">
        <b-col class="mt-4 mt-md-0" md="2">
          <BaseInput
            v-model="priority"
            label="Priorité"
            type="number"
            class="input-group-lg input-group-flush"
          />
        </b-col>
      </b-row>
      <b-row align-h="end">
        <b-col cols="auto">
          <BaseButton size="lg" type="primary" native-type="submit">
            Sauvegarder
          </BaseButton>
        </b-col>
      </b-row>
    </b-card>
  </BaseForm>
</template>
<script>
import { BaseButton, BaseInput } from '@coop-zone/coop-zone-argon'
import BaseForm from '@/components/forms/BaseForm'
import StoreSelect from '@/components/Inputs/StoreSelect'
import { mapGetters, mapState } from 'vuex'

export default {
  components: {
    BaseForm,
    BaseButton,
    BaseInput,
    StoreSelect,
  },
  props: {
    onSuccess: {
      type: Function,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    redirect: {
      type: [String, Boolean],
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapState('schoolEstablishments', ['schoolEstablishments']),
    ...mapGetters('schoolEstablishments', ['schoolEstablishmentByTypes']),
    schoolEstablishmentTypes() {
      return Object.keys(this.schoolEstablishmentByTypes)
    },
    priority: {
      get() {
        return this.$store.state.receptionBoxes.priority
      },
      set(priority) {
        this.$store.commit('receptionBoxes/SET_PRIORITY', priority)
      },
    },
    name: {
      get() {
        return this.$store.state.receptionBoxes.name
      },
      set(name) {
        this.$store.commit('receptionBoxes/SET_NAME', name)
      },
    },
    schoolEstablishments: {
      get() {
        return this.$store.state.receptionBoxes.schoolEstablishments
      },
      set(options) {
        this.$store.commit('receptionBoxes/SET_ESTABLISHEMENTS', options)
      },
    },
    key: {
      get() {
        return this.$store.state.receptionBoxes.key
      },
    },
    StoreId: {
      get() {
        return this.$store.state.receptionBoxes.StoreId
      },
      set(id) {
        this.$store.commit('receptionBoxes/SET_STORE_ID', id)
      },
    },
  },
  async created() {
    this.isLoading = true
    await this.$store.dispatch('schoolEstablishments/getSchoolEstablishments', [
      'ELEMENTARY',
      'HIGHSCHOOL',
      'COLLEGE',
      'UNIVERSITY',
      'PROGRAM',
    ])
    this.isLoading = false
  },
  beforeDestroy() {
    this.$store.commit('receptionBoxes/SET_PRIORITY', '')
    this.$store.commit('receptionBoxes/SET_NAME', '')
    this.$store.commit('receptionBoxes/SET_ESTABLISHEMENTS', [])
  },
}
</script>
