<template>
  <div
    v-if="product.images && product.images.length > 0"
    class="avatar mr-3 flex-shrink-0"
  >
    <img :src="src" />
  </div>
</template>
<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    src() {
      return this.product.images[0].thumbnail
    },
  },
}
</script>
<style lang="sass" scoped>
.avatar
  background-color: var(--lighter)
  height: 44px
  position: relative
  width: 44px

.avatar img
  max-height: 100%
  max-width: 100%
  object-fit: contain
  position: relative
</style>
