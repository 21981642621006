<template>
  <div>
    <BaseHeader class="pb-6" type="default">
      <b-row align-v="center" class="py-4">
        <b-col lg="12" cols="7">
          <h1 class="h2 text-white d-inline-block mb-0">Modifier un pays</h1>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </BaseHeader>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="12">
          <CountryForm title="Modifier le pays" />
        </b-col>
        <b-col xl="12">
          <b-card>
            <FuseList
              :data="states"
              :keys="['name', 'code']"
              title="États associés"
            >
              <el-table-column label="Nom" min-width="160px">
                <template slot-scope="scope">
                  <router-link
                    :to="`/localisation/states/edit/${scope.row.id}`"
                  >
                    {{ scope.row.name }}
                  </router-link>
                </template>
              </el-table-column>
              <el-table-column label="Code ISO" min-width="160px">
                <template slot-scope="scope">
                  <router-link
                    :to="`/localisation/states/edit/${scope.row.id}`"
                  >
                    {{ scope.row.code }}
                  </router-link>
                </template>
              </el-table-column>
            </FuseList>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { RouteBreadcrumb, BaseHeader } from '@coop-zone/coop-zone-argon'
import CountryForm from '@/components/Countries/CountryForm'
import FuseList from '@/components/FuseList'
import { mapState } from 'vuex'

export default {
  components: {
    FuseList,
    CountryForm,
    RouteBreadcrumb,
    BaseHeader,
  },
  computed: {
    ...mapState('countries', ['states']),
  },
}
</script>
