<i18n>
{
  "fr" : {
    "DeleteSuccess": "La succursale a bien été supprimée"
  }
}
</i18n>

<template>
  <div>
    <BaseHeader class="pb-6" type="default">
      <b-row align-v="center" class="py-4">
        <b-col>
          <p class="h2 text-white d-inline-block mb-0">Succursales</p>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </BaseHeader>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="12">
          <StoreForm
            :on-success="save"
            title="Modifier une succursale"
            redirect="/establishments/stores/index"
          >
            <BaseButton
              slot="actions"
              v-confirm-delete="{
                onConfirm: () => deleteStore(),
                confirmText:
                  'La succursale sera supprimée définitivement. L\'inventaire sera également supprimé et ne sera pas récupérable.',
                successText: $t('DeleteSuccess'),
              }"
              type="danger"
              icon
              size="sm"
            >
              <span class="btn-inner--icon"
                ><i class="fas fa-minus-circle"
              /></span>
              <span class="btn-inner--text">Supprimer la succursale</span>
            </BaseButton>
          </StoreForm>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  RouteBreadcrumb,
  BaseHeader,
  BaseButton,
} from '@coop-zone/coop-zone-argon'
import StoreForm from '@/components/Stores/StoreForm'

export default {
  components: {
    StoreForm,
    RouteBreadcrumb,
    BaseButton,
    BaseHeader,
  },
  created() {
    this.$store.dispatch('stores/getStore', this.$route.params.id)
  },
  methods: {
    async deleteStore() {
      await this.$store.dispatch('stores/deleteStore', this.$route.params.id)
      this.$notify({
        message: this.$t('DeleteSuccess'),
        timeout: 5000,
        icon: 'ni ni-check-bold',
        type: 'success',
      })
      this.$router.push('/establishments/stores/index')
    },
    async save() {
      await this.$store.dispatch('stores/updateStore')
    },
  },
}
</script>
