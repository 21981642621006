<template>
  <BaseForm id="deliveryRuleForm" :on-success="onSuccess" :redirect="redirect">
    <b-card>
      <div slot="header">
        <b-row align-v="center">
          <b-col sm="12" lg="6">
            <h6 class="text-uppercase text-muted ls-1 mb-1">
              {{ title }}
            </h6>
          </b-col>
          <b-col sm="12" lg="6" class="text-right">
            <slot name="actions" />
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col>
          <BaseInput v-model="max" label="Max" type="number" rules="required" />
        </b-col>
        <b-col>
          <CurrencyInput
            v-model="basePrice"
            label="Prix de base"
            rules="required"
          />
        </b-col>
        <b-col>
          <CurrencyInput
            v-model="additionalPrice"
            label="Prix additionel"
            rules="required"
          />
        </b-col>
        <b-col>
          <CurrencyInput v-model="freeAfter" label="Gratuit après" />
        </b-col>
        <b-col>
          <BaseInput label="Zone">
            <select v-model="ZoneId" class="form-control">
              <option />
              <option
                v-for="zone in zones"
                :key="zone.id"
                :value="zone.id"
                :selected="ZoneId && zone.id === ZoneId"
              >
                {{ zone.name }}
              </option>
            </select>
          </BaseInput>
        </b-col>
        <b-col>
          <BaseInput label="Groupe de taxe">
            <select v-model="TaxRuleGroupId" class="form-control">
              <option />
              <option
                v-for="group in groups"
                :key="group.id"
                :value="group.id"
                :selected="TaxRuleGroupId && group.id === TaxRuleGroupId"
              >
                {{ group.name }}
              </option>
            </select>
          </BaseInput>
        </b-col>
      </b-row>
      <b-row align-h="end">
        <b-col cols="auto">
          <BaseButton size="lg" type="primary" native-type="submit">
            Sauvegarder
          </BaseButton>
        </b-col>
      </b-row>
    </b-card>
  </BaseForm>
</template>
<script>
import { mapState } from 'vuex'
import { BaseButton, BaseInput } from '@coop-zone/coop-zone-argon'
import BaseForm from '@/components/forms/BaseForm'
import CurrencyInput from '@/components/Inputs/CurrencyInput'

export default {
  components: {
    BaseForm,
    BaseButton,
    BaseInput,
    CurrencyInput,
  },
  props: {
    onSuccess: {
      type: Function,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    redirect: {
      type: [String, Boolean],
      default: false,
    },
  },
  computed: {
    ...mapState('zones', ['zones']),
    ...mapState('taxes/groups', ['groups']),
    max: {
      get() {
        return this.$store.state.shipping.rules.max
      },
      set(max) {
        this.$store.commit('shipping/rules/SET_MAX', max)
      },
    },
    basePrice: {
      get() {
        return this.$store.state.shipping.rules.basePrice
      },
      set(basePrice) {
        this.$store.commit('shipping/rules/SET_BASE_PRICE', basePrice)
      },
    },
    additionalPrice: {
      get() {
        return this.$store.state.shipping.rules.additionalPrice
      },
      set(additionalPrice) {
        this.$store.commit(
          'shipping/rules/SET_ADDITIONAL_PRICE',
          additionalPrice
        )
      },
    },
    freeAfter: {
      get() {
        return this.$store.state.shipping.rules.freeAfter
      },
      set(freeAfter) {
        this.$store.commit('shipping/rules/SET_FREE_AFTER', freeAfter)
      },
    },
    ZoneId: {
      get() {
        return this.$store.state.shipping.rules.ZoneId
      },
      set(zoneId) {
        this.$store.commit('shipping/rules/SET_ZONE_ID', zoneId)
      },
    },
    TaxRuleGroupId: {
      get() {
        return this.$store.state.shipping.rules.TaxRuleGroupId
      },
      set(groupId) {
        this.$store.commit('shipping/rules/SET_TAX_RULE_GROUP_ID', groupId)
      },
    },
  },
  created() {
    this.$store.dispatch('zones/getAllZones')
    this.$store.dispatch('taxes/groups/getGroups')
  },
}
</script>
