import Vue from 'vue'
import { apolloClient } from '@/apollo'
import { uniqueId } from 'lodash'

const createTaxRuleMutation = require('../../apollo/mutations/CreateTaxRule.gql')
const updateTaxeRuleMutation = require('../../apollo/mutations/UpdateTaxRule.gql')
const deleteTaxRuleMutation = require('../../apollo/mutations/DeleteTaxRule.gql')

const state = {
  taxRules: [],
}

const actions = {
  async createRule(
    {
      commit,
      rootState: {
        taxes: {
          groups: { id: TaxRuleGroupId },
        },
      },
    },
    { TaxId = null, CountryId = null, StateId = null, newId = null }
  ) {
    const {
      data: { taxRule },
    } = await apolloClient.query({
      query: createTaxRuleMutation,
      variables: {
        taxRule: {
          TaxId,
          CountryId,
          StateId,
          TaxRuleGroupId,
        },
      },
    })
    commit('ADD_TAX_RULE', { taxRule, newId })
  },

  async updateRule(
    {
      commit,
      rootState: {
        taxes: {
          groups: { id: TaxRuleGroupId },
        },
      },
    },
    { id, TaxId = null, CountryId = null, StateId = null }
  ) {
    const {
      data: { taxRule },
    } = await apolloClient.query({
      query: updateTaxeRuleMutation,
      variables: {
        id,
        taxRule: {
          TaxId,
          CountryId,
          StateId,
          TaxRuleGroupId,
        },
      },
    })
    commit('UPDATE_RULE', taxRule)
  },

  async deleteRule({ commit }, { id }) {
    await apolloClient.query({
      query: deleteTaxRuleMutation,
      variables: { id },
    })
    commit('DELETE_RULE', id)
  },
}

const mutations = {
  SET_TAX_RULES(state, { taxRules = [] }) {
    state.taxRules = taxRules.map((rule) => {
      return {
        ...rule,
        TaxId: rule.Tax ? rule.Tax.id : null,
        CountryId: rule.Country ? rule.Country.id : null,
        StateId: rule.State ? rule.State.id : null,
        edit: false,
      }
    })
  },
  SET_RULE_EDIT(state, { rule, edit }) {
    const ruleIndex = state.taxRules.findIndex(({ id }) => id === rule.id)
    if (ruleIndex >= 0) {
      Vue.set(state.taxRules[ruleIndex], 'edit', edit)
    }
  },
  ADD_TAX_RULE_ROW(state) {
    state.taxRules.push({ new: true, newId: uniqueId() })
  },
  UPDATE_RULE(state, rule) {
    const ruleIndex = state.taxRules.findIndex(({ id }) => id === rule.id)
    Vue.set(state.taxRules, ruleIndex, {
      ...rule,
      TaxId: rule.Tax ? rule.Tax.id : null,
      CountryId: rule.Country ? rule.Country.id : null,
      StateId: rule.State ? rule.State.id : null,
      edit: false,
    })
  },
  ADD_TAX_RULE(state, { taxRule, newId = null }) {
    const newRule = {
      ...taxRule,
      TaxId: taxRule.Tax ? taxRule.Tax.id : null,
      CountryId: taxRule.Country ? taxRule.Country.id : null,
      StateId: taxRule.State ? taxRule.State.id : null,
      edit: false,
    }
    if (newId) {
      const ruleIndex = state.taxRules.findIndex((rule) => rule.newId === newId)
      if (ruleIndex >= 0) {
        Vue.set(state.taxRules, ruleIndex, newRule)
      }
    } else {
      state.taxRules.push(newRule)
    }
  },
  DELETE_RULE(state, ruleId) {
    const ruleIndex = state.taxRules.findIndex(({ id }) => id === ruleId)
    state.taxRules.splice(ruleIndex, 1)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
