<template>
  <BaseBatchAction
    v-bind="$props"
    :before-close="beforeClose"
    @on-open="getFilters"
  >
    <template #button>
      <i class="ni ni-collection" />
      <span>Appliquer des filtres à la sélection</span>
    </template>
    <template v-if="filters.length > 0" #default>
      <BaseForm
        id="form"
        ref="form"
        success-message="Filtres enregistrés"
        :on-success="saveProducts"
        :toast-only="true"
      >
        <ProductMetaFilterGroups
          v-model="filters"
          v-loading="loading"
          title="Appliquer des filtres à la sélection de produits"
          :can-add="false"
          :can-drag="false"
        />
      </BaseForm>
    </template>
    <template v-else #default>
      <h2>Aucun filtre disponible</h2>
      <p class="lead">
        La sélection de produit n'a aucun filtre en commun. Pour appliquer des
        filtres à une sélection de produit assurez-vous de sélectionner des
        produits qui ont des filtres en communs.
      </p>
    </template>
    <template v-if="filters.length > 0" #footer="{ cancel, close }">
      <BaseButton ref="closeButton" @click="cancel">Annuler</BaseButton>
      <BaseButton type="primary" @click="close">Enregistrer</BaseButton>
    </template>
    <template v-else #footer="{ cancel }">
      <BaseButton @click="cancel">Fermer</BaseButton>
    </template>
  </BaseBatchAction>
</template>

<script>
import { apolloClient } from '@/apollo'
import getFiltersByProductIds from '@/apollo/queries/GetFiltersByProductIds.gql'
import updateProductsFilters from '@/apollo/mutations/UpdateProductsFilters.gql'
import ProductMetaFilterGroups from '@/components/Product/ProductMetaFilterGroups.vue'
import BaseBatchAction from './BaseBatchAction.vue'
import { BaseButton } from '@coop-zone/coop-zone-argon'
import BaseForm from '../forms/BaseForm.vue'
export default {
  components: {
    BaseBatchAction,
    BaseButton,
    ProductMetaFilterGroups,
    BaseForm,
  },
  props: {
    batchActionItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      filters: [],
      loading: false,
    }
  },
  methods: {
    async beforeClose() {
      // Pas besoin de sauvegarder si on a pas de filtres
      if (this.filters.length > 0) {
        try {
          await this.$confirm('Voulez-vous enregistrer les changements?', {
            confirmButtonText: 'Enregistrer',
            cancelButtonText: 'Non',
            distinguishCancelAndClose: true,
          })
          this.$refs.form.submit()
        } catch (action) {
          return action
        }
      }
    },
    async getFilters() {
      this.loading = true
      const {
        data: { filters },
      } = await apolloClient.query({
        query: getFiltersByProductIds,
        variables: {
          productIds: this.batchActionItems,
        },
      })
      this.loading = false
      this.filters = filters
    },
    saveProducts() {
      apolloClient.query({
        query: updateProductsFilters,
        variables: {
          filters: this.filters.map(({ id, type, content }) => {
            return {
              id,
              type,
              content: content
                .map(({ FilterValue, id: filterId, type }) => {
                  return {
                    type,
                    filterId,
                    filterValueId: FilterValue ? FilterValue.id : null,
                  }
                })
                .filter(({ filterValueId }) => filterValueId !== null),
            }
          }),
          productIds: this.batchActionItems,
        },
      })
    },
  },
}
</script>
