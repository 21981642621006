<template>
  <div>
    <BaseHeader class="pb-6" type="default">
      <b-row align-v="center" class="py-4">
        <b-col>
          <h1 class="h2 text-white d-inline-block mb-0">Ajouter une valeur</h1>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </BaseHeader>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="12">
          <FilterValueForm title="Ajouter une valeur" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { BaseHeader, RouteBreadcrumb } from '@coop-zone/coop-zone-argon'
import FilterValueForm from '@/components/Filters/FilterValueForm'

export default {
  components: {
    BaseHeader,
    RouteBreadcrumb,
    FilterValueForm,
  },
}
</script>
