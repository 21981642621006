<template>
  <div class="manifest">
    <b-row>
      <b-col>
        <template v-if="type === 'DELIVERY'">
          <p class="h3">
            Expédié le {{ manifest.expeditionDate | dateFormatDefault }}
          </p>
        </template>
        <template v-else>
          <p class="h3">
            Récupéré le {{ manifest.expeditionDate | dateFormatDefault }}
          </p>
        </template>
      </b-col>

      <b-col v-if="manifest.trackingUrl" class="text-right">
        <a
          :href="manifest.trackingUrl"
          target="_blank"
          class="btn btn-primary btn-sm btm-icon"
        >
          <span class="btn-inner--icon">
            <i class="fas fa-shipping-fast mr-2" />
          </span>
          Suivre le colis
        </a>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <el-card shadow="never">
          <b-row class="mb-3">
            <b-col v-if="manifest.transporterName" sm="12" xl="2">
              <span class="h4 font-weight-bold">Transporteur</span>
              <br />
              <small>{{ manifest.transporterName }}</small>
            </b-col>
            <b-col v-if="manifest.trackingNumber" sm="12" xl="2">
              <span class="h4 font-weight-bold">Code de suivi</span>
              <br />
              <small>{{ manifest.trackingNumber }}</small>
            </b-col>
            <b-col sm="12" xl="2">
              <span class="h4 font-weight-bold">Numéro de transaction</span>
              <br />
              <small>{{ manifest.transaction.number }}</small>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <OrderItem
                v-for="item in manifest.items"
                :key="item.id"
                :item="item"
                shadow="never"
                class="order-product"
              />
            </b-col>
          </b-row>
        </el-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import OrderItem from '@/components/Orders/OrderItem'

export default {
  components: {
    OrderItem,
  },
  props: {
    manifest: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
    type: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="sass">
.manifest + .manifest
  margin-top: 20px
</style>
