<template>
  <BaseForm
    v-slot="{ loading }"
    :toast-only="true"
    :on-success="submit"
    :hide-loading="true"
    :success-message="successMessage"
  >
    <template v-if="mode == 'edit'">
      <ReceptionBoxSelect
        v-model="receptionBox"
        size="mini"
        :disabled="loading"
      />

      <BaseButton
        size="sm"
        type="success"
        native-type="submit"
        :disabled="loading"
      >
        <template v-if="loading">
          <b-icon icon="arrow-clockwise" animation="spin" font-scale="1.5" />
        </template>

        <template v-else>Confirmer</template>
      </BaseButton>

      <BaseButton size="sm" type="danger" :disabled="loading" @click="cancel">
        Annuler
      </BaseButton>
    </template>
  </BaseForm>
</template>

<script>
import { BaseButton } from '@coop-zone/coop-zone-argon'
import BaseForm from '@/components/forms/BaseForm'
import ReceptionBoxSelect from '@/components/Inputs/ReceptionBoxSelect'

export default {
  components: {
    BaseForm,
    BaseButton,
    ReceptionBoxSelect,
  },
  props: {
    onSuccess: {
      type: Function,
      required: true,
    },
    successMessage: {
      type: String,
      default: 'Sauvegarde effectuée avec succès',
    },
    mode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      receptionBox: null,
    }
  },
  methods: {
    async submit() {
      await this.onSuccess(this.receptionBox)
      this.$emit('updateMode', 'read')
      this.receptionBox = null
    },

    cancel() {
      this.$emit('updateMode', 'read')
      this.receptionBox = null
    },
  },
}
</script>
