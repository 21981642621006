<template>
  <b-row align-v="center">
    <b-col>
      <p class="h6 text-uppercase text-muted ls-1 mb-1">
        {{ schoolList.id ? 'Modifier une liste' : 'Ajouter une liste' }}
      </p>
      <h2 class="mb-0">
        <span v-if="schoolList.EstablishmentId">{{
          schoolEstablishmentName(schoolList.EstablishmentId)
        }}</span>
        <slot />
        <SchoolListsStatusBadge
          v-if="schoolList.status"
          class="ml-2"
          :status="$t(`schoolLists.statuses.${schoolList.status}`)"
        />
      </h2>
    </b-col>

    <b-col sm="12" lg="6" class="text-right">
      <Publish v-if="schoolList.id" />
      <SendForAdminReview
        v-if="schoolList.status !== 'CANCELED' && schoolList.id"
      />
      <AskForModification
        v-if="schoolList.status !== 'CANCELED' && schoolList.id"
      />
      <SendForCreatorReview
        v-if="schoolList.status !== 'CANCELED' && schoolList.id"
      />
      <Cancel v-if="schoolList.status !== 'CANCELED' && schoolList.id" />

      <SchoolListsExportButton
        v-if="schoolList.id"
        v-slot="{ showFilters }"
        :school-list="schoolList"
      >
        <BaseButton size="sm" @click="showFilters">
          <i class="fa fa-download" />
          Exporter
        </BaseButton>
      </SchoolListsExportButton>

      <a
        ref="downloadfile"
        style="height: 0px; width: 0px; visibility: hidden; display: block"
        :href="fileSource"
        :download="fileName"
      />
    </b-col>
  </b-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { BaseButton } from '@coop-zone/coop-zone-argon'
import {
  AskForModification,
  Cancel,
  Publish,
  SendForAdminReview,
  SendForCreatorReview,
} from '@/components/SchoolLists/Flow'
import SchoolListsStatusBadge from '@/components/SchoolLists/SchoolListsStatusBadge'
import SchoolListsExportButton from '@/components/SchoolLists/SchoolListsExportButton'

export default {
  components: {
    AskForModification,
    BaseButton,
    Cancel,
    Publish,
    SchoolListsStatusBadge,
    SendForAdminReview,
    SchoolListsExportButton,
    SendForCreatorReview,
  },
  data() {
    return {
      fileSource: null,
      fileName: null,
    }
  },
  computed: {
    ...mapState('schoolLists', ['schoolEstablishmentName']),
    ...mapGetters('schoolEstablishments', ['schoolEstablishmentName']),
    ...mapGetters('schoolLists', ['schoolList']),
  },
  methods: {
    async exportSchoolList(schoolList) {
      try {
        const base64file = await this.$store.dispatch(
          'utils/exportSchoolList',
          schoolList
        )
        this.fileSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64file}`
        this.fileName = `${schoolList.name}${
          schoolList.courseCode ? `-${schoolList.courseCode}` : ''
        }`
        this.fileName += '.xlsx'

        this.$nextTick(() => {
          this.$refs.downloadfile.click()
        })
      } catch (e) {
        console.error(e)
      }
    },
  },
}
</script>

<style></style>
