<template>
  <b-card no-body class="mb-4">
    <template v-if="$slots.drawer">
      <slot name="drawer" />
    </template>

    <template slot="header">
      <slot name="header" />
    </template>

    <b-card-body v-if="displaySearchOptions" class="mb--4">
      <b-row v-if="value !== null" class="mb-4">
        <b-col md="12">
          <b-form @submit.prevent>
            <base-input
              v-model="searchTerm"
              label="Rechercher dans la liste"
              form-group-classes="mb-0"
              class="input-group-lg"
              prepend-icon="fas fa-search"
              type="search"
              :placeholder="placeholder"
            />
          </b-form>
        </b-col>
      </b-row>
      <b-row v-if="$slots.filters">
        <b-col cols="12">
          <slot name="filters" />
        </b-col>
      </b-row>
      <b-row v-if="$slots.batch" class="mt-4">
        <b-col cols="12"><h3 class="h5">Actions groupées</h3></b-col>
        <b-col cols="12">
          <slot name="batch" />
        </b-col>
      </b-row>

      <b-row
        v-if="pagination !== null && pagination.totalCount > 0"
        class="mt-4"
      >
        <div
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <p v-if="pagination.totalCount > 0" class="card-category">
            {{ from + 1 }} à {{ to }} de {{ pagination.totalCount }} entrées
          </p>
          <base-pagination
            v-if="pagination.totalCount > pagination.resultsPerPage"
            class="pagination-no-border"
            :value="pagination.currentPage"
            :per-page="pagination.resultsPerPage"
            :total="
              pagination.totalCount > 10000 ? 10000 : pagination.totalCount
            "
            @change="paginationChanged($event)"
          />
        </div>
      </b-row>
    </b-card-body>

    <el-table
      v-loading="loading"
      :data="data"
      row-key="id"
      header-row-class-name="thead-light"
      :row-class-name="rowClassName"
      :row-style="rowStyle"
      v-on="$listeners"
    >
      <slot />
    </el-table>

    <template slot="footer">
      <b-row v-if="pagination !== null && pagination.totalCount > 0">
        <div
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <p v-if="pagination.totalCount > 0" class="card-category">
            {{ from + 1 }} à {{ to }} de {{ pagination.totalCount }} entrées
          </p>
          <base-pagination
            v-if="pagination.totalCount > pagination.resultsPerPage"
            class="pagination-no-border"
            :value="pagination.currentPage"
            :per-page="pagination.resultsPerPage"
            :total="
              pagination.totalCount > 10000 ? 10000 : pagination.totalCount
            "
            @change="paginationChanged($event)"
          />
        </div>
      </b-row>
    </template>
  </b-card>
</template>

<script>
import { BaseInput, BasePagination } from '@coop-zone/coop-zone-argon'
import debounce from 'lodash/debounce'

export default {
  components: {
    BaseInput,
    BasePagination,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: 'Rechercher',
      description: 'Titre de la carte',
    },
    placeholder: {
      type: String,
      default: 'Rechercher',
      description: 'Placeholder du champs recherche',
    },
    data: {
      type: Array,
      required: true,
    },
    rowClassName: {
      type: Function,
      default: () => {},
    },
    rowStyle: {
      type: Function,
      default: () => {},
    },
    pagination: {
      type: Object,
      default() {
        return null
      },
    },
  },
  data() {
    return {
      searchTerm: '',
      loading: true,
    }
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.resultsPerPage
      if (this.pagination.totalCount < highBound) {
        highBound = this.pagination.totalCount
      }
      return highBound
    },
    from() {
      return this.pagination.resultsPerPage * (this.pagination.currentPage - 1)
    },

    displaySearchOptions() {
      return this.value !== null || this.$slots.filters || this.$slots.batch
    },
  },
  watch: {
    searchTerm: 'debouncedSearch',
    value: {
      handler(val) {
        if (val) {
          this.searchTerm = val
        }
      },
      immediate: true,
    },
    data() {
      this.loading = false
    },
  },
  methods: {
    paginationChanged(page) {
      this.search(this.searchTerm, page)
    },
    debouncedSearch: debounce(function search(searchTerm) {
      if (this.searchTerm !== this.value) {
        this.search(searchTerm)
      }
    }, 300),
    search(searchTerm, page = 1) {
      this.loading = true
      this.$emit('term', searchTerm)
      this.$emit('page', page)
      this.$emit('search', searchTerm, page)
    },
  },
}
</script>
