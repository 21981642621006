<template>
  <b-container fluid class="mt--6 pb-5">
    <BaseForm id="updateProduct" :on-success="save">
      <b-row>
        <b-col :xl="8">
          <div v-loading="loading" class="card-wrapper">
            <b-card>
              <div slot="header">
                <h2 class="mb-0">Identification</h2>
              </div>

              <b-row>
                <b-col md="4">
                  <label class="form-control-label">
                    Images de la galerie
                  </label>
                  <MultipleFileUpload
                    v-model="bundle.images"
                    label="Ajouter une image"
                    multiple
                    :formats="[
                      { width: 300, name: 'thumbnail' },
                      { width: 2048, name: 'zoom' },
                    ]"
                  />
                </b-col>

                <b-col class="mt-4 mt-md-0" md="8">
                  <BaseInput
                    v-model="bundle.name"
                    data-cy="input-product-name"
                    label="Nom de l'ensemble"
                    placeholder="Saisissez le nom de l'ensemble"
                    name="Nom de l'ensemble"
                    rules="required"
                  />

                  <BaseInput
                    v-if="bundle.slug"
                    v-model="bundle.slug"
                    readonly
                    label="Identifiant"
                    placeholder="Saisissez le nom de produit"
                  />

                  <BaseInput
                    v-model="bundle.sku"
                    data-cy="input-product-sku"
                    label="SKU"
                    placeholder="Saisissez le sku"
                    name="SKU"
                    rules="required"
                  />
                  <BaseInput
                    v-model="bundle.vigieId"
                    label="Code produit"
                    name="vigieId"
                    readonly
                  />

                  <label class="form-control-label"
                    >Description du produit (optionnel)</label
                  >
                  <HtmlEditor
                    v-model="bundle.description"
                    data-cy="input-product-description"
                  />
                </b-col>
              </b-row>
            </b-card>

            <b-card v-loading="waitForUtils">
              <div slot="header">
                <h2 class="mb-0">Catégorisation</h2>
              </div>

              <b-row>
                <b-col>
                  <input
                    v-model="bundle.type"
                    class="form-control"
                    type="hidden"
                    readonly
                    value="Bundle"
                  />

                  <div data-cy="select-main-category">
                    <TreeselectInput
                      v-if="waitForUtils == false"
                      :value="primaryCategory"
                      rules="required"
                      label="Catégorie principale"
                      :multiple="false"
                      placeholder="Sélectionnez la catégorie"
                      :options="treeselectCategories"
                      @input="updateCategories('primary', $event)"
                    />
                  </div>

                  <div data-cy="select-product-categories">
                    <TreeselectInput
                      v-if="waitForUtils == false"
                      :value="secondaryCategories"
                      label="Catégories secondaires"
                      :multiple="true"
                      placeholder="Sélectionnez les catégories"
                      :options="treeselectCategories"
                      @input="updateCategories('secondary', $event)"
                    />
                  </div>

                  <BaseInput
                    v-if="bundle.type == 'Computer'"
                    v-model="bundle.codeApple"
                    data-cy="apple-product"
                    label="Code produit Apple (optionnel)"
                  />

                  <BaseInput
                    v-if="bundle.type == 'Book' || bundle.type == 'DigitalBook'"
                    v-model="bundle.isbn10"
                    data-cy="isbn10"
                    label="ISBN10"
                    name="isbn10"
                  />

                  <BaseInput
                    v-if="bundle.type == 'Book' || bundle.type == 'DigitalBook'"
                    v-model="bundle.isbn13"
                    data-cy="isbn13"
                    label="ISBN13"
                    name="isbn13"
                  />
                </b-col>
              </b-row>
            </b-card>

            <card>
              <template #header>
                <b-row align-v="center">
                  <b-col>
                    <h3 class="h3 mb-0">Produits</h3>
                  </b-col>
                </b-row>
              </template>

              <b-row class="mb-4">
                <b-col>
                  <ProductAutocomplete
                    placeholder="Ajouter un SKU"
                    :omit-types="digitalTypes.join(',')"
                    @change="addProduct"
                  />
                  <validation-provider
                    ref="provider"
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <input
                      v-model="bundle.products"
                      rules="required"
                      type="hidden"
                    />
                    <div
                      v-if="errors[0]"
                      class="invalid-feedback mb-2"
                      style="display: block"
                    >
                      {{ $t(`formError.${errors[0]}`) }}
                    </div>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <el-table
                    :data="bundle.products"
                    header-row-class-name="thead-light"
                    :default-sort="{ prop: 'name', order: 'ascending' }"
                  >
                    <el-table-column
                      label="Nom du produit"
                      prop="name"
                      min-width="160px"
                    >
                      <template slot-scope="scope">
                        <b-media no-body class="align-items-center">
                          <img
                            v-if="scope.row.images && scope.row.images.length"
                            width="48px"
                            height="48px"
                            class="mr-1"
                            :src="scope.row.images[0].thumbnail"
                          />
                          <b-media-body>
                            <router-link
                              :to="`/catalog/edit/${scope.row.id}-${scope.row.slug}`"
                              class="font-weight-600 name mb-0 text-sm"
                              >{{ scope.row.name }}</router-link
                            >
                          </b-media-body>
                        </b-media>
                      </template>
                    </el-table-column>

                    <el-table-column prop="sku" label="SKU" />
                    <el-table-column align="right">
                      <template slot-scope="scope">
                        <a
                          href="#"
                          target="_self"
                          class="table-action table-action-delete"
                          @click.prevent="removeProduct(scope.row)"
                          ><i class="fa fa-trash"
                        /></a>
                      </template>
                    </el-table-column>
                  </el-table>
                </b-col>
              </b-row>
            </card>

            <card v-loading="waitForUtils">
              <template #header>
                <h2 class="mb-0">Prix et taxes</h2>
                <p class="mb-0">
                  <small
                    >Les prix réguliers sont automatiquement calculés en
                    additionant les prix de chacun des produits qui constituent
                    l'ensemble.</small
                  >
                </p>
              </template>

              <BaseCheckbox v-model="bundle.buyable" class="mb-2">
                Produit achetable
              </BaseCheckbox>

              <b-form-group
                :class="{ 'mb-3': !limitQuantity, 'mb-0': limitQuantity }"
              >
                <BaseCheckbox v-model="limitQuantity" class="mb-2">
                  Limite d'achat par transaction
                </BaseCheckbox>

                <BaseInput
                  v-if="limitQuantity"
                  :value="bundle.purchaseLimit"
                  type="number"
                  min="0"
                  @input="
                    bundle.purchaseLimit = isNaN(parseInt($event))
                      ? 0
                      : parseInt($event)
                  "
                />
              </b-form-group>

              <BaseInput label="Règle de taxes applicable" rules="required">
                <el-select
                  v-if="waitForUtils == false"
                  id="taxe-rules"
                  v-model="bundle.TaxRuleGroupId"
                  data-cy="taxe-rules"
                  placeholder="Sélectionnez la règle de taxe"
                >
                  <!--<el-option :value="null" label="Aucune" />-->
                  <el-option
                    v-for="object in taxRuleGroups"
                    :key="object.id"
                    :value="object.id"
                    :label="object.name"
                  />
                </el-select>
              </BaseInput>

              <ProductPricesFields
                product-type="Bundle"
                :value="{
                  prices: bundle.prices,
                  specialPrices: bundle.specialPrices,
                }"
                @input="
                  updateBundle({
                    prices: $event.prices,
                    specialPrices: $event.specialPrices,
                  })
                "
              />
            </card>

            <card>
              <template #header>
                <b-row align-v="center">
                  <b-col>
                    <h3 class="h3 mb-0">Suggestions de produit</h3>
                  </b-col>
                </b-row>
                <ProductSuggestions
                  v-model="bundle.suggestions"
                  @add="addSuggestion"
                  @remove="removeSuggestion"
                />
              </template>
            </card>

            <b-row align-h="end">
              <b-col cols="auto">
                <BaseButton
                  size="lg"
                  type="secondary"
                  native-type="submit"
                  @click="$emit('action', 'save-edit')"
                >
                  Sauvegarder et modifier
                </BaseButton>
              </b-col>

              <b-col cols="auto">
                <BaseButton
                  size="lg"
                  type="primary"
                  native-type="submit"
                  @click="$emit('action', 'save')"
                >
                  Sauvegarder
                </BaseButton>
              </b-col>
            </b-row>
          </div>
        </b-col>

        <b-col v-if="bundle.slug" xl="4">
          <div class="sticky">
            <card type="gradient-primary">
              <div class="form-primary">
                <BaseInput
                  v-if="fullSlug"
                  v-model="fullSlug"
                  label-classes="form-control-label text-white"
                  readonly
                  label="Identifiant sur le site"
                />

                <a
                  class="btn btn-success btn-lg btn-block"
                  :href="`${websiteUrl}/produit/${fullSlug}`"
                  target="_blank"
                  role="button"
                >
                  Voir sur le site
                </a>

                <BaseButton
                  size="lg"
                  type="secondary"
                  native-type="submit"
                  block
                  @click="$emit('action', 'save-edit')"
                >
                  Enregistrer l'ensemble
                </BaseButton>
              </div>
            </card>
          </div>
        </b-col>
      </b-row>
    </BaseForm>
  </b-container>
</template>

<script>
import { digitalTypes } from '@/data/products'
import { mapState, mapGetters } from 'vuex'
import {
  BaseButton,
  BaseInput,
  BaseCheckbox,
  Card,
  HtmlEditor,
} from '@coop-zone/coop-zone-argon'
import BaseForm from '@/components/forms/BaseForm'
import ProductPricesFields from '@/components/Product/ProductPricesFields'
import ProductAutocomplete from '@/components/ProductAutocomplete'
import ProductSuggestions from '@/components/Product/ProductSuggestions.vue'
import TreeselectInput from '@/components/Inputs/TreeselectInput'
import MultipleFileUpload from '@/components/Inputs/MultipleFileUpload'
import getEnv from '@/utils/env'

export default {
  components: {
    BaseButton,
    BaseCheckbox,
    BaseForm,
    BaseInput,
    Card,
    HtmlEditor,
    MultipleFileUpload,
    ProductAutocomplete,
    ProductPricesFields,
    ProductSuggestions,
    TreeselectInput,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      websiteUrl: getEnv('VUE_APP_WEBSITE_URL'),
      waitForUtils: false,
      limitQuantity: false,
      chargeHandlingFee: false,
      bundle: {
        name: null,
        type: 'Bundle',
        sku: null,
        buyable: true,
        brand: null,
        description: '',
        isbn10: null,
        isbn13: null,
        TaxRuleGroupId: null,
        images: [],
        categories: [],
        metas: [],
        prices: {
          regular: null,
          member: null,
          employee: null,
          institute: null,
          business: null,
          library: null,
          emerging_artist: null,
          professional_artist: null,
          cjms: null,
          special_1: null,
          special_2: null,
          special_3: null,
          special_4: null,
        },
        specialPrices: {
          regular: null,
          member: null,
          employee: null,
          institute: null,
          business: null,
          library: null,
          emerging_artist: null,
          professional_artist: null,
          cjms: null,
          special_1: null,
          special_2: null,
          special_3: null,
          special_4: null,
        },
        products: [],
        filters: [],
        purchaseLimit: 0,
        suggestions: [],
      },
    }
  },
  computed: {
    ...mapState('products', ['productTypes', 'product']),
    ...mapState('utils', ['treeselectCategories', 'taxRuleGroups']),
    ...mapGetters('products', {
      product: 'getBundle',
    }),
    hasUniqueTaxRule() {
      const uniqueIds = new Set(
        this.bundle.products.map((product) => product.TaxRuleGroupId)
      )
      return [...uniqueIds].length === 1
    },
    primaryCategory() {
      return this.bundle.categories[0] || null
    },
    secondaryCategories() {
      return this.bundle.categories.slice(1)
    },
    fullSlug() {
      return `${this.bundle.id}-${this.bundle.slug}`
    },
    digitalTypes() {
      return digitalTypes
    },
  },
  watch: {
    limitQuantity: {
      handler(value) {
        if (value === false) {
          this.bundle.purchaseLimit = 0
        }
      },
    },
    'bundle.purchaseLimit': {
      handler(value) {
        this.limitQuantity = value > 0
      },
      immediate: true,
      deep: true,
    },
  },
  async created() {
    if (this.$route.params.slug) {
      await this.$store.dispatch('products/getBundle', {
        slug: this.$route.params.slug,
      })

      this.bundle = { ...this.product }
    }

    this.waitForUtils = true
    await this.$store.dispatch('utils/getTreeselectCategories')
    await this.$store.dispatch('utils/getTaxRuleGroups')
    this.waitForUtils = false
  },
  methods: {
    async save() {
      if (this.$route.params.slug) {
        await this.$store.dispatch('products/updateBundle', {
          product: this.bundle,
          slug: this.$route.params.slug,
        })

        const queryslug = this.product.id + '-' + this.product.slug
        if (queryslug !== this.$route.params.slug) {
          this.$router.push(
            '/catalog/bundles/edit/' + this.product.id + '-' + this.product.slug
          )
        }
      } else {
        await this.$store.dispatch('products/createBundle', {
          product: this.bundle,
        })
      }
      this.bundle = { ...this.product }
      this.$emit('on-success')
    },
    removeProduct(product) {
      const indexOfProduct = this.bundle.products.indexOf(product)
      this.bundle.products.splice(indexOfProduct, 1)
      this.updateBundlePrices()
    },
    addProduct(data) {
      this.bundle.products.push(data)
      this.updateBundlePrices()
    },
    updateBundlePrices() {
      const priceTypes = Object.keys(this.bundle.prices)
      priceTypes.forEach((type) => {
        const total = this.bundle.products
          .map((product) => product.prices[type])
          .reduce((prev, current) => prev + current, null)

        this.bundle.prices[type] = total
      })
    },
    updateBundle(bundle) {
      this.bundle.prices = bundle.prices
      this.bundle.specialPrices = bundle.specialPrices
    },
    updateCategories(type, value) {
      const [primaryCategory, ...secondaryCategories] = this.bundle.categories

      if (type === 'primary') {
        this.bundle.categories = value
          ? [value, ...secondaryCategories]
          : [...secondaryCategories]
      } else {
        this.bundle.categories = value
          ? [primaryCategory, ...value]
          : [primaryCategory]
      }
    },
    addSuggestion(product) {
      this.bundle.suggestions.push(product)
    },
    removeSuggestion(product) {
      const index = this.bundle.suggestions.findIndex(
        ({ id }) => id === product.id
      )
      this.bundle.suggestions.splice(index, 1)
    },
  },
}
</script>
