<template>
  <BaseForm
    id="schoolSessionForm"
    success-message="Session enregistrée."
    :on-success="onSuccess"
    :redirect="redirect"
  >
    <b-card>
      <div slot="header">
        <b-row align-v="center">
          <b-col sm="12" lg="6">
            <p class="h6 text-uppercase text-muted ls-1 mb-1">
              {{ title }}
            </p>
            <h2 class="mb-0">
              {{ name }}
            </h2>
          </b-col>

          <b-col sm="12" lg="6" class="text-right">
            <slot name="actions" />
          </b-col>
        </b-row>
      </div>

      <b-row>
        <b-col sm="4">
          <BaseInput
            v-model="name"
            label="Nom de la session"
            rules="required"
          />
        </b-col>

        <b-col v-if="isUpdating" sm="4">
          <BaseInput v-model="slug" label="Slug" />
        </b-col>

        <b-col sm="4">
          <BaseInput label="Type de session" rules="required">
            <select v-model="establishmentType" class="form-control">
              <option
                v-for="type in schoolEstablishmentTypes"
                :key="type"
                :value="type"
              >
                {{ $t(`establishments.types.${type}`) }}
              </option>
            </select>
          </BaseInput>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <div slot="header">
        <b-row align-v="center">
          <b-col sm="12" lg="6">
            <p class="h5 text-uppercase text-muted ls-1 mb-1">
              Date de gestion
            </p>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col sm="12">
          <p>
            <small>
              Date à partir de laquelle il sera possible d'associer la session à
              une liste scolaire.
            </small>
          </p>
        </b-col>
        <b-col sm="6">
          <div class="form-group-wrapper">
            <BaseInput
              label="Date de début"
              form-group-classes="mb-0"
              prepend-icon="ni ni-calendar-grid-58"
              rules="required"
            >
              <flat-pickr
                v-model="managementStartDate"
                :config="config"
                class="form-control datepicker"
              />
            </BaseInput>
          </div>
        </b-col>

        <b-col sm="6">
          <div class="form-group-wrapper">
            <BaseInput
              label="Date de fin"
              form-group-classes="mb-0"
              prepend-icon="ni ni-calendar-grid-58"
              rules="required"
            >
              <flat-pickr
                v-model="managementEndDate"
                :config="{ ...config, minDate: managementStartDate }"
                class="form-control datepicker"
              />
            </BaseInput>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <div slot="header">
        <b-row align-v="center">
          <b-col sm="12" lg="6">
            <p class="h5 text-uppercase text-muted ls-1 mb-1">
              Date d'affichage
            </p>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col sm="12">
          <p>
            <small>
              Date à partir de laquelle les listes scolaires associées à cette
              session seront visible sur le site public.
            </small>
          </p>
        </b-col>
        <b-col sm="6">
          <div class="form-group-wrapper">
            <BaseInput
              label="Date de début"
              form-group-classes="mb-0"
              prepend-icon="ni ni-calendar-grid-58"
              rules="required"
            >
              <flat-pickr
                v-model="publishStartDate"
                :config="config"
                class="form-control datepicker"
              />
            </BaseInput>
          </div>
        </b-col>

        <b-col sm="6">
          <div class="form-group-wrapper">
            <BaseInput
              label="Date de fin"
              form-group-classes="mb-0"
              prepend-icon="ni ni-calendar-grid-58"
              rules="required"
            >
              <flat-pickr
                v-model="publishEndDate"
                :config="{ ...config, minDate: publishStartDate }"
                class="form-control datepicker"
              />
            </BaseInput>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <slot />

    <b-row align="end" class="mt-1">
      <b-col>
        <BaseButton size="lg" type="primary" native-type="submit">
          Sauvegarder
        </BaseButton>
      </b-col>
    </b-row>
  </BaseForm>
</template>

<script>
import 'flatpickr/dist/flatpickr.css'
import { French as locale } from 'flatpickr/dist/l10n/fr'

import { BaseButton, BaseInput } from '@coop-zone/coop-zone-argon'
import { mapGetters } from 'vuex'
import BaseForm from '@/components/forms/BaseForm'

export default {
  components: {
    BaseForm,
    BaseButton,
    BaseInput,
  },
  props: {
    onSuccess: {
      type: Function,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    redirect: {
      type: [String, Boolean],
      default: false,
    },
  },
  data() {
    return {
      config: {
        allowInvalidPreload: true,
        dateFormat: 'Z',
        altFormat: 'Y-m-d',
        altInput: true,
        locale,
      },
    }
  },
  computed: {
    isUpdating: function () {
      return !!this.$store.state.schoolSessions.id
    },
    name: {
      get() {
        return this.$store.state.schoolSessions.name
      },
      set(name) {
        this.$store.commit('schoolSessions/SET_NAME', name)
      },
    },

    slug: {
      get() {
        return this.$store.state.schoolSessions.slug
      },
      set(slug) {
        this.$store.commit('schoolSessions/SET_SLUG', slug)
      },
    },

    establishmentType: {
      get() {
        return this.$store.state.schoolSessions.establishmentType
      },
      set(establishmentType) {
        this.$store.commit(
          'schoolSessions/SET_ESTABLISHMENT_TYPE',
          establishmentType
        )
      },
    },
    managementStartDate: {
      get() {
        return this.$store.state.schoolSessions.managementStartDate
      },
      set(date) {
        this.$store.commit('schoolSessions/SET_MANAGEMENT_START_DATE', date)
      },
    },
    managementEndDate: {
      get() {
        return this.$store.state.schoolSessions.managementEndDate
      },
      set(date) {
        this.$store.commit('schoolSessions/SET_MANAGEMENT_END_DATE', date)
      },
    },
    publishStartDate: {
      get() {
        return this.$store.state.schoolSessions.publishStartDate
      },
      set(date) {
        this.$store.commit('schoolSessions/SET_PUBLISH_START_DATE', date)
      },
    },
    publishEndDate: {
      get() {
        return this.$store.state.schoolSessions.publishEndDate
      },
      set(date) {
        this.$store.commit('schoolSessions/SET_PUBLISH_END_DATE', date)
      },
    },
    ...mapGetters('schoolEstablishments', ['schoolEstablishmentTypes']),
  },
}
</script>

<style lang="sass" scoped>
.form-group-wrapper::v-deep .form-control.datepicker[readonly]
  background-color: var(--white)
</style>
