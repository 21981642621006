<template>
  <div class="order-reception-box-widget">
    <BaseButton
      v-if="mode == 'read'"
      class="mr-0"
      size="sm"
      :type="disabled ? 'secondary' : 'warning'"
      :disabled="disabled"
      @click="mode = 'edit'"
    >
      <i class="fas fa-dolly-flatbed" />
      <span>Transférer vers une boîte de réception</span>
    </BaseButton>

    <slot :mode="mode" :updateMode="updateMode" />
  </div>
</template>

<script>
import { BaseButton } from '@coop-zone/coop-zone-argon'

export default {
  components: {
    BaseButton,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mode: 'read',
    }
  },
  methods: {
    updateMode(mode) {
      this.mode = mode
    },
  },
}
</script>

<style lang="sass">
.order-reception-box-widget
  display: inline-block
  margin-right: 0.5rem
</style>
