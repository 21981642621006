import * as blocks from '@/assets/blocks/types'

export default [
  {
    type: blocks.BLOCK_TYPE_BANNER,
    title: 'Bannière',
  },
  {
    type: blocks.BLOCK_TYPE_CALL_TO_ACTION,
    title: "Appels à l'action",
  },
  {
    type: blocks.BLOCK_TYPE_PROMO,
    title: 'Bloc promo #1',
  },
  {
    type: blocks.BLOCK_TYPE_PRODUCT_LIST,
    title: 'Liste de produit #1',
  },
  {
    type: blocks.BLOCK_TYPE_PRODUCT_LIST,
    title: 'Liste de produit #2',
  },
  {
    type: blocks.BLOCK_TYPE_PRODUCT_LIST,
    title: 'Liste de produit #3',
  },
  {
    type: blocks.BLOCK_TYPE_WYSIWYG,
    title: 'Éditeur de texte',
  },
  {
    type: blocks.BLOCK_TYPE_PROMO,
    title: 'Bloc promo #2',
  },
]
