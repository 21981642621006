import Vue from 'vue'

Vue.filter('percentFormat', (float) => {
  const percentValue = float * 100
  const percentString = percentValue.toLocaleString('fr-CA', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  })
  return `${percentString}%`
})
