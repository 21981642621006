import { apolloClient } from '@/apollo'

const searchFilterGroups = require('../../apollo/queries/SearchFilterGroups.gql')
const getFilterGroupQuery = require('../../apollo/queries/GetFilterGroup.gql')
const createFilterGroupMutation = require('../../apollo/mutations/CreateFilterGroup.gql')
const deleteFilterGroupMutation = require('../../apollo/mutations/DeleteFilterGroup.gql')
const updateFilterGroupMutation = require('../../apollo/mutations/UpdateFilterGroup.gql')

const state = {
  groups: [],
  id: null,
  name: '',
  label: null,
  defaultState: false,
}

const actions = {
  async getGroups({ commit }) {
    const {
      data: { groups },
    } = await apolloClient.query({
      query: searchFilterGroups,
      variables: {
        search: '',
      },
    })

    commit('SET_GROUPS', groups)
  },
  async searchGroups(context, searchTerm) {
    const {
      data: { groups },
    } = await apolloClient.query({
      query: searchFilterGroups,
      variables: {
        search: searchTerm,
      },
    })
    return groups
  },
  async getGroup({ commit }, groupId) {
    const {
      data: { group },
    } = await apolloClient.query({
      query: getFilterGroupQuery,
      variables: {
        filterGroupId: groupId,
      },
    })
    commit('SET_GROUP', group)
  },
  async createGroup({ state: { name = '', label = null } }) {
    await apolloClient.query({
      query: createFilterGroupMutation,
      variables: {
        filterGroup: { name, label },
      },
    })
  },
  async updateGroup({ state: { id, name = '', label = null } }) {
    await apolloClient.query({
      query: updateFilterGroupMutation,
      variables: {
        filterGroupId: id,
        filterGroup: {
          name,
          label,
        },
      },
    })
  },
  async deleteGroup({ commit }, filterGroupId) {
    await apolloClient.query({
      query: deleteFilterGroupMutation,
      variables: {
        filterGroupId,
      },
    })
    commit('REMOVE_GROUP', filterGroupId)
  },
}

const mutations = {
  SET_GROUPS(state, groups) {
    state.groups = groups
  },
  SET_GROUP(
    state,
    { id = null, name = '', label = null, default: defaultState = false }
  ) {
    state.id = id
    state.name = name
    state.label = label
    state.defaultState = defaultState
  },
  SET_NAME(state, name) {
    state.name = name
  },
  SET_LABEL(state, label) {
    state.label = label
  },
  REMOVE_GROUP(state, groupId) {
    const groupIndex = state.groups.findIndex((group) => group.id === groupId)
    if (groupIndex >= 0) {
      state.groups.splice(groupIndex, 1)
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
