import Vue from 'vue'
import { apolloClient } from '@/apollo'

const getCallToActionsQuery = require('../apollo/queries/GetCallToActions.gql')
const getCallToActionQuery = require('../apollo/queries/GetCallToAction.gql')
const updateCallToActionMutation = require('../apollo/mutations/UpdateCallToAction.gql')
const createCallToActionMutation = require('../apollo/mutations/CreateCallToAction.gql')
const deleteCallToActionMutation = require('../apollo/mutations/DeleteCallToAction.gql')

const state = {
  callToActionsMap: {},
  id: null,
  label: null,
  url: null,
  image: null,
  categoryIds: [],
}

const actions = {
  async getCallToActions({ commit }) {
    const {
      data: { callToActions },
    } = await apolloClient.query({
      query: getCallToActionsQuery,
    })

    commit('SET_CALL_TO_ACTIONS', callToActions)
  },

  async getCallToAction({ commit }, { id }) {
    const {
      data: { callToAction },
    } = await apolloClient.query({
      query: getCallToActionQuery,
      variables: {
        id,
      },
    })

    commit('SET_CURRENT_CALL_TO_ACTION', callToAction)
  },

  async createCallToAction({
    state: { label, url, image, categoryIds },
    commit,
  }) {
    const {
      data: { createCallToAction },
    } = await apolloClient.query({
      query: createCallToActionMutation,
      variables: {
        callToAction: {
          label,
          url,
          image,
          categoryIds,
        },
      },
    })

    commit('ADD_CALL_TO_ACTION', createCallToAction)
  },

  async updateCallToAction({
    state: { id, label, url, image, categoryIds },
    commit,
  }) {
    const {
      data: { updateCallToAction },
    } = await apolloClient.query({
      query: updateCallToActionMutation,
      variables: {
        id,
        callToAction: {
          label,
          url,
          image,
          categoryIds,
        },
      },
    })

    commit('SET_CURRENT_CALL_TO_ACTION', updateCallToAction)
  },

  async deleteCallToAction({ commit }, id) {
    await apolloClient.query({
      query: deleteCallToActionMutation,
      variables: {
        id,
      },
    })
    commit('REMOVE_CALL_TO_ACTION', id)
  },
}

const getters = {
  getCallToActions: (state) =>
    state.callToActionsMap ? Object.values(state.callToActionsMap) : [],
}

const mutations = {
  SET_LABEL(state, payload) {
    state.label = payload
  },

  SET_URL(state, payload) {
    state.url = payload
  },

  SET_IMAGE(state, payload) {
    state.image = payload
  },

  SET_CATEGORY_IDS(state, payload) {
    state.categoryIds = payload
  },

  SET_CALL_TO_ACTIONS(state, payload) {
    state.callToActionsMap = payload.reduce(
      (acc, cta) => ({ ...acc, [cta.id]: cta }),
      {}
    )
  },

  SET_CURRENT_CALL_TO_ACTION(
    state,
    { id = null, label = null, url = null, image = null, categoryIds = [] }
  ) {
    state.id = id
    state.label = label
    state.url = url
    state.image = image
    state.categoryIds = categoryIds
  },

  ADD_CALL_TO_ACTION(state, payload) {
    Vue.set(state.callToActionsMap, payload.id, payload)
  },

  REMOVE_CALL_TO_ACTION(state, id) {
    Vue.delete(state.callToActionsMap, id)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
