import { apolloClient } from '@/apollo'

const searchQuery = require('../apollo/queries/GetAccountsSearch.gql')
const getUserQuery = require('../apollo/queries/GetUser.gql')
const updateUserMutation = require('../apollo/mutations/UpdateUser.gql')
const updateRoleMutation = require('../apollo/mutations/UpdateRole.gql')
const updateAddressesMutation = require('../apollo/mutations/UpdateAddresses.gql')
const updateClientNumberMutation = require('../apollo/mutations/UpdateClientNumber.gql')

const state = {
  user: null,
}

const actions = {
  async search(context, { search, offset, limit, memberType, active, role }) {
    const { data } = await apolloClient.query({
      query: searchQuery,
      variables: {
        search,
        offset,
        limit,
        memberType,
        role,
        active,
      },
    })

    if (data.accounts) {
      return data.accounts
    }
    return null
  },

  async getUser({ commit }, id) {
    const { data } = await apolloClient.query({
      query: getUserQuery,
      variables: {
        id,
      },
    })
    if (data.account) {
      commit('setUser', data.account)
    }
    return data
  },

  async updateUserCredentials(
    { commit },
    {
      userId,
      active,
      firstName,
      lastName,
      email,
      password,
      phoneNumber,
      notifyBySms = null,
      passwordConfirmation = null,
      studentNumber = null,
      chargeToAccount = null,
    }
  ) {
    const { data } = await apolloClient.mutate({
      mutation: updateUserMutation,
      variables: {
        userId,
        account: {
          active,
          firstName,
          lastName,
          email,
          password,
          passwordConfirmation,
          phoneNumber,
          notifyBySms,
          chargeToAccount,
          studentNumber,
        },
      },
    })

    if (data.updateAccount) {
      commit('updateUserCredentials', data.updateAccount)
      commit('setUpdateTime', data.updateAccount.updatedAt)
    }

    return data
  },

  async updateUserAddresses(
    { commit },
    { userId, billingAddress, shippingAddress }
  ) {
    const { data } = await apolloClient.mutate({
      mutation: updateAddressesMutation,
      variables: {
        userId,
        billingAddress,
        shippingAddress,
      },
    })
    if (data.updateAddresses) {
      commit('updateUserAddresses', data.updateAddresses)
      commit('setUpdateTime', data.updateAddresses.updatedAt)
    }
  },

  async updateRole({ commit }, { id, role }) {
    const { data } = await apolloClient.mutate({
      mutation: updateRoleMutation,
      variables: {
        id,
        role,
      },
    })

    if (data.updateRole) {
      const newRole = data.updateRole ? data.updateRole.role : null
      commit('updateUserRole', newRole)
      commit('setUpdateTime', data.updateRole.updatedAt)
    }
  },

  async updateAccountClientNumber({ commit }, payload) {
    const { data } = await apolloClient.mutate({
      mutation: updateClientNumberMutation,
      variables: {
        ...payload,
      },
    })

    if (data.account) {
      commit('updateUserMembership', data.account)
      commit('setUpdateTime', data.account.updatedAt)
    }
  },
}

const mutations = {
  setUser(state, payload) {
    state.user = payload
  },
  updateUserAddresses(state, { billingAddress, shippingAddress }) {
    state.user.billingAddress = billingAddress
    state.user.shippingAddress = shippingAddress
  },
  updateUserRole(state, role) {
    state.user.role = role
  },
  updateUserCredentials(state, { email, phoneNumber, notifyBySms }) {
    state.user.email = email
    state.user.phoneNumber = phoneNumber
    state.user.notifyBySms = notifyBySms
  },
  updateUserMembership(state, { clientNumber, memberType }) {
    state.user.clientNumber = clientNumber
    state.user.memberType = memberType
  },
  setUpdateTime(state, payload) {
    state.user.updatedAt = payload
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
