<template>
  <div>
    <BaseHeader class="pb-6" type="default">
      <b-row align-v="center" class="py-4">
        <b-col>
          <h1 class="h2 text-white d-inline-block mb-0">
            Ajouter un appel à l'action
          </h1>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </BaseHeader>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="12">
          <CallToActionForm
            title="Ajouter un appel à l'action"
            :on-success="save"
            redirect="/promotions/call-to-actions/index"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { BaseHeader, RouteBreadcrumb } from '@coop-zone/coop-zone-argon'
import CallToActionForm from '@/components/Promotions/CallToActions/CallToActionForm'
import ErrorHandlerMixin from '@/mixins/errorHandler.mixin'

export default {
  components: {
    BaseHeader,
    RouteBreadcrumb,
    CallToActionForm,
  },
  mixins: [ErrorHandlerMixin],
  created() {
    this.$store.commit('callToActions/SET_CURRENT_CALL_TO_ACTION', {})
  },
  methods: {
    async save() {
      try {
        await this.$store.dispatch('callToActions/createCallToAction')
      } catch (error) {
        this.handleError(error)
        throw error
      }
    },
  },
}
</script>
