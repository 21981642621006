import Vue from 'vue'
import { apolloClient } from '@/apollo'

const getLandingPagesQuery = require('../apollo/queries/GetLandingPages.gql')
const getLandingPageQuery = require('../apollo/queries/GetLandingPage.gql')
const createMutation = require('../apollo/mutations/CreateLandingPage.gql')
const updateMutation = require('../apollo/mutations/UpdateLandingPage.gql')
const deleteMutation = require('../apollo/mutations/DeleteLandingPage.gql')

const state = {
  id: null,
  fields: {
    slug: null,
    slugged: null,
    name: null,
    homepage: false,
    visible: false,
    content: [],
  },
  pages: [],
}

const actions = {
  async getPages({ commit }) {
    const {
      data: { landingPages },
    } = await apolloClient.query({
      query: getLandingPagesQuery,
    })
    commit('SET_PAGES', landingPages)
  },
  async fetch({ commit }, id) {
    const { data } = await apolloClient.query({
      query: getLandingPageQuery,
      variables: { id },
    })

    commit('SET_PAGE', data.page)
    commit('seo/SET_CONTENT', data.page.seo || {}, { root: true })
  },
  reset({ commit }) {
    commit('SET_ID', null)
    commit('SET_FIELD_SLUG', null)
    commit('SET_FIELD_NAME', null)
    commit('SET_FIELD_VISIBLE', false)
    commit('SET_HOMEPAGE', false)
    commit('SET_FIELD_CONTENT', [])
    commit('seo/SET_CONTENT', {}, { root: true })
  },

  async create({
    rootState: { seo },
    rootGetters,
    state: {
      fields: { name, slugged: slug, visible, homepage },
    },
  }) {
    const content = rootGetters['blocks/getContent']

    await apolloClient.query({
      query: createMutation,
      variables: {
        page: {
          name,
          slug,
          visible,
          homepage,
          content,
          seo,
        },
      },
    })
  },
  async update({
    rootState: { seo },
    rootGetters,
    commit,
    state: {
      id,
      fields: { name, slugged: slug, visible, homepage },
    },
  }) {
    const content = rootGetters['blocks/getContent']
    const { data } = await apolloClient.query({
      query: updateMutation,
      variables: {
        page: {
          id,
          name,
          slug,
          visible,
          homepage,
          content,
          seo,
        },
      },
    })

    commit('SET_PAGE', data.page)
    commit('seo/SET_CONTENT', data.page.seo || {}, { root: true })
  },

  async delete({ commit }, id) {
    await apolloClient.query({
      query: deleteMutation,
      variables: {
        id,
      },
    })
    commit('REMOVE_PAGE', id)
  },
}

const getters = {
  getName: (state) => state.fields.name,
  getContent: (state) => state.fields.content,
}

const mutations = {
  SET_PAGES(state, payload) {
    state.pages = payload
  },
  SET_PAGE(state, { id = null, name, slug, visible, content, homepage }) {
    state.id = id
    state.fields.name = name
    state.fields.slug = slug
    state.fields.visible = visible
    state.fields.content = content
    state.fields.homepage = homepage
  },
  SET_ID(state, payload) {
    state.id = payload
  },
  SET_FIELD_VISIBLE(state, payload) {
    state.fields.visible = payload
  },
  SET_FIELD_NAME(state, payload) {
    state.fields.name = payload
  },
  SET_FIELD_SLUG(state, payload) {
    state.fields.slug = payload
  },
  SET_FIELD_SLUGGED(state, payload) {
    state.fields.slugged = payload
  },
  SET_FIELD_CONTENT(state, payload) {
    state.fields.content = payload
  },
  SET_HOMEPAGE(state, payload) {
    state.fields.homepage = payload
  },
  REMOVE_PAGE(state, pageId) {
    const index = state.pages.findIndex(({ id }) => id === pageId)
    if (index >= 0) {
      state.pages.splice(index, 1)
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
