<template>
  <BaseForm
    success-message="Transporteur enregistrée"
    :on-success="onSuccess"
    :redirect="redirect"
  >
    <b-card>
      <div slot="header">
        <b-row align-v="center">
          <b-col sm="12" lg="6">
            <h6 class="text-uppercase text-muted ls-1 mb-1">
              {{ title }}
            </h6>
          </b-col>
          <b-col sm="12" lg="6" class="text-right">
            <slot name="actions" />
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col>
          <BaseInput
            v-model="name"
            label="Nom du transporteur"
            type="text"
            rules="required"
          />
        </b-col>
        <b-col>
          <BaseInput
            v-model="urlTemplate"
            label="URL de tracking"
            placeholder="Ex.: https://www.canadapost.com/tracking/{number}/infos"
            type="text"
            rules="required"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <TagsInput
            v-model="identifiers"
            label="Clés d'identification"
            rules="required"
          />
          <p class="text-muted mt--4">
            <small>
              La valeur pour les clés d'identification est sensible à la casse
              (case sensitive)
            </small>
          </p>
        </b-col>
      </b-row>
      <b-row align-h="end">
        <b-col cols="auto">
          <BaseButton size="lg" type="primary" native-type="submit">
            Sauvegarder
          </BaseButton>
        </b-col>
      </b-row>
    </b-card>
  </BaseForm>
</template>
<script>
import { BaseButton, BaseInput, TagsInput } from '@coop-zone/coop-zone-argon'
import BaseForm from '@/components/forms/BaseForm'

export default {
  components: {
    BaseForm,
    BaseButton,
    BaseInput,
    TagsInput,
  },
  props: {
    onSuccess: {
      type: Function,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    redirect: {
      type: [String, Boolean],
      default: false,
    },
  },
  computed: {
    name: {
      get() {
        return this.$store.state.shipping.transporters.name
      },
      set(name) {
        this.$store.commit('shipping/transporters/SET_NAME', name)
      },
    },
    urlTemplate: {
      get() {
        return this.$store.state.shipping.transporters.urlTemplate
      },
      set(template) {
        this.$store.commit('shipping/transporters/SET_URL_TEMPLATE', template)
      },
    },
    identifiers: {
      get() {
        return this.$store.state.shipping.transporters.identifiers
      },
      set(identifiers) {
        this.$store.commit('shipping/transporters/SET_IDENTIFIERS', identifiers)
      },
    },
  },
}
</script>

<style lang="sass" scoped>
::v-deep .tags-input__wrapper .form-control
  flex: 1
  max-width: 100%
</style>
