import { apolloClient } from '@/apollo'

const getFilterValueGroupsQuery = require('../../apollo/queries/GetFilterValueGroups.gql')
const getFilterValueGroupQuery = require('../../apollo/queries/GetFilterValueGroup.gql')
const createFitlerValueGroupMutation = require('../../apollo/mutations/CreateFilterValueGroup.gql')
const updateFilterValueGroupMutation = require('../../apollo/mutations/UpdateFilterValueGroup.gql')
const deleteFilterValueGroupMutation = require('../../apollo/mutations/DeleteFilterValueGroup.gql')

const state = {
  filterValueGroups: [],
  id: null,
  name: '',
}

const actions = {
  async getFilterValueGroups({ commit }, filterId) {
    const {
      data: { filterValueGroups },
    } = await apolloClient.query({
      query: getFilterValueGroupsQuery,
      variables: {
        filterId,
      },
    })
    commit('SET_FILTER_VALUE_GROUPS', filterValueGroups)
  },

  async getFilterValueGroup({ commit }, { filterId, filterValueGroupId }) {
    const {
      data: { filterValueGroup },
    } = await apolloClient.query({
      query: getFilterValueGroupQuery,
      variables: {
        filterId,
        filterValueGroupId,
      },
    })

    commit('SET_FILTER_VALUE_GROUP', filterValueGroup)
  },

  createFilterValueGroup({ state: { name } }, filterId) {
    apolloClient.query({
      query: createFitlerValueGroupMutation,
      variables: {
        filterId,
        filterValueGroup: { name },
      },
    })
  },

  async updateFilterValueGroup({ state: { id, name } }, filterId) {
    apolloClient.query({
      query: updateFilterValueGroupMutation,
      variables: {
        filterId,
        filterValueGroupId: id,
        filterValueGroup: { name },
      },
    })
  },

  async deleteFilterValueGroup({ commit }, { filterId, filterValueGroupId }) {
    await apolloClient.query({
      query: deleteFilterValueGroupMutation,
      variables: {
        filterId,
        filterValueGroupId,
      },
    })

    commit('REMOVE_FILTER_VALUE_GROUP', filterValueGroupId)
  },
}

const mutations = {
  SET_FILTER_VALUE_GROUPS(state, filterValueGroups) {
    state.filterValueGroups = filterValueGroups
  },
  SET_FILTER_VALUE_GROUP(state, { id, name }) {
    state.id = id
    state.name = name
  },
  SET_NAME(state, name) {
    state.name = name
  },
  REMOVE_FILTER_VALUE_GROUP(state, filterValueGroupId) {
    const index = state.filterValueGroups.findIndex(
      ({ id }) => id === filterValueGroupId
    )
    if (index >= 0) {
      state.filterValueGroups.splice(index, 1)
    }
  },
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
