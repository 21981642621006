<i18n>
{
  "fr" : {
    "DeleteSuccess": "La règle a bien été supprimée"
  }
}
</i18n>
<template>
  <div class="content">
    <base-header class="pb-6 bg-default">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h2 text-white d-inline-block mb-0">Frais de transport</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-card no-body class="mb-4">
        <b-row slot="header" align-v="center">
          <b-col cols="8">
            <h3 class="mb-0">Règles de transport</h3>
          </b-col>
          <b-col cols="4" class="text-right">
            <router-link
              class="btn btn-sm btn-primary"
              to="/shipping/delivery_rules/add"
              data-cy="deliveryrule-add-link"
            >
              Ajouter une règle
            </router-link>
          </b-col>
        </b-row>

        <el-table
          :data="rules"
          row-key="id"
          header-row-class-name="thead-light"
        >
          <el-table-column
            label="Escompte après"
            prop="max"
            min-width="75px"
            :render-header="renderHeaderTooltip"
          />
          <el-table-column label="Prix de base" min-width="160px">
            <template slot-scope="{ row }">
              <span>{{ row.basePrice | currencyFormat }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Prix additionnel" min-width="160px">
            <template slot-scope="{ row }">
              <span>{{ row.additionalPrice | currencyFormat }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Gratuité" min-width="160px">
            <template slot-scope="{ row }">
              <span>{{ row.freeAfter | currencyFormat }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Zone" min-width="160px">
            <template slot-scope="{ row }">
              <span v-if="row.Zone">{{ row.Zone.name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Groupe de taxe" min-width="160px">
            <template slot-scope="{ row }">
              <span v-if="row.TaxGroup">{{ row.TaxGroup.name }}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="180px" align="right">
            <template slot-scope="{ row }" class="table-actions">
              <router-link
                class="table-action table-action-edit"
                :to="`/shipping/delivery_rules/edit/${row.id}`"
              >
                <i class="fa fa-edit" />
              </router-link>
              <a
                v-confirm-delete="{
                  onConfirm: () => deleteRule(row),
                  confirmText: 'La règle sera supprimée définitivement',
                  successText: $t('DeleteSuccess'),
                }"
                href="#!"
                class="table-action table-action-delete"
                data-toggle="tooltip"
                data-original-title="Supprimer"
              >
                <i class="fas fa-trash" />
              </a>
            </template>
          </el-table-column>
        </el-table>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import { BaseHeader, RouteBreadcrumb } from '@coop-zone/coop-zone-argon'
import { mapGetters } from 'vuex'
import ErrorHandlerMixin from '@/mixins/errorHandler.mixin'

export default {
  components: {
    BaseHeader,
    RouteBreadcrumb,
  },
  mixins: [ErrorHandlerMixin],
  computed: {
    ...mapGetters('shipping/rules', { rules: 'getRules' }),
  },
  created() {
    this.$store.dispatch('shipping/rules/getAllRules')
  },
  methods: {
    async deleteRule(rule) {
      try {
        await this.$store.dispatch('shipping/rules/deleteRule', rule.id)
      } catch (error) {
        this.handleError(error)
        throw error
      }
    },
    renderHeaderTooltip(h, { column }) {
      return h('span', [
        `${column.label} `,
        h(
          'el-tooltip',
          {
            attrs: {
              content:
                "Après le nombre d'items spécifié, le prix additionnel s'applique plutôt que le prix de base",
              effect: 'light',
              placement: 'top-start',
            },
          },
          [
            h('i', {
              class: 'far fa-question-circle',
            }),
          ]
        ),
      ])
    },
  },
}
</script>
