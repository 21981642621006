import { formError, logicError } from './errors/index.js'
import users from './users/index.js'
import establishments from './establishments/index.js'
import schoolLists from './schoolLists/index.js'
import handlingFees from './handlingFees/index.js'
import promoCodes from './promoCodes/index.js'
import orders from './orders/index.js'
import exports from './exports/index.js'

export default {
  schoolLists,
  establishments,
  users,
  orders,
  exports,
  handlingFees,
  formError,
  logicError,
  promoCodes,
}
