<template>
  <BaseForm
    success-message="Le remboursement a été sauvegardé"
    :on-success="onSuccess"
    :redirect="redirect"
    :toast-only="true"
  >
    <b-card>
      <div slot="header">
        <b-row>
          <b-col cols="12" sm="6">
            <p class="h6 text-uppercase text-muted ls-1 mb-1">
              Consulter un remboursement
            </p>
            <h2>Remboursement ID# {{ transactionId }}</h2>
            <p class="mb-0">
              <small v-if="createdAt">
                <strong>Date de création:</strong>
                {{ createdAt | dateFormat }}
              </small>
            </p>
            <p class="mb-0">
              <small v-if="updatedAt">
                <strong>Dernière modification:</strong>
                {{ updatedAt | dateFormat }}
              </small>
            </p>
          </b-col>

          <b-col v-if="refund.OrderId" align="end" cols="12" sm="6">
            <a
              class="btn base-button btn-default btn-sm"
              :href="`/orders/show/${refund.OrderId}`"
              role="button"
            >
              <i class="far fa-eye" />
              <span>Voir la commande</span>
            </a>
          </b-col>
        </b-row>
      </div>

      <b-row>
        <b-col cols="12" sm="6">
          <BaseInput
            label="ID# de transaction"
            :disabled="true"
            rules="required"
            :value="transactionId"
          >
          </BaseInput>
        </b-col>

        <b-col cols="12" sm="6">
          <BaseInput
            label="ID# de transaction du remboursement"
            :disabled="true"
            rules="required"
            :value="refundTransactionId"
          >
          </BaseInput>
        </b-col>

        <b-col cols="12" sm="6">
          <BaseInput
            label="Montant du remboursement"
            :disabled="true"
            rules="required"
            :value="amount | currencyFormat"
          >
          </BaseInput>
        </b-col>

        <b-col cols="12" sm="6">
          <BaseInput label="Statut" rules="required">
            <RefundStatusSelect v-model="status" />
          </BaseInput>
        </b-col>
      </b-row>

      <b-row v-if="error" class="mb-3">
        <b-col>
          <pre><code>{{ error }}</code></pre>
        </b-col>
      </b-row>

      <b-row align-h="end">
        <b-col cols="auto">
          <BaseButton size="lg" type="primary" native-type="submit">
            Sauvegarder
          </BaseButton>
        </b-col>
      </b-row>
    </b-card>
  </BaseForm>
</template>

<script>
import { mapState } from 'vuex'
import { BaseButton, BaseInput } from '@coop-zone/coop-zone-argon'
import BaseForm from '@/components/forms/BaseForm'
import RefundStatusSelect from '@/components/Inputs/RefundStatusSelect'

export default {
  components: {
    BaseForm,
    BaseButton,
    BaseInput,
    RefundStatusSelect,
  },
  props: {
    onSuccess: {
      type: Function,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    redirect: {
      type: [String, Boolean],
      default: false,
    },
  },
  computed: {
    ...mapState('orders/refunds', ['refund']),
    transactionId: {
      get() {
        return this.refund.transactionId
      },
    },
    amount: {
      get() {
        return this.refund.amount
      },
    },
    status: {
      get() {
        return this.$t(`orders.refund.statuses.${this.refund.status}`)
      },
      set(status) {
        this.$store.commit('orders/refunds/SET_STATUS', status)
      },
    },
    error: {
      get() {
        return this.refund.error
      },
    },
    refundTransactionId: {
      get() {
        return this.refund.refundTransactionId
      },
    },
    createdAt: {
      get() {
        return this.refund.createdAt
      },
    },
    updatedAt: {
      get() {
        return this.refund.updatedAt
      },
    },
  },
}
</script>

<style lang="sass" scoped>
pre
  background: #272822
  padding: 1em
  margin: 0.5em 0
  overflow: auto
  border-radius: 0.3em
  color: #f8f8f2
  font-family: monospace
  text-align: left
  white-space: pre
  word-spacing: normal
  word-break: normal
  word-wrap: normal
  line-height: 1.5
  -moz-tab-size: 4
  -o-tab-size: 4
  tab-size: 4
  hyphens: none
  font-size: 12px
</style>
