import Vue from 'vue'
import uniqid from 'uniqid'
import { apolloClient } from '@/apollo'

const getSchoolListCategoriesQuery = require('../../apollo/queries/GetSchoolListCategories.gql')
const getSchoolListCategoryQuery = require('../../apollo/queries/GetSchoolListCategory.gql')
const createSchoolListCategoryMutation = require('../../apollo/mutations/CreateSchoolListCategory.gql')
const updateSchoolListCategoryMutation = require('../../apollo/mutations/UpdateSchoolListCategory.gql')
const deleteSchoolListCategoryMutation = require('../../apollo/mutations/DeleteSchoolListCategory.gql')

const getItemIndex = (category, { id }) =>
  category.ListCategoryItems.findIndex((x) => x.id === id)

const getSuggestionIndex = (category, { id }) =>
  category.ListCategorySuggestions.findIndex((x) => x.id === id)

const state = {
  categories: {},
  newCategoryIds: [],
  editingCategoryIds: [],
  editingItemIds: [],
  newItemIds: [],
}

const actions = {
  async getCategories({ commit }, payload) {
    const { establishmentId, listId } = payload

    const {
      data: { categories },
    } = await apolloClient.query({
      query: getSchoolListCategoriesQuery,
      variables: {
        establishmentId,
        listId,
      },
    })
    commit('SET_CATEGORIES', categories)
  },

  async saveCategory(
    { commit, getters },
    { listId, establishmentId, category }
  ) {
    const query = getters.categoryIsNew(category)
      ? createSchoolListCategoryMutation
      : updateSchoolListCategoryMutation

    const variables = {
      schoolListCategory: {
        name: category.name,
        position: category.position,
        ListId: listId,
        EstablishmentId: establishmentId,
      },
    }

    if (category.ListCategoryItems) {
      variables.schoolListCategory.listCategoryItems =
        category.ListCategoryItems.map((item) => ({
          name:
            item.name || (item.products.length ? item.products[0].name : ''),
          type: item.type,
          required: item.required,
          productIds: item.products
            ? item.products.map(({ id }) => parseInt(id, 10))
            : [],
          suggestedQuantity: isNaN(parseInt(item.suggestedQuantity, 10))
            ? null
            : parseInt(item.suggestedQuantity, 10),
          neededStudentsQuantity: isNaN(
            parseInt(item.neededStudentsQuantity, 10)
          )
            ? null
            : parseInt(item.neededStudentsQuantity, 10),
          nbStudents: isNaN(parseInt(item.nbStudents, 10))
            ? null
            : parseInt(item.nbStudents, 10),
          commentBuyer: item.commentBuyer || null,
          commentStudent: item.commentStudent || null,
          requiredNextSemester: !!item.requiredNextSemester,
          isMandatory: !!item.isMandatory,
        }))
    } else {
      variables.schoolListCategory.listCategoryItems = []
    }

    if (category.ListCategorySuggestions) {
      variables.schoolListCategory.listCategorySuggestions =
        category.ListCategorySuggestions.map((item) => ({
          name:
            item.name || (item.products.length ? item.products[0].name : ''),
          type: item.type,
          productIds: item.products
            ? item.products.map(({ id }) => parseInt(id, 10))
            : [],
        }))
    } else {
      variables.schoolListCategory.listCategorySuggestions = []
    }

    if (getters.categoryIsNew(category) === false) {
      variables.schoolListCategory.id = category.id
    } else {
      commit('REMOVE_CATEGORY', category)
    }

    const { data } = await apolloClient.query({
      query,
      variables,
    })
    const mutation = getters.categoryIsNew(category)
      ? 'ADD_CATEGORY'
      : 'UPDATE_CATEGORY'
    commit(mutation, data.category)

    commit('SET_CATEGORY_EDIT_STATE', { category, editState: false })
    commit('SET_CATEGORY_NEW_STATE', { category, newState: false })
  },

  async deleteCategory({ commit }, payload) {
    const { establishmentId, category } = payload

    await apolloClient.query({
      query: deleteSchoolListCategoryMutation,
      variables: {
        establishmentId,
        listId: category.ListId,
        categoryId: category.id,
      },
    })
    commit('REMOVE_CATEGORY', category)
  },

  async cancelCategoryEdit({ commit }, payload) {
    const { establishmentId, category } = payload
    const {
      data: { schoolListCategory },
    } = await apolloClient.query({
      query: getSchoolListCategoryQuery,
      variables: {
        establishmentId,
        listId: category.ListId,
        categoryId: category.id,
      },
    })

    commit('UPDATE_CATEGORY', schoolListCategory)
    commit('SET_CATEGORY_EDIT_STATE', {
      category: schoolListCategory,
      editState: false,
    })
  },

  async updateCategoriesPosition(
    { dispatch },
    { list, listId, establishmentId }
  ) {
    for (let i = 0; i < list.length; i += 1) {
      list[i].position = i
      // eslint-disable-next-line no-await-in-loop
      await dispatch('saveCategory', {
        category: list[i],
        listId,
        establishmentId,
      })
    }
  },

  addNewCategory({ commit, state }) {
    const newCategory = {
      id: uniqid(),
      position: Object.values(state.categories).length,
    }
    commit('ADD_CATEGORY', newCategory)
    commit('SET_CATEGORY_NEW_STATE', { category: newCategory, newState: true })
  },

  removeNewCategory({ commit }, category) {
    commit('REMOVE_CATEGORY', category)
    commit('SET_CATEGORY_NEW_STATE', { category, newState: false })
  },

  addNewItem({ commit }, { category, item }) {
    const newItem = {
      id: uniqid(),
      ...item,
    }
    commit('ADD_CATEGORY_ITEM', { category, item: newItem })
    commit('SET_ITEM_EDIT_STATE', { item: newItem, editState: true })
    commit('SET_ITEM_NEW_STATE', { item: newItem, newState: true })
  },

  removeItem({ commit }, { category, item }) {
    commit('REMOVE_CATEGORY_ITEM', { category, item })
    commit('SET_ITEM_EDIT_STATE', { item, editState: false })
    commit('SET_ITEM_NEW_STATE', { item, newState: false })
  },

  addItemToCategory({ commit }, payload) {
    const { item } = payload
    commit('UPDATE_CATEGORY_ITEM', payload)
    commit('SET_ITEM_EDIT_STATE', { item, editState: false })
    commit('SET_ITEM_NEW_STATE', { item, newState: false })
  },

  updateCategoryItems({ commit }, payload) {
    commit('UPDATE_CATEGORY_ITEMS', payload)
  },

  updateCategoryItemProducts({ commit }, payload) {
    commit('UPDATE_CATEGORY_ITEM_PRODUCTS', payload)
  },

  addSuggestionToCategory({ commit }, { category, suggestion }) {
    const newSuggestion = {
      id: uniqid(),
      ...suggestion,
    }
    commit('ADD_CATEGORY_SUGGESTION', { category, suggestion: newSuggestion })
  },

  removeSuggestion({ commit }, { category, suggestion }) {
    commit('REMOVE_CATEGORY_SUGGESTION', { category, suggestion })
  },
}

const mutations = {
  SET_CATEGORIES(state, categories) {
    state.categories = categories.reduce(
      (acc, category) => ({ ...acc, [category.id]: category }),
      {}
    )
  },
  ADD_CATEGORY(state, category) {
    Vue.set(state.categories, category.id, category)
  },
  UPDATE_CATEGORY(state, category) {
    Vue.set(state.categories, category.id, category)
  },

  REMOVE_CATEGORY(state, category) {
    Vue.delete(state.categories, category.id)
  },
  SET_CATEGORY_EDIT_STATE(state, { category, editState }) {
    if (editState === true) {
      state.editingCategoryIds.push(category.id)
    } else if (state.editingCategoryIds.includes(category.id)) {
      state.editingCategoryIds.splice(
        state.editingCategoryIds.findIndex(
          (categoryId) => category.id === categoryId
        ),
        1
      )
    }
  },

  SET_ITEM_EDIT_STATE(state, { item, editState }) {
    if (editState === true) {
      state.editingItemIds.push(item.id)
    } else if (state.editingItemIds.includes(item.id)) {
      state.editingItemIds.splice(
        state.editingItemIds.findIndex((itemId) => item.id === itemId),
        1
      )
    }
  },

  SET_CATEGORY_NEW_STATE(state, { category, newState }) {
    if (newState === true) {
      state.newCategoryIds.push(category.id)
    } else if (state.newCategoryIds.includes(category.id)) {
      state.newCategoryIds.splice(
        state.newCategoryIds.findIndex(
          (categoryId) => category.id === categoryId
        ),
        1
      )
    }
  },

  SET_ITEM_NEW_STATE(state, { item, newState }) {
    if (newState === true) {
      state.newItemIds.push(item.id)
    } else if (state.newItemIds.includes(item.id)) {
      state.newItemIds.splice(
        state.newItemIds.findIndex((itemId) => item.id === itemId),
        1
      )
    }
  },

  UPDATE_CATEGORY_POSITION(state, { category, index }) {
    state.categories[category.id].position = index
  },

  ADD_CATEGORY_ITEM(state, { category, item }) {
    if (state.categories[category.id].ListCategoryItems) {
      state.categories[category.id].ListCategoryItems.push(item)
    } else {
      Vue.set(state.categories[category.id], 'ListCategoryItems', [item])
    }
  },

  ADD_CATEGORY_SUGGESTION(state, { category, suggestion }) {
    if (state.categories[category.id].ListCategorySuggestions) {
      state.categories[category.id].ListCategorySuggestions.push(suggestion)
    } else {
      Vue.set(state.categories[category.id], 'ListCategorySuggestions', [
        suggestion,
      ])
    }
  },

  REMOVE_CATEGORY_ITEM(state, { category, item }) {
    const itemIndex = getItemIndex(state.categories[category.id], item)
    state.categories[category.id].ListCategoryItems.splice(itemIndex, 1)
  },

  REMOVE_CATEGORY_SUGGESTION(state, { category, suggestion }) {
    const suggestionIndex = getSuggestionIndex(
      state.categories[category.id],
      suggestion
    )
    state.categories[category.id].ListCategorySuggestions.splice(
      suggestionIndex,
      1
    )
  },

  UPDATE_CATEGORY_ITEM(state, { category, item }) {
    const itemIndex = getItemIndex(state.categories[category.id], item)
    Vue.set(state.categories[category.id].ListCategoryItems, itemIndex, item)
  },

  UPDATE_CATEGORY_ITEMS(state, { category, items }) {
    Vue.set(state.categories[category.id], 'ListCategoryItems', items)
  },

  UPDATE_CATEGORY_ITEM_PRODUCTS(state, { category, item, products }) {
    const itemIndex = getItemIndex(state.categories[category.id], item)
    Vue.set(
      state.categories[category.id].ListCategoryItems[itemIndex],
      'products',
      products
    )
  },
}

const getters = {
  categoryIsBeingEdited:
    (state) =>
      ({ id }) =>
        state.editingCategoryIds.includes(id),
  categoryIsNew:
    (state) =>
      ({ id }) =>
        state.newCategoryIds.includes(id),
  categoriesAreBeingCreated: (state) => state.newCategoryIds.length > 0,
  categoriesAreBeingEdited: (state) => state.editingCategoryIds.length > 0,
  itemIsBeingEdited:
    (state) =>
      ({ id }) =>
        state.editingItemIds.includes(id),
  itemIsNew:
    (state) =>
      ({ id }) =>
        state.newItemIds.includes(id),
  categoryItemsAreBeingEdited: (state) => (category) => {
    if (!state.categories[category.id].ListCategoryItems) {
      return false
    }
    const itemIds = state.categories[category.id].ListCategoryItems.map(
      (item) => item.id
    )
    const anyItemsBeingEdited = itemIds.some((id) =>
      state.editingItemIds.includes(id)
    )
    const anyNewItems = itemIds.some((id) => state.newItemIds.includes(id))
    return anyItemsBeingEdited || anyNewItems
  },
  sortedCategories: (state) =>
    Object.values(state.categories).sort((a, b) => a.position - b.position),
  getFirstCategory: (state) => Object.values(state.categories)[0],
  schoolListHasItems: (state) =>
    Object.values(state.categories).find(
      ({ ListCategoryItems = null }) =>
        ListCategoryItems && ListCategoryItems.length > 0
    ),
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
