import Vue from 'vue'

import { apolloClient } from '@/apollo'

const getSchoolSessionsQuery = require('../apollo/queries/GetSchoolSessions.gql')
const getManageableSchoolSessionsQuery = require('../apollo/queries/GetManageableSchoolSessions.gql')
const getSchoolSessionQuery = require('../apollo/queries/GetSchoolSession.gql')
const createSchoolSessionMutation = require('../apollo/mutations/CreateSchoolSession.gql')
const updateSchoolSessionMutation = require('../apollo/mutations/UpdateSchoolSession.gql')
const deleteSchoolSessionMutation = require('../apollo/mutations/DeleteSchoolSession.gql')

const state = {
  sessionsMap: {},
  id: null,
  name: null,
  slug: null,
  establishmentType: null,
  managementStartDate: '',
  managementEndDate: '',
  publishStartDate: '',
  publishEndDate: '',
}

const actions = {
  async getSessions({ commit }) {
    const {
      data: { schoolSessions },
    } = await apolloClient.query({
      query: getSchoolSessionsQuery,
    })

    commit('SET_SESSIONS', schoolSessions)
  },

  async getManageableSessions({ commit }, payload) {
    const {
      data: { schoolSessions },
    } = await apolloClient.query({
      query: getManageableSchoolSessionsQuery,
      variables: payload,
    })

    commit('SET_SESSIONS', schoolSessions)
  },

  async clearSessions({ commit }) {
    commit('SET_SESSIONS', [])
  },

  async getSession({ commit }, id) {
    const {
      data: { schoolSession },
    } = await apolloClient.query({
      query: getSchoolSessionQuery,
      variables: {
        sessionId: id,
      },
    })

    commit('SET_SESSION', schoolSession)
    commit('SET_CURRENT_SESSION', schoolSession)
  },

  async createSession({
    state: {
      name,
      establishmentType,
      managementStartDate,
      managementEndDate,
      publishStartDate,
      publishEndDate,
    },
    commit,
  }) {
    const {
      data: { createdSchoolSession },
    } = await apolloClient.query({
      query: createSchoolSessionMutation,
      variables: {
        schoolSession: {
          name,
          establishmentType,
          managementStartDate,
          managementEndDate,
          publishStartDate,
          publishEndDate,
        },
      },
    })

    commit('SET_SESSION', createdSchoolSession)
    commit('SET_CURRENT_SESSION', createdSchoolSession)
  },

  async updateSession({
    state: {
      id,
      name,
      slug,
      establishmentType,
      managementStartDate,
      managementEndDate,
      publishStartDate,
      publishEndDate,
    },
  }) {
    await apolloClient.query({
      query: updateSchoolSessionMutation,
      variables: {
        id,
        schoolSession: {
          name,
          slug,
          establishmentType,
          managementStartDate,
          managementEndDate,
          publishStartDate,
          publishEndDate,
        },
      },
    })
  },

  async deleteSession({ commit }, id) {
    await apolloClient.query({
      query: deleteSchoolSessionMutation,
      variables: {
        id,
      },
    })
    commit('DELETE_SESSION', id)
  },
}

const mutations = {
  SET_SESSIONS(state, payload) {
    state.sessionsMap = payload.reduce(
      (acc, session) => ({ ...acc, [session.id]: session }),
      {}
    )
  },

  SET_CURRENT_SESSION(
    state,
    {
      id,
      name,
      slug,
      establishmentType,
      managementStartDate,
      managementEndDate,
      publishStartDate,
      publishEndDate,
    }
  ) {
    state.id = id
    state.name = name
    state.slug = slug
    state.establishmentType = establishmentType
    state.managementStartDate = managementStartDate
    state.managementEndDate = managementEndDate
    state.publishStartDate = publishStartDate
    state.publishEndDate = publishEndDate
  },

  SET_SESSION(state, payload) {
    Vue.set(state.sessionsMap, payload.id, payload)
  },

  SET_NAME(state, payload) {
    state.name = payload
  },

  SET_SLUG(state, payload) {
    state.slug = payload
  },

  SET_ESTABLISHMENT_TYPE(state, payload) {
    state.establishmentType = payload
  },

  SET_MANAGEMENT_START_DATE(state, payload) {
    state.managementStartDate = payload
  },

  SET_MANAGEMENT_END_DATE(state, payload) {
    state.managementEndDate = payload
  },

  SET_PUBLISH_START_DATE(state, payload) {
    state.publishStartDate = payload
  },

  SET_PUBLISH_END_DATE(state, payload) {
    state.publishEndDate = payload
  },

  DELETE_SESSION(state, id) {
    Vue.delete(state.sessionsMap, id)
  },
}

const getters = {
  getSessions: (state) => Object.values(state.sessionsMap),
  getSessionsByEstablishmentTypes: (state) => (types) => {
    const sessions = Object.values(state.sessionsMap).filter((session) =>
      types.includes(session.establishmentType)
    )
    return sessions
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
