<template>
  <div>
    <BaseButton size="sm" :disabled="loading" @click="getFileUrl()">
      <i class="fa fa-download" />
      Télécharger
    </BaseButton>

    <a
      ref="downloadLink"
      style="height: 0px; width: 0px; visibility: hidden; display: block"
      :href="downloadUrl"
      :download="downloadFilename"
    />
  </div>
</template>

<script>
import { BaseButton } from '@coop-zone/coop-zone-argon'
import ErrorHandlerMixin from '@/mixins/errorHandler.mixin'

export default {
  components: {
    BaseButton,
  },
  mixins: [ErrorHandlerMixin],
  props: {
    filename: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      downloadUrl: null,
      downloadFilename: null,
    }
  },
  methods: {
    async getFileUrl() {
      try {
        this.$emit('loading', true)
        const data = await this.$store.dispatch(this.action, this.filename)
        this.downloadUrl = data.url
        this.downloadFilename = data.fileName
        this.$nextTick(() => {
          this.triggerDownload()
          this.$emit('loading', false)
        })
      } catch (error) {
        this.$emit('loading', false)
        this.handleError(error)
      }
    },

    triggerDownload() {
      this.$refs.downloadLink.click()
    },
  },
}
</script>
