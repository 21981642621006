<template>
  <BaseForm
    ref="filterValueForm"
    success-message="Valeur enregistrée"
    :on-success="save"
  >
    <b-card>
      <div slot="header">
        <b-row align-v="center">
          <b-col sm="12" lg="6">
            <p class="h6 text-uppercase text-muted ls-1 mb-1">
              {{ title }}
            </p>
            <h2 class="mb-0">
              {{ value }}
            </h2>
          </b-col>

          <b-col sm="12" lg="6" class="text-right">
            <slot name="actions" />
          </b-col>
        </b-row>
      </div>

      <b-row>
        <b-col col-sm="5">
          <BaseInput v-model="value" label="Valeur" rules="required" />
        </b-col>

        <b-col col-sm="5">
          <BaseInput label="Groupe">
            <el-select
              v-model="FilterValueGroup"
              placeholder="Sélectionner un groupe"
              value-key="id"
            >
              <el-option label="Sélectionner un groupe" :value="null" />
              <el-option
                v-for="group in filterValueGroups"
                :key="group.id"
                :label="group.name"
                :value="group"
              />
            </el-select>
          </BaseInput>
        </b-col>

        <b-col col-sm="auto">
          <BaseInput label="Couleur">
            <el-color-picker v-model="colorValue" />
          </BaseInput>
        </b-col>
      </b-row>
    </b-card>

    <b-row>
      <b-col>
        <VigieRulesInput
          v-slot="{ remove }"
          v-model="vigieRules"
          title="Règles vigie"
          @on-add="addRuleRow"
        >
          <el-table-column label="cat1" min-width="50px">
            <template #default="{ row }">
              <BaseInput v-model="row.level1" v-uppercase maxlength="3" />
            </template>
          </el-table-column>

          <el-table-column label="cat2" min-width="50px">
            <template #default="{ row }">
              <BaseInput v-model="row.level2" v-uppercase maxlength="3" />
            </template>
          </el-table-column>

          <el-table-column label="cat3" min-width="50px">
            <template #default="{ row }">
              <BaseInput v-model="row.level3" v-uppercase maxlength="3" />
            </template>
          </el-table-column>

          <el-table-column label="cat4" min-width="50px">
            <template #default="{ row }">
              <BaseInput v-model="row.level4" v-uppercase maxlength="3" />
            </template>
          </el-table-column>

          <el-table-column label="cat5" min-width="50px">
            <template #default="{ row }">
              <BaseInput v-model="row.level5" v-uppercase maxlength="3" />
            </template>
          </el-table-column>

          <el-table-column label="cat6" min-width="50px">
            <template #default="{ row }">
              <BaseInput v-model="row.level6" v-uppercase maxlength="3" />
            </template>
          </el-table-column>

          <el-table-column label="cat7" min-width="50px">
            <template #default="{ row }">
              <BaseInput v-model="row.level7" v-uppercase maxlength="3" />
            </template>
          </el-table-column>

          <el-table-column label="cat8" min-width="50px">
            <template #default="{ row }">
              <BaseInput v-model="row.level8" v-uppercase maxlength="3" />
            </template>
          </el-table-column>

          <el-table-column label="cat9" min-width="50px">
            <template #default="{ row }">
              <BaseInput v-model="row.level9" v-uppercase maxlength="3" />
            </template>
          </el-table-column>

          <el-table-column label="cat10" min-width="50px">
            <template #default="{ row }">
              <BaseInput v-model="row.level10" v-uppercase maxlength="3" />
            </template>
          </el-table-column>

          <el-table-column width="40px" align="right">
            <div slot-scope="{ row }" class="table-actions">
              <el-tooltip content="Supprimer" placement="top">
                <a
                  href="#!"
                  class="table-action table-action-delete"
                  data-toggle="tooltip"
                  data-original-title="Supprimer"
                  @click.prevent="remove(row)"
                >
                  <i class="fas fa-trash" />
                </a>
              </el-tooltip>
            </div>
          </el-table-column>
        </VigieRulesInput>
      </b-col>
    </b-row>

    <b-row align="end">
      <b-col>
        <BaseButton
          size="lg"
          type="primary"
          native-type="submit"
          @click="redirect = true"
        >
          Sauvegarder
        </BaseButton>
        <BaseButton
          v-if="!id"
          size="lg"
          type="primary"
          native-type="submit"
          @click="redirect = false"
        >
          Sauvegarder et en saisir un autre
        </BaseButton>
      </b-col>
    </b-row>
  </BaseForm>
</template>

<script>
import { BaseButton, BaseInput } from '@coop-zone/coop-zone-argon'
import { mapState } from 'vuex'
import BaseForm from '@/components/forms/BaseForm'
import VigieRulesInput from '@/components/VigieRulesInput'

export default {
  components: {
    BaseForm,
    BaseButton,
    BaseInput,
    VigieRulesInput,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      redirect: true,
    }
  },
  computed: {
    ...mapState('filters/valueGroups', ['filterValueGroups']),
    ...mapState('filters/values', ['id']),
    value: {
      get() {
        return this.$store.state.filters.values.value
      },
      set(value) {
        this.$store.commit('filters/values/SET_VALUE', value)
      },
    },
    FilterValueGroup: {
      get() {
        return this.$store.state.filters.values.FilterValueGroup
      },
      set(FilterValueGroup) {
        this.$store.commit(
          'filters/values/SET_FILTER_VALUE_GROUP',
          FilterValueGroup
        )
      },
    },
    colorValue: {
      get() {
        return this.$store.state.filters.values.colorValue
      },
      set(colorValue) {
        this.$store.commit('filters/values/SET_COLOR_VALUE', colorValue)
      },
    },
    vigieRules: {
      get() {
        return this.$store.state.filters.values.vigieRules
      },
      set(vigieRules) {
        this.$store.commit('filters/values/SET_VIGIE_RULES', vigieRules)
      },
    },
  },
  created() {
    this.$store.commit('filters/values/SET_FILTER_VALUE', {})
    this.$store.dispatch(
      'filters/valueGroups/getFilterValueGroups',
      this.$route.params.filterId
    )
    if (this.$route.params.filterValueId) {
      this.$store.dispatch('filters/values/getFilterValue', {
        filterId: this.$route.params.filterId,
        filterValueId: this.$route.params.filterValueId,
      })
    }
  },
  methods: {
    async save() {
      if (this.$route.params.filterValueId) {
        await this.$store.dispatch(
          'filters/values/updateFilterValue',
          this.$route.params.filterId
        )
        this.$router.push(`/filters/${this.$route.params.filterId}`)
      } else {
        await this.$store.dispatch(
          'filters/values/createFilterValue',
          this.$route.params.filterId
        )
        if (this.redirect) {
          this.$router.push(`/filters/${this.$route.params.filterId}`)
        } else {
          this.$store.commit('filters/values/SET_FILTER_VALUE', {
            id: this.$route.params.filterValueId,
          })
        }
      }
    },
    addRuleRow() {
      this.vigieRules.push({})
    },
  },
}
</script>
