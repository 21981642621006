<i18n>
{
  "en": {
    "Success": "Le rôle du compte a été changé avec succès."
  },
  "fr": {
    "Success": "Le rôle du compte a été changé avec succès."
  }
}
</i18n>
<template>
  <card>
    <b-row slot="header" align-v="center">
      <b-col cols="8">
        <h3 class="mb-0">Rôle</h3>
      </b-col>
    </b-row>

    <BaseForm
      id="mon-role"
      ref="user-role"
      classes="form"
      :on-success="updateRole"
      :success-message="$t('Success')"
      :toast-only="true"
    >
      <h2 class="heading-small text-muted mb-4">Rôle de l'utilisateur</h2>

      <BaseInput data-cy="select-role" label="Sélectionner le rôle du compte">
        <select v-model="role" class="form-control">
          <option :value="null">Aucun rôle</option>

          <option v-for="object in roles" :key="object.id" :value="object.id">
            {{ object.name }}
          </option>
        </select>
      </BaseInput>
    </BaseForm>
    <b-row slot="footer" align-v="center">
      <b-col cols="12">
        <BaseButton type="default" @click.prevent="$refs['user-role'].submit()">
          Enregistrer le rôle
        </BaseButton>
      </b-col>
    </b-row>
  </card>
</template>

<script>
import { BaseButton, BaseInput, Card } from '@coop-zone/coop-zone-argon'
import { mapState } from 'vuex'
import BaseForm from '@/components/forms/BaseForm'

export default {
  name: 'UserRoleEditForm',
  components: {
    BaseButton,
    BaseForm,
    BaseInput,
    Card,
  },
  data() {
    return {
      role: null,
      roles: [
        {
          id: 'ADMIN',
          name: 'Administrateur',
        },
        {
          id: 'EDITOR',
          name: 'Éditeur',
        },
        {
          id: 'SUPER_EDITOR',
          name: 'Super éditeur',
        },
        {
          id: 'MANAGER',
          name: 'Gestionnaire',
        },
        {
          id: 'TEACHER',
          name: 'Professeur',
        },
        {
          id: 'SCHOOL',
          name: 'École',
        },
      ],
    }
  },
  computed: {
    ...mapState('users', ['user']),
  },
  watch: {
    user: {
      handler(newVal) {
        this.role = newVal.role
      },
      immediate: true,
    },
  },
  mounted() {
    this.$store.dispatch('users/getUser', this.$route.params.id)
  },
  methods: {
    async updateRole() {
      await this.$store.dispatch('users/updateRole', {
        id: this.$route.params.id,
        role: this.role,
      })
      this.mode = 'readonly'
    },
  },
}
</script>
