<template>
  <BaseForm
    id="storeForm"
    success-message="Succursale enregistrée"
    :on-success="onSuccess"
    :redirect="redirect"
  >
    <BaseInput v-model="store.position" type="hidden" />
    <b-card>
      <div slot="header">
        <b-row align-v="center">
          <b-col sm="12" lg="6">
            <p class="h6 text-uppercase text-muted ls-1 mb-1">
              {{ title }}
            </p>
            <h2 class="mb-0">
              {{ name }}
            </h2>
          </b-col>
          <b-col sm="12" lg="6" class="text-right">
            <slot name="actions" />
          </b-col>
        </b-row>
      </div>

      <b-row>
        <b-col class="mt-4 mt-md-0">
          <BaseInput v-model="name" label="Nom" />
        </b-col>

        <b-col>
          <BaseInput v-model="address" label="Adresse" />
        </b-col>
        <b-col>
          <BaseInput
            v-model="vigieId"
            label="Identification Vigie"
            rules="required"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <BaseCheckbox v-model="active" class="mb-3">
            Afficher l'inventaire sur le site public
          </BaseCheckbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <BaseCheckbox v-model="visibleInFooter" class="mb-3">
            Afficher la succursale dans le menu
          </BaseCheckbox>
        </b-col>
      </b-row>
      <b-row align-h="end">
        <b-col cols="auto">
          <BaseButton size="lg" type="primary" native-type="submit">
            Sauvegarder
          </BaseButton>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <div slot="header">
        <b-row align-v="center">
          <b-col>
            <h2 class="mb-0">Contenu de la page succursale</h2>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col>
          <HtmlEditor
            v-model="content"
            :toolbar-options="[
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ size: ['large', false] }],
              ['bold', 'italic', 'underline', 'strike'], // toggled buttons
              ['link'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }], // outdent/indent
              ['image', 'video'],
              ['clean'],
            ]"
          />
        </b-col>
      </b-row>
    </b-card>
  </BaseForm>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  BaseButton,
  BaseCheckbox,
  BaseInput,
  HtmlEditor,
} from '@coop-zone/coop-zone-argon'
import BaseForm from '@/components/forms/BaseForm'

export default {
  components: {
    BaseButton,
    BaseCheckbox,
    BaseForm,
    BaseInput,
    HtmlEditor,
  },
  props: {
    onSuccess: {
      type: Function,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    redirect: {
      type: [String, Boolean],
      default: false,
    },
  },
  computed: {
    ...mapGetters('stores', ['store']),

    name: {
      get() {
        return this.$store.state.stores.name
      },

      set(name) {
        this.$store.commit('stores/SET_NAME', name)
      },
    },
    address: {
      get() {
        return this.$store.state.stores.address
      },
      set(address) {
        this.$store.commit('stores/SET_ADDRESS', address)
      },
    },
    content: {
      get() {
        return this.$store.state.stores.content
      },
      set(content) {
        this.$store.commit('stores/SET_CONTENT', content)
      },
    },

    vigieId: {
      get() {
        return this.$store.state.stores.vigieId
      },

      set(vigieId) {
        this.$store.commit('stores/SET_VIGIE_ID', vigieId)
      },
    },
    active: {
      get() {
        return this.$store.state.stores.active
      },
      set(active) {
        this.$store.commit('stores/SET_ACTIVE', active)
      },
    },
    visibleInFooter: {
      get() {
        return this.$store.state.stores.visibleInFooter
      },
      set(visibleInFooter) {
        this.$store.commit('stores/SET_VISIBLE_IN_FOOTER', visibleInFooter)
      },
    },
  },
}
</script>
