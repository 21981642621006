import Vue from 'vue'
import { apolloClient } from '@/apollo'

import groups from './groups.module'
import values from './values.module'
import valueGroups from './valueGroups.module'

const getFiltersQuery = require('../../apollo/queries/GetFilters.gql')
const searchFiltersQuery = require('../../apollo/queries/SearchFilters.gql')
const getFilterQuery = require('../../apollo/queries/GetFilter.gql')
const createFilterMutation = require('../../apollo/mutations/CreateFilter.gql')
const updateFilterMutation = require('../../apollo/mutations/UpdateFilter.gql')
const deleteFilterMutation = require('../../apollo/mutations/DeleteFilter.gql')

const state = {
  filters: [],
  id: null,
  name: '',
  expanded: true,
  FilterGroup: {},
  currentFilterId: null,
}

const actions = {
  async getFilters({ commit }, { filterGroupId = null } = {}) {
    const variables = {
      search: '',
    }

    if (filterGroupId !== null) {
      variables.filterGroupId = filterGroupId
    }

    const {
      data: { filters },
    } = await apolloClient.query({
      query: getFiltersQuery,
      variables,
    })
    commit('SET_FILTERS', filters)
  },
  async getFilter({ commit }, filterId) {
    const {
      data: { filter },
    } = await apolloClient.query({
      query: getFilterQuery,
      variables: { filterId },
    })
    commit('SET_FILTER', filter)
  },
  async createFilter({ commit, state: { name, expanded, FilterGroup } }) {
    const {
      data: { filter },
    } = await apolloClient.query({
      query: createFilterMutation,
      variables: {
        filter: {
          name,
          expanded,
          FilterGroupId: FilterGroup.id,
        },
      },
    })
    commit('SET_FILTER', filter)
  },
  updateFilter({ state: { id, name, expanded, FilterGroup } }) {
    apolloClient.query({
      query: updateFilterMutation,
      variables: {
        filterId: id,
        filter: {
          name,
          expanded,
          FilterGroupId: FilterGroup.id,
        },
      },
    })
  },
  async deleteFilter({ commit }, filterId) {
    await apolloClient.query({
      query: deleteFilterMutation,
      variables: {
        filterId,
      },
    })
    commit('REMOVE_FILTER', filterId)
  },
  async autocompleteFilters(context, payload) {
    const {
      data: { filters },
    } = await apolloClient.query({
      query: searchFiltersQuery,
      variables: payload,
    })
    return filters
  },
}

const mutations = {
  SET_FILTERS(state, filters) {
    state.filters = filters
  },
  SET_FILTER(state, { id, name = '', expanded = true, FilterGroup }) {
    state.id = id
    state.name = name
    state.expanded = expanded
    state.FilterGroup = FilterGroup
  },
  SET_NAME(state, name) {
    state.name = name
  },
  SET_EXPANDED(state, expanded) {
    state.expanded = expanded
  },
  SET_FILTER_GROUP(state, FilterGroup) {
    state.FilterGroup = FilterGroup
  },
  REMOVE_FILTER(state, filterId) {
    const index = state.filters.findIndex(({ id }) => id === filterId)
    if (index >= 0) {
      state.filters.splice(index, 1)
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    groups,
    values,
    valueGroups,
  },
}
