<template>
  <div class="product-autocomplete">
    <BaseInput
      append-icon="fas fa-search"
      :label="inputLabel"
      form-group-classes="mb-0"
      class="input-group-lg input-group-flush"
    >
      <el-select
        v-model="searchTerm"
        filterable
        remote
        reserve-keyword
        placeholder="Nom de produit, un SKU, un ISBN, un code Apple, un auteur, une marque"
        :remote-method="autocomplete"
        :loading="loading"
        @change="productSelected"
      >
        <el-option
          v-for="item in searchResults"
          :key="item.id"
          :label="item.name"
          :value="item"
          class="product-autocomplete-option"
        >
          <img
            v-if="item.images && item.images.length > 0"
            :src="item.images[0].thumbnail"
          /><span class="product-autocomplete-name"
            ><strong>{{ item.name }}</strong></span
          ><span></span
          ><span class="product-autocomplete-sku">{{ item.sku }}</span
          ><span class="product-autocomplete-vigieid">{{ item.vigieId }}</span
          ><BaseButton
            class="product-autocomplete-button"
            type="default"
            size="sm"
          >
            Sélectionner
          </BaseButton>
        </el-option>
      </el-select>
    </BaseInput>
  </div>
</template>
<script>
import { types as productTypes } from '@/data/products'
import { BaseInput, BaseButton } from '@coop-zone/coop-zone-argon'

export default {
  components: {
    BaseInput,
    BaseButton,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    tagsInputlabel: {
      type: String,
      default: 'Liste de SKU',
    },
    inputLabel: {
      type: String,
      default: '',
    },
    showTagsList: {
      type: Boolean,
      default: true,
    },
    omitTypes: {
      type: String,
      default: '',
    },
    autocompleteType: {
      type: Array,
      default() {
        if (this.omitTypes) {
          const toOmit = this.omitTypes.split(',')
          return productTypes.filter((elem) => !toOmit.includes(elem))
        }
        return productTypes
      },
    },
  },
  data() {
    return {
      searchTerm: null,
      searchResults: [],
      selectedProducts: [],
      loading: false,
    }
  },
  methods: {
    productSelected(product) {
      this.searchTerm = null
      this.searchResults = []
      this.$emit('change', product)
    },
    async autocomplete(search) {
      this.loading = true
      try {
        const { products } = await this.$store.dispatch(
          'products/autocomplete',
          {
            types: this.autocompleteType,
            search,
          }
        )
        this.searchResults = products
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
  },
}
</script>
<style lang="scss">
.product-autocomplete {
  .input-group-append {
    position: absolute;
    right: 0;
    height: 100%;
  }

  .input-group {
    &:hover .input-group-append .input-group-text {
      border-color: #c0c4cc;
    }

    &:focus-within .input-group-append .input-group-text {
      border-color: black;
    }
    .input-group-text {
      transition: all 0.15s ease-in-out;
    }
  }
}

.product-autocomplete-option {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px;
  border-bottom: 1px solid #ebeef5;

  img {
    display: block;
    width: 50px;
    flex-basis: 50px;
    flex-grow: 0;
    flex-shrink: 0;
  }
}

.product-autocomplete-name {
  text-align: left;
  flex-grow: 1;
  flex-shrink: 0;
  margin-left: 5px;
}

.product-autocomplete-sku {
  width: 140px;
  text-align: left;
}

.product-autocomplete-vigieid {
  margin-left: 5px;
  width: 100px;
  text-align: left;
}

.product-autocomplete-button {
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 10px;
}

.el-select-dropdown__item {
  height: auto;
}

.el-select-dropdown__wrap {
  max-height: 500px;
}
</style>
