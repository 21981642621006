<template>
  <div class="order-list">
    <OrderPrintTemplate
      v-for="order in orders"
      :key="order.id"
      :order="order"
      :order-items="
        order.completeTracking.processing.length
          ? order.completeTracking.processing
          : order.products
      "
    />
  </div>
</template>

<script>
import OrderPrintTemplate from '@/components/Orders/OrderPrintTemplate'

export default {
  components: {
    OrderPrintTemplate,
  },
  props: {
    orders: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="sass">
.order-list
  display: none
  width: 1024px
</style>
