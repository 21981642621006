import { RouteBreadcrumb, BaseHeader } from '@coop-zone/coop-zone-argon'
import { mapGetters } from 'vuex'
import ProgramForm from '@/components/SchoolLists/Program/ProgramForm'
import CourseForm from '@/components/SchoolLists/Course/CourseForm'
import GradeForm from '@/components/SchoolLists/Grade/GradeForm'

export default {
  components: {
    ProgramForm,
    CourseForm,
    GradeForm,
    RouteBreadcrumb,
    BaseHeader,
  },
  computed: {
    ...mapGetters('schoolLists', ['schoolList']),
    schoolListForm() {
      switch (this.$route.params.type) {
        case 'course':
          return CourseForm

        case 'grade':
          return GradeForm

        case 'program':
          return ProgramForm
      }
    },
  },
}
