<template>
  <BaseForm
    id="callToActionForm"
    success-message="Appel à l'action enregistré"
    :on-success="onSuccess"
    :redirect="redirect"
  >
    <b-card>
      <div slot="header">
        <b-row align-v="center">
          <b-col sm="12" lg="6">
            <p class="h6 text-uppercase text-muted ls-1 mb-1">
              {{ title }}
            </p>
            <h2 class="mb-0">
              {{ label }}
            </h2>
          </b-col>
          <b-col sm="12" lg="6" class="text-right">
            <slot name="actions" />
          </b-col>
        </b-row>
      </div>

      <div class="row">
        <b-col>
          <BaseInput v-model="label" label="Libellé du lien" rules="required" />
        </b-col>

        <b-col>
          <BaseInput v-model="url" label="URL du lien" rules="required" />
        </b-col>
      </div>

      <div class="row">
        <b-col>
          <BaseInput
            rules="required"
            label="Image"
            label-classes="form-control-label mb-4"
          >
            <SingleFileUpload
              v-model="image"
              label="Sélectionner une image"
              :formats="[{ width: 271, name: 'thumbnail' }]"
            />

            <p class="text-muted mt-2">
              <small>
                Le format suggéré pour l'image est de 512 pixels par 126 pixels.
              </small>
            </p>
          </BaseInput>
        </b-col>
      </div>

      <b-row class="mt-4 mt-md-0">
        <b-col>
          <div v-loading="waitForUtils">
            <TreeselectInput
              v-if="loading == false && waitForUtils == false"
              v-model="categoryIds"
              rules="required"
              label="Catégories"
              placeholder="Sélectionnez les catégories"
              :multiple="true"
              :options="categoriesTree"
              :schema="{
                id: 'id',
                label: 'name',
                children: 'children',
              }"
            />
            <p class="text-muted mt-2">
              <small>
                Les appels à l'action s'affichent sous les filtres dans le menu
                de gauche du catalogue en fonction de la catégorie courante
              </small>
            </p>
          </div>
        </b-col>
      </b-row>

      <b-row align-h="end">
        <b-col cols="auto">
          <BaseButton size="lg" type="primary" native-type="submit">
            Sauvegarder
          </BaseButton>
        </b-col>
      </b-row>
    </b-card>
  </BaseForm>
</template>
<script>
import { BaseButton, BaseInput } from '@coop-zone/coop-zone-argon'
import { mapState } from 'vuex'
import TreeselectInput from '@/components/Inputs/TreeselectInput'
import BaseForm from '@/components/forms/BaseForm'
import SingleFileUpload from '@/components/Inputs/SingleFileUpload'

export default {
  components: {
    BaseButton,
    BaseForm,
    BaseInput,
    SingleFileUpload,
    TreeselectInput,
  },
  props: {
    onSuccess: {
      type: Function,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    redirect: {
      type: [String, Boolean],
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      waitForUtils: false,
    }
  },
  computed: {
    ...mapState('categories', ['categoriesTree']),
    label: {
      get() {
        return this.$store.state.callToActions.label
      },
      set(label) {
        this.$store.commit('callToActions/SET_LABEL', label)
      },
    },
    url: {
      get() {
        return this.$store.state.callToActions.url
      },
      set(url) {
        this.$store.commit('callToActions/SET_URL', url)
      },
    },
    image: {
      get() {
        return this.$store.state.callToActions.image
      },
      set(image) {
        this.$store.commit('callToActions/SET_IMAGE', image)
      },
    },
    categoryIds: {
      get() {
        return this.$store.state.callToActions.categoryIds
      },
      set(categoryIds) {
        this.$store.commit('callToActions/SET_CATEGORY_IDS', categoryIds)
      },
    },
  },
  async created() {
    this.waitForUtils = true
    await this.$store.dispatch('categories/getCategories')
    this.waitForUtils = false
  },
}
</script>

<style lang="sass" scoped>
::v-deep .avatar
  height: 72px
  width: 271px
</style>
