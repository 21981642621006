<template>
  <BasePageBlock v-bind="$props">
    <b-row>
      <b-col>
        <b-row class="mb-4">
          <b-col>
            <b-row>
              <b-col cols="12" sm="8">
                <BaseInput label="Type de gabarit">
                  <select v-model="display" class="form-control">
                    <option value="4">4 blocs / 2 colonnes</option>
                    <option value="2">2 blocs / 2 colonnes</option>
                  </select>
                </BaseInput>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row
          v-for="(child, index) in blocks[blockIndex].childrens"
          :key="index"
          class="mb-4"
        >
          <b-col>
            <div class="list-group-item">
              <b-row class="mb-4">
                <b-col>
                  <h3 class="heading-small text-muted">
                    Bloc #{{ index + 1 }}
                  </h3>
                </b-col>
              </b-row>

              <b-row class="mb-4">
                <b-col>
                  <b-row>
                    <b-col>
                      <h4 class="heading-small text-muted">
                        Image du bloc ({{ imagesSetup[display][index] }})
                      </h4>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <BaseInput rules="required">
                        <SingleFileUpload
                          v-model="child.image"
                          label="Ajouter une image"
                          :formats="[
                            { width: 300, name: 'thumbnail' },
                            { width: 600, name: 'mobile' },
                            { width: 1000, name: 'tablette' },
                          ]"
                        />
                      </BaseInput>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-row>
                    <b-col>
                      <h4 class="heading-small text-muted">
                        Paramètres du bloc
                      </h4>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12" sm="9">
                      <BaseInput
                        v-model="child.text"
                        label="Texte du bloc (optionnel)"
                      />
                    </b-col>
                    <b-col cols="12" sm="3">
                      <BaseInput
                        v-model="child.color"
                        label="Couleur (optionnel)"
                        type="color"
                      />
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12" sm="6">
                      <BaseInput
                        v-model="child.button.text"
                        label="Texte du lien (optionnel)"
                      />
                    </b-col>
                    <b-col cols="12" sm="6">
                      <BaseInput
                        v-model="child.button.url"
                        label="Url du lien (optionnel)"
                      />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </BasePageBlock>
</template>

<script>
import BasePageBlock from './BasePageBlock'
import { cloneDeep } from 'lodash'
import { BaseInput } from '@coop-zone/coop-zone-argon'
import SingleFileUpload from '@/components/Inputs/SingleFileUpload'
import blockMixin from '@/components/Blocks/mixins/block.mixin'

export default {
  components: {
    BasePageBlock,
    BaseInput,
    SingleFileUpload,
  },
  mixins: [blockMixin],
  data() {
    return {
      imagesSetup: {
        2: ['660x660px', '660x660px'],
        4: ['660x660px', '660x300px', '300x300px', '300x300px'],
      },
      childTemplate: {
        image: null,
        text: '',
        color: '#ffffff',
        button: {
          text: '',
          url: '',
        },
      },
    }
  },
  computed: {
    display: {
      get() {
        return this.blocks[this.blockIndex].display
      },
      set(value) {
        this.blocks[this.blockIndex].display = value
      },
    },
  },
  watch: {
    display: {
      handler(display) {
        const children = []
        for (let index = 0; index < parseInt(display, 10); index += 1) {
          if (this.blocks[this.blockIndex].childrens[index]) {
            children[index] = cloneDeep(
              this.blocks[this.blockIndex].childrens[index]
            )
          } else {
            children[index] = cloneDeep(this.childTemplate)
          }
        }

        this.blocks[this.blockIndex].childrens = children.map((item) =>
          cloneDeep(item)
        )
      },
      immediate: true,
    },
  },
}
</script>
