<i18n>
{
  "fr" : {
    "DeleteSuccess": "La catégorie a bien été supprimée"
  }
}
</i18n>

<template>
  <div>
    <BaseHeader class="pb-6" type="default">
      <b-row align-v="center" class="py-4">
        <b-col>
          <p class="h2 text-white d-inline-block mb-0">
            Modifier une catégorie
          </p>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </BaseHeader>

    <b-container fluid class="mt--6 pb-4">
      <b-row>
        <b-col xl="12">
          <CategoryForm
            v-loading="loading"
            :on-success="save"
            title="Modifier une catégorie"
          >
            <BaseButton
              v-if="hierarchyLevel > 2"
              slot="actions"
              v-confirm-delete="{
                onConfirm: () => deleteCategory(),
                confirmText:
                  'La catégorie sera supprimée. Cette action supprimera les catégories enfants ainsi que les liens avec les filtres et les règles Vigie de toutes les catégories ainsi supprimées.',
                successText: 'La catégorie a été supprimée',
              }"
              type="danger"
              icon
              size="sm"
            >
              <span class="btn-inner--icon"
                ><i class="fas fa-minus-circle"
              /></span>
              <span class="btn-inner--text">Supprimer la catégorie</span>
            </BaseButton>
          </CategoryForm>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {
  RouteBreadcrumb,
  BaseHeader,
  BaseButton,
} from '@coop-zone/coop-zone-argon'
import { mapState } from 'vuex'
import CategoryForm from '@/components/Categories/CategoryForm'

export default {
  components: {
    CategoryForm,
    RouteBreadcrumb,
    BaseHeader,
    BaseButton,
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapState('categories', ['hierarchyLevel']),
  },
  async created() {
    this.loading = true
    await this.$store.dispatch('categories/getCategory', this.$route.params.id)
    this.loading = false
  },
  methods: {
    async deleteCategory() {
      this.loading = true
      await this.$store.dispatch(
        'categories/deleteCategory',
        this.$route.params.id
      )
      this.loading = false
      this.$notify({
        message: this.$t('DeleteSuccess'),
        timeout: 5000,
        icon: 'ni ni-check-bold',
        type: 'success',
      })
      this.$router.push('/categories/index/')
    },
    async save() {
      await this.$store.dispatch('categories/updateCategory')
      await this.$store.dispatch('utils/getTreeselectCategoriesById')
    },
  },
}
</script>
