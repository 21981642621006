<template>
  <b-form-group :class="formGroupClasses">
    <slot name="label">
      <label v-if="label" :class="labelClasses">
        {{ label }}
      </label>
    </slot>

    <Treeselect
      v-model="treeselectModel"
      :multiple="multiple"
      :placeholder="placeholder"
      :options="options"
      :normalizer="normalizer"
      data-cy="tree-select"
      :clearable="true"
    />

    <validation-provider ref="provider" v-slot="{ errors }" :rules="rules">
      <input v-model="inputModel" type="hidden" />

      <slot name="error">
        <div v-if="errors[0]" class="invalid-feedback" style="display: block">
          {{ $t(`formError.${errors[0]}`) }}
        </div>
      </slot>
    </validation-provider>

    <slot name="list" />
  </b-form-group>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  components: {
    Treeselect,
  },
  inheritAttrs: false,
  props: {
    value: {
      type: [Array, String],
      default: null,
    },
    multiple: {
      type: Boolean,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      description: 'Input label (text before input)',
      default: '',
    },
    labelClasses: {
      type: String,
      description: 'Input label css classes',
      default: 'form-control-label',
    },
    formGroupClasses: {
      type: String,
      description: 'Form group css classes',
      default: '',
    },
    rules: {
      type: [String, Array, Object],
      description: 'Vee validate validation rules',
      default: '',
    },
    schema: {
      type: Object,
      default: () => ({
        id: 'id',
        label: 'label',
        children: 'children',
      }),
    },
  },
  data() {
    return {
      treeselectModel: this.value,
      inputModel: this.value,
    }
  },
  watch: {
    treeselectModel(newVal) {
      this.inputModel = newVal
      this.$refs.provider.syncValue(newVal)
      this.$refs.provider.validate()
      this.$emit('input', newVal)
    },
  },
  methods: {
    normalizer(node) {
      // Remove the properties of Children = NULL
      if (node.children == null || node.children === 'null') {
        delete node.children
      }

      return {
        id: node[this.schema.id],
        label: node[this.schema.label],
        children: node[this.schema.children],
      }
    },
  },
}
</script>

<style lang="sass">
.vue-treeselect__control
  height: 46px

.vue-treeselect__placeholder
  font-size: 0.875rem
  line-height: 46px

.vue-treeselect__single-value
  color: #8898aa
  font-size: 0.875rem
  line-height: inherit
  top: 50%
  transform: translateY(-50%)

.vue-treeselect__orderable-list .list-group-item
  color: #8898aa
  cursor: move
  font-size: 0.875rem
  padding: 0.75rem 1rem

.vue-treeselect__orderable-list .list-group-item:active
  cursor: move
</style>
