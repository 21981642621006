export function canUpdate(account, schoolList) {
  if (!account || !schoolList) return false

  if (account.role === 'ADMIN' || account.role === 'MANAGER') {
    return true
  }

  if (schoolList.status === 'DRAFT') {
    return ['TEACHER', 'SCHOOL'].includes(account.role)
  }

  if (schoolList.status === 'REVIEWED_BY_COOPZONE') {
    return ['SCHOOL'].includes(account.role)
  }

  return false
}

export function canDelete(account, schoolList) {
  if (!account || !schoolList) return false

  if (account.role === 'ADMIN' || account.role === 'MANAGER') {
    return true
  }

  if (
    schoolList.status === 'DRAFT' &&
    (account.role === 'TEACHER' || account.role === 'SCHOOL')
  ) {
    return true
  }

  return false
}

export function canPublish(account, schoolList) {
  if (!account || !schoolList) return false

  if (
    ['ADMIN', 'MANAGER'].includes(account.role) &&
    schoolList.status !== 'PUBLISHED'
  ) {
    return true
  }

  return false
}

export function canSendToReview(account, schoolList, listHasItems) {
  if (!account || !schoolList) return false

  if (
    account.role === 'TEACHER' &&
    listHasItems &&
    schoolList.status === 'DRAFT'
  ) {
    return true
  }

  if (
    account.role === 'SCHOOL' &&
    listHasItems &&
    ['DRAFT', 'REVIEWED_BY_COOPZONE'].includes(schoolList.status)
  ) {
    return true
  }

  return false
}

export function canSendForReview(account, schoolList) {
  if (!account || !schoolList) return false

  if (
    ['ADMIN', 'MANAGER'].includes(account.role) &&
    schoolList.status === 'APPROBATION_BY_COOPZONE'
  ) {
    return true
  }

  return false
}

export function canCancel(account, schoolList) {
  if (!account || !schoolList) return false

  if (
    ['ADMIN'].includes(account.role) &&
    schoolList.status !== 'CANCELED'
  ) {
    return true
  }

  return false
}

export function canMakeSuggestionToCategory(account) {
  if (!account) return false

  return ['ADMIN'].includes(account.role)
}

export function canAskForModification(account, schoolList) {
  if (!account || !schoolList) return false

  if (
    account.role === 'TEACHER' &&
    ['REVIEWED_BY_COOPZONE', 'CANCELED'].includes(schoolList.status)
  ) {
    return true
  }

  return false
}

export function canSelectDateOfSchoolListExport(account) {
  if (!account) return false

  return ['ADMIN'].includes(account.role)
}
