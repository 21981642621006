<template>
  <BaseFlow
    v-if="canSendForReview(account, schoolList)"
    icon="fas fa-undo-alt"
    @click="sendForReview"
  >
    Marquer comme révisée
  </BaseFlow>
</template>
<script>
import swal from 'sweetalert2'
import flowMixin from './flow.mixin'
import errorHandlerMixin from '@/mixins/errorHandler.mixin'

import { canSendForReview } from '@/abilities/schoolLists'

export default {
  mixins: [flowMixin, errorHandlerMixin],
  methods: {
    async sendForReview() {
      const { isConfirmed, value: annotation } = await swal.fire({
        input: 'textarea',
        inputLabel: 'Indiquez pourquoi la liste doit être révisée',
        confirmButtonText: 'Confirmer',
        cancelButtonText: 'Annuler',
        inputPlaceholder: '',
        showCancelButton: true,
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value) {
              resolve()
            } else {
              resolve('Le champ est obligatoire')
            }
          })
        },
      })

      if (isConfirmed) {
        try {
          await this.$store.dispatch('schoolLists/updateSchoolListToReviewed', {
            schoolList: this.schoolList,
            annotation,
          })
          this.$notify({
            message:
              'La liste a été retournée à son créateur pour une révision',
            timeout: 5000,
            icon: 'ni ni-check-bold',
            type: 'success',
          })

          this.$router.push(
            `/school-lists/type/${this.$route.params.type}/index/${this.schoolList.EstablishmentId}`
          )
        } catch (error) {
          this.handleError(error)
        }
      }
    },
    canSendForReview,
  },
}
</script>
