import { apolloClient } from '@/apollo'

const getHandlingFeesQuery = require('../../apollo/queries/GetHandlingFees.gql')
const updateHandlingFeesMutation = require('../../apollo/mutations/UpdateHandlingFees.gql')
const updateCategoriesChargeHandlingFeesMutation = require('../../apollo/mutations/UpdateCategoriesChargeHandlingFee.gql')
const getCategoriesChargeHandlingFeeTreeRootsQuery = require('../../apollo/queries/GetCategoriesChargeHandlingFeeTreeRoots.gql')

const state = {
  handlingFees: [],
  categoriesWithHandlingFees: [],
}

const actions = {
  async getHandlingFees({ commit }) {
    const {
      data: { handlingFees },
    } = await apolloClient.query({
      query: getHandlingFeesQuery,
    })

    const {
      data: { categories },
    } = await apolloClient.query({
      query: getCategoriesChargeHandlingFeeTreeRootsQuery,
    })

    commit('setHandlingFees', handlingFees)

    if (categories) {
      commit(
        'setCategories',
        categories.map(({ id }) => id)
      )
    }
  },

  async updateHandlingFees({ commit }, payload) {
    const {
      data: { handlingFees },
    } = await apolloClient.mutate({
      mutation: updateHandlingFeesMutation,
      variables: { handlingFees: payload },
    })
    commit('setHandlingFees', handlingFees)
  },
  async saveCategories(context, payload) {
    await apolloClient.mutate({
      mutation: updateCategoriesChargeHandlingFeesMutation,
      variables: {
        slugs: payload,
      },
    })
  },
}

const mutations = {
  setHandlingFees(state, payload) {
    state.handlingFees = payload
  },
  setCategories(state, payload) {
    state.categoriesWithHandlingFees = payload
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
