var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BasePageBlock',_vm._b({},'BasePageBlock',_vm.$props,false),[_c('b-row',{staticClass:"p-3"},[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[(_vm.blocks[_vm.blockIndex].childrens.length < 4)?_c('BaseButton',{attrs:{"type":"primary","icon":"","size":"sm","data-cy":"add-content"},on:{"click":function($event){$event.preventDefault();return _vm.add.apply(null, arguments)}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-plus-circle"})]),_c('span',{staticClass:"btn-inner--text"},[_vm._v("Ajouter")])]):_vm._e()],1)],1),_c('b-row',[_c('b-col',[_c('el-table',{staticClass:"table-responsive align-items-center table-flush table-striped",attrs:{"header-row-class-name":"thead-light","data":_vm.blocks[_vm.blockIndex].childrens}},[_c('el-table-column',{attrs:{"label":"Icone (28x28)","min-width":"125px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('BaseInput',{attrs:{"rules":"required"}},[_c('SingleFileUpload',{attrs:{"formats":[
                  { width: 300, name: 'thumbnail' },
                  { width: 600, name: 'mobile' },
                  { width: 1000, name: 'tablette' } ]},model:{value:(row.image),callback:function ($$v) {_vm.$set(row, "image", $$v)},expression:"row.image"}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"Texte","min-width":"225px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('BaseInput',{attrs:{"name":"Texte","rules":"required"},model:{value:(row.text),callback:function ($$v) {_vm.$set(row, "text", $$v)},expression:"row.text"}})]}}])}),_c('el-table-column',{attrs:{"label":"Lien url","min-width":"225px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('BaseInput',{attrs:{"name":"Lien url","rules":"required"},model:{value:(row.url),callback:function ($$v) {_vm.$set(row, "url", $$v)},expression:"row.url"}})]}}])}),_c('el-table-column',{attrs:{"align":"center","min-width":"75px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return _c('div',{staticClass:"table-actions"},[_c('el-tooltip',{attrs:{"content":"Supprimer","placement":"top"}},[_c('a',{staticClass:"table-action table-action-delete",attrs:{"href":"#!","data-toggle":"tooltip","data-original-title":"Supprimer"},on:{"click":function($event){$event.preventDefault();return _vm.remove(row)}}},[_c('i',{staticClass:"fas fa-trash"})])])],1)}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }