var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('base-header',{staticClass:"pb-6 bg-default"},[_c('b-row',{staticClass:"py-4",attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"7","lg":"6"}},[_c('h6',{staticClass:"h2 text-white d-inline-block mb-0"},[_vm._v("Frais de transport")]),_c('nav',{staticClass:"d-none d-md-inline-block ml-md-4",attrs:{"aria-label":"breadcrumb"}},[_c('RouteBreadcrumb')],1)])],1)],1),_c('b-container',{staticClass:"mt--6",attrs:{"fluid":""}},[_c('b-card',{staticClass:"mb-4",attrs:{"no-body":""}},[_c('b-row',{attrs:{"slot":"header","align-v":"center"},slot:"header"},[_c('b-col',{attrs:{"cols":"8"}},[_c('h3',{staticClass:"mb-0"},[_vm._v("Règles de transport")])]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"4"}},[_c('router-link',{staticClass:"btn btn-sm btn-primary",attrs:{"to":"/shipping/delivery_rules/add","data-cy":"deliveryrule-add-link"}},[_vm._v(" Ajouter une règle ")])],1)],1),_c('el-table',{attrs:{"data":_vm.rules,"row-key":"id","header-row-class-name":"thead-light"}},[_c('el-table-column',{attrs:{"label":"Escompte après","prop":"max","min-width":"75px","render-header":_vm.renderHeaderTooltip}}),_c('el-table-column',{attrs:{"label":"Prix de base","min-width":"160px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm._f("currencyFormat")(row.basePrice)))])]}}])}),_c('el-table-column',{attrs:{"label":"Prix additionnel","min-width":"160px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm._f("currencyFormat")(row.additionalPrice)))])]}}])}),_c('el-table-column',{attrs:{"label":"Gratuité","min-width":"160px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm._f("currencyFormat")(row.freeAfter)))])]}}])}),_c('el-table-column',{attrs:{"label":"Zone","min-width":"160px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.Zone)?_c('span',[_vm._v(_vm._s(row.Zone.name))]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"Groupe de taxe","min-width":"160px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.TaxGroup)?_c('span',[_vm._v(_vm._s(row.TaxGroup.name))]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"min-width":"180px","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('router-link',{staticClass:"table-action table-action-edit",attrs:{"to":("/shipping/delivery_rules/edit/" + (row.id))}},[_c('i',{staticClass:"fa fa-edit"})]),_c('a',{directives:[{name:"confirm-delete",rawName:"v-confirm-delete",value:({
                onConfirm: function () { return _vm.deleteRule(row); },
                confirmText: 'La règle sera supprimée définitivement',
                successText: _vm.$t('DeleteSuccess'),
              }),expression:"{\n                onConfirm: () => deleteRule(row),\n                confirmText: 'La règle sera supprimée définitivement',\n                successText: $t('DeleteSuccess'),\n              }"}],staticClass:"table-action table-action-delete",attrs:{"href":"#!","data-toggle":"tooltip","data-original-title":"Supprimer"}},[_c('i',{staticClass:"fas fa-trash"})])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }