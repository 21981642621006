<template>
  <div>
    <FileUploadPreview
      v-if="files.length > 0"
      :file="files[0]"
      @delete="$emit('input', null)"
    />

    <FileUploadInput
      v-if="files.length == 0"
      :label="label"
      @change="updateFiles"
    />
  </div>
</template>
<script>
import uploadMixin from '@/mixins/upload.mixin'
import FileUploadInput from '@/components/Inputs/FileUploadInput'
import FileUploadPreview from '@/components/Inputs/FileUploadPreview'

export default {
  components: {
    FileUploadInput,
    FileUploadPreview,
  },
  mixins: [uploadMixin],
  methods: {
    async updateFiles(newFile) {
      this.files = [...newFile]
      const uploadedFiles = await this.uploadFiles([...newFile])
      this.$emit('input', [...uploadedFiles.map((file) => ({ ...file }))])
    },
  },
}
</script>
