<template>
  <div class="content">
    <base-header class="pb-6 bg-default">
      <b-row align-v="center" class="py-4">
        <b-col>
          <h6 class="h2 text-white d-inline-block mb-0">Commandes</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <BaseTableSearch
        placeholder="Num. de commande, num. de membre, adresse courriel ou nom du client"
        :data="refunds"
        :pagination="pagination"
        @selection-change="handleBatchChange"
        @sort-change="sortMethod"
        @search="search"
      >
        <template #header>
          <b-row slot="header" align-v="center">
            <b-col>
              <h3 class="mb-0">Liste des remboursements</h3>
            </b-col>
          </b-row>
        </template>

        <template #filters>
          <b-row align-v="center">
            <b-col sm="12" xl="3">
              <BaseInput label="Filtrer par statut">
                <RefundStatusSelect v-model="statusId">
                  <el-option :value="null" label="Tous les statuts" />
                </RefundStatusSelect>
              </BaseInput>
            </b-col>
          </b-row>
        </template>

        <template #batch></template>

        <el-table-column
          :reserve-selection="true"
          type="selection"
          width="55"
        />

        <el-table-column
          label="# de transaction"
          sortable="custom"
          prop="transactionId"
          min-width="150px"
        >
          <template slot-scope="scope">
            {{ scope.row.transactionId }}
          </template>
        </el-table-column>

        <el-table-column
          label="Statut"
          sortable="custom"
          prop="status"
          class-name="text-nowrap"
          min-width="125px"
        >
          <template slot-scope="scope">
            <el-tooltip
              v-if="scope.row.error"
              class="mr-1"
              effect="dark"
              content="Une erreur est survenu lors du traitement du remboursement"
              placement="top"
            >
              <i class="fas fa-exclamation-triangle warning-icon" />
            </el-tooltip>
            {{ $t(`orders.refund.statuses.${scope.row.status}`) }}
          </template>
        </el-table-column>

        <el-table-column
          label="Montant remboursé"
          sortable="custom"
          prop="amount"
          class-name="text-nowrap"
          min-width="125px"
        >
          <template slot-scope="scope">
            {{ scope.row.amount | currencyFormat }}
          </template>
        </el-table-column>

        <el-table-column
          label="Date de création"
          sortable="custom"
          prop="createdAt"
          class-name="text-nowrap"
          min-width="125px"
        >
          <template slot-scope="scope">
            {{ scope.row.createdAt | dateFormatShort }}
          </template>
        </el-table-column>

        <el-table-column
          label="Date de modification"
          sortable="custom"
          prop="updatedAt"
          class-name="text-nowrap"
          min-width="125px"
        >
          <template slot-scope="scope">
            {{ scope.row.updatedAt | dateFormatShort }}
          </template>
        </el-table-column>

        <el-table-column fixed="right" align="right">
          <template slot-scope="scope" class="table-actions">
            <router-link :to="`/orders/refunds/show/${scope.row.id}`" class="table-action">
              <i class="fa fa-edit" />
            </router-link>

            <el-tooltip
              v-if="scope.row.OrderId"
              content="Consulter la commande"
              placement="top"
            >
              <router-link :to="`/orders/show/${scope.row.OrderId}`" class="table-action">
                <i class="far fa-eye" />
              </router-link>
            </el-tooltip>
          </template>
        </el-table-column>
      </BaseTableSearch>
    </b-container>
  </div>
</template>
<script>
import {
  BaseHeader,
  RouteBreadcrumb,
  BaseInput,
} from '@coop-zone/coop-zone-argon'
import { mapState } from 'vuex'
import { isEqual } from 'lodash'
import ErrorHandlerMixin from '@/mixins/errorHandler.mixin'
import BaseTableSearch from '@/components/BaseTableSearch'
import RefundStatusSelect from '@/components/Inputs/RefundStatusSelect'

export default {
  components: {
    BaseHeader,
    BaseInput,
    BaseTableSearch,
    RefundStatusSelect,
    RouteBreadcrumb,
  },
  mixins: [ErrorHandlerMixin],
  data() {
    return {
      page: 1,
      sortObject: null,
      statusId: null,
      loading: false,
    }
  },
  computed: {
    ...mapState('orders/refunds', ['refunds', 'pagination', 'batchActionIds']),
  },
  watch: {
    statusId() {
      this.search(1)
    },
  },
  created() {
    this.statusId = this.$route.query.statusId
      ? this.$route.query.statusId
      : this.statusId
    this.search(1)
    this.$store.commit('orders/refunds/SET_BATCH_ACTION_IDS', [])
  },
  methods: {
    async search(searchTerm, page = 1) {
      this.page = page
      try {
        await this.$store.dispatch('orders/refunds/searchRefunds', {
          statuses: this.statusId,
          page: page,
          limit: this.pagination.resultsPerPage,
          sort: this.sortObject,
        })

        const query = {}
        if (this.statusId) query.statusId = this.statusId

        if (isEqual(query, this.$route.query) == false) {
          this.$router.replace({ query })
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    sortMethod({ prop, order }) {
      if (order) {
        this.sortObject = {
          column: prop,
          order: order === 'descending' ? 'DESC' : 'ASC',
        }
      } else {
        this.sortObject = null
      }

      this.search(this.page)
    },

    handleBatchChange(selectedItems) {
      const ids = selectedItems.map((item) => item.id)
      this.$store.commit('orders/refunds/SET_BATCH_ACTION_IDS', ids)
    },
  },
}
</script>

<style lang="sass" scoped>
.warning-icon
  color: var(--danger)
</style>
