<template>
  <div>
    <el-select
      :value="value"
      :disabled="readonly"
      placeholder="Sélectionner une succursale"
      @change="$emit('input', $event)"
    >
      <slot />
      <el-option
        v-for="store in stores"
        :key="store.id"
        :label="store.name"
        :value="store.id"
      >
        {{ store.name }}
      </el-option>
    </el-select>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('stores', {
      stores: 'sortedStores',
    }),
  },
  created() {
    this.$store.dispatch('stores/getStores')
  },
}
</script>
