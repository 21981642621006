<template>
  <BaseFlow
    v-if="canSendToReview(account, schoolList, schoolListHasItems)"
    type="success"
    icon="ni ni-check-bold"
    @click="sendToReview"
  >
    <template v-if="schoolList.status === 'DRAFT'">
      Envoyer en approbation à Coop Zone
    </template>

    <template v-else>Renvoyer en approbation à Coop Zone</template>
  </BaseFlow>
</template>
<script>
import errorHandlerMixin from '@/mixins/errorHandler.mixin'
import flowMixin from './flow.mixin'

import { canSendToReview } from '@/abilities/schoolLists'

export default {
  mixins: [flowMixin, errorHandlerMixin],
  methods: {
    async sendToReview() {
      try {
        await this.$store.dispatch(
          'schoolLists/updateSchoolListToApprobation',
          this.schoolList
        )
        this.$router.push(
          `/school-lists/type/${this.$route.params.type}/index/${this.schoolList.EstablishmentId}`
        )
        this.$notify({
          message: 'La liste est maintenant en approbation',
          timeout: 5000,
          icon: 'ni ni-check-bold',
          type: 'success',
        })
      } catch (error) {
        this.handleError(error)
      }
    },
    canSendToReview,
  },
}
</script>
