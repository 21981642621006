<template>
  <div class="content">
    <base-header class="pb-6 bg-default">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h1 class="h2 text-white d-inline-block mb-0">Appels à l'action</h1>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-card no-body class="mb-4">
        <b-row slot="header" align-v="center">
          <b-col cols="4">
            <h2 class="h3 mb-0">Appels à l'action</h2>
          </b-col>
          <b-col class="text-right">
            <router-link
              class="btn btn-sm btn-primary"
              to="/promotions/call-to-actions/add"
              data-cy="tax-add-link"
            >
              Créer un nouvel appel a l'action
            </router-link>
          </b-col>
        </b-row>
        <FuseList
          :data="callToActions"
          title="Rechercher un appel à l'action"
          placeholder="label"
          :keys="['label']"
        >
          <el-table-column label="Libellé du lien" min-width="160px">
            <template slot-scope="scope">
              <router-link
                :to="`/promotions/call-to-actions/edit/${scope.row.id}`"
              >
                <span class="font-weight-600 name text-sm">{{
                  scope.row.label
                }}</span>
              </router-link>
            </template>
          </el-table-column>

          <el-table-column label="Image" min-width="160px">
            <template slot-scope="scope">
              <img :src="scope.row.image[0].thumbnail" />
            </template>
          </el-table-column>

          <el-table-column align="right">
            <template slot-scope="scope" class="table-actions">
              <router-link
                class="table-action table-action-edit"
                :to="`/promotions/call-to-actions/edit/${scope.row.id}`"
              >
                <i class="fa fa-edit" />
              </router-link>
              <a
                v-confirm-delete="{
                  onConfirm: () => deleteCallToAction(scope.row.id),
                  confirmText:
                    'L\'appel à l\'action sera définitivement effacé',
                  successText: 'L\'appel à l\'action à été supprimé',
                }"
                href="#!"
                class="table-action table-action-delete"
              >
                <i class="fa fa-trash" />
              </a>
            </template>
          </el-table-column>
        </FuseList>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import { BaseHeader, RouteBreadcrumb } from '@coop-zone/coop-zone-argon'
import { mapGetters } from 'vuex'
import FuseList from '@/components/FuseList'

export default {
  components: {
    BaseHeader,
    FuseList,
    RouteBreadcrumb,
  },
  computed: {
    ...mapGetters('callToActions', {
      callToActions: 'getCallToActions',
    }),
  },
  created() {
    this.$store.dispatch('callToActions/getCallToActions')
  },
  methods: {
    async deleteCallToAction(id) {
      try {
        await this.$store.dispatch('callToActions/deleteCallToAction', id)
      } catch (error) {
        this.handleError(error)
        throw error
      }
    },
  },
}
</script>
