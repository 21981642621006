<template>
  <div>
    <base-header class="pb-6" type="default">
      <b-row align-v="center" class="py-4">
        <b-col>
          <p class="h2 text-white d-inline-block mb-0">Filtres</p>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6 pb-4">
      <b-row>
        <b-col>
          <FilterGroupForm title="Modifier la catégorie de filtres">
            <BaseButton
              v-if="defaultState == false"
              slot="actions"
              v-confirm-delete="{
                onConfirm: () => deleteGroup(),
                confirmText:
                  'Le groupe sera supprimé. Tous les filtres qui y sont associés seront automatiquement réassignés au groupe par défaut',
                successText: `Le groupe a été supprimé`,
              }"
              type="danger"
              icon
              size="sm"
            >
              <span class="btn-inner--icon"
                ><i class="fas fa-minus-circle"
              /></span>
              <span class="btn-inner--text">Supprimer le groupe</span>
            </BaseButton>
          </FilterGroupForm>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import {
  BaseButton,
  BaseHeader,
  RouteBreadcrumb,
} from '@coop-zone/coop-zone-argon'
import FilterGroupForm from '@/components/Filters/FilterGroupForm'

export default {
  components: {
    BaseButton,
    BaseHeader,
    RouteBreadcrumb,
    FilterGroupForm,
  },
  computed: {
    defaultState() {
      return this.$store.state.filters.groups.defaultState
    },
  },
  created() {
    this.$store.dispatch('filters/groups/getGroup', this.$route.params.groupId)
  },
  methods: {
    async deleteGroup() {
      await this.$store.dispatch(
        'filters/groups/deleteGroup',
        this.$route.params.groupId
      )
      this.$router.push('/filters/groups/index')
    },
  },
}
</script>
