import Vue from 'vue'
import Vuex from 'vuex'

import permissions from './permissions.module'
import accounts from './accounts.module'
import blocks from './blocks.module'
import callToActions from './callToActions.module'
import categories from './categories.module'
import countries from './countries.module'
import files from './files.module'
import filters from './filters'
import landingPages from './landingPages.module'
import orders from './orders'
import pages from './pages.module'
import pickingpoints from './pickingpoints.module'
import products from './products.module'
import promoCodes from './promoCodes.module'
import provinces from './provinces.module'
import schoolEstablishments from './schoolEstablishments.module'
import schoolLists from './schoolLists'
import schoolSessions from './schoolSessions.module'
import seo from './seo.module'
import shipping from './shipping'
import stores from './stores.module'
import taxes from './taxes'
import users from './users.module'
import utils from './utils.module'
import zones from './zones.module'
import receptionBoxes from './receptionBoxes.module'

const state = {}

const actions = {}

const mutations = {}

Vue.use(Vuex)

const store = new Vuex.Store({
  state,
  actions,
  mutations,
  modules: {
    permissions,
    accounts,
    blocks,
    callToActions,
    categories,
    countries,
    files,
    filters,
    landingPages,
    orders,
    pages,
    pickingpoints,
    pickingpoints,
    products,
    promoCodes,
    provinces,
    schoolEstablishments,
    schoolLists,
    schoolSessions,
    seo,
    shipping,
    stores,
    taxes,
    users,
    utils,
    zones,
    receptionBoxes,
  },
})

export default store
