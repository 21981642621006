import { apolloClient } from '@/apollo'

import GetMeQuery from '../apollo/queries/GetMe.gql'

import RefreshTokenMutation from '../apollo/mutations/RefreshToken.gql'
import LogoutMutation from '../apollo/mutations/Logout.gql'

const loginMutation = require('../apollo/mutations/Login.gql')

const ROLES = [
  {
    id: 'ADMIN',
    name: 'Administrateur',
  },
  {
    id: 'EDITOR',
    name: 'Éditeur',
  },
  {
    id: 'SUPER_EDITOR',
    name: 'Super éditeur',
  },
  {
    id: 'MANAGER',
    name: 'Gestionnaire',
  },
  {
    id: 'TEACHER',
    name: 'Professeur',
  },
  {
    id: 'SCHOOL',
    name: 'École',
  },
]
const state = {
  account: null,
  token: null,
}

const actions = {
  async login({ commit }, payload) {
    const { data } = await apolloClient.mutate({
      mutation: loginMutation,
      variables: payload,
    })

    commit('token', data.login)
  },

  async logout({ commit }) {
    await apolloClient.mutate({
      mutation: LogoutMutation,
    })

    commit('token', null)
    commit('account', null)
  },

  async me({ commit }) {
    const { data } = await apolloClient.query({
      query: GetMeQuery,
    })

    commit('account', data.me)
  },

  async refreshToken({ commit }) {
    const { data } = await apolloClient.mutate({
      mutation: RefreshTokenMutation,
    })

    commit('token', data.refreshToken)
  },
}

const mutations = {
  token(state, payload) {
    state.token = payload
  },
  account(state, payload) {
    state.account = payload
  },
}

const getters = {
  isLoggedIn: (state) => () => state.account != null,
  getRolesBySlug: () => (slug) => {
    const roleObject = ROLES.filter((role) => {
      return role.id == slug
    })

    if (roleObject.length) return roleObject[0].name
    return '-'
  },
  getRoles: () => {
    return ROLES
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
