<template>
  <b-container v-loading="loading" fluid class="mt--6 pb-5">
    <BaseForm classes="form" :on-success="onSuccess" :redirect="redirect">
      <b-row>
        <b-col xl="8">
          <PageBlocks :blocks="blocks" :page="fields.content" />
          <SeoBlock />

          <b-row align-h="end">
            <b-col cols="auto">
              <BaseButton size="lg" type="primary" native-type="submit">
                Sauvegarder
              </BaseButton>
            </b-col>
          </b-row>
        </b-col>

        <b-col xl="4">
          <div class="sticky">
            <card type="gradient-primary">
              <b-row align-v="center" class="justify-content-between">
                <b-col>
                  <h5 class="h3 text-white mb-0">Identification</h5>
                </b-col>
              </b-row>
              <div class="mt-4">
                <div class="form-primary">
                  <BaseCheckbox
                    v-model="fields.visible"
                    class="mb-3 text-white"
                  >
                    Visible sur le site public
                  </BaseCheckbox>

                  <base-input
                    v-model="fields.name"
                    prepend-icon="ni ni-align-center"
                    class="input-group-alternative mb-3"
                    placeholder="Titre de la page"
                  />

                  <base-input
                    v-model="fields.slug"
                    prepend-icon="ni ni-app"
                    class="input-group-alternative mb-3"
                    placeholder="Slug"
                  />

                  <base-input
                    :value="slugged"
                    readonly
                    class="input-group-alternative mb-3"
                    label-classes="form-control-label text-white"
                    label="Prévisualisation du slug"
                  />

                  <a
                    v-if="id"
                    class="btn btn-success btn-lg btn-block"
                    :href="`${websiteUrl}/p/${fields.slug}`"
                    target="_blank"
                    role="button"
                  >
                    Voir sur le site
                  </a>

                  <BaseButton type="secondary" native-type="submit" block>
                    Enregistrer la page
                  </BaseButton>
                  <BaseButton
                    v-if="id"
                    v-confirm-delete="{
                      onConfirm: () => deletePage(),
                      confirmText: 'La page sera définitivement effacée',
                      successText: 'La page été supprimé',
                    }"
                    type="warning"
                    native-type="button"
                    block
                  >
                    Supprimer la page
                  </BaseButton>
                </div>
              </div>
            </card>

            <slot name="actions" />
          </div>
        </b-col>
      </b-row>
    </BaseForm>
  </b-container>
</template>
<script>
import { page as pageBlocks } from '@/assets/blocks/pages'
import {
  BaseButton,
  BaseCheckbox,
  BaseInput,
  Card,
} from '@coop-zone/coop-zone-argon'
import { mapState } from 'vuex'
import slugify from 'slugify'
import BaseForm from '@/components/forms/BaseForm'
import { PageBlocks } from '@/components/Blocks'
import SeoBlock from '@/components/seo/SeoBlock'
import getEnv from '@/utils/env'

export default {
  components: {
    BaseButton,
    BaseCheckbox,
    BaseForm,
    BaseInput,
    Card,
    PageBlocks,
    SeoBlock,
  },
  props: {
    onSuccess: {
      type: Function,
      required: true,
    },
    redirect: {
      type: [String, Boolean],
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapState('pages', ['id', 'fields']),
    name: {
      get() {
        return this.fields.name
      },
      set(value) {
        this.$store.commit('pages/SET_FIELD_NAME', value)
      },
    },
    slug: {
      get() {
        return this.fields.slug
      },
      set(value) {
        this.$store.commit('pages/SET_FIELD_SLUG', value)
      },
    },
    visible: {
      get() {
        return this.fields.visible
      },
      set(value) {
        this.$store.commit('pages/SET_FIELD_VISIBLE', value)
      },
    },
    slugged() {
      const options = { lower: true, strict: true }
      const slugged = this.fields.slug
        ? slugify(this.fields.slug, options)
        : this.fields.name
        ? slugify(this.fields.name, options)
        : null
      this.$store.commit('pages/SET_FIELD_SLUGGED', slugged)
      return slugged
    },
  },
  created() {
    this.websiteUrl = getEnv('VUE_APP_WEBSITE_URL')
    this.blocks = pageBlocks
  },
  methods: {
    async deletePage() {
      this.loading = true
      await this.$store.dispatch('pages/delete', this.id)
      this.loading = false
      this.$router.push('/pages')
    },
  },
}
</script>
<style lang="sass">
.sticky
  position: sticky
  top: 10px

.preview
  padding: 0

  img
    width: 100%

  .card-body
    padding: 0

.ql-editor
  max-height: 40em
  resize: vertical

/* HACK pour corriger les bugs d'affichage de Quill */
.ql-editor strong
  font-weight: bold

.ql-action:after
  display: none

.ql-container .ql-tooltip:hover.ql-hidden
  display: none !important

.ql-tooltip
  width: auto
</style>
