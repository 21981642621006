<template>
  <div>
    <BaseInput
      v-model="value"
      label="Vous ne trouvez pas un produit? Décrivez l’article recherché dans le champ ci-dessous et un représentant Coop Zone pourra vous aider."
      placeholder="Catégorie, mots clés, marque, autres..."
    />
    <BaseButton
      v-if="value !== null"
      type="primary"
      class="mt-2"
      @click.prevent="addToCategory"
    >
      Ajouter l'item
    </BaseButton>
  </div>
</template>
<script>
import { BaseInput, BaseButton } from '@coop-zone/coop-zone-argon'

export default {
  components: {
    BaseButton,
    BaseInput,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      value: null,
    }
  },
  methods: {
    addToCategory() {
      if (this.value !== null) {
        this.$store.dispatch('schoolLists/categories/addNewItem', {
          category: this.category,
          item: {
            type: 'OTHER',
            name: this.value,
          },
        })
        this.value = null
      }
    },
  },
}
</script>
