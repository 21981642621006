import { apolloClient } from '@/apollo'
import { BLOCK_TYPE_WYSIWYG } from '@/assets/blocks/types'

const getPagesQuery = require('../apollo/queries/GetPages.gql')
const getPageQuery = require('../apollo/queries/GetPage.gql')
const createPage = require('../apollo/mutations/CreatePage.gql')
const updateMutation = require('../apollo/mutations/UpdatePage.gql')
const deleteMutation = require('../apollo/mutations/DeletePage.gql')

const state = {
  id: null,
  fields: {
    slug: null,
    slugged: null,
    name: null,
    visible: false,
    content: [],
  },
  pages: [],
}

const actions = {
  async getPages({ commit }) {
    const {
      data: { pages },
    } = await apolloClient.query({
      query: getPagesQuery,
    })
    commit('SET_PAGES', pages)
  },
  async fetch({ commit }, id) {
    const { data } = await apolloClient.query({
      query: getPageQuery,
      variables: { id },
    })
    commit('SET_PAGE', data.page)
    commit('seo/SET_CONTENT', data.page.seo || {}, { root: true })
  },

  reset({ commit }) {
    commit('SET_ID', null)
    commit('SET_FIELD_SLUG', null)
    commit('SET_FIELD_NAME', null)
    commit('SET_FIELD_VISIBLE', false)
    commit('SET_FIELD_CONTENT', [{ type: BLOCK_TYPE_WYSIWYG, text: '' }])
    commit('seo/SET_CONTENT', {}, { root: true })
  },

  async create({
    rootState: { seo },
    rootGetters,
    state: {
      fields: { name, slugged: slug, visible },
    },
  }) {
    const content = rootGetters['blocks/getContent'][0]
    await apolloClient.query({
      query: createPage,
      variables: {
        page: {
          name,
          slug,
          visible,
          content: content.text || '',
          seo,
        },
      },
    })
  },
  async update({
    rootState: { seo },
    rootGetters,
    state: {
      id,
      fields: { name, slugged: slug, visible },
    },
    commit,
  }) {
    const content = rootGetters['blocks/getContent'][0]
    const { data } = await apolloClient.query({
      query: updateMutation,
      variables: {
        page: {
          id,
          name,
          slug,
          visible,
          content: content.text || '',
          seo,
        },
      },
    })

    commit('SET_PAGE', data.page)
    commit('seo/SET_CONTENT', data.page.seo || {}, { root: true })
  },

  async delete({ commit }, pageId) {
    await apolloClient.query({
      query: deleteMutation,
      variables: {
        id: pageId,
      },
    })
    commit('REMOVE_PAGE', pageId)
  },
}

const getters = {
  getName: (state) => state.fields.name,
}

const mutations = {
  SET_PAGES(state, payload) {
    state.pages = payload
  },
  SET_PAGE(state, { id = null, name, slug, visible, content }) {
    state.id = id
    state.fields.name = name
    state.fields.slug = slug
    state.fields.visible = visible
    state.fields.content = [{ type: BLOCK_TYPE_WYSIWYG, text: content }]
  },
  SET_ID(state, payload) {
    state.id = payload
  },
  SET_FIELD_VISIBLE(state, payload) {
    state.fields.visible = false
  },
  SET_FIELD_NAME(state, payload) {
    state.fields.name = payload
  },
  SET_FIELD_SLUG(state, payload) {
    state.fields.slug = payload
  },
  SET_FIELD_SLUGGED(state, payload) {
    state.fields.slugged = payload
  },
  SET_FIELD_CONTENT(state, payload) {
    state.fields.content = payload
  },
  REMOVE_PAGE(state, pageId) {
    const index = state.pages.findIndex(({ id }) => id === pageId)
    if (index >= 0) {
      state.pages.splice(index, 1)
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
