<template>
  <div>
    <notifications />
    <b-navbar
      class="navbar-horizontal navbar-main navbar-light navbar-transparent"
    >
      <div class="container">
        <div class="navbar-wrapper ml-auto mr-auto ml-lg-0">
          <b-navbar-brand to="/">
            <img src="img/brand/branding-coopzone.svg" />
          </b-navbar-brand>
        </div>

        <b-navbar-nav class="align-items-lg-center ml-lg-auto">
          <li class="nav-item d-none d-lg-block ml-lg-4">
            <a
              :href="websiteUrl"
              target="_blank"
              class="btn btn-neutral btn-icon"
            >
              <span class="btn-inner--icon">
                <i class="ni ni-world-2 mr-2" />
              </span>
              <span class="nav-link-inner--text">Voir le site public</span>
            </a>
          </li>
        </b-navbar-nav>
      </div>
    </b-navbar>

    <div class="main-content">
      <zoom-center-transition :duration="pageTransitionDuration" mode="out-in">
        <router-view />
      </zoom-center-transition>
    </div>

    <footer id="footer-main" class="py-5">
      <b-container>
        <b-row align-v="center">
          <b-col>
            <div class="copyright text-center text-muted text-white">
              © {{ year }}
              <a
                href="https://www.zone.coop/"
                class="font-weight-bold text-white ml-1"
                target="_blank"
                >CoopZone</a
              >
            </div>
          </b-col>
        </b-row>
      </b-container>
    </footer>
  </div>
</template>
<script>
import { ZoomCenterTransition } from 'vue2-transitions'
import getEnv from '@/utils/env'

export default {
  components: {
    ZoomCenterTransition,
  },
  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu()
      setTimeout(() => {
        next()
      }, this.menuTransitionDuration)
    } else {
      next()
    }
  },
  props: {
    backgroundColor: {
      type: String,
      default: 'black',
    },
  },
  data() {
    return {
      websiteUrl: getEnv('VUE_APP_WEBSITE_URL'),
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 200,
      year: new Date().getFullYear(),
      pageClass: 'login-page',
    }
  },
  computed: {
    title() {
      return `${this.$route.name} Page`
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.updateBackground()
      },
    },
  },
  beforeDestroy() {
    this.removeBackgroundColor()
  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle('nav-open')
      this.showMenu = !this.showMenu
    },
    closeMenu() {
      document.body.classList.remove('nav-open')
      this.showMenu = false
    },
    setBackgroundColor() {
      document.body.classList.add('bg-primary')
    },
    removeBackgroundColor() {
      document.body.classList.remove('bg-primary')
    },
    updateBackground() {
      if (!this.$route.meta.noBodyBackground) {
        this.setBackgroundColor()
      } else {
        this.removeBackgroundColor()
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.navbar-brand
  margin-right: 0
</style>

<style lang="scss">
$scaleSize: 0.8;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}

.main-content .zoomIn {
  animation-name: zoomIn8;
}

@keyframes zoomOut8 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-content .zoomOut {
  animation-name: zoomOut8;
}
</style>
