<template>
  <div class="content">
    <base-header class="pb-6 bg-default">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="12">
          <h6 class="h2 text-white d-inline-block mb-0">Provinces</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-card no-body class="mb-4">
        <b-row slot="header" align-v="center">
          <b-col xl="4">
            <h3 class="mb-0">Provinces</h3>
          </b-col>
          <b-col class="text-right">
            <router-link
              class="btn btn-sm btn-primary"
              to="/localisation/states/add"
              data-cy="state-add-link"
            >
              Ajouter une province
            </router-link>
          </b-col>
        </b-row>
        <b-row slot="header" class="mt-5" align-v="center">
          <b-col sm="12" xl="4">
            <BaseInput label="Filtrer les provinces par pays">
              <select v-model="country" class="form-control">
                <option
                  v-for="c in countries"
                  :key="c.id"
                  :value="c.code"
                  :selected="c.code == country"
                >
                  {{ c.name }}
                </option>
              </select>
            </BaseInput>
          </b-col>
          <b-col class="text-right">
            <BaseCheckbox v-model="showZoneless">
              Afficher uniquement les provinces sans zone
            </BaseCheckbox>
          </b-col>
        </b-row>

        <FuseList
          :data="showZoneless ? zoneless : provinces"
          :keys="['name', 'code']"
        >
          <el-table-column
            label="Province"
            min-width="160px"
            sortable
            sort-by="name"
            :sort-method="sortByName"
          >
            <template slot-scope="scope">
              <router-link :to="`/localisation/states/edit/${scope.row.id}`">
                <span class="font-weight-600 name text-sm">{{
                  scope.row.name
                }}</span>
              </router-link>
            </template>
          </el-table-column>
          <el-table-column
            label="Code ISO"
            min-width="160px"
            sortable
            sort-by="code"
          >
            <template slot-scope="scope">
              <router-link :to="`/localisation/states/edit/${scope.row.id}`">
                <span class="font-weight-600 name text-sm">{{
                  scope.row.code
                }}</span>
              </router-link>
            </template>
          </el-table-column>
          <el-table-column
            label="Pays"
            min-width="160px"
            sortable
            sort-by="country.name"
            :sort-method="sortByCountry"
          >
            <template v-if="scope.row.country" slot-scope="scope">
              {{ scope.row.country.name }}
            </template>
          </el-table-column>
          <el-table-column
            label="Zone"
            min-width="160px"
            sortable
            sort-by="zone.name"
            :sort-method="sortByZone"
          >
            <template v-if="scope.row.zone" slot-scope="scope">
              {{ scope.row.zone.name }}
            </template>
          </el-table-column>
          <el-table-column align="right">
            <template slot-scope="scope" class="table-actions">
              <router-link
                class="table-action table-action-edit"
                :to="`/localisation/states/edit/${scope.row.id}`"
              >
                <i class="fa fa-edit" />
              </router-link>
            </template>
          </el-table-column>
        </FuseList>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import {
  BaseInput,
  BaseCheckbox,
  BaseHeader,
  RouteBreadcrumb,
} from '@coop-zone/coop-zone-argon'
import { mapState, mapGetters } from 'vuex'
import FuseList from '@/components/FuseList'
export default {
  components: {
    BaseInput,
    BaseCheckbox,
    BaseHeader,
    FuseList,
    RouteBreadcrumb,
  },
  data() {
    return {
      showZoneless: false,
      country: 'CA',
    }
  },
  computed: {
    ...mapState('provinces', ['provinces']),
    ...mapState('countries', ['countries']),
    ...mapGetters('provinces', ['zoneless']),
  },
  watch: {
    country(newValue) {
      if (newValue) {
        this.$store.dispatch('provinces/getProvincesForCountry', this.country)
      }
    },
  },
  created() {
    this.$store.dispatch('countries/getAllCountries')
    this.$store.dispatch('provinces/getProvincesForCountry', this.country)
  },
  methods: {
    sortByZone(a, b) {
      return a.zone.name.localeCompare(b.zone.name)
    },
    sortByCountry(a, b) {
      return a.country.name.localeCompare(b.country.name)
    },
    sortByName(a, b) {
      return a.name.localeCompare(b.name)
    },
  },
}
</script>
