<template>
  <div class="mt-2">
    <MultipleFileUploadList
      v-if="files.length > 0"
      :files="files"
      :disabled="processing"
      class="mb-4"
      @order="$emit('input', $event)"
      @delete="
        $emit(
          'input',
          files.filter((item) => item != $event)
        )
      "
    />

    <FileUploadInput :label="label" :multiple="true" @change="updateFiles" />
  </div>
</template>
<script>
import uploadMixin from '@/mixins/upload.mixin'
import FileUploadInput from '@/components/Inputs/FileUploadInput'
import MultipleFileUploadList from '@/components/Inputs/MultipleFileUploadList'

export default {
  components: {
    FileUploadInput,
    MultipleFileUploadList,
  },
  mixins: [uploadMixin],
  data() {
    return {
      processing: false,
    }
  },
  methods: {
    async updateFiles(newFiles) {
      this.files = this.files.concat([...newFiles])
      this.processing = true
      const uploadedFiles = await this.uploadFiles([...newFiles])
      this.$emit('input', [
        ...this.value,
        ...uploadedFiles.map((file) => ({ ...file })),
      ])
      this.processing = false
    },
  },
}
</script>
