/* eslint-disable no-new */
import Vue from 'vue'
import { Argon } from '@coop-zone/coop-zone-argon'
import VueCurrencyInput from 'vue-currency-input'
import VueCookies from 'vue-cookies'
import Vue2Filters from 'vue2-filters'

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import apolloProvider from './apollo'

import App from './App'
import router from './router'
import store from './store'
import i18n from './i18n'

import './filters/currencyFormat'
import './filters/percentFormat'
import './filters/dateFormat'
import './filters/dateFormatShort'
import './filters/dateFormatShortWithTime'
import './filters/dateFormatDefault'
import './filters/dateFormatText'
import './filters/dateFormatDefaultWithTime'

import './dayjs'
import './directives/uppercase.directive'
import './directives/confirmDelete.directive'

Vue.config.productionTip = false

Vue.use(VueCurrencyInput, {
  componentName: 'VueCurrencyInput',
  globalOptions: {
    currency: { prefix: '', suffix: ' $ CAD' },
    locale: 'fr-CA',
    valueAsInteger: true,
    precision: 2,
    autoDecimalMode: true,
    distractionFree: false,
  },
})

Vue.use(Argon)
Vue.use(Vue2Filters)
Vue.use(VueCookies)

Sentry.init({
  Vue,
  dsn: process.env.SENTRY_DSN,
  environment: `admin-${process.env.NODE_ENV}`,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ["localhost", "admin.coopzone.ca", /^\//],
    }),
  ],
  tracesSampleRate: 1.0,
});

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  apolloProvider,
  render: (h) => h(App),
})
