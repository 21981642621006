<template>
  <div>
    <div class="header bg-gradient-secondary py-7 py-lg-8 pt-9">
      <b-container>
        <div class="header-body text-center mb-5">
          <b-row class="justify-content-center">
            <b-col xl="5" lg="6" md="8" class="px-5">
              <h1 class="text-primary">Connexion</h1>
              <p class="text-lead text-primary">
                Veuillez utiliser le formulaire ci-dessous pour accéder au
                panneau d'administration.
              </p>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon class="fill-primary" points="2560 0 2560 100 0 100" />
        </svg>
      </div>
    </div>

    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card no-body class="bg-secondary border-0 mb-0">
            <b-card-body class="px-lg-5 py-lg-5">
              <BaseForm
                id="connexion"
                classes="form"
                :recaptcha="true"
                :on-success="submit"
              >
                <BaseInput
                  v-model="email"
                  name="email"
                  placeholder="Courriel"
                  type="text"
                  rules="required|email"
                />

                <BaseInput
                  v-model="password"
                  name="mot de passe"
                  placeholder="Mot de passe"
                  type="password"
                  rules="required"
                />

                <BaseButton
                  data-cy="admin-login-box"
                  type="primary"
                  native-type="submit"
                >
                  Se connecter
                </BaseButton>
              </BaseForm>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { BaseButton, BaseInput } from '@coop-zone/coop-zone-argon'
import BaseForm from '@/components/forms/BaseForm'

export default {
  components: {
    BaseForm,
    BaseButton,
    BaseInput,
  },
  data() {
    return {
      email: null,
      password: null,
    }
  },
  computed: {
    ...mapState('accounts', ['token', 'account']),
  },
  watch: {
    token: {
      async handler(newVal) {
        if (newVal) {
          await this.$store.dispatch('accounts/me')

          switch (this.account.role) {
            case 'SCHOOL':
              this.$router.push('/school-lists/type/grade/index')
              break

            case 'TEACHER':
              this.$router.push('/school-lists/type/course/index')
              break

            default:
              this.$router.push('dashboard')
              break
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    async submit(recaptcha) {
      await this.$store.dispatch('accounts/login', {
        email: this.email,
        password: this.password,
        recaptcha,
      })
    },
  },
}
</script>
