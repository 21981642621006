<i18n>
{
  "en": {
    "Book": "Livre",
    "Computer": "Informatique",
    "Art": "Arts",
    "OfficeSupply": "Papeterie"
  },
  "fr": {
    "Book": "Livre",
    "Computer": "Informatique",
    "Art": "Arts",
    "OfficeSupply": "Papeterie"
  }
}
</i18n>
<template>
  <b-container fluid class="mt--6 pb-5">
    <BaseForm id="updateProduct" :on-success="save">
      <b-row>
        <b-col :xl="8">
          <div v-loading="loading" class="card-wrapper">
            <b-card>
              <div slot="header">
                <h2 class="mb-0">Identification</h2>
              </div>

              <b-row>
                <b-col md="4">
                  <label class="form-control-label">
                    Images de la galerie
                  </label>
                  <MultipleFileUpload
                    ref="fileUpload"
                    v-model="product.images"
                    label="Ajouter une image"
                    multiple
                    :formats="[
                      { width: 300, name: 'thumbnail' },
                      { width: 2048, name: 'zoom' },
                    ]"
                  />
                </b-col>

                <b-col class="mt-4 mt-md-0" md="8">
                  <BaseInput
                    v-model="product.name"
                    data-cy="input-product-name"
                    label="Nom du produit"
                    placeholder="Saisissez le nom de produit"
                    name="Nom du produit"
                    rules="required"
                  />

                  <BaseInput
                    v-if="product.slug"
                    v-model="product.slug"
                    readonly
                    label="Identifiant"
                  />

                  <BaseInput
                    v-model="product.sku"
                    data-cy="input-product-sku"
                    label="SKU"
                    placeholder="Saisissez le sku"
                    name="SKU"
                    rules="required"
                  />

                  <BaseInput
                    v-model="product.vigieId"
                    label="Code produit (sera fourni par Vigie)"
                    name="vigieId"
                    readonly
                  />

                  <label class="form-control-label"
                    >Description du produit (optionnel)</label
                  >

                  <HtmlEditor
                    v-model="product.description"
                    data-cy="input-product-description"
                  />
                </b-col>
              </b-row>
            </b-card>

            <b-card v-loading="waitForUtils">
              <div slot="header">
                <h2 class="mb-0">Information sur le produit</h2>
              </div>

              <b-row>
                <b-col>
                  <BaseInput
                    data-cy="select-product-type"
                    label="Sélectionnez le type de produit"
                    rules="required"
                  >
                    <el-select
                      v-model="product.type"
                      filterable
                      clearable
                      placeholder="Sélectionnez un type"
                    >
                      <el-option
                        v-for="object in productTypes"
                        :key="object.id"
                        :label="object.name"
                        :value="object.id"
                      />
                    </el-select>
                  </BaseInput>

                  <div data-cy="select-main-category">
                    <TreeselectInput
                      v-if="waitForUtils == false"
                      :value="primaryCategory"
                      rules="required"
                      label="Catégorie principale"
                      :multiple="false"
                      placeholder="Sélectionnez la catégorie"
                      :options="treeselectCategories"
                      @input="updateCategories('primary', $event)"
                    />
                  </div>

                  <div data-cy="select-product-categories">
                    <TreeselectInput
                      v-if="waitForUtils == false"
                      :value="secondaryCategories"
                      label="Catégories secondaires"
                      :multiple="true"
                      placeholder="Sélectionnez les catégories"
                      :options="treeselectCategories"
                      @input="updateCategories('secondary', $event)"
                    />
                  </div>

                  <BaseInput
                    v-if="product.type == 'Computer'"
                    v-model="product.codeApple"
                    data-cy="apple-product"
                    label="Code produit Apple (optionnel)"
                  />
                  <BaseInput
                    v-model="product.supplierNumber"
                    label="Numéro de fournisseur"
                    name="supplierNumber"
                    :readonly="product.vigieId"
                    :required="!product.vigieId"
                  />

                  <BaseInput
                    v-model="product.supplierName"
                    label="Nom du fournisseur (sera fourni par Vigie)"
                    name="supplierName"
                    :readonly="true"
                  />
                  <BaseInput
                    v-if="isABook"
                    v-model="product.editorNumber"
                    label="Numéro de l'éditeur"
                    name="editorNumber"
                    :disabled="
                      product.type != 'Book' && product.type != 'DigitalBook'
                    "
                    :readonly="product.vigieId"
                  />

                  <BaseInput
                    v-if="isABook"
                    v-model="product.editorName"
                    label="Nom de l'éditeur"
                    name="editorName"
                    :disabled="
                      product.type != 'Book' && product.type != 'DigitalBook'
                    "
                    :readonly="product.vigieId"
                  />

                  <div data-cy="tag-input-authors">
                    <TagsInput
                      v-if="isABook"
                      v-model="product.productCollection"
                      label="Collections"
                      placeholder="Ajouter des collections"
                    />
                  </div>

                  <BaseInput
                    v-if="isABook"
                    v-model="product.partNumber"
                    label="Numéro dans la collection"
                  />

                  <BaseInput
                    v-if="isABook"
                    v-model="product.isbn10"
                    data-cy="isbn10"
                    label="ISBN10"
                    name="isbn10"
                  />

                  <BaseInput
                    v-if="isABook"
                    v-model="product.isbn13"
                    data-cy="isbn13"
                    label="ISBN13"
                    name="isbn13"
                  />

                  <div data-cy="tag-input-authors">
                    <TagsInput
                      v-if="isABook"
                      v-model="product.authors"
                      label="Auteurs"
                      placeholder="Ajouter des auteurs"
                    />
                  </div>

                  <BaseInput
                    v-if="
                      product.type != 'Book' &&
                      product.type != 'DigitalBook' &&
                      product.type != 'CourseNote'
                    "
                    v-model="product.brand"
                    label="Marque"
                    data-cy="marque"
                    name="Marque"
                  />
                  <BaseInput
                    v-if="isABook"
                    label="Date de publication"
                    form-group-classes="mb-0"
                    prepend-icon="ni ni-calendar-grid-58"
                    :required="!product.vigieId"
                  >
                    <flat-pickr
                      v-model="product.publicationDate"
                      class="form-control datepicker"
                    />
                  </BaseInput>
                </b-col>
              </b-row>
            </b-card>

            <ProductMetaFilterGroups
              v-model="product.filters"
              title="Filtres / métadonnées"
            />

            <b-card v-loading="waitForUtils">
              <div slot="header">
                <h2 class="mb-0">Prix et taxes</h2>
              </div>

              <b-form-group
                :class="{ 'mb-3': !limitQuantity, 'mb-0': limitQuantity }"
              >
                <BaseCheckbox v-model="limitQuantity" class="mb-2">
                  Limite d'achat par transaction
                </BaseCheckbox>

                <BaseInput
                  v-if="limitQuantity"
                  :value="product.purchaseLimit"
                  type="number"
                  min="0"
                  @input="
                    product.purchaseLimit = isNaN(parseInt($event))
                      ? 0
                      : parseInt($event)
                  "
                />
              </b-form-group>
              <BaseInput label="Règle de taxes applicable" rules="required">
                <el-select
                  id="taxe-rules"
                  v-model="product.TaxRuleGroupId"
                  data-cy="taxe-rules"
                  placeholder="Sélectionnez la règle de taxe"
                >
                  <!--<el-option :value="null" label="Aucune" />-->
                  <el-option
                    v-for="object in taxRuleGroups"
                    :key="object.id"
                    :value="object.id"
                    :label="object.name"
                  />
                </el-select>
              </BaseInput>

              <ProductPricesFields
                class="mt-5"
                :value="{
                  prices: product.prices,
                  specialPrices: product.specialPrices,
                }"
                @input="
                  updateProduct({
                    ...product,
                    prices: $event.prices,
                    specialPrices: $event.specialPrices,
                  })
                "
              />
            </b-card>

            <card
              gradient="success"
              footer-classes="bg-transparent"
              body-classes="bg-white"
            >
              <b-row slot="header" align-v="center">
                <b-col cols="8">
                  <h5 class="h3 mb-0">Livraison / Manutention</h5>
                </b-col>
                <b-col cols="4" class="text-right" />
              </b-row>

              <b-row class="mb-4">
                <b-col>
                  <BaseCheckbox
                    v-model="product.chargeShippingFee"
                    class="mb-2"
                  >
                    Activer les frais de livraison
                  </BaseCheckbox>
                  <BaseCheckbox
                    v-model="product.chargeHandlingFee"
                    class="mb-3"
                  >
                    Charger les frais de manutention pour ce produit
                  </BaseCheckbox>
                  <p
                    v-if="automaticHandlingFees.length > 0"
                    class="text-warning small"
                  >
                    <i class="fas fa-exclamation-triangle" /> Cette option est
                    supplantée puisque les frais de manutention sont chargés
                    pour l'une des catégories de ce produit.
                  </p>
                </b-col>
              </b-row>

              <CurrencyInput
                form-group-classes="mb-0"
                :value="product.additionalHandlingFee || 0"
                label="Ajouter un frais de manutention unique additionnel"
                @input="product.additionalHandlingFee = $event"
              />

              <ProductHandlingFees
                v-if="automaticHandlingFees.length > 0"
                slot="footer"
                :handling-fees="automaticHandlingFees"
                title="Des frais de manutention sont automatiquement appliqués sur ce produit"
              />
              <ProductHandlingFees
                v-else-if="product.chargeHandlingFee === true"
                slot="footer"
                :handling-fees="manualHandlingFee"
                title="Ces frais de manutention sont appliqués"
              />
            </card>

            <card
              v-if="product.bundles && product.bundles.length"
              gradient="success"
              footer-classes="bg-transparent"
              body-classes="bg-white"
            >
              <b-row slot="header" align-v="center">
                <b-col cols="8">
                  <h5 class="h3 mb-0">Inclus dans ces ensembles de produits</h5>
                </b-col>
                <b-col cols="4" class="text-right" />
              </b-row>

              <b-row class="mb-4">
                <b-col>
                  <el-table :data="product.bundles">
                    <el-table-column
                      label="Nom de l'ensemble"
                      min-width="160px"
                    >
                      <template slot-scope="scope">
                        <router-link
                          :to="`/catalog/bundles/edit/${scope.row.id}-${scope.row.slug}`"
                        >
                          <b-media no-body class="align-items-center">
                            <img
                              v-if="scope.row.images.length"
                              class="mr-1"
                              :src="scope.row.images[0].thumbnail"
                            />
                            <b-media-body>
                              <span class="font-weight-600 name mb-0 text-sm">{{
                                scope.row.name
                              }}</span>
                            </b-media-body>
                          </b-media>
                        </router-link>
                      </template>
                    </el-table-column>

                    <el-table-column prop="sku" label="SKU" />
                  </el-table>
                </b-col>
              </b-row>
            </card>

            <b-row>
              <b-col>
                <VigieRulesInput
                  title="Code vigie"
                  :value="[product.vigieCategories]"
                >
                  <el-table-column label="cat1" min-width="70px">
                    <template #default="{ row }">
                      <BaseInput
                        v-model="row.level1"
                        v-uppercase
                        maxlength="3"
                        rules="required"
                        :hide-error-message="true"
                        :readonly="product.vigieId !== null"
                      />
                    </template>
                  </el-table-column>

                  <el-table-column label="cat2" min-width="70px">
                    <template #default="{ row }">
                      <BaseInput
                        v-model="row.level2"
                        v-uppercase
                        maxlength="3"
                        :readonly="product.vigieId !== null"
                      />
                    </template>
                  </el-table-column>

                  <el-table-column label="cat3" min-width="70px">
                    <template #default="{ row }">
                      <BaseInput
                        v-model="row.level3"
                        v-uppercase
                        maxlength="3"
                        :readonly="product.vigieId !== null"
                      />
                    </template>
                  </el-table-column>

                  <el-table-column label="cat4" min-width="70px">
                    <template #default="{ row }">
                      <BaseInput
                        v-model="row.level4"
                        v-uppercase
                        maxlength="3"
                        :readonly="product.vigieId !== null"
                      />
                    </template>
                  </el-table-column>

                  <el-table-column label="cat5" min-width="70px">
                    <template #default="{ row }">
                      <BaseInput
                        v-model="row.level5"
                        v-uppercase
                        maxlength="3"
                        :readonly="product.vigieId !== null"
                      />
                    </template>
                  </el-table-column>

                  <el-table-column label="cat6" min-width="70px">
                    <template #default="{ row }">
                      <BaseInput
                        v-model="row.level6"
                        v-uppercase
                        maxlength="3"
                        :readonly="product.vigieId !== null"
                      />
                    </template>
                  </el-table-column>

                  <el-table-column label="cat7" min-width="70px">
                    <template #default="{ row }">
                      <BaseInput
                        v-model="row.level7"
                        v-uppercase
                        maxlength="3"
                        :readonly="product.vigieId !== null"
                      />
                    </template>
                  </el-table-column>

                  <el-table-column label="cat8" min-width="70px">
                    <template #default="{ row }">
                      <BaseInput
                        v-model="row.level8"
                        v-uppercase
                        maxlength="3"
                        :readonly="product.vigieId !== null"
                      />
                    </template>
                  </el-table-column>

                  <el-table-column label="cat9" min-width="70px">
                    <template #default="{ row }">
                      <BaseInput
                        v-model="row.level9"
                        v-uppercase
                        maxlength="3"
                        :readonly="product.vigieId !== null"
                      />
                    </template>
                  </el-table-column>

                  <el-table-column label="cat10" min-width="70px">
                    <template #default="{ row }">
                      <BaseInput
                        v-model="row.level10"
                        v-uppercase
                        maxlength="3"
                        :readonly="product.vigieId !== null"
                      />
                    </template>
                  </el-table-column>
                </VigieRulesInput>
              </b-col>
            </b-row>

            <card no-body>
              <template #header>
                <b-row align-v="center">
                  <b-col>
                    <h3 class="h3 mb-0">Suggestions de produit</h3>
                  </b-col>
                </b-row>
                <ProductSuggestions
                  v-model="product.suggestions"
                  @add="addSuggestion"
                  @remove="removeSuggestion"
                />
              </template>
            </card>

            <b-row align-h="end">
              <b-col cols="auto">
                <BaseButton
                  size="lg"
                  type="secondary"
                  native-type="submit"
                  @click="$emit('action', 'save-edit')"
                >
                  Sauvegarder et modifier
                </BaseButton>
              </b-col>

              <b-col cols="auto">
                <BaseButton
                  size="lg"
                  type="primary"
                  native-type="submit"
                  @click="$emit('action', 'save')"
                >
                  Sauvegarder
                </BaseButton>
              </b-col>
            </b-row>
          </div>
        </b-col>

        <b-col xl="4">
          <div class="sticky">
            <div v-if="product.slug">
              <card type="gradient-primary">
                <div class="form-primary">
                  <BaseCheckbox
                    v-model="product.visible"
                    class="mb-2 text-white"
                  >
                    Produit visible sur le site public
                  </BaseCheckbox>

                  <BaseCheckbox
                    v-model="product.buyable"
                    class="mb-2 text-white"
                  >
                    Produit achetable
                  </BaseCheckbox>

                  <BaseCheckbox
                    v-model="product.buyableOnlyWhenInStock"
                    class="mb-3 text-white"
                  >
                    Produit non-achetable lorsque quantité à zéro
                  </BaseCheckbox>

                  <BaseInput
                    v-if="fullSlug"
                    v-model="fullSlug"
                    label-classes="form-control-label text-white"
                    readonly
                    label="Identifiant sur le site"
                  />

                  <a
                    class="btn btn-success btn-lg btn-block"
                    :href="`${websiteUrl}/produit/${fullSlug}`"
                    target="_blank"
                    role="button"
                  >
                    Voir sur le site
                  </a>

                  <BaseButton
                    size="lg"
                    type="secondary"
                    native-type="submit"
                    block
                    :disabled="disabledSubmit"
                    @click="$emit('action', 'save-edit')"
                  >
                    Enregistrer le produit
                  </BaseButton>
                </div>
              </card>
            </div>

            <card v-if="product.bundles && product.bundles.length">
              <b-row slot="header" align-v="center">
                <b-col cols="8">
                  <h5 class="h4 mb-0">
                    Inclus dans
                    {{
                      product.bundles.length
                        | pluralize(["l'ensemble", 'les ensembles'])
                    }}
                    de produits
                    {{
                      product.bundles.length
                        | pluralize(['suivant', 'suivants'])
                    }}
                  </h5>
                </b-col>
                <b-col cols="4" class="text-right" />
              </b-row>

              <b-list-group flush class="my--3">
                <b-list-group-item
                  v-for="bundle in product.bundles"
                  :key="bundle.id"
                  class="px-0"
                >
                  <b-row align-v="center">
                    <b-col cols="auto">
                      <router-link
                        v-if="bundle.images.length"
                        :to="`/catalog/edit/${bundle.id}-${bundle.slug}`"
                        class="avatar rounded-circle"
                      >
                        <b-img
                          alt="Image placeholder"
                          :src="bundle.images[0].thumbnail"
                        />
                      </router-link>
                    </b-col>
                    <b-col class="ml--2">
                      <h4 class="h5 mb-0">
                        <router-link
                          v-if="bundle.images.length"
                          :to="`/catalog/bundles/edit/${bundle.id}-${bundle.slug}`"
                          >{{ bundle.name }}</router-link
                        >
                      </h4>
                      <small>{{ bundle.sku }}</small>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </card>

            <card v-if="product.suggestedIn && product.suggestedIn.length">
              <b-row slot="header" align-v="center">
                <b-col cols="8">
                  <h5 class="h4 mb-0">
                    Lié en tant que suggestion
                    {{
                      product.suggestedIn.length
                        | pluralize([
                          'au produit suivant',
                          'aux produits suivants',
                        ])
                    }}
                  </h5>
                </b-col>
                <b-col cols="4" class="text-right" />
              </b-row>
              <b-list-group flush class="my--3">
                <b-list-group-item
                  v-for="suggestion in product.suggestedIn"
                  :key="suggestion.id"
                  class="px-0"
                >
                  <b-row align-v="center">
                    <b-col cols="auto">
                      <router-link
                        v-if="suggestion.images.length"
                        :to="`/catalog/edit/${suggestion.id}-${suggestion.slug}`"
                        class="avatar rounded-circle"
                      >
                        <b-img
                          alt="Image placeholder"
                          :src="suggestion.images[0].thumbnail"
                        />
                      </router-link>
                    </b-col>
                    <b-col class="ml--2">
                      <h4 class="h5 mb-0">
                        <router-link
                          :to="`/catalog/edit/${suggestion.id}-${suggestion.slug}`"
                          >{{ suggestion.name }}</router-link
                        >
                      </h4>
                      <small>{{ suggestion.vigieId }}</small>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </card>
          </div>
        </b-col>
      </b-row>
    </BaseForm>
  </b-container>
</template>

<script>
import {
  BaseButton,
  BaseInput,
  BaseCheckbox,
  Card,
  TagsInput,
  HtmlEditor,
} from '@coop-zone/coop-zone-argon'
import { mapState, mapGetters } from 'vuex'
import BaseForm from '@/components/forms/BaseForm'
import CurrencyInput from '@/components/Inputs/CurrencyInput'
import MultipleFileUpload from '@/components/Inputs/MultipleFileUpload'
import ProductHandlingFees from '@/components/Product/ProductHandlingFees'
import ProductMetaFilterGroups from '@/components/Product/ProductMetaFilterGroups'
import ProductPricesFields from '@/components/Product/ProductPricesFields'
import ProductSuggestions from '@/components/Product/ProductSuggestions'
import TreeselectInput from '@/components/Inputs/TreeselectInput'
import VigieRulesInput from '@/components/VigieRulesInput'
import getEnv from '@/utils/env'

export default {
  components: {
    BaseButton,
    BaseCheckbox,
    BaseForm,
    BaseInput,
    Card,
    CurrencyInput,
    HtmlEditor,
    MultipleFileUpload,
    ProductHandlingFees,
    ProductMetaFilterGroups,
    ProductPricesFields,
    ProductSuggestions,
    TagsInput,
    TreeselectInput,
    VigieRulesInput,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      websiteUrl: getEnv('VUE_APP_WEBSITE_URL'),
      waitForUtils: false,
      limitQuantity: false,
      chargeHandlingFee: false,
      product: {
        name: null,
        type: null,
        sku: null,
        brand: null,
        visible: false,
        buyable: true,
        buyableOnlyWhenInStock: false,
        description: '',
        purchaseLimit: 0,
        isbn10: null,
        isbn13: null,
        vigieId: null,
        supplierNumber: null,
        supplierName: null,
        editorNumber: null,
        editorName: null,
        publicationDate: null,
        TaxRuleGroupId: null,
        images: [],
        filters: [],
        categories: [],
        authors: [],
        productCollection: [],
        metas: [],
        prices: {
          regular: null,
          member: null,
          employee: null,
          institute: null,
          business: null,
          library: null,
          emerging_artist: null,
          professional_artist: null,
          cjms: null,
          special_1: null,
          special_2: null,
          special_3: null,
          special_4: null,
        },
        specialPrices: {
          regular: null,
          member: null,
          employee: null,
          institute: null,
          business: null,
          library: null,
          emerging_artist: null,
          professional_artist: null,
          cjms: null,
          special_1: null,
          special_2: null,
          special_3: null,
          special_4: null,
        },
        vigieCategories: {
          level1: '',
          level2: '',
          level3: '',
          level4: '',
          level5: '',
          level6: '',
          level7: '',
          level8: '',
          level9: '',
          level10: '',
        },
        suggestions: [],
      },
    }
  },
  computed: {
    ...mapState('products', ['productTypes', 'automaticHandlingFees']),
    ...mapState('utils', ['treeselectCategories', 'taxRuleGroups']),
    ...mapGetters('products', {
      currentProduct: 'getProduct',
    }),
    disabledSubmit() {
      return this.$refs.fileUpload && this.$refs.fileUpload.processing
    },
    fullSlug() {
      return `${this.product.id}-${this.product.slug}`
    },
    primaryCategory() {
      return this.product.categories[0] || null
    },
    secondaryCategories() {
      return this.product.categories.slice(1)
    },
    manualHandlingFee() {
      const price = this.$store.state.products.manualHandlingFee
      return [
        {
          type: 'Manual',
          name: this.$t(this.product.type),
          price,
        },
      ]
    },
    isABook() {
      return ['Book', 'DigitalBook'].includes(this.product.type)
    },
  },
  watch: {
    '$route.params.slug': {
      async handler(slug) {
        if (slug) {
          await this.$store.dispatch('products/getProduct', {
            slug,
          })
          this.product = { ...this.currentProduct }
        }

        this.waitForUtils = true
        await this.$store.dispatch('utils/getTreeselectCategories')
        await this.$store.dispatch('utils/getTaxRuleGroups')
        this.waitForUtils = false
        this.$store.dispatch('products/getProductHandlingFee')
      },
      immediate: true,
    },
    limitQuantity: {
      handler(value) {
        if (value === false) {
          this.product.purchaseLimit = 0
        }
      },
    },
    'product.purchaseLimit': {
      handler(value) {
        this.limitQuantity = value > 0
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    async save() {
      if (this.$route.params.slug) {
        await this.$store.dispatch('products/updateProduct', {
          product: this.product,
          slug: this.$route.params.slug,
        })

        if (
          `${this.currentProduct.id}-${this.currentProduct.slug}` !==
          this.$route.params.slug
        ) {
          this.$router.replace(
            `/catalog/edit/${this.currentProduct.id}-${this.currentProduct.slug}`
          )
        }
      } else {
        await this.$store.dispatch('products/createProduct', {
          product: this.product,
        })
      }

      this.product = { ...this.currentProduct }
      this.$emit('on-success')
    },
    async updateCategories(type, value) {
      const [primaryCategory, ...secondaryCategories] = this.product.categories

      if (type === 'primary') {
        this.product.categories = value
          ? [value, ...secondaryCategories]
          : [...secondaryCategories]
      } else {
        this.product.categories = value
          ? [primaryCategory, ...value]
          : [primaryCategory]
      }
      const filters = await this.$store.dispatch('products/getProductFilters', {
        productId: this.product.id,
        categories: this.product.categories,
      })
      this.product.filters = filters
    },

    updateProduct(product) {
      this.product.prices = product.prices
      this.product.specialPrices = product.specialPrices
    },
    addSuggestion(product) {
      this.product.suggestions.push(product)
    },
    removeSuggestion(product) {
      const index = this.product.suggestions.findIndex(
        ({ id }) => id === product.id
      )
      this.product.suggestions.splice(index, 1)
    },
  },
}
</script>

<style lang="sass" scoped>
.align-items-center img
  width: 48px
  height: 48px

.datepicker.flatpickr-input.form-control[readonly]
  background-color: white
</style>
