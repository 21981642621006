<template>
  <div>
    <BaseHeader class="pb-6" type="default">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">
          <h1 class="h2 text-white d-inline-block mb-0">
            Modifier un code promo
          </h1>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </BaseHeader>

    <CodePromoForm
      v-if="promoCode.id"
      :data="promoCode"
      @save="save"
      @action="action = $event"
    />
  </div>
</template>

<script>
import { RouteBreadcrumb, BaseHeader } from '@coop-zone/coop-zone-argon'
import { mapActions, mapState } from 'vuex'
import CodePromoForm from '@/components/Promotions/CodePromos/CodePromoForm'
import ErrorHandlerMixin from '@/mixins/errorHandler.mixin'

export default {
  components: {
    RouteBreadcrumb,
    CodePromoForm,
    BaseHeader,
  },
  mixins: [ErrorHandlerMixin],
  data() {
    return {
      action: null,
    }
  },
  computed: {
    ...mapState('promoCodes', ['promoCode']),
  },
  async created() {
    await this.getPromoCode(this.$route.params.codePromoId)
  },
  methods: {
    ...mapActions('promoCodes', ['getPromoCode', 'updatePromoCode']),
    async save(saveObject) {
      try {
        await this.updatePromoCode(saveObject)
        this.$notify({
          message: 'Le code promo a bien été enregistré',
          timeout: 5000,
          icon: 'ni ni-check-bold',
          type: 'success',
        })

        if (this.action === 'save') {
          this.$router.push('/promotions/codes')
        }
      } catch (e) {
        this.handleError(e)
      }
    },
  },
}
</script>
