<template>
  <b-row>
    <b-col>
      <b-card-body>
        <b-row>
          <b-col>
            <h5 v-if="title" class="h3">
              {{ title }}
            </h5>
            <b-form>
              <b-form-group class="mb-0">
                <BaseInput
                  v-model="search"
                  class="input-group-lg input-group-flush mb-0"
                  prepend-icon="fas fa-search"
                  placeholder="Filtrer"
                  type="search"
                />
              </b-form-group>
            </b-form>
          </b-col>
        </b-row>
      </b-card-body>
      <el-table
        :data="filteredData"
        row-key="id"
        header-row-class-name="thead-light"
        :class="tableClasses"
        :default-sort="tableDefaultSort"
      >
        <slot />
      </el-table>
    </b-col>
  </b-row>
</template>
<script>
import { BaseInput } from '@coop-zone/coop-zone-argon'
import Fuse from 'fuse.js'

export default {
  components: { BaseInput },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    keys: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
    emptyText: {
      type: String,
      default: 'Aucun résultat',
    },
    tableClasses: {
      type: String,
      default: '',
    },
    tableDefaultSort: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      search: '',
      fuseSearch: null,
    }
  },
  computed: {
    filteredData() {
      if (this.search === '') {
        return this.data
      }
      return this.fuseSearch.search(this.search)
    },
  },
  watch: {
    data: {
      handler(newValue) {
        this.search = ''
        this.fuseSearch = null
        if (newValue) {
          this.fuseSearch = new Fuse(newValue, {
            keys: this.keys,
            threshold: 0.3,
          })
        }
      },
      immediate: true,
    },
  },
}
</script>
