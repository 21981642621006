<template>
  <div class="content">
    <base-header class="pb-6 bg-default">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="12">
          <h1 class="h2 text-white d-inline-block mb-0">
            Liste des exportations de catégories
          </h1>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <BaseTableSearch :data="exports">
        <template #header>
          <b-row slot="header" align-v="center">
            <b-col cols="4">
              <h3 class="mb-0">Exportations</h3>
            </b-col>
            <b-col class="text-right">
              <BaseButton size="sm" :disabled="loading" @click="handleExport">
                <i class="fas fa-file-export" />
                Exporter
              </BaseButton>
            </b-col>
          </b-row>
        </template>

        <el-table-column
          prop="requestDate"
          sortable
          label="Date de requête"
          width="225px"
        >
          <template slot-scope="scope">
            {{ scope.row.requestDate | dateFormatDefaultWithTime }}
          </template>
        </el-table-column>
        <el-table-column prop="status" sortable label="Statut" width="150px">
          <template slot-scope="scope">
            {{ $t(`exports.statuses.${scope.row.status}`) }}
          </template>
        </el-table-column>

        <el-table-column
          prop="completeDate"
          sortable
          label="Complété le"
          width="225px"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.completeDate">
              {{ scope.row.completeDate | dateFormatDefaultWithTime }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          prop="account.firstName"
          sortable
          label="Demandé par"
          width="175px"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.account">
              {{ scope.row.account.firstName }} {{ scope.row.account.lastName }}
            </span>
          </template>
        </el-table-column>

        <el-table-column label="Erreur" min-width="200px">
          <template slot-scope="scope">
            {{ scope.row.exception }}
          </template>
        </el-table-column>

        <el-table-column align="right">
          <template slot-scope="scope" class="table-actions">
            <DownloadFileButton
              v-if="scope.row.status === 'COMPLETED'"
              action="utils/getExportFile"
              :filename="scope.row.fileName"
              :loading="loading"
              @loading="loading = $event"
            />
          </template>
        </el-table-column>
      </BaseTableSearch>
    </b-container>
  </div>
</template>
<script>
import {
  BaseHeader,
  BaseButton,
  RouteBreadcrumb,
} from '@coop-zone/coop-zone-argon'
import { mapState } from 'vuex'
import BaseTableSearch from '@/components/BaseTableSearch'
import DownloadFileButton from '@/components/DownloadFileButton'
import ErrorHandlerMixin from '@/mixins/errorHandler.mixin'

export default {
  components: {
    BaseHeader,
    BaseButton,
    BaseTableSearch,
    RouteBreadcrumb,
    DownloadFileButton,
  },
  mixins: [ErrorHandlerMixin],
  data() {
    return {
      loading: false,
      refreshTimeout: null,
      exports: [],
    }
  },
  async created() {
    this.loading = true
    await this.fetch()
    this.loading = false

    this.refreshTimeout = setInterval(async () => {
      await this.fetch()
    }, 15000)
  },
  beforeDestroy() {
    clearInterval(this.refreshTimeout)
  },
  methods: {
    async fetch() {
      this.exports = await this.$store.dispatch(
        'utils/getExportQueue',
        'category'
      )
    },
    async handleExport() {
      try {
        this.loading = true
        this.exports.unshift(
          await this.$store.dispatch('utils/createExportation', {
            type: 'category',
          })
        )

        this.$notify({
          message: 'La requête a bien été enregistrée',
          timeout: 5000,
          icon: 'ni ni-check-bold',
          type: 'success',
        })

        this.loading = false
      } catch (error) {
        this.loading = false
        this.handleError(error)
      }
    },
  },
}
</script>
