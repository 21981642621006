<template>
  <BaseForm
    id="countryForm"
    success-message="Pays enregistré"
    :on-success="save"
  >
    <b-card>
      <div slot="header">
        <h6 class="text-uppercase text-muted ls-1 mb-1">
          {{ title }}
        </h6>
        <h2 class="mb-0">
          {{ name }}
        </h2>
      </div>
      <b-row>
        <b-col col-sm="8">
          <BaseInput
            v-model="name"
            data-cy="input-country-name"
            label="Nom"
            rules="required"
          />
        </b-col>
        <b-col col-sm="4">
          <BaseInput
            v-model="code"
            data-cy="input-country-iso"
            label="Code ISO"
            rules="required"
          />
        </b-col>
        <b-col col-sm="6">
          <BaseInput label="Zone">
            <select v-model="ZoneId" class="form-control">
              <option />
              <option
                v-for="zone in zones"
                :key="zone.id"
                :value="zone.id"
                :selected="zone.id === ZoneId"
              >
                {{ zone.name }}
              </option>
            </select>
          </BaseInput>
        </b-col>
      </b-row>
      <b-row align-h="end">
        <b-col cols="auto">
          <BaseButton size="lg" type="primary" native-type="submit"
            >Sauvegarder</BaseButton
          >
        </b-col>
      </b-row>
    </b-card>
  </BaseForm>
</template>
<script>
import { mapState } from 'vuex'
import { BaseButton, BaseInput } from '@coop-zone/coop-zone-argon'
import BaseForm from '@/components/forms/BaseForm'

export default {
  components: {
    BaseForm,
    BaseButton,
    BaseInput,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('zones', ['zones']),
    name: {
      get() {
        return this.$store.state.countries.name
      },
      set(name) {
        this.$store.commit('countries/SET_NAME', name)
      },
    },
    code: {
      get() {
        return this.$store.state.countries.code
      },
      set(code) {
        this.$store.commit('countries/SET_CODE', code)
      },
    },
    ZoneId: {
      get() {
        return this.$store.state.countries.ZoneId
      },
      set(ZoneId) {
        this.$store.commit('countries/SET_ZONE_ID', ZoneId)
      },
    },
  },
  created() {
    this.$store.commit('countries/SET_COUNTRY', {})
    if (this.$route.params.id) {
      this.$store.dispatch('countries/getCountry', this.$route.params.id)
    }
    this.$store.dispatch('zones/getAllZones')
  },
  methods: {
    async save() {
      if (this.$route.params.id) {
        this.$store.dispatch('countries/updateCountry')
      } else {
        await this.$store.dispatch('countries/createCountry')
        this.$router.push('/localisation/countries/index')
      }
    },
  },
}
</script>
