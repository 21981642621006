<template>
  <div class="content">
    <base-header class="pb-6 bg-default">
      <b-row align-v="center" class="py-4">
        <b-col>
          <p class="h2 text-white d-inline-block mb-0">Sessions</p>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-card no-body class="mb-4">
        <b-row slot="header" align-v="center">
          <b-col cols="4">
            <h1 class="h3 mb-0">Liste des sessions</h1>
          </b-col>
          <b-col class="text-right">
            <router-link
              class="btn btn-sm btn-primary"
              to="/school-lists/sessions/add"
            >
              Ajouter une session
            </router-link>
          </b-col>
        </b-row>

        <FuseList :data="sessions" :keys="['name']">
          <el-table-column
            label="Nom"
            min-width="160px"
            sortable
            sort-by="name"
            :sort-method="(a, b) => a.name.localeCompare(b.name)"
          >
            <template slot-scope="scope">
              <router-link :to="`/school-lists/sessions/${scope.row.id}`">
                <span class="font-weight-600 name text-sm">{{
                  scope.row.name
                }}</span>
              </router-link>
            </template>
          </el-table-column>

          <el-table-column label="Slug" min-width="160px" prop="slug">
          </el-table-column>

          <el-table-column
            label="Type de session"
            min-width="140px"
            sortable
            sort-by="name"
            :sort-method="
              (a, b) => a.establishmentType.localeCompare(b.establishmentType)
            "
          >
            <template slot-scope="scope">
              {{ $t(`establishments.types.${scope.row.establishmentType}`) }}
            </template>
          </el-table-column>

          <el-table-column
            label="Début (gestion)"
            min-width="100px"
            sortable
            sort-by="managementStartDate"
          >
            <template slot-scope="scope">
              {{ scope.row.managementStartDate | dateFormatShort }}
            </template>
          </el-table-column>

          <el-table-column
            label="Fin (gestion)"
            min-width="100px"
            sortable
            sort-by="managementEndDate"
          >
            <template slot-scope="scope">
              {{ scope.row.managementEndDate | dateFormatShort }}
            </template>
          </el-table-column>

          <el-table-column
            label="Début (affichage)"
            min-width="100px"
            sortable
            sort-by="publishStartDate"
          >
            <template slot-scope="scope">
              {{ scope.row.publishStartDate | dateFormatShort }}
            </template>
          </el-table-column>

          <el-table-column
            label="Fin (affichage)"
            min-width="100px"
            sortable
            sort-by="publishEndDate"
          >
            <template slot-scope="scope">
              {{ scope.row.publishEndDate | dateFormatShort }}
            </template>
          </el-table-column>

          <el-table-column min-width="100px" align="right">
            <template slot-scope="scope" class="table-actions">
              <router-link
                class="table-action table-action-edit"
                :to="`/school-lists/sessions/${scope.row.id}`"
              >
                <i class="fa fa-edit" />
              </router-link>
              <a
                v-confirm-delete="{
                  onConfirm: () => deleteSession(scope.row),
                  confirmText: 'La session sera supprimée',
                  successText: `La session a été supprimée`,
                }"
                href="#!"
                class="table-action table-action-delete"
                data-toggle="tooltip"
                data-original-title="Supprimer"
              >
                <i class="fas fa-trash" />
              </a>
            </template>
          </el-table-column>
        </FuseList>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import { BaseHeader, RouteBreadcrumb } from '@coop-zone/coop-zone-argon'
import { mapGetters } from 'vuex'
import ErrorHandlerMixin from '@/mixins/errorHandler.mixin'

import FuseList from '@/components/FuseList'

export default {
  components: {
    BaseHeader,
    FuseList,
    RouteBreadcrumb,
  },
  mixins: [ErrorHandlerMixin],
  computed: {
    ...mapGetters('schoolSessions', { sessions: 'getSessions' }),
  },
  created() {
    this.$store.dispatch('schoolSessions/getSessions')
  },
  methods: {
    async deleteSession(session) {
      try {
        await this.$store.dispatch('schoolSessions/deleteSession', session.id)
      } catch (error) {
        this.handleError(error)
        throw error
      }
    },
  },
}
</script>
