<template>
  <el-select
    placeholder="Sélectionner une catégorie de filtre"
    :value="value"
    :loading="loading"
    @change="$emit('input', $event)"
  >
    <slot />
    <el-option
      v-for="item in groups"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    />
  </el-select>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapState('filters/groups', ['groups']),
  },
  created() {
    this.$store.dispatch('filters/groups/getGroups')
  },
}
</script>
