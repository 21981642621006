import BlankRouterView from '@/views/Layout/BlankRouterView'

import OrdersSearch from '@/views/Dashboard/Orders/OrdersSearch'
import OrdersShow from '@/views/Dashboard/Orders/OrdersShow'
import OrderRefundsSearch from '@/views/Dashboard/Orders/OrderRefundsSearch'
import OrderRefundsShow from '@/views/Dashboard/Orders/OrderRefundsShow'

export default {
  path: '/orders',
  name: 'Commandes',
  redirect: '/orders/search',
  component: BlankRouterView,
  children: [
    {
      path: '/orders/search',
      name: 'Rechercher une commande',
      component: OrdersSearch,
      meta: {
        roles: ['ADMIN', 'MANAGER'],
      },
    },
    {
      path: '/orders/show/:id',
      name: 'Consulter la commande',
      component: OrdersShow,
      meta: {
        roles: ['ADMIN', 'MANAGER'],
      },
    },
    {
      path: '/orders/refunds',
      name: 'Rechercher un remboursement',
      component: OrderRefundsSearch,
      meta: {
        roles: ['ADMIN', 'MANAGER'],
      },
    },
    {
      path: '/orders/refunds/show/:id',
      name: 'Consulter le remboursement',
      component: OrderRefundsShow,
      meta: {
        roles: ['ADMIN', 'MANAGER'],
      },
    },
  ],
}
