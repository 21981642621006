import Vue from 'vue'
import { apolloClient } from '@/apollo'

const getStoreQuery = require('../apollo/queries/GetStore.gql')
const getStoresQuery = require('../apollo/queries/GetStores.gql')
const createStoreMutation = require('../apollo/mutations/CreateStore.gql')
const updateStoreMutation = require('../apollo/mutations/UpdateStore.gql')
const deleteStoreMutation = require('../apollo/mutations/DeleteStore.gql')

const state = {
  stores: [],
  id: null,
  name: null,
  position: 9999,
  address: null,
  content: '',
  vigieId: null,
  active: false,
  visibleInFooter: false,
}

const actions = {
  async getStore({ commit }, id) {
    const {
      data: { store },
    } = await apolloClient.query({
      query: getStoreQuery,
      variables: { id },
    })
    if (store.content === null) {
      store.content = ''
    }
    commit('SET_STORE', store)
  },

  async getStores({ commit }) {
    const {
      data: { stores },
    } = await apolloClient.query({
      query: getStoresQuery,
    })
    commit('SET_STORES', stores)
  },

  async createStore({
    state: {
      name,
      address,
      content,
      vigieId,
      active,
      visibleInFooter,
      position,
    },
  }) {
    await apolloClient.query({
      query: createStoreMutation,
      variables: {
        store: {
          active: !!active,
          visibleInFooter: !!visibleInFooter,
          address,
          name,
          content,
          vigieId,
          position,
        },
      },
    })
  },

  async updateStore({
    state: {
      id,
      name,
      content,
      address,
      vigieId,
      position,
      active,
      visibleInFooter,
    },
    commit,
  }) {
    const {
      data: { store },
    } = await apolloClient.query({
      query: updateStoreMutation,
      variables: {
        id,
        store: {
          active: !!active,
          visibleInFooter: !!visibleInFooter,
          address,
          name,
          content,
          vigieId,
          position,
        },
      },
    })

    commit('updateStore', store)
  },

  async deleteStore({ commit }, id) {
    await apolloClient.query({
      query: deleteStoreMutation,
      variables: { id },
    })
    commit('SET_STORE', {})
  },

  updateStoresPositions({ commit, dispatch }, { list }) {
    for (let i = 0; i < list.length; i += 1) {
      list[i].position = i
      commit('SET_STORE', list[i])
      dispatch('updateStore')
    }
  },
}

const mutations = {
  SET_NAME(state, payload) {
    state.name = payload
  },

  SET_ADDRESS(state, payload) {
    state.address = payload
  },

  SET_CONTENT(state, payload) {
    state.content = payload
  },

  SET_VIGIE_ID(state, payload) {
    state.vigieId = payload
  },

  SET_ACTIVE(state, payload) {
    state.active = payload
  },

  SET_VISIBLE_IN_FOOTER(state, payload) {
    state.visibleInFooter = payload
  },

  SET_STORE(
    state,
    {
      id = null,
      position = 9999,
      name = null,
      address = null,
      vigieId = null,
      content = '',
      active = false,
      visibleInFooter = false,
    }
  ) {
    state.id = id
    state.position = position
    state.name = name
    state.address = address
    state.vigieId = vigieId
    state.content = content
    state.active = active
    state.visibleInFooter = visibleInFooter
  },

  SET_STORES(state, payload) {
    state.stores = payload
  },

  updateStore(state, payload) {
    const storeIndex = state.stores.findIndex(
      (store) => store.id === payload.id
    )
    Vue.set(state.stores, storeIndex, payload)
  },
}

const getters = {
  store: (state) => {
    return {
      id: state.id,
      name: state.name,
      position: state.position,
      address: state.address,
      content: state.content,
      vigieId: state.vigieId,
      active: state.active,
      visibleInFooter: state.visibleInFooter,
    }
  },
  sortedStores: (state) => state.stores.sort((a, b) => a.position - b.position),
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
