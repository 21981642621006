<template>
  <BaseForm id="zoneForm" success-message="Zone enregistrée" :on-success="save">
    <b-card>
      <div slot="header">
        <h6 class="text-uppercase text-muted ls-1 mb-1">
          {{ title }}
        </h6>
        <h2 class="mb-0">
          {{ name }}
        </h2>
      </div>
      <b-col cols="12">
        <BaseInput
          v-model="name"
          label="Nom"
          data-cy="input-zone-name"
          placeholder="Saisissez le nom de la zone"
          rules="required"
        />
      </b-col>
      <b-row align-h="end">
        <b-col cols="auto">
          <BaseButton size="lg" type="primary" native-type="submit">
            Sauvegarder
          </BaseButton>
        </b-col>
      </b-row>
    </b-card>
  </BaseForm>
</template>
<script>
import { BaseButton, BaseInput } from '@coop-zone/coop-zone-argon'
import BaseForm from '@/components/forms/BaseForm'

export default {
  components: {
    BaseForm,
    BaseButton,
    BaseInput,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    name: {
      get() {
        return this.$store.state.zones.name
      },
      set(name) {
        this.$store.commit('zones/SET_NAME', name)
      },
    },
  },
  created() {
    this.$store.commit('zones/SET_ZONE', {})
    if (this.$route.params.id) {
      this.$store.dispatch('zones/getZoneData', this.$route.params.id)
    }
  },
  methods: {
    save() {
      if (this.$route.params.id) {
        this.$store.dispatch('zones/update')
      } else {
        this.$store.dispatch('zones/create')
      }
    },
  },
}
</script>
