<template>
  <div>
    <base-header class="pb-6" type="default">
      <b-row align-v="center" class="py-4">
        <b-col>
          <p class="h2 text-white d-inline-block mb-0">Sessions</p>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6 pb-4">
      <b-row>
        <b-col>
          <SchoolSessionForm
            :on-success="save"
            title="Ajouter une session"
            redirect="/school-lists/sessions/index"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { BaseHeader, RouteBreadcrumb } from '@coop-zone/coop-zone-argon'
import { mapState } from 'vuex'
import SchoolSessionForm from '@/components/SchoolSessions/SchoolSessionForm'

export default {
  components: {
    BaseHeader,
    RouteBreadcrumb,
    SchoolSessionForm,
  },
  created() {
    this.$store.commit('schoolSessions/SET_CURRENT_SESSION', {})
  },
  methods: {
    async save() {
      await this.$store.dispatch('schoolSessions/createSession')
    },
  },
}
</script>
