<template>
  <div>
    <BaseHeader class="pb-6" type="default">
      <b-row align-v="center" class="py-4">
        <b-col>
          <p class="h2 text-white d-inline-block mb-0">Créer une liste</p>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </BaseHeader>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="12">
          <component :is="schoolListForm" :on-success="save" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import schoolListMixin from './schoolList.mixin'

export default {
  mixins: [schoolListMixin],
  created() {
    this.$store.commit('schoolLists/SET_SCHOOL_LIST', {
      EstablishmentId: this.$route.params.establishmentId || null,
      status: 'DRAFT',
    })
  },
  methods: {
    async save() {
      await this.$store.dispatch(
        'schoolLists/createSchoolListByType',
        this.$route.params.type
      )
      this.$notify({
        message: 'Liste enregistrée',
        timeout: 5000,
        icon: 'ni ni-check-bold',
        type: 'success',
      })
      this.$router.push(
        `/school-lists/type/${this.$route.params.type}/edit/${this.schoolList.EstablishmentId}/${this.schoolList.id}`
      )
    },
  },
}
</script>
