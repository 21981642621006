<template>
  <div class="content">
    <base-header class="pb-6 bg-default">
      <b-row align-v="center" class="py-4">
        <b-col>
          <p class="h2 text-white d-inline-block mb-0">Filtres</p>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-card no-body class="mb-4">
        <b-row slot="header" align-v="center">
          <b-col cols="4">
            <h1 class="h3 mb-0">Liste des filtres</h1>
          </b-col>
          <b-col class="text-right">
            <router-link class="btn btn-sm btn-primary" to="/filters/add">
              Ajouter un filtre
            </router-link>
          </b-col>
        </b-row>

        <b-row slot="header" class="mt-5" align-v="center">
          <b-col sm="12" xl="3">
            <BaseInput label="Filtrer par catégorie">
              <FilterGroupSelect v-model="filterGroupId">
                <el-option :value="null" label="Toutes les catégories" />
              </FilterGroupSelect>
            </BaseInput>
          </b-col>
        </b-row>

        <FuseList :data="filters" :keys="['name', 'FilterGroup.name']">
          <el-table-column
            label="Nom du filtre"
            sortable
            sort-by="name"
            min-width="160px"
          >
            <template slot-scope="scope">
              <router-link :to="`/filters/${scope.row.id}`">
                <span class="font-weight-600 name text-sm">{{
                  scope.row.name
                }}</span>
              </router-link>
            </template>
          </el-table-column>

          <el-table-column
            label="Catégorie de filtre"
            sortable
            sort-by="FilterGroup.name"
            min-width="160px"
          >
            <template slot-scope="scope">
              {{ scope.row.FilterGroup.name }}
            </template>
          </el-table-column>

          <el-table-column min-width="100px" align="right">
            <template slot-scope="scope" class="table-actions">
              <router-link
                class="table-action table-action-edit"
                :to="`/filters/${scope.row.id}`"
              >
                <i class="fa fa-edit" />
              </router-link>
              <a
                v-confirm-delete="{
                  onConfirm: () => deleteFilter(scope.row.id),
                  confirmText: 'Le filtre sera supprimé',
                  successText: `Le filtre ${scope.row.name} a été supprimé`,
                }"
                href="#!"
                class="table-action table-action-delete"
                data-toggle="tooltip"
                data-original-title="Supprimer"
              >
                <i class="fas fa-trash" />
              </a>
            </template>
          </el-table-column>
        </FuseList>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import {
  BaseHeader,
  BaseInput,
  RouteBreadcrumb,
} from '@coop-zone/coop-zone-argon'
import { mapState } from 'vuex'
import { isEqual } from 'lodash'
import ErrorHandlerMixin from '@/mixins/errorHandler.mixin'
import FuseList from '@/components/FuseList'
import FilterGroupSelect from '@/components/Inputs/FilterGroupSelect'

export default {
  components: {
    BaseHeader,
    BaseInput,
    FuseList,
    FilterGroupSelect,
    RouteBreadcrumb,
  },
  mixins: [ErrorHandlerMixin],
  data() {
    const { filterGroupId = null } = this.$route.query

    return {
      filterGroupId,
    }
  },
  computed: {
    ...mapState('filters', ['filters']),
  },
  watch: {
    filterGroupId() {
      this.search()
    },
  },
  created() {
    this.search()
  },
  methods: {
    async search() {
      try {
        await this.$store.dispatch('filters/getFilters', {
          filterGroupId: this.filterGroupId,
        })

        const query = {}
        if (this.filterGroupId) query.filterGroupId = this.filterGroupId
        if (isEqual(query, this.$route.query) == false) {
          this.$router.replace({ query })
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    async deleteFilter(filterId) {
      try {
        await this.$store.dispatch('filters/deleteFilter', filterId)
      } catch (error) {
        this.handleError(error)
        throw error
      }
    },
  },
}
</script>
