import Vue from 'vue'
import { apolloClient } from '@/apollo'

const createPromoCodeMutation = require('../apollo/mutations/CreatePromoCode.gql')
const updatePromoCodeMutation = require('../apollo/mutations/UpdatePromoCode.gql')
const deletePromoCodeMutation = require('../apollo/mutations/DeletePromoCode.gql')
const getPromoCodesQuery = require('../apollo/queries/GetPromoCodes.gql')
const getPromoCodeQuery = require('../apollo/queries/GetPromoCode.gql')

const state = {
  promoCode: {},
  searchedPromoCodes: [],
  pagination: {
    currentPage: 1,
    numberOfPages: 0,
    resultsPerPage: 10,
    totalCount: 0,
  },
}

const actions = {
  async deletePromoCode({ commit }, id) {
    await apolloClient.query({
      query: deletePromoCodeMutation,
      variables: { id },
    })
  },

  async getPromoCode({ commit }, id) {
    commit('SET_PROMO_CODE', {})

    const {
      data: { promoCode },
    } = await apolloClient.query({
      query: getPromoCodeQuery,
      variables: { id },
    })

    commit('SET_PROMO_CODE', promoCode)
  },

  async createPromoCode(ctx, payload) {
    const {
      data: { createPromoCode },
    } = await apolloClient.query({
      query: createPromoCodeMutation,
      variables: {
        promoCode: payload,
      },
    })
    return createPromoCode
  },

  async updatePromoCode({ commit }, payload) {
    const { id } = payload
    delete payload.id
    delete payload.account
    await apolloClient.query({
      query: updatePromoCodeMutation,
      variables: {
        id,
        promoCode: payload,
      },
    })
  },

  async searchPromos({ commit }) {
    const {
      data: { promoCodes },
    } = await apolloClient.query({
      query: getPromoCodesQuery,
    })
    commit('SET_PROMO_CODES', promoCodes)
  },
}

const mutations = {
  SET_PROMO_CODE(state, payload) {
    state.promoCode = payload
  },
  SET_PROMO_CODES(state, payload) {
    state.searchedPromoCodes = payload
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
