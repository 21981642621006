<i18n>
{
  "fr" : {
    "DeleteSuccess": "La boîte de réception a bien été supprimée"
  }
}
</i18n>
<template>
  <div>
    <BaseHeader class="pb-6" type="default">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">
          <h1 class="h2 text-white d-inline-block mb-0">
            Modifier une boîte de réception
          </h1>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </BaseHeader>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col>
          <ReceptionBoxForm
            title="Boîte de réception"
            :on-success="save"
            redirect="/reception-boxes/index"
          >
            <BaseButton
              v-if="deletable"
              slot="actions"
              v-confirm-delete="{
                onConfirm: () => deleteReceptionBox(),
                confirmText:
                  'La boîte de réception sera supprimée définitivement',
                successText: $t('DeleteSuccess'),
              }"
              type="danger"
              icon
              size="sm"
            >
              <span class="btn-inner--icon">
                <i class="fas fa-minus-circle" />
              </span>
              <span class="btn-inner--text">
                Supprimer la boîte de réception
              </span>
            </BaseButton>
          </ReceptionBoxForm>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {
  RouteBreadcrumb,
  BaseHeader,
  BaseButton,
} from '@coop-zone/coop-zone-argon'
import { mapState } from 'vuex'
import ReceptionBoxForm from '@/components/Orders/ReceptionBoxForm'
import ErrorHandlerMixin from '@/mixins/errorHandler.mixin'

export default {
  components: {
    ReceptionBoxForm,
    RouteBreadcrumb,
    BaseHeader,
    BaseButton,
  },
  mixins: [ErrorHandlerMixin],
  computed: {
    ...mapState('receptionBoxes', ['deletable']),
  },
  created() {
    this.$store.dispatch(
      'receptionBoxes/getReceptionBox',
      this.$route.params.id
    )
  },
  methods: {
    async save() {
      await this.$store.dispatch('receptionBoxes/updateReceptionBox')
    },
    async deleteReceptionBox() {
      try {
        await this.$store.dispatch(
          'receptionBoxes/deleteReceptionBox',
          this.$route.params.id
        )
        this.$notify({
          message: this.$t('DeleteSuccess'),
          timeout: 5000,
          icon: 'ni ni-check-bold',
          type: 'success',
        })
        this.$router.push('/reception-boxes/index')
      } catch (error) {
        this.handleError(error)
        throw error
      }
    },
  },
}
</script>
