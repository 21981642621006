<i18n>
{
  "fr" : {
    "DeleteSuccess": "La liste a bien été supprimée",
    "annotations": {
      "CANCELED": "Annulation/Refus de la liste",
      "REVIEWED_BY_COOPZONE": "Révision de la liste"
    }
  }
}
</i18n>

<template>
  <div>
    <BaseHeader class="pb-6" type="default">
      <b-row align-v="center" class="py-4">
        <b-col>
          <p class="h2 text-white d-inline-block mb-0">Modifier une liste</p>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </BaseHeader>

    <b-container fluid class="mt--6 pb-4">
      <b-row>
        <b-col xl="12">
          <component
            :is="schoolListForm"
            v-loading="!schoolList.id"
            :on-success="save"
            :readonly="!canUpdate(account, schoolList)"
          />
        </b-col>
      </b-row>
      <b-row v-if="schoolList.annotations && schoolList.annotations.length > 0">
        <b-col xl="12">
          <card shadow gradient="default" header-classes="bg-transparent">
            <h3 slot="header" class="mb-0 text-white">Annotations</h3>
            <time-line type="one-side">
              <time-line-item
                v-for="item in schoolList.annotations"
                :key="item.id"
                class="mb-2"
                badge-type="danger"
                badge-icon="ni ni-notification-70"
              >
                <h5 class="text-white mb-0">
                  {{ $t('annotations.' + item.type) }}
                </h5>
                <small class="text-light font-weight-bold">{{
                  item.createdAt | dateFormatText
                }}</small>
                <p class="text-light text-md mt-1 mb-0">
                  {{ item.content }}
                </p>
                <h5 class="text-white mt-3 mb-0">
                  <small class="text-muted">Annoté par</small>
                  {{ item.from.firstName }}
                </h5>
              </time-line-item>
            </time-line>
          </card>
        </b-col>
      </b-row>
      <b-row v-if="canUpdate(account, schoolList)" align="end">
        <b-col>
          <BaseButton size="lg" type="primary" @click="save">
            <span v-if="schoolList.status === 'DRAFT'" class="btn-inner--text">
              Sauvegarder en brouillon
            </span>
            <span v-else class="btn-inner--text">Sauvegarder</span>
          </BaseButton>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {
  BaseInput,
  BaseButton,
  Modal,
  Card,
  TimeLine,
  TimeLineItem,
} from '@coop-zone/coop-zone-argon'
import { mapState, mapGetters } from 'vuex'
import { GraphQLErrors } from '@coop-zone/coop-zone-form-errors'

import schoolListMixin from './schoolList.mixin'

import { canUpdate } from '@/abilities/schoolLists'

export default {
  components: {
    BaseButton,
    BaseInput,
    Card,
    Modal,
    TimeLine,
    TimeLineItem,
  },
  mixins: [schoolListMixin],
  computed: {
    ...mapState('accounts', ['account']),
    ...mapGetters('schoolLists/categories', ['sortedCategories']),
    ...mapGetters('schoolEstablishments', ['schoolEstablishment']),
  },
  async created() {
    this.$store.commit('schoolLists/SET_SCHOOL_LIST', {})

    try {
      await this.$store.dispatch('schoolLists/getSchoolList', {
        EstablishmentId: this.$route.params.establishmentId,
        id: this.$route.params.id,
      })

      if (this.$route.params.type === 'course') {
        await this.$store.dispatch(
          'schoolEstablishments/getSchoolEstablishment',
          this.$route.params.establishmentId
        )
        await this.$store.dispatch(
          'schoolLists/sections/getSectionsByType',
          this.schoolEstablishment.type
        )
      }
    } catch (error) {
      const errors = GraphQLErrors(error).logicErrors()

      if (errors && errors.length > 0) {
        this.$notify({
          message: this.$t(`logicError.${errors[0]}`),
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type: 'danger',
        })

        if (errors.includes('NOT_AUTHORIZED')) {
          this.$router.push(
            `/school-lists/type/${this.$route.params.type}/index`
          )
        }
      } else {
        this.$notify({
          message: this.$t(`logicError.DEFAULT`),
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type: 'danger',
        })
      }
    }
  },
  methods: {
    async save() {
      // eslint-disable-next-line no-restricted-syntax
      for (const category of this.sortedCategories) {
        // eslint-disable-next-line no-await-in-loop
        await this.$store.dispatch('schoolLists/categories/saveCategory', {
          category,
          establishmentId: this.$route.params.establishmentId,
          listId: this.$route.params.id,
        })
      }
      await this.$store.dispatch('schoolLists/updateSchoolListByType', {
        currentEstablishmentId: this.$route.params.establishmentId,
        type: this.$route.params.type,
      })
      this.$notify({
        message: 'Liste enregistrée',
        timeout: 5000,
        icon: 'ni ni-check-bold',
        type: 'success',
      })
    },
    canUpdate,
  },
}
</script>
