import Vue from 'vue'

Vue.filter('dateFormatDefaultWithTime', (string) => {
  const dateObj = new Date(string)
  return new Intl.DateTimeFormat('fr-CA', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  }).format(dateObj)
})
