<template>
  <div class="content">
    <base-header class="pb-6 bg-default">
      <b-row align-v="center" class="py-4">
        <b-col>
          <p class="h2 text-white d-inline-block mb-0">Filtres</p>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-card no-body class="mb-4">
        <b-row slot="header" align-v="center">
          <b-col cols="4">
            <h1 class="h3 mb-0">Liste des catégories de filtres</h1>
          </b-col>
          <b-col class="text-right">
            <router-link
              class="btn btn-sm btn-primary"
              to="/filters/groups/add"
            >
              Ajouter une catégorie
            </router-link>
          </b-col>
        </b-row>

        <FuseList :data="fuseGroups" :keys="['name', 'displayLabel']">
          <el-table-column
            label="Nom de la catégorie"
            width="250px"
            sortable
            sort-by="name"
          >
            <template slot-scope="scope">
              <router-link :to="`/filters/groups/${scope.row.id}`">
                <i v-if="scope.row.default == true" class="fa fa-flag mr-3" />
                <span class="font-weight-600 name text-sm">{{
                  scope.row.name
                }}</span>
              </router-link>
            </template>
          </el-table-column>

          <el-table-column
            label="Libellé affiché sur le site"
            sortable
            sort-by="displayLabel"
            min-width="160px"
          >
            <template slot-scope="scope">
              {{ scope.row.displayLabel }}
            </template>
          </el-table-column>

          <el-table-column width="100px" align="right">
            <template slot-scope="scope" class="table-actions">
              <router-link
                class="table-action table-action-edit"
                :to="`/filters/groups/${scope.row.id}`"
              >
                <i class="fa fa-edit" />
              </router-link>
              <a
                v-if="scope.row.default == false"
                v-confirm-delete="{
                  onConfirm: () => deleteGroup(scope.row.id),
                  confirmText:
                    'Le groupe sera supprimé. Tous les filtres qui y sont associés seront automatiquement réassignés au groupe par défaut',
                  successText: `Le groupe ${scope.row.name} a été supprimé`,
                }"
                href="#!"
                class="table-action table-action-delete"
                data-toggle="tooltip"
                data-original-title="Supprimer"
              >
                <i class="fas fa-trash" />
              </a>
            </template>
          </el-table-column>
        </FuseList>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import { BaseHeader, RouteBreadcrumb } from '@coop-zone/coop-zone-argon'
import { mapState, mapActions } from 'vuex'
import FuseList from '@/components/FuseList'

export default {
  components: {
    BaseHeader,
    FuseList,
    RouteBreadcrumb,
  },
  computed: {
    ...mapState('filters/groups', ['groups']),
    fuseGroups() {
      return this.groups.reduce(
        (results, group) => [
          ...results,
          { ...group, displayLabel: this.displayLabel(group) },
        ],
        []
      )
    },
  },
  created() {
    this.$store.dispatch('filters/groups/getGroups')
  },
  methods: {
    ...mapActions('filters/groups', ['deleteGroup']),
    displayLabel(filterGroup) {
      return filterGroup.label || filterGroup.name
    },
  },
}
</script>
