<template>
  <BasePageBlock v-bind="$props">
    <b-row class="p-3">
      <b-col cols="12" class="text-right">
        <BaseButton
          v-if="blocks[blockIndex].childrens.length < 4"
          type="primary"
          icon
          size="sm"
          data-cy="add-content"
          @click.prevent="add"
        >
          <span class="btn-inner--icon"><i class="fas fa-plus-circle" /></span>
          <span class="btn-inner--text">Ajouter</span>
        </BaseButton>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <el-table
          class="table-responsive align-items-center table-flush table-striped"
          header-row-class-name="thead-light"
          :data="blocks[blockIndex].childrens"
        >
          <el-table-column label="Icone (28x28)" min-width="125px">
            <template #default="{ row }">
              <BaseInput rules="required">
                <SingleFileUpload
                  v-model="row.image"
                  :formats="[
                    { width: 300, name: 'thumbnail' },
                    { width: 600, name: 'mobile' },
                    { width: 1000, name: 'tablette' },
                  ]"
                />
              </BaseInput>
            </template>
          </el-table-column>
          <el-table-column label="Texte" min-width="225px">
            <template #default="{ row }">
              <BaseInput v-model="row.text" name="Texte" rules="required" />
            </template>
          </el-table-column>
          <el-table-column label="Lien url" min-width="225px">
            <template #default="{ row }">
              <BaseInput v-model="row.url" name="Lien url" rules="required" />
            </template>
          </el-table-column>

          <el-table-column align="center" min-width="75px">
            <div slot-scope="{ row }" class="table-actions">
              <el-tooltip content="Supprimer" placement="top">
                <a
                  href="#!"
                  class="table-action table-action-delete"
                  data-toggle="tooltip"
                  data-original-title="Supprimer"
                  @click.prevent="remove(row)"
                >
                  <i class="fas fa-trash" />
                </a>
              </el-tooltip>
            </div>
          </el-table-column>
        </el-table>
      </b-col>
    </b-row>
  </BasePageBlock>
</template>

<script>
import BasePageBlock from './BasePageBlock'
import { BaseInput, BaseButton } from '@coop-zone/coop-zone-argon'
import SingleFileUpload from '@/components/Inputs/SingleFileUpload'
import blockMixin from '@/components/Blocks/mixins/block.mixin'

export default {
  components: {
    BasePageBlock,
    BaseInput,
    BaseButton,
    SingleFileUpload,
  },
  mixins: [blockMixin],
  methods: {
    remove(item) {
      const index = this.blocks[this.blockIndex].childrens.indexOf(item)
      if (index !== -1) {
        this.blocks[this.blockIndex].childrens.splice(index, 1)
      }
    },
    add() {
      this.blocks[this.blockIndex].childrens.push({
        image: null,
        text: '',
        url: '',
      })
    },
  },
}
</script>
