import Vue from 'vue'
import { apolloClient } from '@/apollo'

const statesQuery = require('../apollo/queries/GetStates.gql')
const stateQuery = require('../apollo/queries/GetState.gql')
const updateStateMutation = require('../apollo/mutations/UpdateState.gql')
const createStateMutation = require('../apollo/mutations/CreateState.gql')

const state = {
  allProvinces: [],
  provinces: [],
  id: null,
  name: '',
  code: '',
  CountryId: null,
  ZoneId: null,
}

const actions = {
  async getProvincesForCountry({ commit }, countryCode) {
    if (!countryCode) {
      commit('setAllProvincesForCountry', [])
      return
    }
    const { data } = await apolloClient.query({
      query: statesQuery,
      variables: {
        countryCode,
      },
    })
    if (data.states) {
      commit('setAllProvincesForCountry', data.states)
    }
  },

  async getAllProvinces({ commit }) {
    const {
      data: { states },
    } = await apolloClient.query({
      query: statesQuery,
    })
    if (states) {
      commit('setAllProvinces', states)
    }
  },

  async getProvince({ commit }, id) {
    const { data } = await apolloClient.query({
      query: stateQuery,
      variables: {
        id,
      },
    })

    if (data.state) {
      if (data.state.zone) {
        data.state.ZoneId = data.state.zone.id
      }
      if (data.state.country) {
        data.state.CountryId = data.state.country.id
      }
      commit('SET_PROVINCE', data.state)
    }
  },

  async createProvince({ state: { name, code, ZoneId = null, CountryId } }) {
    const province = {
      name,
      code,
      CountryId,
    }

    if (ZoneId !== null) {
      province.ZoneId = ZoneId
    }
    await apolloClient.query({
      query: createStateMutation,
      variables: {
        state: province,
      },
    })
  },

  async updateProvince({
    state: { id, name, code, ZoneId = null, CountryId },
  }) {
    const province = {
      name,
      code,
      CountryId,
    }

    if (ZoneId !== null) {
      province.ZoneId = ZoneId
    }

    await apolloClient.query({
      query: updateStateMutation,
      variables: {
        id,
        state: province,
      },
    })
  },
}
const mutations = {
  setAllProvincesForCountry(state, payload) {
    state.provinces = payload
  },
  setAllProvinces(state, payload) {
    state.allProvinces = payload
  },
  SET_PROVINCE(
    state,
    { id = null, name = '', code = '', ZoneId = null, CountryId = null }
  ) {
    state.id = id
    state.name = name
    state.code = code
    state.ZoneId = ZoneId
    state.CountryId = CountryId
  },
  SET_NAME(state, name) {
    state.name = name
  },
  SET_CODE(state, code) {
    state.code = code
  },
  SET_ZONE_ID(state, ZoneId) {
    state.ZoneId = ZoneId
  },
  SET_COUNTRY_ID(state, CountryId) {
    state.CountryId = CountryId
  },
}

const getters = {
  zoneless: (state) => state.provinces.filter((x) => x.zone === null),
  provincesForCountry: (state) => (countryId) =>
    state.allProvinces.filter((x) => x.country && x.country.id === countryId),
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
