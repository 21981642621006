<template>
  <card>
    <b-row slot="header" align-v="center">
      <b-col cols="8">
        <h2 class="h3 mb-0">SEO</h2>
      </b-col>
    </b-row>
    <div>
      <b-row>
        <b-col>
          <BaseInput v-model="title" label="Titre SEO de la page" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <BaseInput
            v-model="description"
            label="Meta description de la page"
            type="textarea"
          >
            <p slot="infoBlock" class="text-muted mt-2">
              <small
                >La meta description devrait avoir un maximum de 155
                caractères.</small
              >
            </p>
          </BaseInput>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <BaseInput v-model="ogTitle" label="Titre open graph de la page">
            <p slot="infoBlock" class="text-muted mt-2">
              <small
                >Le titre open graph devrait avoir un maximum de 80
                caractères.</small
              >
            </p>
          </BaseInput>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <BaseInput
            v-model="ogDescription"
            label="Description open graph de la page"
            type="textarea"
          >
            <p slot="infoBlock" class="text-muted mt-2">
              <small
                >La description open graph devrait avoir un maximum de 200
                caractères.</small
              >
            </p>
          </BaseInput>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <BaseInput
            label="Image open graph de la page"
            label-classes="form-control-label mb-4"
          >
            <SingleFileUpload
              v-model="ogImage"
              label="Sélectionner une image"
              :formats="[{ width: 400, name: 'thumbnail' }]"
            />
            <p slot="infoBlock" class="text-muted mt-2">
              <small
                >L'image open graph devrait avoir un format de 1200 pixels par
                630 pixels.</small
              >
            </p>
          </BaseInput>
        </b-col>
      </b-row>
    </div>
  </card>
</template>

<script>
import { Card, BaseInput } from '@coop-zone/coop-zone-argon'
import SingleFileUpload from '@/components/Inputs/SingleFileUpload'

export default {
  name: 'SeoBlock',
  components: {
    Card,
    BaseInput,
    SingleFileUpload,
  },
  computed: {
    title: {
      get() {
        return this.$store.state.seo.title
      },
      set(value) {
        this.$store.commit('seo/SET_TITLE', value)
      },
    },
    description: {
      get() {
        return this.$store.state.seo.description
      },
      set(value) {
        this.$store.commit('seo/SET_DESCRIPTION', value)
      },
    },
    ogTitle: {
      get() {
        return this.$store.state.seo.ogTitle
      },
      set(value) {
        this.$store.commit('seo/SET_OG_TITLE', value)
      },
    },
    ogDescription: {
      get() {
        return this.$store.state.seo.ogDescription
      },
      set(value) {
        this.$store.commit('seo/SET_OG_DESCRIPTION', value)
      },
    },
    ogImage: {
      get() {
        return this.$store.state.seo.ogImage
      },
      set(value) {
        this.$store.commit('seo/SET_OG_IMAGE', value)
      },
    },
  },
}
</script>
