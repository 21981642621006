<template>
  <b-card no-body>
    <b-card-header>
      <b-row>
        <b-col :cols="canAdd ? '6' : '12'">
          <h2 class="mb-0">
            {{ title }}
          </h2>
        </b-col>
        <b-col
          v-if="createGroupMode == false && canAdd"
          cols="6"
          class="text-right"
        >
          <el-tooltip content="Ajouter une ligne" placement="top">
            <BaseButton
              type="primary"
              icon
              size="sm"
              @click.prevent="createGroupMode = true"
            >
              <span class="btn-inner--icon"
                ><i class="fas fa-plus-circle"
              /></span>
              <span class="btn-inner--text">Ajouter un groupe</span>
            </BaseButton>
          </el-tooltip>
        </b-col>

        <b-col v-else-if="canAdd" cols="6" class="text-right">
          <b-row align-v="center">
            <b-col>
              <BaseInput
                v-model="groupTemplate.name"
                form-group-classes="mb-0"
                name="group-name"
                placeholder="Nom du groupe"
                @keydown.enter="confirmAddGroup"
              />
            </b-col>

            <b-col cols="auto">
              <el-tooltip content="Enregistrer" placement="top">
                <a
                  href="#!"
                  class="table-action table-action-save"
                  data-toggle="tooltip"
                  data-original-title="Enregistrer"
                  @click.prevent="confirmAddGroup"
                >
                  <i class="fas fa-check text-success" />
                </a>
              </el-tooltip>
              <el-tooltip content="Annuler" placement="top">
                <a
                  href="#!"
                  class="table-action table-action-cancel"
                  data-toggle="tooltip"
                  data-original-title="Annuler"
                  @click.prevent="cancelAddGroup"
                >
                  <i class="fas fa-window-close text-danger" />
                </a>
              </el-tooltip>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card-header>

    <Draggable :value="value" handle=".handle" @input="$emit('input', $event)">
      <ProductMetaFilterGroupFields
        v-for="(group, index) in value"
        :key="group.id || index"
        :filters="group.content"
        :filter-group="group"
        :can-add="canAdd"
        :can-drag="canDrag"
        @update="group.content = $event"
        @delete="removeGroup(group)"
      />
    </Draggable>
  </b-card>
</template>

<script>
import Draggable from 'vuedraggable'
import { BaseInput, BaseButton } from '@coop-zone/coop-zone-argon'
import ProductMetaFilterGroupFields from '@/components/Product/ProductMetaFilterGroupFields'

export default {
  components: {
    BaseInput,
    BaseButton,
    ProductMetaFilterGroupFields,
    Draggable,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: Array,
      default: () => [],
    },
    canAdd: {
      type: Boolean,
      default: true,
    },
    canDrag: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      createGroupMode: false,
      groupTemplate: {
        type: 'META',
      },
    }
  },
  methods: {
    confirmAddGroup() {
      if (this.groupTemplate.name) {
        const groups = [...this.value]
        groups.unshift({
          ...this.groupTemplate,
          content: [{ name: '', value: '', type: 'META' }],
        })
        this.$emit('input', groups)
        this.groupTemplate = { type: 'META' }
        this.createGroupMode = false
      }
    },
    cancelAddGroup() {
      this.groupTemplate = { type: 'META' }
      this.createGroupMode = false
    },
    removeGroup(group) {
      const index = this.value.indexOf(group)
      if (index !== -1) {
        const groups = [...this.value]
        groups.splice(index, 1)
        this.$emit('input', groups)
      }
    },
  },
}
</script>
