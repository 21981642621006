<template>
  <BaseForm
    success-message="Catégories enregistrées"
    :toast-only="true"
    :on-success="onSuccess"
    :redirect="redirect"
  >
    <b-card>
      <h2 v-if="title" slot="header" class="mb-0">
        {{ title }}
      </h2>

      <b-row>
        <b-col>
          <TreeselectInput
            v-if="!isLoading"
            :value="selectedCategories"
            label="Catégories"
            :multiple="true"
            placeholder="Sélectionnez les catégories"
            :options="treeselectCategories"
            @input="$emit('input', $event)"
          />
        </b-col>
      </b-row>

      <b-card-footer class="px-0">
        <b-row align-h="end">
          <b-col cols="auto">
            <BaseButton size="lg" type="primary" native-type="submit">
              Sauvegarder
            </BaseButton>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-card>
  </BaseForm>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { BaseButton } from '@coop-zone/coop-zone-argon'
import BaseForm from '@/components/forms/BaseForm'
import TreeselectInput from '@/components/Inputs/TreeselectInput'

export default {
  components: {
    BaseForm,
    BaseButton,
    TreeselectInput,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    onSuccess: {
      type: Function,
      required: true,
    },
    selectedCategories: {
      type: Array,
      required: true,
    },
    redirect: {
      type: [String, Boolean],
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapState('utils', ['treeselectCategories']),
  },
}
</script>
