<template>
  <div>
    <BaseHeader class="pb-6" type="default">
      <b-row align-v="center" class="py-4">
        <b-col>
          <h1 class="h2 text-white d-inline-block mb-0">
            Modifier un appel à l'action
          </h1>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </BaseHeader>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="12">
          <CallToActionForm
            v-loading="loading"
            :loading="loading"
            :on-success="save"
            title="Modifier un appel à l'action"
            redirect="/promotions/call-to-actions/index"
          >
            <BaseButton
              slot="actions"
              v-confirm-delete="{
                onConfirm: () => deleteCallToAction(),
                confirmText: `L'appel à l'action sera supprimé définitivement`,
                successText: `L'appel à l'action a bien été supprimé`,
              }"
              type="danger"
              icon
              size="sm"
            >
              <span class="btn-inner--icon">
                <i class="fas fa-minus-circle" />
              </span>
              <span class="btn-inner--text">Supprimer l'appel à l'action</span>
            </BaseButton>
          </CallToActionForm>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import {
  BaseHeader,
  BaseButton,
  RouteBreadcrumb,
} from '@coop-zone/coop-zone-argon'
import { mapState } from 'vuex'
import CallToActionForm from '@/components/Promotions/CallToActions/CallToActionForm'
import ErrorHandlerMixin from '@/mixins/errorHandler.mixin'

export default {
  components: {
    BaseHeader,
    BaseButton,
    RouteBreadcrumb,
    CallToActionForm,
  },
  mixins: [ErrorHandlerMixin],
  data() {
    return {
      loading: false,
    }
  },
  async created() {
    this.loading = true
    await this.$store.dispatch('callToActions/getCallToAction', {
      id: this.$route.params.callToActionId,
    })
    this.loading = false
  },
  methods: {
    async save() {
      try {
        await this.$store.dispatch('callToActions/updateCallToAction')
      } catch (error) {
        this.handleError(error)
        throw error
      }
    },
    async deleteCallToAction() {
      try {
        await this.$store.dispatch(
          'callToActions/deleteCallToAction',
          this.$route.params.callToActionId
        )
        this.$router.push('/promotions/call-to-actions/index')
      } catch (error) {
        this.handleError(error)
        throw error
      }
    },
  },
}
</script>
