<template>
  <div class="content">
    <base-header class="pb-6 bg-default">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="12">
          <h6 class="h2 text-white d-inline-block mb-0">Pays</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-card no-body class="mb-4">
        <b-row slot="header" align-v="center">
          <b-col cols="4">
            <h3 class="mb-0">Pays</h3>
          </b-col>
          <b-col class="text-right">
            <router-link
              class="btn btn-sm btn-primary"
              to="/localisation/countries/add"
              data-cy="country-add-link"
            >
              Ajouter un pays
            </router-link>
          </b-col>
        </b-row>
        <b-row slot="header" class="mt-5" align-v="center">
          <b-col class="text-right">
            <BaseCheckbox v-model="showZoneless">
              Afficher uniquement les pays sans zone
            </BaseCheckbox>
          </b-col>
        </b-row>

        <FuseList
          :data="showZoneless ? zoneless : countries"
          :keys="['name', 'code']"
        >
          <el-table-column
            label="Pays"
            min-width="160px"
            sortable
            sort-by="name"
            :sort-method="sortByName"
          >
            <template slot-scope="scope">
              <router-link :to="`/localisation/countries/edit/${scope.row.id}`">
                <span class="font-weight-600 name text-sm">{{
                  scope.row.name
                }}</span>
              </router-link>
            </template>
          </el-table-column>
          <el-table-column
            label="Code ISO"
            min-width="160px"
            sortable
            sort-by="code"
          >
            <template slot-scope="scope">
              {{ scope.row.code }}
            </template>
          </el-table-column>
          <el-table-column label="Zone" min-width="160px">
            <template v-if="scope.row.zone" slot-scope="scope">
              {{ scope.row.zone.name }}
            </template>
          </el-table-column>
          <el-table-column align="right">
            <template slot-scope="scope" class="table-actions">
              <router-link
                class="table-action table-action-edit"
                :to="`/localisation/countries/edit/${scope.row.id}`"
              >
                <i class="fa fa-edit" />
              </router-link>
            </template>
          </el-table-column>
        </FuseList>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import {
  BaseHeader,
  RouteBreadcrumb,
  BaseCheckbox,
} from '@coop-zone/coop-zone-argon'
import { mapState, mapGetters } from 'vuex'
import FuseList from '@/components/FuseList'

export default {
  components: {
    BaseHeader,
    BaseCheckbox,
    FuseList,
    RouteBreadcrumb,
  },
  data() {
    return {
      showZoneless: false,
    }
  },
  computed: {
    ...mapState('countries', ['countries']),
    ...mapGetters('countries', ['zoneless']),
  },
  created() {
    this.$store.dispatch('countries/getAllCountries')
  },
  methods: {
    sortByName(a, b) {
      return a.name.localeCompare(b.name)
    },
  },
}
</script>
