<template>
  <div class="content">
    <base-header class="pb-6 bg-default">
      <b-row align-v="center" class="py-4">
        <b-col>
          <h6 class="h2 text-white d-inline-block mb-0">Commandes</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <BaseTableSearch
        placeholder="Num. de commande, num. de membre, adresse courriel ou nom du client"
        :data="orders"
        :pagination="pagination"
        :value="term"
        :row-style="handleRowStyle"
        @selection-change="handleBatchChange"
        @sort-change="sortMethod"
        @search="search"
      >
        <template #header>
          <b-row slot="header" align-v="center">
            <b-col>
              <h3 class="mb-0">Gérer les commandes</h3>
            </b-col>
          </b-row>
        </template>

        <template #filters>
          <b-row align-v="center">
            <b-col sm="12" xl="3">
              <BaseInput label="Filtrer par statut">
                <OrderStatusSelect v-model="statusId">
                  <el-option :value="null" label="Tous les statuts" />
                </OrderStatusSelect>
              </BaseInput>
            </b-col>

            <b-col sm="12" xl="3">
              <BaseInput label="Filtrer par boîte de réception">
                <ReceptionBoxSelect v-model="receptionBoxId">
                  <el-option
                    :value="null"
                    label="Toutes les boîtes de réception"
                  />
                </ReceptionBoxSelect>
              </BaseInput>
            </b-col>
          </b-row>
        </template>

        <template #batch>
          <OrderReceptionBoxWidget
            v-slot="{ mode, updateMode }"
            :disabled="batchActionIds.length == 0"
          >
            <OrderReceptionBoxForm
              success-message="Les commandes ont bien été transférées à la boite de réception"
              :on-success="batchUpdateReceptionBox"
              :mode="mode"
              @updateMode="updateMode"
            />
          </OrderReceptionBoxWidget>

          <OrderPrintButton
            :disabled="batchActionIds.length == 0"
            container-id="orders-print-container"
            :on-print="batchPrintOrders"
          >
            <i class="fas fa-print" />
            <span>Imprimer les commandes</span>
          </OrderPrintButton>
        </template>

        <el-table-column
          :reserve-selection="true"
          type="selection"
          width="55"
        />

        <el-table-column
          sortable="custom"
          prop="actionRequired"
          align="center"
          min-width="75px"
        >
          <template slot-scope="scope">
            <el-tooltip
              v-if="scope.row.actionRequired"
              effect="dark"
              content="Action requise"
              placement="top"
            >
              <i class="fas fa-exclamation-triangle warning-icon" />
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column
          label="Date"
          sortable="custom"
          prop="createdAt"
          class-name="text-nowrap"
          min-width="200px"
        >
          <template slot-scope="scope">
            <router-link :to="`/orders/show/${scope.row.id}`">
              {{ scope.row.createdAt | dateFormatShortWithTime }}
            </router-link>
          </template>
        </el-table-column>

        <el-table-column
          label="# commande"
          sortable="custom"
          prop="id"
          min-width="150px"
        >
          <template slot-scope="scope">
            {{ scope.row.id }}
          </template>
        </el-table-column>

        <el-table-column
          label="Nom du client"
          sortable="custom"
          prop="clientName"
          min-width="160px"
        >
          <template slot-scope="scope">
            {{ scope.row.billingAddress.firstName }}
            {{ scope.row.billingAddress.lastName }}
          </template>
        </el-table-column>

        <el-table-column
          label="# de client"
          sortable="custom"
          prop="clientNumber"
          min-width="160px"
        >
          <template slot-scope="scope">
            {{ scope.row.clientNumber }}
          </template>
        </el-table-column>

        <el-table-column
          label="Statut"
          sortable="custom"
          prop="status"
          min-width="170px"
        >
          <template slot-scope="scope">
            {{ statusName(scope.row.status) }}
          </template>
        </el-table-column>

        <el-table-column label="Catégories" min-width="160px">
          <template slot-scope="scope">
            <i
              v-for="itemtype in getItemsType(scope.row.items)"
              :key="itemtype"
              :class="['mr-1 ml-1', typesIcones[itemtype]]"
            />
          </template>
        </el-table-column>

        <el-table-column
          label="Total"
          sortable="custom"
          prop="total"
          min-width="160px"
        >
          <template slot-scope="scope">
            {{ scope.row.total | currencyFormat }}
          </template>
        </el-table-column>

        <el-table-column
          label="Boite de réception"
          sortable="custom"
          prop="receptionBox"
          min-width="175px"
        >
          <template slot-scope="scope">
            {{ receptionBoxName(scope.row.receptionBox) }}
          </template>
        </el-table-column>

        <el-table-column
          label="Imprimé"
          sortable="custom"
          prop="printed"
          min-width="110px"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.printed"
              class="badge text-uppercase badge-lg badge-success badge-pill"
            >
              Oui
            </span>

            <span
              v-else
              class="badge text-uppercase badge-lg badge-danger badge-pill"
            >
              Non
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="Imprimé par"
          sortable="custom"
          prop="printed"
          min-width="200px"
        >
          <template slot-scope="scope">
            <span
              v-html="scope.row.printed ? scope.row.printedAccount.email : '-'"
            >
            </span>
          </template>
        </el-table-column>

        <el-table-column fixed="right" align="right">
          <template slot-scope="scope" class="table-actions">
            <router-link :to="`/orders/show/${scope.row.id}`" class="table-action">
              <i class="far fa-eye" />
            </router-link>
          </template>
        </el-table-column>
      </BaseTableSearch>

      <OrderPrintList id="orders-print-container" :orders="printOrders" />
    </b-container>
  </div>
</template>
<script>
import {
  BaseHeader,
  RouteBreadcrumb,
  BaseInput,
} from '@coop-zone/coop-zone-argon'
import { mapState, mapGetters } from 'vuex'
import { isEqual } from 'lodash'
import ErrorHandlerMixin from '@/mixins/errorHandler.mixin'
import BaseTableSearch from '@/components/BaseTableSearch'
import OrderStatusSelect from '@/components/Inputs/OrderStatusSelect'
import ReceptionBoxSelect from '@/components/Inputs/ReceptionBoxSelect'
import OrderPrintList from '@/components/Orders/OrderPrintList'
import OrderPrintButton from '@/components/Orders/OrderPrintButton'
import OrderReceptionBoxForm from '@/components/Orders/OrderReceptionBoxForm'
import OrderReceptionBoxWidget from '@/components/Orders/OrderReceptionBoxWidget'

export default {
  components: {
    BaseHeader,
    BaseInput,
    BaseTableSearch,
    OrderPrintButton,
    OrderPrintList,
    OrderReceptionBoxForm,
    OrderReceptionBoxWidget,
    OrderStatusSelect,
    ReceptionBoxSelect,
    RouteBreadcrumb,
  },
  mixins: [ErrorHandlerMixin],
  data() {
    return {
      term: '',
      page: 1,
      sortObject: null,
      statusId: null,
      receptionBoxId: null,
      loading: false,
    }
  },
  computed: {
    ...mapState('orders', [
      'pagination',
      'batchActionIds',
      'typesIcones',
      'statuses',
    ]),
    ...mapState('orders', { orders: 'searchOrders' }),
    ...mapGetters('orders', {
      printOrders: 'getBatchActionOrders',
      statusName: 'getStatusName',
    }),
  },
  watch: {
    statusId() {
      this.search(this.term, 1)
    },
    receptionBoxId() {
      this.search(this.term, 1)
    },
  },
  created() {
    this.term = this.$route.query.term ? this.$route.query.term : this.term
    this.statusId = this.$route.query.statusId
      ? this.$route.query.statusId
      : this.statusId
    this.receptionBoxId = this.$route.query.receptionBoxId
      ? this.$route.query.receptionBoxId
      : this.receptionBoxId
    this.search(this.term, 1)
    this.$store.commit('orders/SET_BATCH_ACTION_IDS', [])
  },
  methods: {
    async search(searchTerm, page = 1) {
      this.term = searchTerm
      this.page = page
      try {
        await this.$store.dispatch('orders/getSearchOrders', {
          search: this.term,
          statuses: this.statusId,
          receptionBoxIds: this.receptionBoxId ? [this.receptionBoxId] : [],
          page: page,
          limit: this.pagination.resultsPerPage,
          sort: this.sortObject,
        })

        const query = {}
        if (this.term) query.term = this.term
        if (this.statusId) query.statusId = this.statusId
        if (this.receptionBoxId) query.receptionBoxId = this.receptionBoxId

        if (isEqual(query, this.$route.query) == false) {
          this.$router.replace({ query })
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    sortMethod({ prop, order }) {
      if (order) {
        this.sortObject = {
          column: prop,
          order: order === 'descending' ? 'DESC' : 'ASC',
        }
      } else {
        this.sortObject = null
      }

      this.search(this.term, this.page)
    },

    handleBatchChange(selectedItems) {
      const ids = selectedItems.map((item) => item.id)
      this.$store.commit('orders/SET_BATCH_ACTION_IDS', ids)
    },

    handleRowStyle({ row, rowIndex }) {
      if (row.actionRequired === true) {
        return { 'background-color': '#f6f9fc' }
      }
    },

    async batchPrintOrders() {
      await this.$store.dispatch('orders/getOrders', {
        ids: this.batchActionIds,
      })
      await this.$store.dispatch('orders/printOrders', {
        ids: this.batchActionIds,
        status: true,
      })
      await this.search(this.term, this.page)
    },
    async batchUpdateReceptionBox(receptionBoxId) {
      await this.$store.dispatch('orders/updateReceptionBox', {
        ids: this.batchActionIds,
        receptionBoxId,
      })
      await this.search(this.term, this.page)
    },
    getItemsType(items) {
      const digitalTypes = [
        'DigitalWatermark',
        'DigitalAccessCode',
        'DigitalCoupon',
        'DigitalOther',
      ]

      const types = items.map((item) => {
        if (digitalTypes.includes(item.type)) {
          return 'DigitalProduct'
        } else {
          return item.type
        }
      })

      const uniqueTypes = [...new Set(types)]
      return uniqueTypes.filter((value) =>
        Object.keys(this.typesIcones).includes(value)
      )
    },
    receptionBoxName(receptionBox) {
      return receptionBox ? receptionBox.name : '-'
    },
  },
}
</script>

<style lang="sass" scoped>
.warning-icon
  color: var(--danger)
</style>
