<template>
  <div v-if="user">
    <div class="header pb-6 d-flex align-items-center profile-header">
      <!-- Mask -->
      <span class="mask bg-gradient-primary opacity-8" />
      <!-- Header container -->
      <b-container fluid>
        <b-row>
          <b-col sm="12">
            <h1 class="display-2 text-white">
              {{ `${user.firstName} ${user.lastName}` }}
              <p
                v-if="user.clientNumber && user.memberType !== 'regular'"
                class="text-white lead mt-0 mb-5 d-flex align-items-center"
              >
                <small class="member-number-label">Numéro de client :</small>
                <strong class="member-number">#{{ user.clientNumber }}</strong>
                -<span class="member-number-type">
                  {{ $t(`users.memberType.${user.memberType}`) }}
                </span>
              </p>
            </h1>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="5" class="order-xl-2">
          <card>
            <!-- Card body -->
            <b-row>
              <b-col>
                <h4 class="mb-0">
                  {{ `${user.firstName} ${user.lastName}` }}
                </h4>
                <p class="text-sm text-muted">
                  Type d'usager: {{ $t(`users.memberType.${user.memberType}`) }}
                </p>
                <p v-if="user.memberType === 'member'" class="text-sm text-muted">
                  Points Z: {{ `${user.loyaltyPoints}` }}
                </p>
                <span v-if="user.notifyBySms" class="text-success">● </span>
                <small v-if="user.notifyBySms">Notifier par SMS</small>
                <span v-if="!user.notifyBySms" class="text-danger">● </span>
                <small v-if="!user.notifyBySms">Ne pas notifier par SMS</small>
              </b-col>
              <b-col>
                <UserClientNumberEditForm />
              </b-col>
            </b-row>
          </card>
          <card v-if="user.createdAt" gradient="success" class="border-0">
            <b-row>
              <b-col>
                <h5
                  class="card-title text-uppercase text-muted mb-0 text-white"
                >
                  Création du compte
                </h5>
                <span class="h2 font-weight-bold mb-0 text-white">
                  {{ createdAt }}
                </span>
              </b-col>
              <b-col md="auto">
                <div
                  class="icon icon-shape bg-white text-dark rounded-circle shadow"
                >
                  <i class="ni ni-single-02" />
                </div>
              </b-col>
            </b-row>
          </card>
          <card v-if="user.updatedAt" gradient="primary" class="border-0">
            <b-row>
              <b-col>
                <h5
                  class="card-title text-uppercase text-muted mb-0 text-white"
                >
                  Dernière modification au compte
                </h5>
                <span class="h2 font-weight-bold mb-0 text-white">
                  {{ updatedAt }}
                </span>
              </b-col>
              <b-col md="auto">
                <div
                  class="icon icon-shape bg-white text-dark rounded-circle shadow"
                >
                  <i class="ni ni-check-bold" />
                </div>
              </b-col>
            </b-row>
          </card>
          <card v-if="user.lastLogin" gradient="default" class="border-0">
            <b-row>
              <b-col>
                <h5
                  class="card-title text-uppercase text-muted mb-0 text-white"
                >
                  Dernière connexion
                </h5>
                <span class="h2 font-weight-bold mb-0 text-white">
                  {{ lastLogin }}
                </span>
              </b-col>
              <b-col md="auto">
                <div
                  class="icon icon-shape bg-white text-dark rounded-circle shadow"
                >
                  <i class="ni ni-time-alarm" />
                </div>
              </b-col>
            </b-row>
          </card>
        </b-col>
        <b-col xl="7" class="order-xl-1">
          <UserEditForm />
          <UserRoleEditForm
            v-if="account && roles[account.role].includes('ROLES')"
          />
          <UserAddressesEditForm />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { Card } from '@coop-zone/coop-zone-argon'
import { mapState } from 'vuex'
import dayjs from 'dayjs'
import UserEditForm from '@/components/User/UserEditForm'
import UserAddressesEditForm from '@/components/User/UserAddressesEditForm'
import UserClientNumberEditForm from '@/components/User/UserClientNumberEditForm'
import UserRoleEditForm from '../../../components/User/UserRoleEditForm'

export default {
  components: {
    Card,
    UserEditForm,
    UserRoleEditForm,
    UserAddressesEditForm,
    UserClientNumberEditForm,
  },
  computed: {
    ...mapState('accounts', ['account']),
    ...mapState('permissions', ['roles']),
    ...mapState('users', ['user']),
    createdAt() {
      return dayjs(this.user.createdAt, 'fr').format('D MMMM YYYY')
    },
    updatedAt() {
      return dayjs(this.user.updatedAt, 'fr').format('D MMMM YYYY à HH:mm')
    },
    lastLogin() {
      return dayjs(this.user.lastLogin, 'fr').format('D MMMM YYYY à HH:mm')
    },
  },
  mounted() {
    this.$store.dispatch('users/getUser', this.$route.params.id)
  },
}
</script>
<style>
.profile-header {
  background-image: url(/img/brand/z.svg);
  background-size: contain;
  background-position: left top;
  background-repeat: no-repeat;
  min-height: 310px;
}
.card-profile-image img {
  width: 120px;
  height: 120px;
  background-color: white;
}
.member-number-label {
  display: inline-block;
  margin-right: 8px;
}
.member-number {
  margin-right: 8px;
}
.member-number-type {
  margin-left: 8px;
}
</style>
