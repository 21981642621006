<template>
  <el-select
    placeholder="Sélectionner un statut"
    :value="value"
    :disabled="disabled"
    @change="$emit('input', $event)"
  >
    <slot />
    <el-option
      v-for="{ id, name } in statuses"
      :key="id"
      :value="id"
      :label="name"
      :selected="id == value"
    >
      {{ name }}
    </el-option>
  </el-select>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    types: {
      type: Array,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('orders', ['statuses']),
  },
}
</script>
