import transporters from './transporters.module'
import rules from './rules.module'
import handlingFees from './handlingFees.module'

export default {
  namespaced: true,
  modules: {
    transporters,
    rules,
    handlingFees,
  },
}
