import BlankRouterView from '@/views/Layout/BlankRouterView'
import UsersSearch from '@/views/Dashboard/Users/UsersSearch'
import UserEdit from '@/views/Dashboard/Users/UserEdit'

export default {
  path: '/users',
  name: 'Utilisateurs',
  redirect: '/users/search',
  component: BlankRouterView,
  children: [
    {
      path: '/users/search',
      name: 'Rechercher un utilisateur',
      component: UsersSearch,
      meta: {
        roles: ['ADMIN', 'SUPER_EDITOR', 'MANAGER'],
      },
    },
    {
      path: '/users/edit/:id',
      name: 'Utilisateur',
      component: UserEdit,
      meta: {
        roles: ['ADMIN', 'SUPER_EDITOR', 'MANAGER'],
      },
    },
  ],
}
