var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('base-header',{staticClass:"pb-6 bg-default"},[_c('b-row',{staticClass:"py-4",attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"7","lg":"12"}},[_c('h6',{staticClass:"h2 text-white d-inline-block mb-0"},[_vm._v("Utilisateurs")]),_c('nav',{staticClass:"d-none d-md-inline-block ml-md-4",attrs:{"aria-label":"breadcrumb"}},[_c('RouteBreadcrumb')],1)])],1)],1),_c('b-container',{staticClass:"mt--6",attrs:{"fluid":""}},[_c('BaseTableSearch',{attrs:{"data":_vm.accounts,"pagination":_vm.pagination,"value":_vm.term,"data-cy":"search-user","placeholder":"Nom, prénom, # membre"},on:{"search":_vm.search},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('b-row',[_c('b-col',[_c('h3',{staticClass:"mb-0"},[_vm._v("Rechercher un utilisateur")])])],1)]},proxy:true},{key:"filters",fn:function(){return [_c('b-row',[_c('b-col',{attrs:{"sm":"12","xl":"4"}},[_c('BaseInput',{attrs:{"label":"Filtrer par type d'usager"}},[_c('el-select',{model:{value:(_vm.memberType),callback:function ($$v) {_vm.memberType=$$v},expression:"memberType"}},[_c('el-option',{attrs:{"value":null,"label":"Tous les types"}}),_vm._l((_vm.membershipTypes),function(ref){
var id = ref.id;
var name = ref.name;
return _c('el-option',{key:id,attrs:{"value":id,"label":name}})})],2)],1)],1),_c('b-col',{attrs:{"sm":"12","xl":"4"}},[_c('BaseInput',{attrs:{"label":"Filtrer par actif/inactif"}},[_c('el-select',{model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('el-option',{attrs:{"value":null,"label":"Tous"}}),_c('el-option',{attrs:{"value":true,"label":"Actif"}}),_c('el-option',{attrs:{"value":false,"label":"Inactif"}})],1)],1)],1),_c('b-col',{attrs:{"sm":"12","xl":"4"}},[_c('BaseInput',{attrs:{"label":"Rôle"}},[_c('el-select',{model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}},[_c('el-option',{attrs:{"value":null,"label":"Tous"}}),_vm._l((_vm.roles),function(optionRole){return _c('el-option',{key:optionRole.id,attrs:{"value":optionRole.id,"label":optionRole.name}})})],2)],1)],1)],1)]},proxy:true}])},[_c('el-table-column',{attrs:{"label":"Nom de l'utilisateur"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":("/users/edit/" + (scope.row.id))}},[_c('span',{staticClass:"font-weight-600 name text-sm mb-0"},[_vm._v(_vm._s(((scope.row.firstName) + " " + (scope.row.lastName))))])])]}}])}),_c('el-table-column',{attrs:{"label":"Création du compte"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return (row.createdAt)?[_vm._v(" "+_vm._s(_vm._f("dateFormat")(row.createdAt))+" ")]:undefined}}],null,true)}),_c('el-table-column',{attrs:{"formatter":_vm.clientNumber,"label":"Numéro de client"}}),_c('el-table-column',{attrs:{"label":"Type d'usager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$t(("users.memberType." + (row.memberType))))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"email","label":"Courriel"}}),_c('el-table-column',{attrs:{"label":"Role"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getRolesBySlug(row.role))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Actif","prop":"active"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.active)?_c('span',{staticClass:"badge text-uppercase badge-lg badge-success badge-pill"},[_vm._v(" Oui ")]):_c('span',{staticClass:"badge text-uppercase badge-lg badge-danger badge-pill"},[_vm._v(" Non ")])]}}])}),_c('el-table-column',{attrs:{"align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{staticClass:"table-action table-action-edit",attrs:{"to":("/users/edit/" + (scope.row.id))}},[_c('i',{staticClass:"fa fa-edit"})])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }