<template>
  <div class="content">
    <base-header class="pb-6 bg-default">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h2 text-white d-inline-block mb-0">Catalogue</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <BaseTableSearch
        :data="products"
        :pagination="pagination"
        data-cy="search-product"
        placeholder="Nom, sku, code produit"
        :value="term"
        @term="term = $event"
        @page="page = $event"
        @sort-change="sortMethod"
      >
        <template #header>
          <b-row>
            <b-col>
              <h3 class="mb-0">Rechercher un ensemble</h3>
            </b-col>
            <b-col class="text-right">
              <router-link
                class="btn btn-sm btn-primary"
                to="/catalog/bundles/add"
              >
                Ajouter un ensemble
              </router-link>
            </b-col>
          </b-row>
        </template>

        <el-table-column
          label="Nom de l'ensemble"
          min-width="160px"
          sortable="custom"
          prop="name.raw"
        >
          <template slot-scope="scope">
            <b-media no-body class="align-items-center">
              <router-link
                v-if="scope.row.images && scope.row.images.length > 0"
                :to="`/catalog/edit/${scope.row.id}-${scope.row.slug}`"
                class="avatar mr-3"
              >
                <img :src="scope.row.images[0].thumbnail" />
              </router-link>
              <b-media-body>
                <router-link
                  :to="`/catalog/bundles/edit/${scope.row.id}-${scope.row.slug}`"
                >
                  <span class="font-weight-600 name mb-0 text-sm">{{
                    scope.row.name
                  }}</span>
                </router-link>
              </b-media-body>
            </b-media>
          </template>
        </el-table-column>
        <el-table-column prop="sku" label="SKU" sortable="custom" />
        <el-table-column align="right">
          <template slot-scope="scope" class="table-actions">
            <router-link
              class="table-action table-action-edit"
              :to="`/catalog/bundles/edit/${scope.row.id}-${scope.row.slug}`"
            >
              <i class="fa fa-edit" />
            </router-link>

            <a
              :href="`${websiteUrl}/produit/${scope.row.id}-${scope.row.slug}`"
              class="table-action"
              target="_blank"
            >
              <i class="fa fa-eye" />
            </a>
            <a
              v-confirm-delete="{
                onConfirm: () => deleteBundle(scope.row),
                confirmText: `L'ensemble sera supprimé`,
                successText: `L'ensemble ${scope.row.name} a été supprimé`,
              }"
              href="#!"
              class="table-action table-action-delete"
              data-toggle="tooltip"
              data-original-title="Supprimer"
            >
              <i class="fas fa-trash" />
            </a>
          </template>
        </el-table-column>
      </BaseTableSearch>
    </b-container>
  </div>
</template>
<script>
import { BaseHeader, RouteBreadcrumb } from '@coop-zone/coop-zone-argon'
import { mapState } from 'vuex'
import BaseTableSearch from '@/components/BaseTableSearch'
import ErrorHandlerMixin from '@/mixins/errorHandler.mixin'
import getEnv from '@/utils/env'

export default {
  components: {
    BaseHeader,
    BaseTableSearch,
    RouteBreadcrumb,
  },
  mixins: [ErrorHandlerMixin],
  data() {
    return {
      websiteUrl: getEnv('VUE_APP_WEBSITE_URL'),
      term: '',
      page: 1,
      sortObject: null,
    }
  },
  computed: {
    ...mapState('products', ['pagination', 'products']),
  },
  watch: {
    term() {
      this.search()
    },
    page() {
      this.search()
    },
  },
  async created() {
    if (this.$route.query.term) {
      this.term = this.$route.query.term
    }
    await this.search()
  },
  methods: {
    sortMethod({ prop, order }) {
      if (order) {
        this.sortObject = {
          column: prop,
          order: order === 'descending' ? 'DESC' : 'ASC',
        }
      } else {
        this.sortObject = null
      }

      this.search()
    },
    async search() {
      try {
        await this.$store.dispatch('products/searchProducts', {
          types: ['Bundle'],
          search: this.term,
          page: this.page,
          limit: this.pagination.resultsPerPage,
          showAll: true,
          sort: this.sortObject,
        })

        if (this.term !== '' && this.term !== this.$route.query.term) {
          this.$router.push({
            path: this.$route.fullPath,
            query: { term: this.term },
          })
        } else if (this.$route.path !== this.$route.fullPath) {
          this.$router.push({ path: this.$route.path })
        }
      } catch (e) {
        console.log(e)
      }
    },
    async deleteBundle(bundle) {
      try {
        await this.$store.dispatch('products/deleteBundle', bundle)
        this.search('')
      } catch (error) {
        this.handleError(error)
        throw error
      }
    },
  },
}
</script>
