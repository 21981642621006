import { apolloClient } from '@/apollo'

const getZonesQueries = require('../apollo/queries/GetZones.gql')
const getZoneQuery = require('../apollo/queries/GetZone.gql')
const createZoneMutation = require('../apollo/mutations/CreateZone.gql')
const updateZoneMutation = require('../apollo/mutations/UpdateZone.gql')

const state = {
  zones: [],
  id: null,
  name: '',
  countries: [],
  states: [],
}

const actions = {
  async create({ state: { name = '' } }) {
    await apolloClient.query({
      query: createZoneMutation,
      variables: {
        zone: { name },
      },
    })
  },
  async update({ state: { id, name } }, payload) {
    await apolloClient.query({
      query: updateZoneMutation,
      variables: {
        id,
        zone: { name },
      },
    })
  },
  async getZoneData({ commit }, id) {
    const {
      data: { zone },
    } = await apolloClient.query({
      query: getZoneQuery,
      variables: {
        id,
      },
    })
    commit('SET_ZONE', zone)
  },
  async getAllZones({ commit }) {
    const {
      data: { zones },
    } = await apolloClient.query({
      query: getZonesQueries,
    })
    commit('SET_ZONES', zones)
  },
}
const mutations = {
  SET_ZONE(state, { id = null, name = '', countries, states }) {
    state.id = id
    state.name = name
    state.countries = countries
    state.states = states
  },
  SET_ZONES(state, payload) {
    state.zones = payload
  },
  SET_NAME(state, name) {
    state.name = name
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
