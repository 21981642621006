<i18n>
{
  "fr" : {
    "DeleteSuccess": "La boîte de réception a bien été supprimée"
  }
}
</i18n>

<template>
  <div class="content">
    <base-header class="pb-6 bg-default">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h2 text-white d-inline-block mb-0">
            Boîtes de réceptions
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <RouteBreadcrumb />
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-card no-body class="mb-4">
        <b-row slot="header" align-v="center">
          <b-col cols="8">
            <h3 class="mb-0">Liste des boîtes de réceptions</h3>
          </b-col>
          <b-col cols="4" class="text-right">
            <router-link
              class="btn btn-sm btn-primary"
              to="/reception-boxes/add"
              data-cy="deliveryrule-add-link"
            >
              Ajouter une boîte de réception
            </router-link>
          </b-col>
        </b-row>

        <el-table
          :data="receptionBoxes"
          row-key="id"
          header-row-class-name="thead-light"
        >
          <el-table-column width="100px" label="Priorité" prop="priority" />
          <el-table-column
            width="400px"
            label="Nom de la boîte de réception"
            prop="name"
          >
            <template slot-scope="{ row }">
              <router-link :to="`/reception-boxes/edit/${row.id}`">
                <span class="font-weight-600 name text-sm mb-0">{{
                  row.name
                }}</span>
              </router-link>
            </template>
          </el-table-column>
          <el-table-column width="200px" label="Succursale" prop="Store">
            <template v-if="row.Store" slot-scope="{ row }">
              {{ row.Store.name || '' }}
            </template>
          </el-table-column>
          <el-table-column min-width="75px" align="right">
            <template slot-scope="{ row }" class="table-actions">
              <router-link
                class="table-action table-action-edit"
                :to="`/reception-boxes/edit/${row.id}`"
              >
                <i class="fa fa-edit" />
              </router-link>
              <a
                v-if="row.deletable"
                v-confirm-delete="{
                  onConfirm: () => deleteReceptionBox(row),
                  confirmText:
                    'La boîte de réception sera supprimée définitivement',
                  successText: $t('DeleteSuccess'),
                }"
                href="#!"
                class="table-action table-action-delete"
                data-toggle="tooltip"
                data-original-title="Supprimer"
              >
                <i class="fas fa-trash" />
              </a>
            </template>
          </el-table-column>
        </el-table>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import { BaseHeader, RouteBreadcrumb } from '@coop-zone/coop-zone-argon'
import { mapState } from 'vuex'
import ErrorHandlerMixin from '@/mixins/errorHandler.mixin'

export default {
  components: {
    BaseHeader,
    RouteBreadcrumb,
  },
  mixins: [ErrorHandlerMixin],
  computed: {
    ...mapState('receptionBoxes', ['receptionBoxes']),
  },
  async created() {
    this.$store.dispatch('receptionBoxes/getAllReceptionBoxes')
  },
  methods: {
    async deleteReceptionBox(receptionBox) {
      try {
        await this.$store.dispatch(
          'receptionBoxes/deleteReceptionBox',
          receptionBox.id
        )
      } catch (error) {
        this.handleError(error)
        throw error
      }
    },
  },
}
</script>
