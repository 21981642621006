<template>
  <div class="avatar">
    <b-img v-if="file.thumbnail" :src="file.thumbnail" rounded />

    <span v-else class="loading">
      <i class="fas fa-circle-notch fa-spin" />
    </span>

    <b-button variant="danger" size="sm" @click="$emit('delete', file)">
      <i class="fas fa-trash" />
    </b-button>
  </div>
</template>

<script>
export default {
  props: {
    file: {
      type: [Object, File],
      required: true,
    },
  },
}
</script>

<style lang="sass" scoped>
.loading
  font-size: 30px

.avatar
  background-color: var(--lighter)
  height: 80px
  position: relative
  width: 80px

.avatar img
  max-height: 100%
  max-width: 100%
  object-fit: contain
  position: relative

button
  position: absolute
  bottom: 5px
  right: 5px
</style>
