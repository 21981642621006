import Vue from 'vue'
import uniqid from 'uniqid'
import { apolloClient } from '@/apollo'

const getSchoolListSectionsQuery = require('../../apollo/queries/GetSchoolListSections.gql')
const getSchoolListSectionsByTypeQuery = require('../../apollo/queries/GetSchoolListSectionByType.gql')
const getSchoolListSectionQuery = require('../../apollo/queries/GetSchoolListSection.gql')
const createSchoolListSectionMutation = require('../../apollo/mutations/CreateSchoolListSection.gql')
const updateSchoolListSectionMutation = require('../../apollo/mutations/UpdateSchoolListSection.gql')
const deleteSchoolListSectionMutation = require('../../apollo/mutations/DeleteSchoolListSection.gql')

const state = {
  sectionsMap: {},
  sectionsByTypeMap: {},
  id: null,
  name: null,
  establishmentType: null,
}

const actions = {
  async getSection({ commit }, id) {
    const {
      data: { section },
    } = await apolloClient.query({
      query: getSchoolListSectionQuery,
      variables: {
        sectionId: id,
      },
    })
    commit('SET_SECTION', section)
  },

  async getSections({ commit }) {
    const {
      data: { sections },
    } = await apolloClient.query({
      query: getSchoolListSectionsQuery,
    })
    commit('SET_SECTIONS', sections)
  },
  async getSectionsByType({ commit }, type) {
    const {
      data: { sections },
    } = await apolloClient.query({
      query: getSchoolListSectionsByTypeQuery,
      variables: {
        type,
      },
    })
    commit('SET_SECTIONS_BY_TYPE', sections)
  },

  async createSection({ state: { name, establishmentType }, commit }) {
    const {
      data: { newSection },
    } = await apolloClient.query({
      query: createSchoolListSectionMutation,
      variables: {
        schoolListSection: {
          name,
          establishmentType,
        },
      },
    })

    commit('ADD_SECTION', newSection)
  },

  async updateSection({ state: { id, name, establishmentType }, commit }) {
    const {
      data: { updatedSection },
    } = await apolloClient.query({
      query: updateSchoolListSectionMutation,
      variables: {
        sectionId: id,
        schoolListSection: {
          name,
          establishmentType,
        },
      },
    })

    commit('UPDATE_SECTION', updatedSection)
  },

  async deleteSection({ commit }, section) {
    await apolloClient.query({
      query: deleteSchoolListSectionMutation,
      variables: {
        sectionId: section.id,
      },
    })

    commit('REMOVE_SECTION', section)
  },
}

const mutations = {
  SET_NAME(state, payload) {
    state.name = payload
  },

  SET_ESTABLISHMENT_TYPE(state, payload) {
    state.establishmentType = payload
  },

  SET_SECTION(state, { id = null, name = null, establishmentType = null }) {
    state.id = id
    state.name = name
    state.establishmentType = establishmentType
  },

  SET_SECTIONS(state, payload) {
    state.sectionsMap = payload.reduce(
      (acc, section) => ({ ...acc, [section.id]: section }),
      {}
    )
  },

  SET_SECTIONS_BY_TYPE(state, payload) {
    state.sectionsByTypeMap = payload.reduce(
      (acc, section) => ({ ...acc, [section.id]: section }),
      {}
    )
  },

  ADD_SECTION(state, section) {
    Vue.set(state.sectionsMap, section.id, section)
  },

  UPDATE_SECTION(state, section) {
    Vue.set(state.sectionsMap, section.id, section)
  },

  REMOVE_SECTION(state, section) {
    Vue.delete(state.sectionsMap, section.id)
  },
}

const getters = {
  getSectionsByType: (state) => Object.values(state.sectionsByTypeMap),
  getSections: (state) => Object.values(state.sectionsMap),
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
