<template>
  <BasePageBlock v-bind="$props">
    <b-row>
      <b-col>
        <b-row>
          <b-col>
            <h3 class="heading-small text-muted">Titre du bloc</h3>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <BaseInput
              v-model="blocks[blockIndex].title"
              label="Texte du titre"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-row>
          <b-col>
            <h3 class="heading-small text-muted">Bouton</h3>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" sm="6">
            <BaseInput
              v-model="blocks[blockIndex].button.text"
              label="Texte du lien"
            />
          </b-col>

          <b-col cols="12" sm="6">
            <BaseInput
              v-model="blocks[blockIndex].button.url"
              label="Url du lien"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-row>
          <b-col>
            <h3 class="heading-small text-muted">Produits</h3>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <TagsInput v-model="blocks[blockIndex].childrens" label="Skus" />
            <ProductAutocomplete
              placeholder="Ajouter un SKU"
              @change="addChild"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </BasePageBlock>
</template>

<script>
import BasePageBlock from './BasePageBlock'
import { BaseInput, TagsInput } from '@coop-zone/coop-zone-argon'
import ProductAutocomplete from '@/components/ProductAutocomplete'
import blockMixin from '@/components/Blocks/mixins/block.mixin'

export default {
  components: {
    BasePageBlock,
    BaseInput,
    TagsInput,
    ProductAutocomplete,
  },
  mixins: [blockMixin],
  methods: {
    addChild({ sku }) {
      this.blocks[this.blockIndex].childrens.push(sku)
    },
  },
}
</script>
