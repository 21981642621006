<template>
  <BaseForm
    id="countryForm"
    success-message="Province enregistrée"
    :on-success="save"
  >
    <b-card>
      <div slot="header">
        <h6 class="text-uppercase text-muted ls-1 mb-1">
          {{ title }}
        </h6>
        <h2 class="mb-0">
          {{ name }}
        </h2>
      </div>
      <b-row>
        <b-col col-sm="3">
          <BaseInput
            v-model="name"
            data-cy="input-state-name"
            label="Nom"
            rules="required"
          />
        </b-col>
        <b-col col-sm="3">
          <BaseInput
            v-model="code"
            data-cy="input-state-iso"
            label="Code ISO"
            rules="required"
          />
        </b-col>
        <b-col col-sm="3">
          <BaseInput label="Pays" rules="required">
            <select v-model="CountryId" class="form-control">
              <option
                v-for="country in countries"
                :key="country.id"
                :value="country.id"
                :selected="CountryId === country.id"
              >
                {{ country.name }}
              </option>
            </select>
          </BaseInput>
        </b-col>
        <b-col col-sm="3">
          <BaseInput label="Zone">
            <select v-model="ZoneId" class="form-control">
              <option />
              <option
                v-for="zone in zones"
                :key="zone.id"
                :value="zone.id"
                :selected="zone.id === ZoneId"
              >
                {{ zone.name }}
              </option>
            </select>
          </BaseInput>
        </b-col>
      </b-row>
      <b-row align-h="end">
        <b-col cols="auto">
          <BaseButton size="lg" type="primary" native-type="submit">
            Sauvegarder
          </BaseButton>
        </b-col>
      </b-row>
    </b-card>
  </BaseForm>
</template>
<script>
import { mapState } from 'vuex'
import { BaseButton, BaseInput } from '@coop-zone/coop-zone-argon'
import BaseForm from '@/components/forms/BaseForm'

export default {
  components: {
    BaseForm,
    BaseButton,
    BaseInput,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('zones', ['zones']),
    ...mapState('countries', ['countries']),
    name: {
      get() {
        return this.$store.state.provinces.name
      },
      set(name) {
        this.$store.commit('provinces/SET_NAME', name)
      },
    },
    code: {
      get() {
        return this.$store.state.provinces.code
      },
      set(code) {
        this.$store.commit('provinces/SET_CODE', code)
      },
    },
    ZoneId: {
      get() {
        return this.$store.state.provinces.ZoneId
      },
      set(ZoneId) {
        this.$store.commit('provinces/SET_ZONE_ID', ZoneId)
      },
    },
    CountryId: {
      get() {
        return this.$store.state.provinces.CountryId
      },
      set(CountryId) {
        this.$store.commit('provinces/SET_COUNTRY_ID', CountryId)
      },
    },
  },
  created() {
    this.$store.commit('provinces/SET_PROVINCE', {})
    if (this.$route.params.id) {
      this.$store.dispatch('provinces/getProvince', this.$route.params.id)
    }
    this.$store.dispatch('zones/getAllZones')
    this.$store.dispatch('countries/getAllCountries')
  },
  methods: {
    save() {
      if (this.$route.params.id) {
        this.$store.dispatch('provinces/updateProvince')
      } else {
        this.$store.dispatch('provinces/createProvince')
      }
    },
  },
}
</script>
